import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

export const schema = Yup.object({
  praia: Yup.string().required('Campo obrigatório'),
  // gruposand: Yup.string().required('Campo obrigatório'),
  cleaning_date: Yup.string().required('Campo obrigatório'),
  type: Yup.string().required('Campo obrigatório'),
  extension_cleaned: Yup.number('Campo obrigatório').positive(),
  number_of_collected_garbage: Yup.number('Campo obrigatório').positive(),
  Estimated_total_weight_collected: Yup.number('Campo obrigatório').positive(),
  time_spent_cleaning: Yup.string().required('Campo obrigatório'),
  paper_bag: Yup.number().min(0),
  plastic_bag: Yup.number().min(0),
  balloons: Yup.number().min(0),
  plastic_bottles: Yup.number().min(0),
  glass_bottles: Yup.number().min(0),
  beverage_can: Yup.number().min(0),
  bottle_caps: Yup.number().min(0),
  clothing: Yup.number().min(0),
  cutlery: Yup.number().min(0),
  food_packaging: Yup.number().min(0),
  toy: Yup.number().min(0),
  straw: Yup.number().min(0),
  Cigarette: Yup.number().min(0),
  lighters: Yup.number().min(0),
  cigar_butts: Yup.number().min(0),
  Tobacco_Packaging: Yup.number().min(0),
  Home_appliances: Yup.number().min(0),
  battery: Yup.number().min(0),
  Construction_material: Yup.number().min(0),
  Car_Parts: Yup.number().min(0),
  fuel_Barrel: Yup.number().min(0),
  Tires: Yup.number().min(0),
  condoms: Yup.number().min(0),
  diapers: Yup.number().min(0),
  syringes: Yup.number().min(0),
  pads: Yup.number().min(0),
  Bait_packaging: Yup.number().min(0),
  buoys: Yup.number().min(0),
  plastic_tarpaulin: Yup.number().min(0),
  Nylon_ropes: Yup.number().min(0),
  Fishing_nets: Yup.number().min(0),
  fishing_lines: Yup.number().min(0),
  fishing_bait: Yup.number().min(0),
  package_of_cleaning_products: Yup.number().min(0),
  lubricating_oil: Yup.number().min(0),
  scotch_tapes: Yup.number().min(0),
  Traps: Yup.number().min(0),
  crates: Yup.number().min(0),
  wooden_pallet: Yup.number().min(0),
})
