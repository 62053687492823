import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import FloraService from '../../service/parametrizacao/Flora'

export const createFlora = createAsyncThunk('flora/create', async (data) => {
  const response = await FloraService.create(data)
  return response.data
})
export const retrieveFlora = createAsyncThunk('flora/retrieve', async () => {
  const response = await FloraService.retrieve()
  return response.data
})

export const updateFlora = createAsyncThunk('flora/update', async ({ data, id }) => {
  const response = await FloraService.update(data, id)
  return response.data
})
export const deleteFlora = createAsyncThunk('flora/delete', async (id) => {
  const response = await FloraService.delete(id)
  return response.data
})

const initialState = []

const floraSlice = createSlice({
  name: 'flora',
  initialState,
  extraReducers: {
    [createFlora.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveFlora.fulfilled]: (state, action) => [...action.payload],
    [updateFlora.fulfilled]: (state, action) => {
      const index = state.findIndex((flora) => flora.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteFlora.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default floraSlice.reducer
