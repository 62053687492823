import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { CChartBar } from '@coreui/react-chartjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { retrieveContagemAtividade } from 'src/store/reducers/contagemAtividadeSlice'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function ChartContagemAtividade({ praia, year }) {
  const dispatch = useDispatch()

  const contagemAtividades = useSelector((state) => state.contagemAtividades)

  const [data, setData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0])
  const { t } = useTranslation()

  useEffect(() => {
    const data = contagemAtividades.filter((resultado) => resultado.praia.id === praia)

    let sea_bathing = 0
    let sunbathing = 0
    let walking_and_or_running = 0
    let picnicking = 0
    let fishing = 0
    let playing = 0
    let windsurfing_kitsurfing = 0
    let horse_riding = 0
    let sitting = 0
    let other = 0

    data.map((item) => {
      sea_bathing += item.sea_bathing
      sunbathing += item.sunbathing
      walking_and_or_running += item.walking_and_or_running
      picnicking += item.picnicking
      fishing += item.fishing
      playing += item.playing
      windsurfing_kitsurfing += item.windsurfing_kitsurfing
      horse_riding += item.horse_riding
      sitting += item.sitting
      other += item.other
    })
    setData([
      sea_bathing,
      sunbathing,
      walking_and_or_running,
      picnicking,
      fishing,
      playing,
      windsurfing_kitsurfing,
      horse_riding,
      sitting,
      other,
    ])
  }, [contagemAtividades, praia])

  useEffect(() => {
    dispatch(retrieveContagemAtividade())
  }, [dispatch])

  return (
    <CCard className="mb-4">
      <CCardHeader className="fw-bold">{t('QUANTIDADE_ATIVIDADES')}</CCardHeader>
      <CCardBody>
        <CChartBar
          data={{
            labels: [
              t('BANHO'),
              t('TOMAR_SOL'),
              t('CAMINHANDO_CORRENDO'),
              t('PIQUENIQUES'),
              t('PESCARIA'),
              t('JOGANDO'),
              t('PASSEIO'),
              t('SENTADO'),
              t('OUTROS'),
            ],
            datasets: [
              {
                label: t('QUANTIDADE'),
                backgroundColor: '#FF7F47',

                borderRadius: 10,
                data,
              },
            ],
          }}
          labels="months"
        />
      </CCardBody>
    </CCard>
  )
}

ChartContagemAtividade.propTypes = {
  praia: PropTypes.any,
  year: PropTypes.any,
}
export default ChartContagemAtividade
