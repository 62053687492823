import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import GruposandService from '../../service/Gruposand'

export const createGruposand = createAsyncThunk('gruposand/create', async (data) => {
  const response = await GruposandService.create(data)
  return response.data
})
export const retrieveGruposand = createAsyncThunk('gruposand/retrieve', async () => {
  const response = await GruposandService.retrieve()
  return response.data
})

export const updateGruposand = createAsyncThunk('gruposand/update', async ({ data, id }) => {
  const response = await GruposandService.update(data, id)
  return response.data
})
export const deleteGruposand = createAsyncThunk('gruposand/delete', async (id) => {
  const response = await GruposandService.delete(id)
  return response.data
})

const initialState = []

const gruposandSlice = createSlice({
  name: 'gruposand',
  initialState,
  extraReducers: {
    [createGruposand.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveGruposand.fulfilled]: (state, action) => [...action.payload],
    [updateGruposand.fulfilled]: (state, action) => {
      const index = state.findIndex((gruposand) => gruposand.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteGruposand.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default gruposandSlice.reducer
