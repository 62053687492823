import axiosService from '../../axios'

class MedicaoCorrenteService {
  create(data) {
    return axiosService.post('/correntelitoraneas', data)
  }

  retrieve() {
    return axiosService.get('/correntelitoraneas')
  }

  update(data, id) {
    return axiosService.put(`/correntelitoraneas/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/correntelitoraneas/${id}`)
  }
}

export default new MedicaoCorrenteService()
