import React, { useEffect, useState } from "react";
import {
  CButton,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import UploadService from "src/service/Upload";
import { retrieveGruposand } from "src/store/reducers/gruposandSlice";
import { retrieveRoles } from "src/store/reducers/roleSlice";
import { createUser, updateUser } from "src/store/reducers/userSlice";
import { SUPER_ADMIN } from "src/utils/permission";
import { createItem } from "../../../store/reducers/itemSlice";

function EditUserDialog({ visible, setVisible, data }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.account);
  const gruposands = useSelector((state) => state.gruposands);
  const roles = useSelector((state) => state.roles);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [gruposand, setGruposand] = useState("");
  const [role, setRole] = useState("");

  const initState = () => {};

  const saveItem = async () => {

    const user = {
      username,
      email,
      role,
      gruposand,
    };

    dispatch(updateUser({ data: user, id: data.id }))
      .unwrap()
      .then(() => {
        Swal.fire({
          text: "Utilizador criado com sucesse!",
          icon: "success",
          confirmButtonColor: "#FF7F47",
          confirmButtonText: "Ok!",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
        setVisible(false);
      })
      .catch(() => {
        Swal.fire({
          text: "Utilizador não adicionado!",
          icon: "error",
          confirmButtonColor: "#FF7F47",
          confirmButtonText: "Ok!",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      });
  };

  useEffect(() => {
    if (data) {
      const role = roles.find((role) => role.name === data.role);

      if (data.grupo) {
        const gruposand = gruposands.find(
          (gruposand) => gruposand.name_grupo === data.grupo
        );
        setGruposand(gruposand.id);
      } else {
        setGruposand("");
      }
      setUsername(data.username);
      setEmail(data.email);
      setRole(role.id);
    }
  }, [data, roles, gruposands]);

  useEffect(() => {
    dispatch(retrieveGruposand());
    dispatch(retrieveRoles());
  }, [dispatch]);

  return (
    <CModal
      backdrop="static"
      alignment="center"
      visible={visible}
      size="lg"
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar utilizador</CModalTitle>
      </CModalHeader>

      <CModalBody>
        <CRow>
          <CCol lg={6}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">
                Username <strong className="text-danger">*</strong>
              </CFormLabel>
              <CFormInput
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                type="text"
                id="nome"
              />
            </div>
          </CCol>

          <CCol lg={6}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">
                Email <strong className="text-danger">*</strong>
              </CFormLabel>
              <CFormInput
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                id="nome"
              />
            </div>
          </CCol>

          {user && user.role.name === SUPER_ADMIN && (
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="nome">Grupo</CFormLabel>
                <CFormSelect
                  aria-label="Default select example"
                  value={gruposand}
                  onChange={(e) => setGruposand(e.target.value)}
                >
                  <option value="" disabled>
                    Selecionar grupo
                  </option>
                  {gruposands.map((grupo) => (
                    <option key={grupo.id} value={grupo.id}>
                      {grupo.name_grupo}
                    </option>
                  ))}
                </CFormSelect>
              </div>
            </CCol>
          )}
          {user && user.role.name === SUPER_ADMIN ? (
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="nome">Role</CFormLabel>
                <CFormSelect
                  aria-label="Default select example"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="" disabled>
                    Selecionar role
                  </option>
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </CFormSelect>
              </div>
            </CCol>
          ) : (
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="nome">Role</CFormLabel>
                <CFormSelect
                  aria-label="Default select example"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="" disabled>
                    Selecionar role
                  </option>
                  {roles.filter(role => role.name != SUPER_ADMIN).map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </CFormSelect>
              </div>
            </CCol>
          )}
        </CRow>
      </CModalBody>

      <CModalFooter>
        <CButton color="secondary" onClick={() => setVisible(false)}>
          Cancelar
        </CButton>

        <CButton onClick={saveItem} className="main-button" color="primary">
          Adicionar
        </CButton>
      </CModalFooter>
    </CModal>
  );
}
EditUserDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  data: PropTypes.object,
};

export default EditUserDialog;
