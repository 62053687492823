import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { PropTypes } from 'prop-types'

function PaginateItems({ children, itemsPerPage, items, setCurrentItems }) {
  const [pageCount, setPageCount] = useState(0)
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage
    if (items) {
      setCurrentItems(items.slice(itemOffset, endOffset))
      setPageCount(Math.ceil(items.length / itemsPerPage))
    }
    window.scrollTo(0, 0)
  }, [itemOffset, itemsPerPage, items, setCurrentItems])

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length
    setItemOffset(newOffset)
  }

  return (
    <>
      {children}
      <ReactPaginate
        nextLabel="próximo >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< anterior"
        className="paginate"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakLinkClassName="page-link"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  )
}
PaginateItems.propTypes = {
  items: PropTypes.any,
  children: PropTypes.node,
  itemsPerPage: PropTypes.number,
  setCurrentItems: PropTypes.func,
}

export default PaginateItems
