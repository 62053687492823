import axiosService from '../../axios'

class ResultadoAguaService {
  create(data) {
    return axiosService.post('/resultadoamostraaguas', data)
  }

  retrieve() {
    return axiosService.get('/resultadoamostraaguas')
  }

  update(data, id) {
    return axiosService.put(`/resultadoamostraaguas/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/resultadoamostraaguas/${id}`)
  }
}

export default new ResultadoAguaService()
