import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import ReactDatatable from '@ashvin27/react-datatable'
import { retrieveResultadoG, deleteResultadoG } from 'src/store/reducers/resultadoGSlice'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'
import SwalAlert from 'src/utils/SwalAlert'
import { ADMIN, SUPER_ADMIN } from 'src/utils/permission'
import EditResultadoGDialog from './EditResultadoGDialog'
import AddResultadoGDialog from './AddResultadoGDialog'
import { columns, config } from './tableConf'

function ResultadoG() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const resultadosG = useSelector((state) => state.resultadosG)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')
  const [currentItem, setCurrentItem] = useState({})
  const [role, setRole] = useState(null)

  const [data, setData] = useState([])

  const destroy = (record, index) => {
    SwalAlert.warning().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteResultadoG(record.id))
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success')
      }
    })
  }

  const changeItem = (record, index) => {
    setAction('edit')
    setCurrentItem(record)
    setVisible(true)
  }

  useEffect(() => {
    dispatch(retrieveResultadoG())
      .unwrap()
      .then(() => setLoading(false))
  }, [dispatch])

  useEffect(() => {
    if (user) {
      let datas = resultadosG

      if (user.role.name !== SUPER_ADMIN) {
        datas = resultadosG.filter(
          (resultado) => resultado.amostraareia.gruposand === user.gruposand.id,
        )
      }

      const data = datas.map((item, index) => ({
        index: index + 1,
        id: item.id,
        amostra: item.amostraareia.sample_number_sand,
        lote: item.amostraareia.batch_number_sand,
        Shape: item.Shape,
        Size: item.Size,
        Color: item.Color,
        sand_collect_date: moment(item.sand_collect_date).format('DD-MM-YYYY'),
      }))
      setData(data)
      setRole(user.role.name)
    }
  }, [resultadosG, user])
  return (
    <CContainer lg>
      <CRow className="mb-4 mt-4">
        {ADMIN.includes(role) && (
          <CCol>
            <CButton
              className="main-button"
              onClick={() => {
                setAction('add')
                setVisible(true)
              }}
            >
              Adicionar
            </CButton>
          </CCol>
        )}
      </CRow>
      <div className="table-responsive">
        <ReactDatatable
          config={config(user && role)}
          records={data}
          loading={loading}
          columns={columns(destroy, changeItem, role)}
        />
      </div>

      {action === 'add' ? (
        <AddResultadoGDialog visible={visible} setVisible={setVisible} />
      ) : action === 'edit' ? (
        <EditResultadoGDialog visible={visible} setVisible={setVisible} item={currentItem} />
      ) : (
        ''
      )}
    </CContainer>
  )
}
export default ResultadoG
