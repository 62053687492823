import React, { useEffect, useState } from 'react'

import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import TableWrapper from 'src/components/TableWrapper'
import ReactDatatable from '@ashvin27/react-datatable'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUser, retrieveUser } from 'src/store/reducers/userSlice'
import Swal from 'sweetalert2'
import { ADMIN, ADMIN_PRAIA } from 'src/utils/permission'
import AddUserDialog from './AddUserDialog'
import EditUserDialog from './EditUserDialog'
import { columns, config } from './tableConf'

const Users = () => {
  const dispatch = useDispatch()
  const users = useSelector((state) => state.users)
  const { user } = useSelector((state) => state.account)
  const [data, setData] = useState([])
  const [visible, setVisible] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [action, setAction] = useState('')

  const destroy = (record, index) => {
    Swal.fire({
      title: 'Apagar utilizador?',
      text: 'Ação não pode ser revertido!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF7F47',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, apagar!',
      cancelButtonText: 'Cancelar',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteUser(record.id))
        Swal.fire('Apagado!', 'Utilizador foi apagado.', 'success')
      }
    })
  }

  const editUser = (record, index) => {
    setAction('')
    setCurrentUser(record)
    setVisible(true)
  }

  useEffect(() => {
    if (user) {
      let newUser = users
      if (user.role.name === ADMIN_PRAIA) {
        newUser = users.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id)
      }

      const data = newUser.map((user, index) => ({
        id: user.id,
        index: index + 1,
        username: user.username,
        name: `${user.name ?? ""} ${user.last_name ?? ""}`,
        email: user.email,
        telefone: user.telefone,
        role: user.role.name,
        grupo: user.gruposand && user.gruposand.name_grupo,
      }))
      setData(data)
    }
  }, [users, user])

  useEffect(() => dispatch(retrieveUser()), [dispatch])
  return (
    <CContainer lg>
      <TableWrapper title="Lista Utilizadores">
        <CRow className="mb-4 ">
          <CCol>
            <CButton
              onClick={() => {
                setAction('add')
                setVisible(true)
              }}
              className="main-button"
            >
              Adicionar
            </CButton>
          </CCol>
        </CRow>
        <ReactDatatable config={config} records={data} columns={columns(destroy, editUser)} />
      </TableWrapper>
      {/* ItemDialog */}
      {action === 'add' ? (
        <AddUserDialog setVisible={setVisible} visible={visible} />
      ) : (
        <EditUserDialog setVisible={setVisible} visible={visible} data={currentUser} />
      )}
    </CContainer>
  )
}

export default Users
