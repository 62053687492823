import React from 'react'
import { cilPen, cilTrash, cilImage, cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { ADMIN, OBSERVADOR } from 'src/utils/permission'

export const config = (permission) => ({
  page_size: 10,
  length_menu: [10, 20, 50],
  show_filter: true,
  show_pagination: true,
  pagination: 'advance',
  filename: 'ContagemAtividade',
  button: {
    csv: ADMIN.includes(permission),
    print: ADMIN.includes(permission),
  },
  language: {
    length_menu: 'Mostrar _MENU_ registros por página',
    filter: 'Pesquisar ...',
    info: 'Mostrando _START_ a _END_ de _TOTAL_ entradas',
    pagination: {
      previous: 'Anterior',
      next: 'Próximo',
    },
  },
})

export const columns = (destroy, edit, see, permission) => [
  {
    key: 'index',
    text: '#',
    className: 'index',
    width: 20,
    align: 'center',
  },
  {
    key: 'date',
    text: 'Data',
    className: 'name',
    align: 'left',
    width: 200,
  },
  {
    key: 'praia',
    text: 'Praia',
    className: 'address',
    align: 'left',
  },
  {
    key: 'grupo',
    text: 'Grupo',
    className: 'address',
    align: 'left',
  },
  {
    key: 'time',
    text: 'Tempo',
    className: 'address',
    align: 'left',
  },
  {
    key: 'total',
    text: 'Total',
    className: 'address',
    align: 'left',
  },
  {
    key: 'action',
    text: 'Action',
    className: 'postcode',
    align: 'center',
    width: 150,
    cell: (record, index) => (
        <>
          <button
            className="btn btn-success btn-sm"
            onClick={() => see(record, index)}
            style={{ marginRight: '5px' }}
          >
            <CIcon name={cilPlus} />
          </button>
          {permission !== OBSERVADOR && (
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => edit(record, index)}
              style={{ marginRight: '5px' }}
            >
              <CIcon name={cilPen} />
            </button>
          )}

          {ADMIN.includes(permission) && (
            <button className="btn btn-danger btn-sm" onClick={() => destroy(record, index)}>
              <CIcon name={cilTrash} />
            </button>
          )}
        </>
      ),
  },
]
