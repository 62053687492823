import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ItemPraiaService from '../../service/ItemPraia'

export const createItemPraia = createAsyncThunk('itemPraia/create', async (data) => {
  const response = await ItemPraiaService.create(data)
  return response.data
})
export const retrieveItemPraia = createAsyncThunk('itemPraia/retrieve', async () => {
  const response = await ItemPraiaService.retrieve()
  return response.data
})

export const updateItemPraia = createAsyncThunk('itemPraia/update', async ({ data, id }) => {
  const response = await ItemPraiaService.update(data, id)
  return response.data
})
export const deleteItemPraia = createAsyncThunk('itemPraia/delete', async (id) => {
  const response = await ItemPraiaService.delete(id)
  return response.data
})

const initialState = []

const itemPraiaSlice = createSlice({
  name: 'itemPraia',
  initialState,
  extraReducers: {
    [createItemPraia.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveItemPraia.fulfilled]: (state, action) => [...action.payload],
    [updateItemPraia.fulfilled]: (state, action) => {
      const index = state.findIndex((itemPraia) => itemPraia.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteItemPraia.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})
export default itemPraiaSlice.reducer
