/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import amilcarImg from 'src/assets/images/team/Amilcar.jpg'
import americoImg from 'src/assets/images/team/americo.jpg'
import hernaniImg from 'src/assets/images/team/hernani.jpg'
import olavoImg from 'src/assets/images/team/olavo.jpg'
import humbertoImg from 'src/assets/images/team/humberto.jpg'
import arianaImg from 'src/assets/images/team/ariana.jpg'
import adeltonImg from 'src/assets/images/team/adelton.jpg'
import flaviaImg from 'src/assets/images/team/flavia.jpg'
import rubenImg from 'src/assets/images/team/ruben.jpg'
import noneImg from 'src/assets/images/team/none.jpg'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import loader from '../../../assets/loader.json'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const teamWrapper = {
  items: 3,
  loop: true,
  margin: 25,
  smartSpeed: 1500,
  autoplay: false,
  dots: false,
  nav: true,
  navText: ["<i class='fas fa-chevron-left'></i>", "<i class='fas fa-chevron-right'></i>"],
  responsive: {
    0: {
      items: 1,
      nav: false,
      dots: false,
    },
    600: {
      items: 2,
      nav: false,
      dots: false,
    },
    1000: {
      items: 3,
      dots: false,
      nav: true,
      loop: true,
    },
  },
}

function Devs() {
  return (
    <>
      <br />
      <br />
      <br />
      <div className="team-area pt-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="team-wrap">
                <h2>Team</h2>
                <ul className="team-links">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>{' '}
                    <i className="fas fa-chevron-right" />
                  </li>
                  <li>Team</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="team-wrapper mt-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="section-head head-left pb-40">
                <h5>Leader Team</h5>
                <h2>Leaders</h2>
              </div>
            </div>
          </div>

          <OwlCarousel className="team-slider owl-carousel" {...teamWrapper}>
            <div className="team-card">
              <div className="team-thumb">
                <img src={hernaniImg} alt="" className="img-fluid" />
                <div className="team-info">
                  <strong>Hernâni Chantre</strong>
                  <p>Team Leader</p>
                  <ul className="team-links">
                    <li>
                      <a href="mailto:hernani.chantre@docente.unicv.edu.cv" target="_blank">
                        <i className="far fa-envelope" />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/hernâni-d-chantre-42576a19/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                      <a href="https://github.com/hdchantre" target="_blank">
                        <i className="fab fa-github" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="team-card">
              <div className="team-thumb">
                <img src={olavoImg} alt="" className="img-fluid" />
                <div className="team-info">
                  <strong>José Olavo</strong>
                  <p>Analista de Sistema</p>
                  <ul className="team-links">
                    <li>
                      <a href="mailto:joseo.teixeira@docente.unicv.edu.cv" target="_blank">
                        <i className="far fa-envelope" />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/josé-olavo-da-paz-teixeira-b83230138/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                      <a href="#" target="_blank">
                        <i className="fab fa-github" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="team-card">
              <div className="team-thumb">
                <img src={noneImg} alt="" className="img-fluid" />
                <div className="team-info">
                  <strong>Marcelo Correia</strong>
                  <p>Analista de Segurança</p>
                  <ul className="team-links">
                    <li>
                      <a href="mailto:barrosco@yahoo.de" target="_blank">
                        <i className="far fa-envelope" />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/marcelo-correia-97637b129/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                      <a href="#" target="_blank">
                        <i className="fab fa-github" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
      <br />

      <div className="team-wrapper mt-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="section-head head-left pb-40">
                <h5>Team Developers</h5>
                <h2>Developers</h2>
              </div>
            </div>
          </div>

          <OwlCarousel className="team-slider owl-carousel" {...teamWrapper}>
            <div className="team-card">
              <div className="team-thumb">
                <img src={amilcarImg} alt="" className="img-fluid" />
                <div className="team-info">
                  <strong>Amilcar Júnior</strong>
                  <p>FullStack Developer</p>
                  <ul className="team-links">
                    <li>
                      <a href="mailto:amilcarjunior2000@gmail.com" target="_blank">
                        <i className="far fa-envelope" />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/amilcar-júnior-4b7b1b22b/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                      <a href="https://github.com/Amilcar-Junior" target="_blank">
                        <i className="fab fa-github" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="team-card">
              <div className="team-thumb">
                <img src={americoImg} alt="" className="img-fluid" />
                <div className="team-info">
                  <strong>Américo Silva</strong>
                  <p>FullStack Developer</p>
                  <ul className="team-links">
                    <li>
                      <a href="mailto:americo.silva@student.unicv.edu.cv" target="_blank">
                        <i className="far fa-envelope" />
                      </a>
                      <a href="https://www.linkedin.com/in/leomiradev/" target="_blank">
                        <i className="fab fa-linkedin" />
                      </a>
                      <a href="https://github.com/leorodrigues10" target="_blank">
                        <i className="fab fa-github" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="team-card">
              <div className="team-thumb">
                <img src={adeltonImg} alt="" className="img-fluid" />
                <div className="team-info">
                  <strong>Adelton da Silva</strong>
                  <p>FullStack Developer</p>
                  <ul className="team-links">
                    <li>
                      <a href="mailto:adeltonair@gmail.com" target="_blank">
                        <i className="far fa-envelope" />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/adelton-silva-873518180/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                      <a href="https://github.com/Adelton-Silva" target="_blank">
                        <i className="fab fa-github" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="team-card">
              <div className="team-thumb">
                <img src={flaviaImg} alt="" className="img-fluid" />
                <div className="team-info">
                  <strong>Flavia de Pina</strong>
                  <p>FullStack Developer</p>
                  <ul className="team-links">
                    <li>
                      <a href="mailto:flaviadianap.fdp@gmail.com" target="_blank">
                        <i className="far fa-envelope" />
                      </a>
                      <a href="#" target="_blank">
                        <i className="fab fa-linkedin" />
                      </a>
                      <a href="https://github.com/flavia16" target="_blank">
                        <i className="fab fa-github" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="team-card">
              <div className="team-thumb">
                <img src={humbertoImg} alt="" className="img-fluid" />
                <div className="team-info">
                  <strong>Humberto Graça</strong>
                  <p>FullStack Developer</p>
                  <ul className="team-links">
                    <li>
                      <a href="mailto:humbertograca.unicv@gmail.com" target="_blank">
                        <i className="far fa-envelope" />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/humberto-graça-3b1860212/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                      <a href="https://github.com/HumbertoProg" target="_blank">
                        <i className="fab fa-github" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="team-card">
              <div className="team-thumb">
                <img src={rubenImg} alt="" className="img-fluid" />
                <div className="team-info">
                  <strong>Rubem Correia</strong>
                  <p>Design web e multimédia</p>
                  <ul className="team-links">
                    <li>
                      <a href="mailto:rubencorreia.unicv@gmail.com" target="_blank">
                        <i className="far fa-envelope" />
                      </a>
                      <a href="#" target="_blank">
                        <i className="fab fa-linkedin" />
                      </a>
                      <a href="https://github.com/rubenrivaldo" target="_blank">
                        <i className="fab fa-github" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="team-card">
              <div className="team-thumb">
                <img src={arianaImg} alt="" className="img-fluid" />
                <div className="team-info">
                  <strong>Ariana Horta</strong>
                  <p>FullStack Developer</p>
                  <ul className="team-links">
                    <li>
                      <a href="mailto:arianahorta.unicv@gmail.com" target="_blank">
                        <i className="far fa-envelope" />
                      </a>
                      <a href="#" target="_blank">
                        <i className="fab fa-linkedin" />
                      </a>
                      <a href="https://github.com/ArianMendes" target="_blank">
                        <i className="fab fa-github" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </>
  )
}

export default Devs
