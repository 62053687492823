import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AtividadeHumanaService from '../../service/monitorizacao/AtividadeHumana'

export const createAtividadeHumana = createAsyncThunk('atividadeHumana/create', async (data) => {
  const response = await AtividadeHumanaService.create(data)
  return response.data
})
export const retrieveAtividadeHumana = createAsyncThunk('atividadeHumana/retrieve', async () => {
  const response = await AtividadeHumanaService.retrieve()
  return response.data
})

export const deleteAtividadeHumana = createAsyncThunk('atividadeHumana/delete', async (id) => {
  const response = await AtividadeHumanaService.delete(id)
  return response.data
})

export const updateAtividadeHumana = createAsyncThunk(
  'atividadeHumana/update',
  async ({ data, id }) => {
    const response = await AtividadeHumanaService.update(data, id)
    return response.data
  },
)

const initialState = []

const atividadeHumanaSlice = createSlice({
  name: 'AtividadeHumana',
  initialState,
  extraReducers: {
    [createAtividadeHumana.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveAtividadeHumana.fulfilled]: (state, action) => [...action.payload],
    [updateAtividadeHumana.fulfilled]: (state, action) => {
      const index = state.findIndex((AtividadeHumana) => AtividadeHumana.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteAtividadeHumana.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default atividadeHumanaSlice.reducer
