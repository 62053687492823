import axiosService from '../axios'

class ProjetoService {
  create(data) {
    return axiosService.post('/projetoiniciativas', data)
  }

  retrieve() {
    return axiosService.get('/projetoiniciativas')
  }

  update(data, id) {
    return axiosService.put(`/projetoiniciativas/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/projetoiniciativas/${id}`)
  }
}

export default new ProjetoService()
