import { CContainer, CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react'
import { use } from 'i18next'
import React, { useState } from 'react'
import TableWrapper from 'src/components/TableWrapper'
import Recolha from './Atividade'
import Resultado from './Contagem'

function AtividadesHumanas() {
  const [activeKey, setActiveKey] = useState(1)
  return (
    <CContainer>
      <TableWrapper title="Lista Atividades Humanas">
        <CNav variant="tabs">
          <CNavItem>
            <CNavLink
              className="tab-nav-link"
              active={activeKey === 1}
              onClick={() => setActiveKey(1)}
              href="#"
            >
              Atividade
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink
              className="tab-nav-link"
              active={activeKey === 2}
              onClick={() => setActiveKey(2)}
              href="#"
            >
              Contagem
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          <CTabPane visible={activeKey === 1}>
            <Recolha />
          </CTabPane>
          <CTabPane visible={activeKey === 2}>
            <Resultado />
          </CTabPane>
        </CTabContent>
      </TableWrapper>
    </CContainer>
  )
}
export default AtividadesHumanas
