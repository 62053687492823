import { CButton, CCol, CContainer, CFormLabel, CHeaderDivider, CRow } from '@coreui/react'
import React from 'react'
import PropTypes from 'prop-types'
import GalleryImage from 'src/components/ImageGallery'
import CIcon from '@coreui/icons-react'
import { cilXCircle } from '@coreui/icons'
import { CDatePicker, CTimePicker } from '@coreui/react-pro'

function Info({ currentPraia, setVisible }) {
  return (
    <CContainer lg className="pt-4">
      <CRow>
        {currentPraia.image_praia.length ? (
          <CCol lg={6} className="gallery-content">
            <GalleryImage items={currentPraia.image_praia} />
          </CCol>
        ) : (
          ''
        )}
        <CCol lg={6} className="p-4">
          <CRow className="mb-3">
            <CFormLabel className="col-sm-4 lab">Nome:</CFormLabel>
            <CCol sm={8}>
              <CFormLabel>{currentPraia.name_praia ? currentPraia.name_praia : 'N/A'}</CFormLabel>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CFormLabel className="col-sm-4 lab">Concelho:</CFormLabel>
            <CCol sm={8}>
              <CFormLabel>
                {currentPraia.concelho ? currentPraia.concelho.name_concelho : 'N/A'}
              </CFormLabel>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CFormLabel className="col-sm-4 lab">Localidade:</CFormLabel>
            <CCol sm={8}>
              <CFormLabel>{currentPraia.district ? currentPraia.district : 'N/A'}</CFormLabel>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CFormLabel className="col-sm-4 lab">Classificação:</CFormLabel>
            <CCol sm={8}>
              <CFormLabel>
                {currentPraia.classificacao ? currentPraia.classificacao.rate : 'N/A'}
              </CFormLabel>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CFormLabel className="col-sm-4 lab">Salva vidas:</CFormLabel>
            <CCol sm={8}>
              <CFormLabel>{currentPraia.saves_lives ? currentPraia.saves_lives : 'N/A'}</CFormLabel>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CFormLabel className="col-sm-4 lab">Grupo:</CFormLabel>
            <CCol sm={8}>
              <CFormLabel>
                {currentPraia.gruposand ? currentPraia.gruposand.name_grupo : 'N/A'}
              </CFormLabel>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CFormLabel className="col-sm-4 lab">Melhor Horário:</CFormLabel>
            <CCol sm={8}>
              <CFormLabel>
                {currentPraia.time_end
                  ? `${currentPraia.time_start.split('.')[0] 
                    } - ${ 
                    currentPraia.time_end.split('.')[0]}`
                  : 'N/A'}
              </CFormLabel>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow className="p-3">
        <CFormLabel className="col-sm-1 lab">Descricao:</CFormLabel>
        <CCol sm={11}>
          <CFormLabel className="description">
            {currentPraia.description ? currentPraia.description : 'N/A'}
          </CFormLabel>
        </CCol>
      </CRow>
      {/*  <CRow className="pt-2 ps-3">
        <h4 className="col-sm-12 lab">Informação histórica:</h4>
      </CRow>
      <CRow className="ps-4">
        <CFormLabel className="col-sm-1 lab">Descrição:</CFormLabel>
        <CCol sm={11}>
          <CFormLabel className="description">
            {currentPraia.historic_description ? currentPraia.historic_description : 'N/A'}
          </CFormLabel>
        </CCol>
      </CRow>
      <CRow className="ps-4">
        <CCol lg={6}>
          <CRow className="mb-3">
            <CFormLabel className="col-sm-1 lab">Data:</CFormLabel>
            <CCol sm={11}>
              <CFormLabel>{currentPraia.time ? currentPraia.time : 'N/A'}</CFormLabel>
            </CCol>
          </CRow>
        </CCol>
        <CCol lg={6}>
          <CRow className="mb-3">
            <CFormLabel className="col-sm-1 lab">Fonte:</CFormLabel>
            <CCol sm={11}>
              <CFormLabel>{currentPraia.source ? currentPraia.source : 'N/A'}</CFormLabel>
            </CCol>
          </CRow>
        </CCol>
        {currentPraia.image_praia.length ? <GalleryImage items={currentPraia.image_praia} /> : ''}
      </CRow> */}
      <CHeaderDivider />
      <CRow className="d-flex justify-content-end">
        <CCol lg={2}>
          <CButton onClick={() => setVisible(false)} color="secondary" className="w-100">
            <CIcon icon={cilXCircle} className="me-2" />
            Fechar
          </CButton>
        </CCol>
      </CRow>
    </CContainer>
  )
}

Info.propTypes = {
  currentPraia: PropTypes.object,
  setVisible: PropTypes.func,
}
export default Info
