import * as Yup from 'yup'

export const schema = Yup.object({
  praia: Yup.string().required('Campo obrigatório'),
  // gruposand: Yup.string().required('Campo obrigatório'),
  type_tartarugas: Yup.string().required('Campo obrigatório'),
  date: Yup.string().required('Campo obrigatório'),
  date_of_emergence_of_hatchlings: Yup.string().required('Campo obrigatório'),
  number_of_crab_holes_per_square_metre: Yup.number()
    .positive('33...')
    .min(0.1, 'Valor minimo 1')
    .required('Campo obrigatório'),
  distance_of_edge_of_the_vegetation: Yup.number()
    .positive('33...')
    .min(0.1, 'Valor minimo 1')
    .required('Campo obrigatório'),
  observation_of_crab_holes: Yup.string(),
  comments: Yup.string(),
  maciez: Yup.string().required('Campo obrigatório'),
  sand_observations: Yup.string(),
})
