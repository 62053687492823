import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useAuth from '../../../hooks/useAuth'
import { schema } from './validation'

const Login = (props) => {
  const { t } = useTranslation()
  const { login } = useAuth()
  const [message, setMessage] = useState(null)
  const [validated, setValidated] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const onSubmit = async (data) => {
    try {
      await login(data)
    } catch (error) {
      setMessage('Credenciais incorretos')
    }
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={7} lg={5}>
              <CCardGroup>
                <CCard className="p-4 rounded-2">
                  <CCardBody>
                    <CForm
                      autoComplete="off"
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <h3>{t('loginTitle')}</h3>
                      <p className="text-medium-emphasis">{t('loginSubtitle')}</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput
                          autoComplete="identifier"
                          type="text"
                          placeholder={t('loginUserPlaceHolder')}
                          {...register('identifier')}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-2">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          autoComplete="false"
                          type="password"
                          placeholder={t('loginPassPlaceHolder')}
                          {...register('password')}
                        />
                      </CInputGroup>

                      <small style={{ color: 'red' }}>{message}</small>
                      <CRow className="mt-2">
                        <CCol xs={6}>
                          <CButton type="submit" className="px-4 main-button">
                            {' '}
                            {t('loginButton')}
                          </CButton>
                        </CCol>
                        {/* <CCol xs={6} className="text-right">
                          <Link to="recuperar-palavra-passe" className="px-0 forgot-pass">
                            {t('loginForgotPass')}
                          </Link>
                        </CCol> */}
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
  )
}

export default Login
