import React, { useState, useEffect } from 'react';
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import { cilSave, cilXCircle } from '@coreui/icons';
import { updateGruposand } from 'src/store/reducers/gruposandSlice';
import SwalAlert from 'src/utils/SwalAlert';

function EditGruposandDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch();

  const [name_grupo, setName_grupo] = useState();
  const [descripition, setDescripition] = useState();

  const initState = () => {};

  const saveGruposand = async (e) => {
    e.preventDefault();

    const info = {
      name_grupo,
      descripition,
    };
    dispatch(
      updateGruposand({
        data: info,
        id: item.id,
      })
    )
      .unwrap()
      .then(() => {
        setVisible(false);
        SwalAlert.success();
      })
      .catch(() => {
        SwalAlert.error();
      });
  };

  useEffect(() => {
    if (item) {
      setName_grupo(item.grupo);
      setDescripition(item.desc);
    }
  }, [item]);

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar Grupo Sandwatch</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={saveGruposand}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Nome</CFormLabel>
                <CFormInput
                  type="text"
                  id="item"
                  required
                  value={name_grupo}
                  defaultValue={name_grupo}
                  onChange={(e) => setName_grupo(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Descrição</CFormLabel>
                <CFormInput
                  type="descripition"
                  id="item"
                  value={descripition}
                  defaultValue={descripition}
                  onChange={(e) => setDescripition(e.target.value)}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  );
}
EditGruposandDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
};

export default EditGruposandDialog;
