import Swal from 'sweetalert2'

class SwalAlert {
  success() {
    Swal.fire({
      text: 'Registro adicionado com sucesso!',
      icon: 'success',
      confirmButtonColor: '#FF7F47',
      confirmButtonText: 'Ok!',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    })
  }

  successDelete() {
    Swal.fire({
      text: 'Registro apagado com sucesso!',
      icon: 'success',
      confirmButtonColor: '#FF7F47',
      confirmButtonText: 'Ok!',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    })
  }

  errorDelete() {
    Swal.fire({
      text: 'Registro nao apagado!',
      icon: 'error',
      confirmButtonColor: '#FF7F47',
      confirmButtonText: 'Ok!',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    })
  }

  errorSave() {
    Swal.fire({
      text: 'Por favor, preencha todos os campos!',
      icon: 'error',
      confirmButtonColor: '#FF7F47',
      confirmButtonText: 'Ok!',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    })
  }

  error() {
    Swal.fire({
      text: 'Registo nao adicionado!',
      icon: 'error',
      confirmButtonColor: '#FF7F47',
      confirmButtonText: 'Ok!',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    })
  }

  warning() {
    return Swal.fire({
      title: 'Eliminar Registo?',
      text: 'Ação não pode ser revertido!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF7F47',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, eliminar!',
      cancelButtonText: 'Cancelar',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    })
  }
}
export default new SwalAlert()
