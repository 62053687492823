import axiosService from '../../axios'

class FloraPraiaService {
  create(data) {
    return axiosService.post('/florapraias', data)
  }

  retrieve() {
    return axiosService.get('/florapraias')
  }

  update(data, id) {
    return axiosService.put(`/florapraias/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/florapraias/${id}`)
  }
}

export default new FloraPraiaService()
