import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AuthService from '../../service/Auht'

export const updateProfile = createAsyncThunk('profile/update', async ({ data, id }) => {
  const response = await AuthService.update(data, id)
  return response.data
})

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    initAccount: (state, action) => {
      const { user, isLoggedIn } = action.payload
      state.user = user
      state.isLoggedIn = true
      state.isInitialized = true
    },
    logInAccount: (state, action) => {
      const user = action.payload

      state.user = user
      state.isLoggedIn = true
      state.isInitialized = true
    },
    logOutAccount: (state, action) => {
      state.user = null
      state.isLoggedIn = false
      state.isInitialized = false
    },
  },
  extraReducers: {
    [updateProfile.fulfilled]: (state, action) => {
      state.user = action.payload
    },
  },
})

export const { initAccount, logInAccount, logOutAccount } = accountSlice.actions
export default accountSlice.reducer
