import { CCol } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import { useDispatch, useSelector } from 'react-redux'
import { useParams , Link } from 'react-router-dom'
import { retrieveIlhas } from 'src/store/reducers/ilhaSlice'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import { retrieveProjeto } from 'src/store/reducers/projetoSlice'
import moment from 'moment'
import projeto_prev from '../../../assets/images/projeto_prev.png'
import loader from '../../../assets/loader.json'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

function ProjetoDetails() {
  const { id } = useParams()
  const dispatch = useDispatch()

  const projetos = useSelector((state) => state.projetos)
  const [projeto, setProjeto] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const projeto = projetos.find((projeto) => projeto.id === parseInt(id, 10))
    setProjeto(projeto)
    console.log(projeto)
  }, [projetos, id])

  const praias = useSelector((state) => state.praias)
  const ilhas = useSelector((state) => state.ilhas)
  const { map } = useSelector((state) => state.map)
  const [praia, setPraia] = useState(null)
  const [ilha, setIlha] = useState(null)

  useEffect(() => {
    dispatch(retrieveProjeto())
      .unwrap()
      .then(() => setLoading(false))
  }, [dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const ApiUrl = process.env.REACT_APP_SERVER_URL

  useEffect(() => {
    if (praia && map) {
      if (praia.coordinates) {
        const { lat, lng } = praia.coordinates
        map.flyTo([lat, lng], 14)
      }
    }
  }, [praia, map])

  useEffect(() => {
    const praia = praias.find((praia) => praia.id === parseInt(id, 10))
    setPraia(praia)
  }, [praias, id])

  useEffect(() => {
    if (praia) {
      const ilha = ilhas.find((ilha) => ilha.id === praia.concelho.ilha)
      setIlha(ilha)
    }
  }, [praia, ilhas])

  useEffect(() => {
    dispatch(retrievePraias())
      .unwrap()
      .then(() => setLoading(false))
    dispatch(retrieveIlhas())
  }, [dispatch])

  return (
    <div className="projeto-details-wrapper pt-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {loading ? (
                <CCol xs={12}>
                  <Lottie
                    options={defaultOptions}
                    height={100}
                    width={100}
                    isStopped={false}
                    isPaused={false}
                  />
                </CCol>
              ) : (
                <div className="projeto-details">
                  <div className="projeto-title-xl">
                    <h3>{projeto.name}</h3>
                    <div className="projeto-info-xl">
                      <Link to="#" className="projeto-writer">
                        <i className="fas fa-users" />
                        <strong>{projeto.gruposand && projeto.gruposand.name_grupo}</strong>
                      </Link>
                    </div>
                  </div>
                  <div className="projeto-img-xl">
                    <img
                      src={projeto.img ? ApiUrl + projeto.img.url : projeto_prev}
                      alt=""
                      className="img-fluid"
                    />
                    <div className="projeto-date">
                      <i className="fas fa-calendar" />
                      {moment(projeto.start_date).format('DD/MM/YYYY')} -{' '}
                      {moment(projeto.end_date).format('DD/MM/YYYY')}
                    </div>
                  </div>
                  <div className="projeto-texts mt-30">
                    <br />
                    <br />

                    <h3 className="sub-title">Objetivos</h3>
                    <strong className="mt-20">
                      {projeto.objective ? projeto.objective : 'Nenhum'}
                    </strong>
                    <br />
                    <br />

                    <h3 className="sub-title">Fundos</h3>
                    <strong className="mt-20">
                      {projeto.background ? projeto.background : '0'} Esc
                    </strong>
                    <br />
                    <br />
                    <h3 className="sub-title">Atividades planejadas</h3>
                    <strong className="mt-20">
                      {projeto.planned_activities ? projeto.planned_activities : 'Nenhum'}
                    </strong>
                    <br />
                    <br />
                    <h3 className="sub-title">Recursos Necessarios</h3>
                    <strong className="mt-20">
                      {projeto.resources_needed ? projeto.resources_needed : 'Nenhum'}
                    </strong>
                    <br />
                    <br />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
  )
}

export default ProjetoDetails
