import {
  CFormLabel,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react'
import { cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { deleteItemPraia } from 'src/store/reducers/itemPraiaSlice'
import Swal from 'sweetalert2'
import SwalAlert from 'src/utils/SwalAlert'
import { ADMIN, SUPER_ADMIN } from 'src/utils/permission'

function InfoDialog({ visible, setVisible, currentItem }) {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const [activeKey, setActiveKey] = useState(1)

  const destroy = () => {
    Swal.fire({
      title: 'Apagar registo?',
      text: 'Ação não pode ser revertido!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF7F47',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, apagar!',
      cancelButtonText: 'Cancelar',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(currentItem)
        dispatch(deleteItemPraia(currentItem.id))
          .unwrap()
          .then(() => {
            setVisible(false)
            SwalAlert.successDelete()
          })
          .catch(() => {
            SwalAlert.errorDelete()
          })
      }
    })
  }
  return (
    <CModal
      backdrop="static"
      alignment="center"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar registro</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CNav variant="tabs">
          <CNavItem>
            <CNavLink
              className="tab-nav-link"
              active={activeKey === 1}
              onClick={() => setActiveKey(1)}
              href="#"
            >
              Info
            </CNavLink>
          </CNavItem>
          {user && user.role.name === SUPER_ADMIN ? (
            <CNavItem>
              <CNavLink onClick={destroy} className="tab-nav-delete" href="#">
                <CIcon icon={cilTrash} size="lg" />
              </CNavLink>
            </CNavItem>
          ) : (
            currentItem &&
            ADMIN.includes(user.role.name) &&
            currentItem.praia.gruposand.id === user.gruposand.id && (
              <CNavItem>
                <CNavLink onClick={destroy} className="tab-nav-delete" href="#">
                  <CIcon icon={cilTrash} size="lg" />
                </CNavLink>
              </CNavItem>
            )
          )}
        </CNav>
        <CTabContent>
          <CTabPane visible={activeKey === 1}>
            <CFormLabel className="mt-3">
              {' '}
              {currentItem && currentItem.iten.descripition}
            </CFormLabel>
          </CTabPane>
        </CTabContent>
      </CModalBody>
    </CModal>
  )
}
InfoDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  children: PropTypes.node,
  data: PropTypes.object,
  currentItem: PropTypes.object,
}
export default memo(InfoDialog)
