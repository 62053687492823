import React, { useState, useEffect } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { cilSave, cilXCircle } from "@coreui/icons";
import { SelectPicker, DatePicker } from "rsuite";
import { retrieveGruposand } from "src/store/reducers/gruposandSlice";
import { retrievePraias } from "src/store/reducers/praiaSlice";
import SwalAlert from "src/utils/SwalAlert";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createMedicaoCorrente } from "src/store/reducers/medicaoCorrenteSlice";
import { ADMIN_PRAIA, SUPER_ADMIN } from "src/utils/permission";
import UploadService from "../../../../service/Upload";
import { addSchema } from "./validation";

function AddMedicaoCorrenteDialog({ visible, setVisible }) {
  const dispatch = useDispatch();
  const [grupoOptions, setGrupoOption] = useState([]);
  const [praiaOptions, setPraiaOption] = useState([]);
  const { user } = useSelector((state) => state.account);

  const gruposands = useSelector((state) => state.gruposands);
  const praias = useSelector((state) => state.praias);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addSchema) });

  const [images, setImage] = useState(null);

  const saveMedicaoCorrente = async (data) => {
    const formData = new FormData();
    let img;
    if (images) {
      formData.append("files", images[0]);
      const { data } = await UploadService.upload(formData);
      img = data;
    }
    const values = {
      ...data,
      praia: parseInt(data.praia, 10),
      gruposand: parseInt(data.gruposand, 10),
      date: new Date(data.date),
      img,
    };
    if (ADMIN_PRAIA.includes(user.role.name)) {
      values.gruposand = user.gruposand.id;
    }

    dispatch(createMedicaoCorrente({ ...data, ...values, img }))
      .unwrap()
      .then(() => {
        SwalAlert.success();
        reset();
      })
      .catch((err) => {
        SwalAlert.error();
      });
  };

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }));
    setGrupoOption(options);
  }, [gruposands]);

  useEffect(() => {
    let filtered = praias;

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id);
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }));
    setPraiaOption(options);
  }, [praias, user]);

  useEffect(() => {
    dispatch(retrieveGruposand());
    dispatch(retrievePraias());
  }, [dispatch]);

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CForm onSubmit={handleSubmit(saveMedicaoCorrente)}>
        <CModalHeader>
          <CModalTitle>Adicionar Medição de Corrente Litorânea</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Praia <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="praia"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker
                      {...field}
                      data={praiaOptions}
                      style={{ width: "100%" }}
                    />
                  )}
                />
                <small className="text-danger">{errors.praia?.message}</small>
              </div>
            </CCol>
            {SUPER_ADMIN.includes(user.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">
                        Grupo <strong className="text-danger">*</strong>
                      </CFormLabel>
                    </CCol>
                  </CRow>
                  <Controller
                    name="gruposand"
                    control={control}
                    render={({ field }) => (
                      <SelectPicker
                        {...field}
                        data={grupoOptions}
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                  <small className="text-danger">
                    {errors.gruposand?.message}
                  </small>
                </div>
              </CCol>
            )}

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker className="w-100" {...field} />
                  )}
                />
                <small className="text-danger">{errors.date?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Distância depois de 1 minuto(m){" "}
                  <strong className="text-danger">*</strong>
                </CFormLabel>

                <CFormInput
                  type="number"
                  id="item"
                  {...register("distance_after_1_minute")}
                />
                <small className="text-danger">
                  {errors.distance_after_1_minute?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Direção depois de 1 minuto(graus){" "}
                  <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  {...register("direction_after_1_minute")}
                />
                <small className="text-danger">
                  {errors.direction_after_1_minute?.message}
                </small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Velocidade depois de 1 minuto(cm/s){" "}
                  <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  {...register("spoed_after_1_minute")}
                />
                <small className="text-danger">
                  {errors.spoed_after_1_minute?.message}
                </small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Distância dpois de 5 minutos(m)
                  <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  {...register("distance_after_5_minutes")}
                />
                <small className="text-danger">
                  {errors.distance_after_5_minutes?.message}
                </small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Direção depois de 5 minutos(graus){" "}
                  <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  {...register("direction_after_5_minutes")}
                />
                <small className="text-danger">
                  {errors.direction_after_5_minutes?.message}
                </small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Velocidade depois de 5 minutos(cm/s){" "}
                  <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  {...register("spoed_after_5_minutes")}
                />
                <small className="text-danger">
                  {errors.spoed_after_5_minutes?.message}
                </small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Observações <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="item"
                  {...register("observations")}
                />
                <small className="text-danger">
                  {errors.observations?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="formFile">Imagem</CFormLabel>
                <CFormInput
                  onChange={(e) => setImage(e.target.files)}
                  type="file"
                  id="formFile"
                  accept=".png,.jpg"
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  );
}
AddMedicaoCorrenteDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default AddMedicaoCorrenteDialog;
