import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { retrieveSedimento } from 'src/store/reducers/sedimentoSlice'
import { createResultadoB } from 'src/store/reducers/resultadoBSlice'
import SwalAlert from 'src/utils/SwalAlert'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './validation'

function AddResultadoBDialog({ visible, setVisible }) {
  const dispatch = useDispatch()

  const sedimentos = useSelector((state) => state.sedimentos)

  const [sedimentoOptions, setSedimentoOption] = useState([])

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const saveResultadoB = async (data) => {
    const values = {
      ...data,
      amostraareia: parseInt(data.amostraareia, 10),
      collect_date_sand: new Date(data.collect_date_sand),
    }

    dispatch(createResultadoB({ ...data, ...values }))
      .unwrap()
      .then(() => {
        SwalAlert.success()
        reset({
          amostraareia: '',
          collect_date_sand: '',
          sand_temperature: '0',
          dissolved_oxygen_sand: '0',
          dissolved_oxygen_demand_BOD_sand: '0',
          nitrate_sand: '0',
          phosphate_sand: '0',
          yeast: '0',
          filamentous_fungi: '0',
          protozoa: '0',
          fecal_coliform_sand: '0',
          Counting_enterococcos_sand: '0',
          Counting_escherichina_sand: '0',
          salinity_sand: '0',
          conductivity_sand: '0',
          turbidity_sand: '0',
          ph: '0',
          sand_temperature: '0',
        })
      })
      .catch((err) => {
        SwalAlert.error()
      })
  }

  useEffect(() => {
    const options = sedimentos.map((item) => ({
      value: item.id,
      label: item.sample_number_sand,
    }))
    setSedimentoOption(options)
  }, [sedimentos])

  useEffect(() => {
    dispatch(retrieveSedimento())
  }, [dispatch])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>
          Adicionar Resultado de Análise Microbiológico das Amostras de Areia
        </CModalTitle>
      </CModalHeader>
      <CForm onSubmit={handleSubmit(saveResultadoB)}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Número da amostra <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="amostraareia"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker {...field} data={sedimentoOptions} style={{ width: '100%' }} />
                  )}
                />
                <small className="text-danger">{errors.amostraareia?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="collect_date_sand"
                  control={control}
                  render={({ field }) => <DatePicker className="w-100" {...field} />}
                />
                <small className="text-danger">{errors.collect_date_sand?.message}</small>
              </div>
            </CCol>
            <p1 style={{ color: 'darkblue' }}> ANÁLISE MICROBIOLÓGICA DA AMOSTRA</p1> <br />
            <br />
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Oxigênio dissolvido</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  defaultValue={0}
                  min={0}
                  {...register('dissolved_oxygen_sand')}
                />
                <small className="text-danger">{errors.dissolved_oxygen_sand?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Demanda Bioquímica de Oxigênio (DBQ)</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  min={0}
                  defaultValue={0}
                  {...register('dissolved_oxygen_demand_BOD_sand')}
                />
                <small className="text-danger">
                  {errors.dissolved_oxygen_demand_BOD_sand?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Leveduras</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  min={0}
                  defaultValue={0}
                  {...register('yeast')}
                />
                <small className="text-danger">{errors.yeast?.message}</small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Fungos Filamentosos</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  min={0}
                  defaultValue={0}
                  {...register('filamentous_fungi')}
                />
                <small className="text-danger">{errors.filamentous_fungi?.message}</small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Protozoarios e Helmintos</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  min={0}
                  defaultValue={0}
                  {...register('protozoa')}
                />
                <small className="text-danger">{errors.protozoa?.message}</small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Bactérias Coliformes </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  placeholder="0"
                  defaultValue={0}
                  {...register('fecal_coliform_sand')}
                />
                <small className="text-danger">{errors.fecal_coliform_sand?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Contagem de Enterococcos fecais- Filtração por Membrana{' '}
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  defaultValue={0}
                  min={0}
                  {...register('Counting_enterococcos_sand')}
                />
                <small className="text-danger">{errors.Counting_enterococcos_sand?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Contagem de Escherichina coli- Filtração por Membrana{' '}
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  defaultValue={0}
                  min={0}
                  {...register('Counting_escherichina_sand')}
                />
                <small className="text-danger">{errors.Counting_escherichina_sand?.message}</small>
              </div>
            </CCol>
            <p1 style={{ color: 'darkblue' }}> ANÁLISE FÍSICO-QUÍMICO DA AMOSTRA</p1>
            <br />
            <br />
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Nitratos</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  defaultValue={0}
                  min={0}
                  {...register('nitrate_sand')}
                />
                <small className="text-danger">{errors.nitrate_sand?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Fosfato</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  defaultValue={0}
                  min={0}
                  {...register('phosphate_sand')}
                />
                <small className="text-danger">{errors.phosphate_sand?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Salinidade</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  placeholder="0"
                  defaultValue={0}
                  min={0}
                  {...register('salinity_sand')}
                />
                <small className="text-danger">{errors.salinity_sand?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Condutividade</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  placeholder="0"
                  defaultValue={0}
                  min={0}
                  {...register('conductivity_sand')}
                />
                <small className="text-danger">{errors.conductivity_sand?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Temperatura</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  placeholder="0"
                  defaultValue={0}
                  min={0}
                  {...register('sand_temperature')}
                />
                <small className="text-danger">{errors.sand_temperature?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Turbidez</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  defaultValue={0}
                  min={0}
                  {...register('turbidity_sand')}
                />
                <small className="text-danger">{errors.turbidity_sand?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Ph</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  defaultValue={0}
                  min={0}
                  step="0.001"
                  {...register('ph')}
                />
                <small className="text-danger">{errors.ph?.message}</small>
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
AddResultadoBDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default AddResultadoBDialog
