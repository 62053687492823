import React from 'react'
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import PropTypes from 'prop-types'

function TableWrapper({ title, children }) {
  return (
    <CCard>
      <CCardHeader>
        <strong>{title}</strong>
      </CCardHeader>
      <CCardBody>{children}</CCardBody>
    </CCard>
  )
}

TableWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
}
export default TableWrapper
