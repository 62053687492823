import { CCol, CContainer, CRow, CButton } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import Lottie from 'react-lottie'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import GalleryImage from 'src/components/ImageGallery'
import Mapa from 'src/components/Map'
import { retrieveIlhas } from 'src/store/reducers/ilhaSlice'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import loader from '../../../assets/loader.json'
import AddProblemaDialog from './AddProblemaDialog'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

function PraiaDetails() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const praias = useSelector((state) => state.praias)
  const ilhas = useSelector((state) => state.ilhas)
  const [map, setMap] = useState(undefined)
  const [praia, setPraia] = useState(null)
  const [ilha, setIlha] = useState(null)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')

  /*  const changeItem = (record, index) => {
    setAction('')
    setCurrentItem(record)
    setVisible(true)
  } */

  useEffect(() => {
    if (praia && map) {
      if (praia.coordinates) {
        const { lat, lng } = praia.coordinates
        map.flyTo([lat, lng], 14)
      }
    }
  }, [praia, map])

  useEffect(() => {
    const praia = praias.find((praia) => praia.id === parseInt(id, 10))
    setPraia(praia)
  }, [praias, id])

  useEffect(() => {
    if (praia) {
      const ilha = ilhas.find((ilha) => ilha.id === praia.concelho.ilha)
      setIlha(ilha)
    }
  }, [praia, ilhas])

  useEffect(() => {
    dispatch(retrievePraias())
      .unwrap()
      .then(() => setLoading(false))
    dispatch(retrieveIlhas())
  }, [dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <CContainer>
      <div style={{ height: '76px' }} />
      <h2 className="pt-4 pb-4">{praia && praia.name_praia}</h2>
      <CRow>
        <MapContainer
          whenCreated={setMap}
          center={[16.130132, -23.928763]}
          zoom={10}
          style={{ height: '40vh', borderRadius: '10px' }}
        >
          <TileLayer
            maxZoom={20}
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {praia && praia.coordinates && (
            <Marker position={[praia.coordinates.lat, praia.coordinates.lng]} />
          )}
        </MapContainer>
      </CRow>
      <CRow className="mt-4">
        {loading ? (
          <CCol xs={12}>
            <Lottie
              options={defaultOptions}
              height={100}
              width={100}
              isStopped={false}
              isPaused={false}
            />
          </CCol>
        ) : (
          <>
            {' '}
            {praia && praia.image_praia.length ? (
              <CCol lg={5}>
                <GalleryImage items={praia.image_praia} />
              </CCol>
            ) : (
              ''
            )}
            <CCol lg={7}>
              <CRow className="mt-3 ms-lg-4">
                <CCol className="pb-3" md={6}>
                  <h5>Ilha</h5>
                  <p>{ilha && ilha.name_ilha}</p>
                </CCol>
                <CCol className="pb-3" md={6}>
                  <h5>Concelho</h5>
                  <p>{praia && praia.concelho.name_concelho}</p>
                </CCol>
                <CCol className="pb-3" md={6}>
                  <h5>Zona</h5>
                  <p>{praia && praia.district}</p>
                </CCol>
                <CCol className="pb-3" md={6}>
                  <h5>Melhor Horario</h5>
                  <p>
                    {praia.time_end &&
                      praia.time_start &&
                      `${praia.time_start.split('.')[0]  } - ${  praia.time_end.split('.')[0]}`}
                  </p>
                </CCol>
                <CCol className="pb-3" md={6}>
                  <h5>Classificacao</h5>
                  <p>{praia && praia.classificacao.rate}</p>
                </CCol>
                <CCol className="pb-3" md={6}>
                  <h5>Coordenadas</h5>
                  {praia.coordinates && (
                    <>
                      <p>Latitude: {praia.coordinates.lat}</p>
                      <p>Longitude: {praia.coordinates.lng}</p>
                    </>
                  )}
                </CCol>
              </CRow>
            </CCol>
            <CRow className="mt-4">
              <h5>Descricao</h5>
              <p className="description">{praia && praia.description}</p>
            </CRow>
            <hr />
            <CButton
              className="main-button"
              onClick={() => {
                setAction('add')
                setVisible(true)
              }}
            >
              Adicionar problemas/Incidente
            </CButton>
          </>
        )}
      </CRow>
      <AddProblemaDialog visible={visible} setVisible={setVisible} />
    </CContainer>
  )
}

export default PraiaDetails
