import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import Swal from 'sweetalert2'
import { updateProblema } from 'src/store/reducers/problemaSlice'
import Date, { convert } from 'src/utils/Date'
import SwalAlert from 'src/utils/SwalAlert'
import UploadService from '../../../service/Upload'

function EditProblemaDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch()

  const [praiaOptions, setPraiaOption] = useState([])

  const praias = useSelector((state) => state.praias)

  const [date, setDate] = useState('')
  const [praia, setPraia] = useState('')
  const [issue, setIssue] = useState()
  const [email, setEmail] = useState()
  const [images, setImage] = useState(null)

  const initState = () => {}
  const saveProblema = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    let image_issue
    if (images) {
      formData.append('files', images[0])
      const { data } = await UploadService.upload(formData)
      image_issue = data
    }
    const data = { praia, issue, image_issue, email, date }

    dispatch(
      updateProblema({
        data,
        id: item.id,
      }),
    )
      .then(() => {
        setVisible(false)
        SwalAlert.success()
      })
      .catch(() => {
        SwalAlert.error()
      })
  }

  useEffect(() => {
    if (item) {
      const praia = praias.find((praia) => praia.name_praia === item.praia)
      setPraia(praia.id)
      setIssue(item.incidente)
      setEmail(item.email)
      setDate(convert(item.data))
    }
  }, [item, praias])

  useEffect(() => {
    const options = praias.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }))
    setPraiaOption(options)
  }, [praias])

  useEffect(() => {
    dispatch(retrievePraias())
  }, [dispatch])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar Problema</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={saveProblema}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Praia</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={praiaOptions}
                  style={{ width: '100%' }}
                  value={praia}
                  onChange={setPraia}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Data</CFormLabel>
                  </CCol>
                </CRow>
                <DatePicker
                  className="w-100"
                  format="dd-MM-yyyy"
                  defaultValue={date}
                  value={date}
                  onChange={setDate}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Insidente</CFormLabel>
                <CFormTextarea
                  defaultValue={issue}
                  value={issue}
                  onChange={(e) => setIssue(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Email</CFormLabel>
                <CFormInput
                  type="email"
                  id="item"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto</CFormLabel>
                <CFormInput
                  type="file"
                  id="item"
                  accept=".png,.jpg"
                  onChange={(e) => setImage(e.target.files)}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
EditProblemaDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
}

export default EditProblemaDialog
