import React from 'react'
import { cilPen, cilTrash, cilImage } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

export const config = {
  page_size: 10,
  length_menu: [10, 20, 50],
  show_filter: true,
  show_pagination: true,
  pagination: 'advance',
  button: {
    csv: true,
    print: true,
  },
  language: {
    length_menu: 'Mostrar _MENU_ registros por página',
    filter: 'Pesquisar ...',
    info: 'Mostrando _START_ a _END_ de _TOTAL_ entradas',
    pagination: {
      previous: 'Anterior',
      next: 'Próximo',
    },
  },
}

export const columns = (destroy, edit) => [
  {
    key: 'index',
    text: '#',
    className: 'index',
    width: 20,
    align: 'center',
  },
  {
    key: 'username',
    text: 'Utilizador',
    className: 'name',
    align: 'left',
    width: 200,
  },
  {
    key: 'email',
    text: 'Email',
    className: 'name',
    align: 'left',
  },
  {
    key: 'name',
    text: 'Nome',
    className: 'address',
    align: 'left',
    width: 180,
  },
  {
    key: 'telefone',
    text: 'Telefone',
    className: 'address',
    align: 'left',
  },
  {
    key: 'role',
    text: 'Role',
    className: 'address',
    align: 'left',
  },
  {
    key: 'grupo',
    text: 'Grupo',
    className: 'address',
    align: 'left',
    width: 150,
  },
  {
    key: 'action',
    text: 'Action',
    className: 'postcode',
    align: 'center',
    width: 100,
    cell: (record, index) => (
        <>
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => edit(record, index)}
            style={{ marginRight: '5px' }}
          >
            <CIcon name={cilPen} />
          </button>
          <button className="btn btn-danger btn-sm" onClick={() => destroy(record, index)}>
            <CIcon name={cilTrash} />
          </button>
        </>
      ),
  },
]
