import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { retrieveGruposand } from 'src/store/reducers/gruposandSlice'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import Swal from 'sweetalert2'
import { updateSedimento } from 'src/store/reducers/sedimentoSlice'
import SwalAlert from 'src/utils/SwalAlert'
import { convert } from 'src/utils/Date'
import { ADMIN_PRAIA, SUPER_ADMIN } from 'src/utils/permission'
import UploadService from '../../../../service/Upload'

function EditSedimentoDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch()
  const [grupoOptions, setGrupoOption] = useState([])
  const [praiaOptions, setPraiaOption] = useState([])
  const [selecaoOptions, setSelecaoOption] = useState([])
  const [tipoOptions, setTipoOption] = useState([])
  const { user } = useSelector((state) => state.account)

  const gruposands = useSelector((state) => state.gruposands)
  const praias = useSelector((state) => state.praias)

  const [praia, setPraia] = useState('')
  const [gruposand, setGrupo] = useState()
  const [sample_number_sand, setSample_number_sand] = useState('')
  const [batch_number_sand, setBatch_number_sand] = useState()
  const [date_collect, setDate_collect] = useState('')
  const [sand_type, setSand_type] = useState()
  const [selection, setSelection] = useState('')
  const [images, setImage] = useState(null)

  const saveSedimento = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    let photo
    if (images) {
      formData.append('files', images[0])
      const { data } = await UploadService.upload(formData)
      photo = data
    }

    const info = {
      praia,
      gruposand,
      date_collect,
      sample_number_sand,
      batch_number_sand,
      sand_type,
      selection,
      photo,
    }
    dispatch(
      updateSedimento({
        data: info,
        id: item.id,
      }),
    )
      .unwrap()
      .then(() => {
        setVisible(false)
        SwalAlert.success()
      })
      .catch(() => {
        SwalAlert.error()
      })
  }

  useEffect(() => {
    if (item) {
      const praia = praias.find((praia) => praia.name_praia === item.praia)
      const gruposand = gruposands.find((grupo) => grupo.name_grupo === item.grupo)

      setPraia(praia.id)
      setGrupo(gruposand.id)
      setSample_number_sand(item.sample_number_sand)
      setBatch_number_sand(item.batch_number_sand)
      setDate_collect(convert(item.date_collect))
      setSand_type(item.sand_type)
      setSelection(item.selection)
    }
    console.log(praia)
  }, [item, praias, gruposands])

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }))
    setGrupoOption(options)
  }, [gruposands])

  useEffect(() => {
    let filtered = praias;

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id)
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }))
    setPraiaOption(options)
  }, [praias, user])

  useEffect(() => {
    const options = [
      { label: 'Principalmente Fina', value: 'Principalmente Fina' },
      { label: 'Principalmente Grossa', value: 'Principalmente Grossa' },
      { label: 'Fina e Média', value: 'Fina e Media' },
      { label: 'Grossa e Média', value: 'Grossa e Media' },
      { label: 'Mistura de Grossa e Fina', value: 'Mistura de Grossa e Fina' },
    ]
    setSelecaoOption(options)
  }, [])

  useEffect(() => {
    const options = [
      { label: 'Areia Molhada', value: 'Areia Molhada' },
      { label: 'Areia Seca', value: 'Areia Seca' },
    ]
    setTipoOption(options)
  }, [])

  useEffect(() => {
    dispatch(retrieveGruposand())
    dispatch(retrievePraias())
  }, [dispatch])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar Sedimentos Praia</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={saveSedimento}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Praia</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={praiaOptions}
                  style={{ width: '100%' }}
                  value={praia}
                  onChange={setPraia}
                />
              </div>
            </CCol>
            {SUPER_ADMIN.includes(user.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">Grupo</CFormLabel>
                    </CCol>
                  </CRow>
                  <SelectPicker
                    data={grupoOptions}
                    style={{ width: '100%' }}
                    defaultValue={gruposand}
                    value={gruposand}
                    onChange={setGrupo}
                  />
                </div>
              </CCol>
            )}


            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Número de amostra</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={sample_number_sand}
                  onChange={(e) => setSample_number_sand(e.target.value)}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Lote</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={batch_number_sand}
                  onChange={(e) => setBatch_number_sand(e.target.value)}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Data</CFormLabel>
                  </CCol>
                </CRow>
                <DatePicker
                  className="w-100"
                  format="dd-MM-yyyy"
                  defaultValue={date_collect}
                  value={date_collect}
                  onChange={setDate_collect}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Tipo de Areia</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={tipoOptions}
                  style={{ width: '100%' }}
                  value={sand_type}
                  onChange={setSand_type}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Seleção</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={selecaoOptions}
                  style={{ width: '100%' }}
                  value={selection}
                  onChange={setSelection}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto</CFormLabel>
                <CFormInput type="file" id="item" onChange={(e) => setImage(e.target.files)} />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
EditSedimentoDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
}

export default EditSedimentoDialog
