import axiosService from '../../axios'

class ActividadeService {
  create(data) {
    return axiosService.post('/atividades', data)
  }

  retrieve() {
    return axiosService.get('/atividades')
  }

  update(data, id) {
    return axiosService.put(`/atividades/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/atividades/${id}`)
  }
}

export default new ActividadeService()
