import axiosService from '../../axios'

class ResultadoGService {
  create(data) {
    return axiosService.post('/resultadoamostraareiamicros', data)
  }

  retrieve() {
    return axiosService.get('/resultadoamostraareiamicros')
  }

  update(data, id) {
    return axiosService.put(`/resultadoamostraareiamicros/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/resultadoamostraareiamicros/${id}`)
  }
}

export default new ResultadoGService()
