import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import SedimentoService from '../../service/monitorizacao/Sedimento'

export const createSedimento = createAsyncThunk('sedimento/create', async (data) => {
  const response = await SedimentoService.create(data)
  return response.data
})

export const retrieveSedimento = createAsyncThunk('sedimento/retrieve', async () => {
  const response = await SedimentoService.retrieve()
  return response.data
})

export const updateSedimento = createAsyncThunk('sedimento/update', async ({ data, id }) => {
  const response = await SedimentoService.update(data, id)
  return response.data
})

export const deleteSedimento = createAsyncThunk('sedimento/delete', async (id) => {
  const response = await SedimentoService.delete(id)
  return response.data
})

const initialState = []

const sedimentoSlice = createSlice({
  name: 'Sedimento',
  initialState,
  extraReducers: {
    [createSedimento.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveSedimento.fulfilled]: (state, action) => [...action.payload],
    [updateSedimento.fulfilled]: (state, action) => {
      const index = state.findIndex((Sedimento) => Sedimento.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteSedimento.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default sedimentoSlice.reducer
