import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
} from '@coreui/react'
import { cilPen, cilTrash, cilImage } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import EditPraia from './EditPraia'
import Info from './Info'
import AddCoordenate from './AddCoordenate'
import AddInfo from './AddInfo'

function AddPraiaDialog({ visible, setVisible }) {
  const [activeKey, setActiveKey] = useState(1)
  return (
    <CModal
      backdrop="static"
      alignment="center"
      visible={visible}
      size={activeKey === 2 ? 'xl' : 'lg'}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar registro</CModalTitle>
      </CModalHeader>

      <CModalBody>
        <CNav variant="tabs">
          <CNavItem>
            <CNavLink
              className="tab-nav-link"
              active={activeKey === 1}
              onClick={() => setActiveKey(1)}
              href="#"
            >
              Adicionar Coordenada
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink
              className="tab-nav-link"
              active={activeKey === 2}
              onClick={() => setActiveKey(2)}
              href="#"
            >
              Adicionar praia
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          <CTabPane visible={activeKey === 1}>
            <AddCoordenate setVisible={setVisible} />
          </CTabPane>
          <CTabPane visible={activeKey === 2}>
            <AddInfo setVisible={setVisible} />
          </CTabPane>
        </CTabContent>
      </CModalBody>
    </CModal>
  )
}
AddPraiaDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  children: PropTypes.node,
  data: PropTypes.object,
  currentPraia: PropTypes.node,
}

export default AddPraiaDialog
