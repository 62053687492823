import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react'
import { cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { deletePraia } from 'src/store/reducers/praiaSlice'
import SwalAlert from 'src/utils/SwalAlert'
import Swal from 'sweetalert2'
import { current } from '@reduxjs/toolkit'
import { ADMIN, OBSERVADOR, SUPER_ADMIN } from 'src/utils/permission'
import Info from './Info'
import EditPraia from './EditPraia'

function PraiaDialog({ visible, setVisible, currentPraia }) {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const [role, setRole] = useState(null)
  const [activeKey, setActiveKey] = useState(1)

  useEffect(() => {
    if (user) {
      setRole(user.role.name)
    }
  }, [user])
  const destroy = () => {
    Swal.fire({
      title: 'Apagar registo?',
      text: 'Ação não pode ser revertido!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF7F47',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, apagar!',
      cancelButtonText: 'Cancelar',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletePraia(currentPraia.id))
          .unwrap()
          .then(() => {
            setVisible(false)
            SwalAlert.successDelete()
          })
          .catch(() => {
            SwalAlert.errorDelete()
          })
      }
    })
  }
  return (
    <CModal
      backdrop="static"
      alignment="center"
      visible={visible}
      size="xl"
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar registro</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CNav variant="tabs">
          <CNavItem>
            <CNavLink
              className="tab-nav-link"
              active={activeKey === 1}
              onClick={() => setActiveKey(1)}
              href="#"
            >
              Detalhes
            </CNavLink>
          </CNavItem>
          {user && user.role.name === SUPER_ADMIN ? (
            <>
              <CNavItem>
                <CNavLink
                  className="tab-nav-link"
                  active={activeKey === 2}
                  onClick={() => setActiveKey(2)}
                  href="#"
                >
                  Editar praia
                </CNavLink>
              </CNavItem>
              {ADMIN.includes(role) && (
                <CNavItem>
                  <CNavLink className="tab-nav-delete" onClick={destroy} href="#">
                    <CIcon icon={cilTrash} size="lg" />
                  </CNavLink>
                </CNavItem>
              )}
            </>
          ) : (
            !OBSERVADOR.includes(role) && (
              <>
                <CNavItem>
                  <CNavLink
                    className="tab-nav-link"
                    active={activeKey === 2}
                    onClick={() => setActiveKey(2)}
                    href="#"
                  >
                    Editar praia
                  </CNavLink>
                </CNavItem>
                {currentPraia && currentPraia.gruposand.id === user.gruposand.id && (
                  <CNavItem>
                    <CNavLink className="tab-nav-delete" onClick={destroy} href="#">
                      <CIcon icon={cilTrash} size="lg" />
                    </CNavLink>
                  </CNavItem>
                )}
              </>
            )
          )}
        </CNav>
        <CTabContent>
          <CTabPane visible={activeKey === 1}>
            <Info currentPraia={currentPraia} setVisible={setVisible} />
          </CTabPane>
          <CTabPane visible={activeKey === 2}>
            <EditPraia currentPraia={currentPraia} setVisible={setVisible} />
          </CTabPane>
        </CTabContent>
      </CModalBody>
    </CModal>
  )
}
PraiaDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  children: PropTypes.node,
  data: PropTypes.object,
  currentPraia: PropTypes.object,
}
export default memo(PraiaDialog)
