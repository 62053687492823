import * as Yup from 'yup'

export const schema = Yup.object({
  praia: Yup.string().required('Campo obrigatório'),
  // gruposand: Yup.string().required('Campo obrigatório'),
  name: Yup.string().required('Campo obrigatório'),
  objective: Yup.string().required('Campo obrigatório'),
  start_date: Yup.string().required('Campo obrigatório'),
  end_date: Yup.string().required('Campo obrigatório'),
  background: Yup.number('Adicionar apenas numeros').positive().min(1, 'Valor minimo 1'),
  planned_activities: Yup.string(),
  resources_needed: Yup.string(),
  how_will_we_know_we_achieved_our_objective: Yup.string(),
})
