import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import TableWrapper from 'src/components/TableWrapper'
import ReactDatatable from '@ashvin27/react-datatable'
import {
  deleteAtividadeHumana,
  retrieveAtividadeHumana,
} from 'src/store/reducers/atividadeHumanaSlice'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'
import GalleryImage from 'src/components/ImageGallery'
import ModalImage from 'src/components/Modal/ImageModal'
import { ADMIN, SUPER_ADMIN } from 'src/utils/permission'
import EditAtividadeHumanaDialog from './EditAtividadeHumanaDialog'
import AddAtividadeHumanaDialog from './AddAtividadeHumanaDialog'
import { columns, config } from './tableConf'

function AtividadeHumana() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const atividadesHumanas = useSelector((state) => state.atividadesHumanas)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')
  const [currentItem, setCurrentItem] = useState(null)
  const [data, setData] = useState([])
  const [images, setImages] = useState(null)
  const [imageShow, setShowImage] = useState(false)
  const [role, setRole] = useState(null)

  const destroy = (record, index) => {
    Swal.fire({
      title: 'Eliminar Registo?',
      text: 'Ação não pode ser revertido!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF7F47',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar!',
      cancelButtonText: 'Cancelar',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteAtividadeHumana(record.id))
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success')
      }
    })
  }

  const changeItem = (record, index) => {
    setAction('')
    setCurrentItem(record)
    setVisible(true)
  }

  const showImage = (record, index) => {
    setImages(record.image)
    setShowImage(true)
  }

  useEffect(() => {
    dispatch(retrieveAtividadeHumana())
      .unwrap()
      .then(() => setLoading(false))
      .catch()
  }, [dispatch])

  useEffect(() => {
    if (user) {
      let datas = atividadesHumanas
      if (user.role.name !== SUPER_ADMIN) {
        datas = atividadesHumanas.filter(
          (atividade) => atividade.gruposand.id === user.gruposand.id,
        )
      }
      const data = datas.map((item, index) => ({
        index: index + 1,
        id: item.id,
        data: moment(item.data).format('DD-MM-YYYY'),
        praia: item.praia.name_praia,
        grupo: item.gruposand.name_grupo,
        atividade: item.atividade.nome_atividade,
        time_start: item.time_start,
        time_end: item.time_end,
        image: item.Img,
      }))
      setData(data)
      setRole(user.role.name)
    }
  }, [atividadesHumanas, user])

  return (
    <CContainer lg>
      <CRow className="mb-4 mt-4">
        {ADMIN.includes(role) && (
          <CCol>
            <CButton
              className="main-button"
              onClick={() => {
                setAction('add')
                setVisible(true)
              }}
            >
              Adicionar
            </CButton>
          </CCol>
        )}
      </CRow>

      <div className="table-responsive">
        <ReactDatatable
          config={config(user && role)}
          records={data}
          loading={loading}
          columns={columns(destroy, changeItem, showImage, role)}
        />
      </div>

      {action === 'add' ? (
        <AddAtividadeHumanaDialog visible={visible} setVisible={setVisible} />
      ) : (
        <EditAtividadeHumanaDialog visible={visible} setVisible={setVisible} item={currentItem} />
      )}
      <ModalImage visible={imageShow} setVisible={setShowImage}>
        <GalleryImage items={images} />
      </ModalImage>
    </CContainer>
  )
}
export default AtividadeHumana
