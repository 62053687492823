import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import ReactDatatable from '@ashvin27/react-datatable'
import { retrieveResultadoAgua, deleteResultadoAgua } from 'src/store/reducers/resultadoAguaSlice'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'
import SwalAlert from 'src/utils/SwalAlert'
import { ADMIN, SUPER_ADMIN } from 'src/utils/permission'
import VerMaisResultadoDialog from './VerMaisResultadoDialog'
import EditResultadoDialog from './EditResultadoDialog'
import AddResultadoDialog from './AddResultadoDialog'
import { columns, config } from './tableConf'

function ResultadoAgua() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const resultadoAguas = useSelector((state) => state.resultadoAguas)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')
  const [currentItem, setCurrentItem] = useState(null)
  const [role, setRole] = useState(null)
  const [data, setData] = useState([])

  const destroy = (record, index) => {
    SwalAlert.warning().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteResultadoAgua(record.id))
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success')
      }
    })
  }

  const changeItem = (record, index) => {
    setAction('edit')
    setCurrentItem(record)
    setVisible(true)
  }

  const see = (record, index) => {
    setAction('')
    setCurrentItem(record)
    setVisible(true)
  }

  useEffect(() => {
    dispatch(retrieveResultadoAgua())
      .unwrap()
      .then(() => setLoading(false))
  }, [dispatch])

  useEffect(() => {
    if (user) {
      let datas = resultadoAguas

      if (user.role.name !== SUPER_ADMIN) {
        datas = resultadoAguas.filter(
          (amostra) => amostra.amostraagua.gruposand === user.gruposand.id,
        )
      }

      const data = datas.map((item, index) => ({
        index: index + 1,
        id: item.id,
        amostra: item.amostraagua.sample_number,
        lote: item.amostraagua.Batch_number,
        dissolved_oxygen: item.dissolved_oxygen,
        phosphate_ppm: item.phosphate_ppm,
        transparency: item.transparency,
        date_resultado: moment(item.date_resultado).format('DD-MM-YYYY'),
        nitrate_ppm: item.nitrate_ppm,
        ph: item.ph,
        temperature: item.temperature,
        fecal_coliform: item.fecal_coliform,
        dissolved_oxygen_demand_BOD_ppm: item.dissolved_oxygen_demand_BOD_ppm,
        salinity_ppt: item.salinity_ppt,
        turbidity_jtu: item.turbidity_jtu,
        other_observation_eg_smell_colour_of_water: item.other_observation_eg_smell_colour_of_water,
        Counting_enterococcos: item.Counting_enterococcos,
        Counting_escherichina: item.Counting_escherichina,
        conductivity: item.conductivity,
      }))
      setData(data)
      setRole(user.role.name)
    }
  }, [resultadoAguas, user])
  return (
    <CContainer lg>
      <CRow className="mb-4 mt-4">
        {ADMIN.includes(role) && (
          <CCol>
            <CButton
              className="main-button"
              onClick={() => {
                setAction('add')
                setVisible(true)
              }}
            >
              Adicionar
            </CButton>
          </CCol>
        )}
      </CRow>

      <ReactDatatable
        config={config(user && user.role.name)}
        records={data}
        loading={loading}
        columns={columns(destroy, changeItem, see, role)}
      />

      {action === 'add' ? (
        <AddResultadoDialog visible={visible} setVisible={setVisible} />
      ) : action === 'edit' ? (
        <EditResultadoDialog visible={visible} setVisible={setVisible} item={currentItem} />
      ) : (
        <VerMaisResultadoDialog visible={visible} setVisible={setVisible} item={currentItem} />
      )}
    </CContainer>
  )
}
export default ResultadoAgua
