import React, { useState, useEffect } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { cilSave, cilXCircle } from "@coreui/icons";
import { SelectPicker, DatePicker } from "rsuite";
import { retrieveGruposand } from "src/store/reducers/gruposandSlice";
import { retrievePraias } from "src/store/reducers/praiaSlice";
import { createAmostraAgua } from "src/store/reducers/amostraAguaSlice";
import SwalAlert from "src/utils/SwalAlert";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ADMIN_PRAIA, SUPER_ADMIN } from "src/utils/permission";
import { schema } from "./validation";
import UploadService from "../../../../service/Upload";

function AddAmostraAguaDialog({ visible, setVisible }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.account);
  const gruposands = useSelector((state) => state.gruposands);
  const praias = useSelector((state) => state.praias);

  const [grupoOptions, setGrupoOption] = useState([]);
  const [praiaOptions, setPraiaOption] = useState([]);
  const [transferenciaOptions, setTransferenciaOption] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [images, setImage] = useState(null);

  const saveAmostraAgua = async (data) => {
    const formData = new FormData();

    let Sample_photo = [];

    if (images) {
      formData.append("files", images[0]);
      const { data } = await UploadService.upload(formData);
      Sample_photo = data;
    }

    const values = {
      ...data,
      praia: parseInt(data.praia, 10),
      gruposand: parseInt(data.gruposand, 10),
      Collection_Date: new Date(data.Collection_Date),
      Expiration_date: new Date(data.Expiration_date),
      Sample_photo,
    };

    if (ADMIN_PRAIA.includes(user.role.name)) {
      values.gruposand = user.gruposand.id;
    }

    dispatch(createAmostraAgua({ ...data, ...values, Sample_photo }))
      .unwrap()
      .then(() => {
        SwalAlert.success();
        reset({
          praia: "",
          gruposand: "",
          sample_number: "",
          Batch_number: "",
          Quantity: "",
          transparency: "",
          Collection_Date: "",
          Expiration_date: "",
          Sample_photo: "",
        });
      })
      .catch((err) => {
        SwalAlert.error();
      });
  };

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }));
    setGrupoOption(options);
  }, [gruposands]);

  useEffect(() => {
    let filtered = praias;

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id);
      }
    }

    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }));
    setPraiaOption(options);
  }, [praias, user]);

  useEffect(() => {
    const options = [
      { label: "Alta", value: "Alta" },
      { label: "Media", value: "Media" },
      { label: "Baixa", value: "Baixa" },
    ];
    setTransferenciaOption(options);
  }, []);

  useEffect(() => {
    dispatch(retrieveGruposand());
    dispatch(retrievePraias());
  }, [dispatch]);

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar Amostra de Água</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={handleSubmit(saveAmostraAgua)}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Praia <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="praia"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker
                      {...field}
                      data={praiaOptions}
                      style={{ width: "100%" }}
                    />
                  )}
                />
                <small className="text-danger">{errors.praia?.message}</small>
              </div>
            </CCol>
            {SUPER_ADMIN.includes(user.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">
                        Grupo <strong className="text-danger">*</strong>
                      </CFormLabel>
                    </CCol>
                  </CRow>
                  <Controller
                    name="gruposand"
                    control={control}
                    render={({ field }) => (
                      <SelectPicker
                        {...field}
                        data={grupoOptions}
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                  <small className="text-danger">
                    {errors.gruposand?.message}
                  </small>
                </div>
              </CCol>
            )}
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Número de amostra <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  {...register("sample_number")}
                />
                <small className="text-danger">
                  {errors.sample_number?.message}
                </small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Lote <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  {...register("Batch_number")}
                />
                <small className="text-danger">
                  {errors.Batch_number?.message}
                </small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data de Coleta <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="Collection_Date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker className="w-100" {...field} />
                  )}
                />
                <small className="text-danger">
                  {errors.Collection_Date?.message}
                </small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data de Validade{" "}
                      <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="Expiration_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker className="w-100" {...field} />
                  )}
                />
                <small className="text-danger">
                  {errors.Expiration_date?.message}
                </small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Quantidade(ml) <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  step="0.001"
                  {...register("Quantity")}
                />
                <small className="text-danger">
                  {errors.Quantity?.message}
                </small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Transparência <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="transparency"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker
                      {...field}
                      data={transferenciaOptions}
                      style={{ width: "100%" }}
                    />
                  )}
                />
                <small className="text-danger">
                  {errors.transparency?.message}
                </small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto</CFormLabel>
                <CFormInput
                  type="file"
                  id="item"
                  onChange={(e) => setImage(e.target.files)}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  );
}
AddAmostraAguaDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default AddAmostraAguaDialog;
