import React, { Component } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function BreadCrumb() {
  const { t } = useTranslation()

  return (
    <>
      {/* ===============  breadcrumb area start =============== */}
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="breadcrumb-wrap">
                <h2>{t('BANNER.TITULO')}</h2>
                <ul className="breadcrumb-links">
                  <li>
                    <Link to="/">{t('BANNER.LINK1')}</Link>
                  </li>
                  <li>
                    <i className="fas fa-chevron-right" />
                  </li>
                  <li>{t('BANNER.LINK2')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===============  breadcrumb area end =============== */}
    </>
  )
}
export default BreadCrumb
