import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AmostraAguaService from '../../service/monitorizacao/AmostraAgua'

export const createAmostraAgua = createAsyncThunk('amostraAgua/create', async (data) => {
  const response = await AmostraAguaService.create(data)
  return response.data
})

export const retrieveAmostraAgua = createAsyncThunk('amostraAgua/retrieve', async () => {
  const response = await AmostraAguaService.retrieve()
  return response.data
})

export const updateAmostraAgua = createAsyncThunk('amostraAgua/update', async ({ data, id }) => {
  const response = await AmostraAguaService.update(data, id)
  return response.data
})

export const deleteAmostraAgua = createAsyncThunk('amostraAgua/delete', async (id) => {
  const response = await AmostraAguaService.delete(id)
  return response.data
})

const initialState = []

const amostraAguaSlice = createSlice({
  name: 'AmostraAgua',
  initialState,
  extraReducers: {
    [createAmostraAgua.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveAmostraAgua.fulfilled]: (state, action) => [...action.payload],
    [updateAmostraAgua.fulfilled]: (state, action) => {
      const index = state.findIndex((AmostraAgua) => AmostraAgua.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteAmostraAgua.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default amostraAguaSlice.reducer
