import React, { useEffect, useState } from 'react'
import {
  CAvatar,
  CButton,
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { retrieveItem } from 'src/store/reducers/itemSlice'
import Select from 'src/components/Map/Select'
import { createItemPraia } from 'src/store/reducers/itemPraiaSlice'
import SwalAlert from 'src/utils/SwalAlert'
import SelectPraia from './SelectPraia'
import picture from '../../../assets/picture.png'

const URL = process.env.REACT_APP_SERVER_URL
function AddPraiaItemDialog({ visible, setVisible }) {
  const dispatch = useDispatch()
  const items = useSelector((state) => state.items)
  const { coordinate, map, layerId } = useSelector((state) => state.map)
  const { praia } = useSelector((state) => state.selectedPraia)
  const [activeId, setActiveId] = useState()

  const removeLayer = () => {
    map.eachLayer((layer) => {
      if (layer._leaflet_id === layerId) {
        map.removeLayer(layer)
      }
    })
  }

  const saveItem = () => {
    if (!praia && coordinate && activeId) {
      SwalAlert.errorSave()
      return
    }

    dispatch(
      createItemPraia({
        iten: activeId,
        praia,
        coordinate,
      }),
    )
      .unwrap()
      .then(() => {
        removeLayer()
        setVisible(false)
        SwalAlert.success()
      })
      .catch(() => {
        SwalAlert.error()
      })
  }

  useEffect(() => {
    dispatch(retrieveItem())
  }, [dispatch])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar item</CModalTitle>
      </CModalHeader>

      <CModalBody>
        <SelectPraia />
        <CRow lg={{ gutterY: 4 }}>
          {items.map((item) => (
            <CCol lg={3} key={item.id}>
              <div
                onClick={() => {
                  setActiveId(item.id)
                }}
                className={`item-card ${
                  activeId === item.id ? 'active-card' : ''
                } w-100 p-2 border border-1 border-warning rounded shadow-md d-flex flex-column align-items-center`}
              >
                <CAvatar src={item.img ? URL + item.img.url : picture} size="lg" />
                <p className="mt-1 text-center">{item.name_item}</p>
              </div>
            </CCol>
          ))}
        </CRow>
      </CModalBody>

      <CModalFooter>
        <CButton color="secondary" onClick={() => setVisible(false)}>
          Cancelar
        </CButton>
        <CButton onClick={saveItem} className="main-button" color="primary">
          Adicionar
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

AddPraiaItemDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
}

export default AddPraiaItemDialog
