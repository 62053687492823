import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import TableWrapper from 'src/components/TableWrapper'
import ReactDatatable from '@ashvin27/react-datatable'
import { deleteDiarioPraia, retrieveDiarioPraia } from 'src/store/reducers/diarioPraiaSlice'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'
import GalleryImage from 'src/components/ImageGallery'
import ModalImage from 'src/components/Modal/ImageModal'
import { ADMIN, SUPER_ADMIN } from 'src/utils/permission'
import { useTranslation } from 'react-i18next'
import { en } from './i18n/en'
import { pt } from './i18n/pt'
import EditDiarioPraiaDialog from './EditDiarioPraiaDialog'
import AddDiarioPraiaDialog from './AddDiarioPraiaDialog'
import { columns, config } from './tableConf'

/* i18n.addResourceBundle('en', 'diarios', en)
i18n.addResourceBundle('pt', 'diarios', pt)  */

function DiarioPraia() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const diariosPraias = useSelector((state) => state.diariosPraias)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')
  const [currentItem, setCurrentItem] = useState(null)
  const [data, setData] = useState([])
  const [images, setImages] = useState(null)
  const [imageShow, setShowImage] = useState(false)
  const [role, setRole] = useState(null)
  const { t } = useTranslation('diarios')

  const destroy = (record, index) => {
    Swal.fire({
      title: 'Eliminar Registo?',
      text: 'Ação não pode ser revertido!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF7F47',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, eliminar!',
      cancelButtonText: 'Cancelar',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteDiarioPraia(record.id))
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success')
      }
    })
  }

  const changeItem = (record, index) => {
    setAction('')
    setCurrentItem(record)
    setVisible(true)
  }

  const showImage = (record, index) => {
    setImages(record.image)
    setShowImage(true)
  }

  useEffect(() => {
    dispatch(retrieveDiarioPraia())
      .unwrap()
      .then(() => setLoading(false))
  }, [dispatch])

  useEffect(() => {
    if (user) {
      let datas = diariosPraias

      if (user.role.name !== SUPER_ADMIN) {
        datas = diariosPraias.filter((grupo) => grupo.gruposand.id === user.gruposand.id)
      }
      const data = datas.map((item, index) => ({
        index: index + 1,
        id: item.id,
        date: moment(item.date).format('DD-MM-YYYY'),
        descrição: item.description,
        praia: item.praia && item.praia.name_praia,
        grupo: item.gruposand && item.gruposand.name_grupo,
        image: item.foto,
      }))
      setData(data)
      setRole(user.role.name)
    }
  }, [diariosPraias, user])
  return (
    <CContainer fluid>
      <TableWrapper title="Lista Diário de Praia">
        <CRow className="mb-4 ">
          {ADMIN.includes(role) && (
            <CCol>
              <CButton
                className="main-button"
                onClick={() => {
                  setAction('add')
                  setVisible(true)
                }}
              >
                Adicionar
              </CButton>
            </CCol>
          )}
        </CRow>
        <div className="table-responsive">
          <ReactDatatable
            config={config(user && role)}
            records={data}
            loading={loading}
            columns={columns(destroy, changeItem, showImage, role)}
          />
        </div>
      </TableWrapper>
      {action === 'add' ? (
        <AddDiarioPraiaDialog visible={visible} setVisible={setVisible} />
      ) : (
        <EditDiarioPraiaDialog visible={visible} setVisible={setVisible} item={currentItem} />
      )}

      <ModalImage visible={imageShow} setVisible={setShowImage}>
        <GalleryImage items={images} />
      </ModalImage>
    </CContainer>
  )
}
export default DiarioPraia
