import { CContainer } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import Loader from 'src/components/Loader/Card'
import PaginateItems from 'src/components/Pagination'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import image from '../../../assets/images/blog/b-1.png'

const ApiUrl = process.env.REACT_APP_SERVER_URL

function Search() {
  const { query } = useParams()
  const dispatch = useDispatch()
  const praias = useSelector((state) => state.praias)
  const [filteredPraias, setFilteredPraias] = useState([])
  const [currentItems, setCurrentItems] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (query !== null) {
      const data = praias.filter((praia) =>
        praia.name_praia.toLowerCase().includes(query.toLowerCase()),
      )
      setFilteredPraias(data)
    }
  }, [praias, query])

  useEffect(() => {
    setLoading(false)
    dispatch(retrievePraias())
      .unwrap()
      .then(() => setLoading(false))
  }, [dispatch])
  return (
    <CContainer>
      <div style={{ height: '76px' }} />
      <h3 className="mt-4">Resultado da pesquisa:</h3>
      <div className="content-result">
        <div className="row">
          {loading ? (
            <Loader />
          ) : (
            <PaginateItems
              itemsPerPage={8}
              items={filteredPraias}
              setCurrentItems={setCurrentItems}
            >
              {currentItems ? (
                currentItems.map((praia) => (
                  <div key={praia.id} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="package-card">
                      <div className="package-thumb">
                        <Link to={`/praia-details/${praia.id}`}>
                          <img
                              src={
                                praia.image_praia.length ? ApiUrl + praia.image_praia[0].url : image
                              }
                              alt=""
                              className="img-fluid"
                            />
                        </Link>
                      </div>
                      <div className="package-details">
                        <h3>
                          <Link className="fs-5" to={`/praia-details/${praia.id}`}>
                            <i className="fas fa-map-marker-alt" />
                            {praia.name_praia}
                          </Link>
                        </h3>
                        <div className="package-rating">
                          <strong>{praia.concelho.name_concelho}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>Praia não encontrada</p>
              )}
            </PaginateItems>
          )}
        </div>
      </div>
    </CContainer>
  )
}

export default Search
