import axiosService from '../../axios'

class SedimentoService {
  create(data) {
    return axiosService.post('/amostraareias', data)
  }

  retrieve() {
    return axiosService.get('/amostraareias')
  }

  update(data, id) {
    return axiosService.put(`/amostraareias/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/amostraareias/${id}`)
  }
}

export default new SedimentoService()
