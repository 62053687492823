import React, { memo, useEffect, useState } from 'react'
import {
  CAvatar,
  CCol,
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormCheck,
  CFormSelect,
  CRow,
} from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux'
import { retrieveIlhas } from 'src/store/reducers/ilhaSlice'
import { SelectPicker } from 'rsuite'
import { retrieveItem } from 'src/store/reducers/itemSlice'
import { handleGroupItem, handleGroupPraia } from 'src/store/reducers/mapSlice'
import { useLocation } from 'react-router-dom'
import loader from 'src/assets/load.json'
import Lottie from 'react-lottie'
import { SUPER_ADMIN } from 'src/utils/permission'
import picture from '../../assets/picture.png'

const URL = process.env.REACT_APP_SERVER_URL

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const Select = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { user } = useSelector((state) => state.account)
  const { map, groupPraia, groupItem } = useSelector((state) => state.map)
  const { loading } = useSelector((state) => state.utils)
  const ilhas = useSelector((state) => state.ilhas)
  const items = useSelector((state) => state.items)
  const praias = useSelector((state) => state.praias)
  const [filteredPraias, setFilteredPraias] = useState([])
  const [newPraias, setNewPraias] = useState([])
  const [ilhaOptions, setIlhaOptions] = useState([])
  const [praiaOptions, setPraiaOptions] = useState([])
  const [ilhaId, setIlhaId] = useState(null)
  const [praia, setPraia] = useState(null)

  useEffect(() => {
    if (ilhaId) {
      const ilha = ilhas.find((ilha) => ilha.id === ilhaId)
      const { lat, lng } = ilha.coordinate
      map.flyTo([lat, lng], 11)
    }
  }, [ilhaId, map, ilhas])

  useEffect(() => {
    if (praia) {
      const praias = newPraias.find((item) => item.id === praia)
      const { lat, lng } = praias.coordinates
      map.flyTo([lat, lng], 17)
    }
  }, [praia, map, newPraias])

  useEffect(() => {
    const options = ilhas.map((item) => ({
      value: item.id,
      label: item.name_ilha,
    }))
    setIlhaOptions(options)
  }, [ilhas])

  // filter all beaches with coordinate
  useEffect(() => {
    let filtered = praias.filter((item) => item.coordinates)

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = filtered.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id)
      }
    }

    setNewPraias(filtered)
    setFilteredPraias(filtered)
  }, [praias, user])

  useEffect(() => {
    const options = filteredPraias.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }))
    setPraiaOptions(options)
  }, [filteredPraias])

  useEffect(() => {
    if (ilhaId) {
      const options = newPraias.filter((item) => item.concelho.ilha === ilhaId)
      setFilteredPraias(options)
    }
  }, [ilhaId, newPraias])

  useEffect(() => {
    dispatch(retrieveIlhas())
    dispatch(retrieveItem())
  }, [dispatch])
  return (
    <CContainer fluid className="mb-3" style={{ padding: 0 }}>
      <CRow style={{ padding: 0 }} xs={{ gutterY: 2 }}>
        <CCol sm={6} md={5} lg={2}>
          <SelectPicker
            placeholder="Selecionar ilha"
            data={ilhaOptions}
            onChange={setIlhaId}
            style={{ width: '100%' }}
          />
        </CCol>
        <CCol sm={6} md={5} lg={2}>
          <SelectPicker
            placeholder="Selecionar praia"
            data={praiaOptions}
            onChange={setPraia}
            style={{ width: '100%' }}
          />
        </CCol>
        <CCol sm={6} md={5} lg={2} className="d-flex align-items-center">
          <CFormCheck
            onChange={() => dispatch(handleGroupPraia(!groupPraia))}
            id="praia"
            label={groupPraia ? 'Desagrupar praia' : 'Agrupar praia'}
            checked={groupPraia}
          />
        </CCol>

        {location.pathname === '/praia/adicionar-items' && (
          <>
            <CCol sm={6} md={5} lg={2} className="d-flex align-items-center">
              <CFormCheck
                onChange={() => dispatch(handleGroupItem(!groupItem))}
                id="item"
                label={groupItem ? 'Desagrupar item' : 'Agrupar item'}
                checked={groupItem}
              />
            </CCol>
            <CCol sm={6} md={5} lg={1}>
              <CDropdown>
                <CDropdownToggle size="sm" color="secondary">
                  Legenda de items
                </CDropdownToggle>
                <CDropdownMenu>
                  {items.map((item) => (
                      <CDropdownItem key={item.id}>
                        <CAvatar className="me-2" src={item.img ? URL + item.img.url : picture} />
                        {item.name_item}
                      </CDropdownItem>
                    ))}
                </CDropdownMenu>
              </CDropdown>
            </CCol>
          </>
        )}

        {loading && (
          <CCol className="d-flex justify-content-end align-items-center">
            <div className="">
              <Lottie
                className="bg-black"
                options={defaultOptions}
                height={50}
                width={50}
                isStopped={false}
                isPaused={false}
              />
            </div>
          </CCol>
        )}
      </CRow>
    </CContainer>
  )
}
export default memo(Select)
