import { CContainer, CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react'
import React, { useState } from 'react'
import TableWrapper from 'src/components/TableWrapper'
import MedicaoCorrente from './Corrente'
import MedicaoOnda from './Onda'

function Medicao() {
  const [activeKey, setActiveKey] = useState(1)
  return (
    <CContainer fluid>
      <TableWrapper title="Medições">
        <CNav variant="tabs">
          <CNavItem>
            <CNavLink
              className="tab-nav-link"
              active={activeKey === 1}
              onClick={() => setActiveKey(1)}
              href="#"
            >
              Correntes Litorâneas
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink
              className="tab-nav-link"
              active={activeKey === 2}
              onClick={() => setActiveKey(2)}
              href="#"
            >
              Ondas
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          <CTabPane visible={activeKey === 1}>
            <MedicaoCorrente />
          </CTabPane>
          <CTabPane visible={activeKey === 2}>
            <MedicaoOnda />
          </CTabPane>
        </CTabContent>
      </TableWrapper>
    </CContainer>
    /*
    <CContainer>
      <TableWrapper title="Medicao">
        <CNav variant="tabs">
          <CNavItem>
            <CNavLink
              className="tab-nav-link"
              active={activeKey === 1}
              onClick={() => setActiveKey(1)}
              href="#"
            >
              Corrente
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink
              className="tab-nav-link"
              active={activeKey === 2}
              onClick={() => setActiveKey(2)}
              href="#"
            >
              Onda
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          <CTabPane visible={activeKey === 1}>
            <MedicaoCorrente />
          </CTabPane>
          <CTabPane visible={activeKey === 2}>
            <MedicaoOnda />
          </CTabPane>
        </CTabContent>
      </TableWrapper>
    </CContainer> */
  )
}
export default Medicao
