import axiosService from '../axios'

class GruposandService {
  create(data) {
    return axiosService.post('/gruposands', data)
  }

  retrieve() {
    return axiosService.get('/gruposands')
  }

  update(data, id) {
    return axiosService.put(`gruposands/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`gruposands/${id}`)
  }
}

export default new GruposandService()
