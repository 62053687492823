import React, { memo } from "react";
import { Link } from "react-router-dom";
import semPraia from "../../assets/images/praias/banner-3.png";


import SANTAO from "../../assets/images/banners/SANTAO_.jpg";
import SVICENTE from "../../assets/images/banners/SVICENTE_.jpg";
import SNICOLAU from "../../assets/images/banners/SNICOLAU_.jpg";
import SAL from "../../assets/images/banners/SAL_.jpg";
import BVIST from "../../assets/images/banners/BVIST_.jpg";
import MAIO from "../../assets/images/banners/MAIO_.jpg";
import STIAGO from "../../assets/images/banners/STIAGO_.jpg";
import FOGO from "../../assets/images/banners/FOGO.jpg";
import BRAVA from "../../assets/images/banners/BRAVA_.jpg";
import { PropTypes } from "prop-types";

const ApiUrl = process.env.REACT_APP_SERVER_URL;

function PraiaCard({ praia, ilha }) {
  const getIlhaImage = () => {
    ilha = ilha.toLowerCase();
    return ilha === "santo antão"
      ? SANTAO
      : ilha === "são vicente"
      ? SVICENTE
      : ilha === "são nicolau"
      ? SNICOLAU
      : ilha === "sal"
      ? SAL
      : ilha === "boa vista"
      ? BVIST
      : ilha === "maio"
      ? MAIO
      : ilha === "santiago"
      ? STIAGO
      : ilha === "fogo"
      ? FOGO
      : BRAVA;
  };
  return (
    <div className="package-card">
      <div className="package-thumb">
        <Link to={`/praia-details/${praia.id}`}>
          <img
            src={
              praia.image_praia.length
                ? ApiUrl + praia.image_praia[0].url
                : getIlhaImage()
            }
            alt=""
            className="img-fluid"
          />
        </Link>
      </div>
      <div className="package-details">
        <h3>
          <Link className="fs-5" to={`/praia-details/${praia.id}`}>
            <i className="fas fa-map-marker-alt" />
            {praia.name_praia}
          </Link>
        </h3>
        <div className="package-rating">
          <strong>{praia.concelho}</strong>
        </div>
      </div>
    </div>
  );
}
PraiaCard.propTypes = {
  praia: PropTypes.any,
};

export default memo(PraiaCard);
