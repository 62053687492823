import React from 'react'
import MainBanner from './MainBanner'
import Praias from './Praias'

function Home() {
  return (
    <div>
      <div style={{ height: '78px' }} />
      <MainBanner />
      <Praias />
    </div>
  )
}

export default Home
