import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import FloraPraiaService from '../../service/monitorizacao/Flora'

export const createFloraPraia = createAsyncThunk('floraPraia/create', async (data) => {
  const response = await FloraPraiaService.create(data)
  return response.data
})
export const retrieveFloraPraia = createAsyncThunk('floraPraia/retrieve', async () => {
  const response = await FloraPraiaService.retrieve()
  return response.data
})

export const updateFloraPraia = createAsyncThunk('floraPraia/update', async ({ data, id }) => {
  const response = await FloraPraiaService.update(data, id)
  return response.data
})
export const deleteFloraPraia = createAsyncThunk('floraPraia/delete', async (id) => {
  const response = await FloraPraiaService.delete(id)
  return response.data
})

const initialState = []

const floraPraiaSlice = createSlice({
  name: 'FloraPraia',
  initialState,
  extraReducers: {
    [createFloraPraia.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveFloraPraia.fulfilled]: (state, action) => [...action.payload],
    [updateFloraPraia.fulfilled]: (state, action) => {
      const index = state.findIndex((FloraPraia) => FloraPraia.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteFloraPraia.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default floraPraiaSlice.reducer
