import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { retrieveGruposand } from 'src/store/reducers/gruposandSlice'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import Swal from 'sweetalert2'
import { updateDiarioPraia } from 'src/store/reducers/diarioPraiaSlice'
import Date, { convert } from 'src/utils/Date'
import SwalAlert from 'src/utils/SwalAlert'
import { ADMIN_PRAIA, SUPER_ADMIN } from 'src/utils/permission'
import UploadService from '../../../service/Upload'

function EditDiarioPraiaDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.account)

  const [grupoOptions, setGrupoOption] = useState([])
  const [praiaOptions, setPraiaOption] = useState([])

  const gruposands = useSelector((state) => state.gruposands)
  const praias = useSelector((state) => state.praias)

  const [date, setDate] = useState('')
  const [praia, setPraia] = useState('')
  const [gruposand, setGrupo] = useState()
  const [description, setDescription] = useState()
  const [images, setImage] = useState(null)

  const initState = () => {
  }
  const saveDiarioPraia = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    let foto
    if (images) {
      formData.append('files', images[0])
      const { data } = await UploadService.upload(formData)
      foto = data
    }
    const data = { date, description, praia, foto, gruposand }

    dispatch(
      updateDiarioPraia({
        data,
        id: item.id,
      }),
    )
      .then(() => {
        setVisible(false)
        SwalAlert.success()
      })
      .catch(() => {
        SwalAlert.error()
      })
  }

  /* if (ADMIN_PRAIA.includes(user.role.name)) {
    values.gruposand = user.gruposand.id;
  }
 */

  useEffect(() => {
    if (item) {
      const praia = praias.find((praia) => praia.name_praia === item.praia)
      const gruposand = gruposands.find((grupo) => grupo.name_grupo === item.grupo)
      setPraia(praia.id)
      setGrupo(gruposand.id)
      setDescription(item.descrição)
      setDate(convert(item.date))
    }
  }, [item, praias, gruposands])

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }))
    setGrupoOption(options)
  }, [gruposands])

  useEffect(() => {
    let filtered = praias;

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id)
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }))
    setPraiaOption(options)
  }, [praias, user])

  useEffect(() => {
    dispatch(retrieveGruposand())
    dispatch(retrievePraias())
  }, [dispatch])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar Diario Praia</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={saveDiarioPraia}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Praia</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={praiaOptions}
                  style={{ width: '100%' }}
                  value={praia}
                  onChange={setPraia}
                />
              </div>
            </CCol>
            {SUPER_ADMIN.includes(user?.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">Grupo</CFormLabel>
                    </CCol>
                  </CRow>
                  <SelectPicker
                    data={grupoOptions}
                    style={{ width: '100%' }}
                    defaultValue={gruposand}
                    value={gruposand}
                    onChange={setGrupo}
                  />
                </div>
              </CCol>
            )}
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Data</CFormLabel>
                  </CCol>
                </CRow>
                <DatePicker
                  className="w-100"
                  format="dd-MM-yyyy"
                  defaultValue={date}
                  value={date}
                  onChange={setDate}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Descrição</CFormLabel>
                <CFormTextarea
                  defaultValue={description}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto</CFormLabel>
                <CFormInput
                  type="file"
                  id="item"
                  accept=".png,.jpg"
                  onChange={(e) => setImage(e.target.files)}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2"/>
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2"/>
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}

EditDiarioPraiaDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
}

export default EditDiarioPraiaDialog
