import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import RoleService from '../../service/Role'

export const retrieveRoles = createAsyncThunk('role/retrieve', async () => {
  const response = await RoleService.retrieve()
  return response.data
})

const initialState = []

const roleSlice = createSlice({
  name: 'role',
  initialState,
  extraReducers: {
    [retrieveRoles.fulfilled]: (state, action) => [...action.payload.roles],
  },
})

export default roleSlice.reducer
