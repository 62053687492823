import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler,
  CNavItem,
  CFormSelect,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu, cilTranslate } from '@coreui/icons'
import { useTranslation } from 'react-i18next'
import NavItem from 'rsuite/esm/Nav/NavItem'
import logo from '../assets/logo.png'

import { AppHeaderDropdown } from './header/index'
import { showSidebar } from '../store/reducers/sidebar'

import pt from '../assets/flags/portugal.png'
import en from '../assets/flags/united-kingdom.png'

const AppHeader = () => {
  const dispatch = useDispatch()
  const { sidebarShow } = useSelector((state) => state.sidebar)
  const { i18n, t } = useTranslation()
  const [selected, setSelected] = useState(i18n.language)

  const langs = [
    { name: 'pt', desc: t('langPt') },
    { name: 'en', desc: t('langEn') },
  ]

  const handleSelect = (flag) => {
    setSelected(flag)
    i18n.changeLanguage(flag)
  }

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => {
            dispatch(showSidebar(!sidebarShow))
          }}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          <img src={logo} alt="logo" /> <span className="ms-2">SandwatchCV</span>
        </CHeaderBrand>

        <CHeaderNav className="ms-3 d-flex align-content-center">
          <CNavItem>
            <CHeaderNav className="d-flex align-content-center">
              <NavItem href="http://vmi762908.contaboserver.net/help/" target="_blank">
                <i className="fas fa-question-circle" />
              </NavItem>
              <CNavItem>
                <CDropdown className="flag-Dropdown">
                  <CDropdownToggle className="flag-drop-btn">
                    <CIcon icon={cilTranslate} />
                  </CDropdownToggle>
                  <CDropdownMenu className="flag-list">
                    {langs.map((lang) => (
                      <CDropdownItem
                        key={lang.name}
                        onClick={() => handleSelect(lang.name)}
                        className="flag-item"
                      >
                        {' '}
                        {lang.desc}
                      </CDropdownItem>
                    ))}
                  </CDropdownMenu>
                </CDropdown>
              </CNavItem>
            </CHeaderNav>
          </CNavItem>
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  )
}
export default AppHeader
