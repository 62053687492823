import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { retrieveGruposand } from 'src/store/reducers/gruposandSlice'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import { createProjeto } from 'src/store/reducers/projetoSlice'
import SwalAlert from 'src/utils/SwalAlert'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ADMIN_PRAIA, SUPER_ADMIN } from 'src/utils/permission'
import { schema } from './validation'
import UploadService from '../../../service/Upload'

function AddProjetoDialog({ visible, setVisible }) {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const gruposands = useSelector((state) => state.gruposands)
  const praias = useSelector((state) => state.praias)

  const [grupoOptions, setGrupoOption] = useState([])
  const [praiaOptions, setPraiaOption] = useState([])

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const [images, setImage] = useState(null)

  /* save projeto */
  const saveProjeto = async (data) => {
    const formData = new FormData()
    let img
    if (images) {
      formData.append('files', images[0])
      const { data } = await UploadService.upload(formData)
      img = data
    }

    const values = {
      ...data,
      praia: parseInt(data.praia, 10),
      gruposand: parseInt(data.gruposand, 10),
      start_date: new Date(data.start_date),
      end_date: new Date(data.end_date),
      img,
    }

    if (ADMIN_PRAIA.includes(user.role.name)) {
      values.gruposand = user.gruposand.id
    }
    console.log(values)
    dispatch(createProjeto({ ...data, ...values, img }))
      .unwrap()
      .then(() => {
        SwalAlert.success()
        reset({
          praia: '',
          gruposand: '',
          name: '',
          objective: '',
          start_date: '',
          end_date: '',
          background: '',
          planned_activities: '',
          resources_needed: '',
          how_will_we_know_we_achieved_our_objective: '',
        })
      })
      .catch((err) => {
        SwalAlert.error()
      })
  }

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }))
    setGrupoOption(options)
  }, [gruposands])

  useEffect(() => {
    let filtered = praias

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id)
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }))
    setPraiaOption(options)
  }, [praias, user])

  useEffect(() => {
    dispatch(retrieveGruposand())
    dispatch(retrievePraias())
  }, [dispatch])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar Projetos</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={handleSubmit(saveProjeto)}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Praia <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="praia"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker {...field} data={praiaOptions} style={{ width: '100%' }} />
                  )}
                />
                <small className="text-danger">{errors.praia?.message}</small>
              </div>
            </CCol>
            {SUPER_ADMIN.includes(user.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">
                        Grupo <strong className="text-danger">*</strong>
                      </CFormLabel>
                    </CCol>
                  </CRow>
                  <Controller
                    name="gruposand"
                    control={control}
                    render={({ field }) => (
                      <SelectPicker {...field} data={grupoOptions} style={{ width: '100%' }} />
                    )}
                  />
                  <small className="text-danger">{errors.gruposand?.message}</small>
                </div>
              </CCol>
            )}
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Projeto</CFormLabel>
                <CFormInput type="text" id="item" {...register('name')} />
                <small className="text-danger">{errors.name?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Objetivo</CFormLabel>
                <CFormInput type="text" id="item" {...register('objective')} />
                <small className="text-danger">{errors.objective?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data de Inicio <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="start_date"
                  control={control}
                  render={({ field }) => <DatePicker className="w-100" {...field} />}
                />
                <small className="text-danger">{errors.start_date?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data de Fim <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="end_date"
                  control={control}
                  render={({ field }) => <DatePicker className="w-100" {...field} />}
                />
                <small className="text-danger">{errors.end_date?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Fundos(CVE) <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput type="number" id="item" {...register('background')} />
                <small className="text-danger">{errors.background?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Atividade Planeadas</CFormLabel>
                <CFormTextarea {...register('planned_activities')} />
                <small className="text-danger">{errors.planned_activities?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Recursos Necessários</CFormLabel>
                <CFormTextarea {...register('resources_needed')} />
                <small className="text-danger">{errors.resources_needed?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Objetivos Atingidos</CFormLabel>
                <CFormTextarea {...register('how_will_we_know_we_achieved_our_objective')} />
                <small className="text-danger">
                  {errors.how_will_we_know_we_achieved_our_objective?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto</CFormLabel>
                <CFormInput type="file" id="item" onChange={(e) => setImage(e.target.files)} />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
AddProjetoDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default AddProjetoDialog
