import React, { useEffect, useState } from 'react'
import {
  CButton,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { updateDetrito } from 'src/store/reducers/detritoSlice'
import { updateFauna } from 'src/store/reducers/faunaSlice'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { updateItem } from '../../../store/reducers/itemSlice'

function EditFaunaDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch()
  const [name, setItem] = useState('')
  const [id, setId] = useState(null)

  useEffect(() => {
    setId(item.id)
    setItem(item.name)
  }, [item])

  const saveDetrito = (e) => {
    e.preventDefault()

    const data = { species: name }
    dispatch(updateFauna({ data, id }))
      .unwrap()
      .then(() => {
        Swal.fire({
          text: 'Fauna editado com sucesso!',
          icon: 'success',
          confirmButtonColor: '#FF7F47',
          confirmButtonText: 'Ok!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        })
      })
      .catch((err) => {
        Swal.fire({
          text: 'Fauna não editado!',
          icon: 'error',
          confirmButtonColor: '#FF7F47',
          confirmButtonText: 'Ok!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        })
      })
    setVisible(false)
  }

  return (
    <CModal
      backdrop="static"
      alignment="center"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar Fauna</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={saveDetrito}>
        <CModalBody>
          <div className="mb-3">
            <CFormLabel htmlFor="item">Fauna</CFormLabel>
            <CFormInput
              type="text"
              id="item"
              value={name}
              onChange={(e) => setItem(e.target.value)}
            />
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
EditFaunaDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  item: PropTypes.object,
  children: PropTypes.node,
}

export default EditFaunaDialog
