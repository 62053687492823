import * as Yup from 'yup'

export const addSchema = Yup.object({
  praia: Yup.string().required('Campo obrigatorio'),
  // gruposand: Yup.string().required('Campo obrigatorio'),
  direction_after_1_minute: Yup.number('Adicionar apenas numeros')
    .positive()
    .min(1, 'Valor minimo 1')
    .required('Campo obrigatorio'),
  distance_after_1_minute: Yup.number('Adicionar apenas numeros')
    .positive()
    .min(1, 'Valor minimo 1')
    .required('Campo obrigatorio'),
  spoed_after_1_minute: Yup.number('Adicionar apenas numeros')
    .positive()
    .min(1, 'Valor minimo 1')
    .required('Campo obrigatorio'),
  direction_after_5_minutes: Yup.number('Adicionar apenas numeros')
    .positive()
    .min(1, 'Valor minimo 1')
    .required('Campo obrigatorio'),
  distance_after_5_minutes: Yup.number('Adicionar apenas numeros')
    .positive()
    .min(1, 'Valor minimo 1')
    .required('Campo obrigatorio'),
  spoed_after_5_minutes: Yup.number('Adicionar apenas numeros')
    .positive()
    .min(1, 'Valor minimo 1')
    .required('Campo obrigatorio'),
  date: Yup.string().required('Campo obrigatorio'),
})
