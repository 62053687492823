import axiosService from '../../axios'

class AmostraAguaService {
  create(data) {
    return axiosService.post('/amostraaguas', data)
  }

  retrieve() {
    return axiosService.get('/amostraaguas')
  }

  update(data, id) {
    return axiosService.put(`/amostraaguas/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/amostraaguas/${id}`)
  }
}

export default new AmostraAguaService()
