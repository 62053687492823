import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import ReactDatatable from '@ashvin27/react-datatable'
import { retrieveSedimento, deleteSedimento } from 'src/store/reducers/sedimentoSlice'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'
import SwalAlert from 'src/utils/SwalAlert'
import GalleryImage from 'src/components/ImageGallery'
import ModalImage from 'src/components/Modal/ImageModal'
import { ADMIN, SUPER_ADMIN } from 'src/utils/permission'
import EditSedimentoDialog from './EditSedimentoDialog'
import AddSedimentoDialog from './AddSedimentoDialog'
import { columns, config } from './tableConf'

function Sedimento() {
  const dispatch = useDispatch()
  const sedimentos = useSelector((state) => state.sedimentos)
  const { user } = useSelector((state) => state.account)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')
  const [currentItem, setCurrentItem] = useState(null)
  const [images, setImages] = useState(null)
  const [imageShow, setShowImage] = useState(false)
  const [role, setRole] = useState(null)

  const [data, setData] = useState([])

  const destroy = (record, index) => {
    SwalAlert.warning().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSedimento(record.id))
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success')
      }
    })
  }

  const changeItem = (record, index) => {
    setAction('edit')
    setCurrentItem(record)
    setVisible(true)
  }

  const showImage = (record, index) => {
    setImages(record.photo)
    setShowImage(true)
  }

  useEffect(() => {
    dispatch(retrieveSedimento())
      .unwrap()
      .then(() => setLoading(false))
  }, [dispatch])

  useEffect(() => {
    if (user) {
      let datas = sedimentos

      if (user.role.name !== SUPER_ADMIN) {
        datas = sedimentos.filter((sedimento) => sedimento.gruposand.id === user.gruposand.id)
      }
      const data = datas.map((item, index) => ({
        index: index + 1,
        id: item.id,
        praia: item.praia.name_praia,
        grupo: item.gruposand.name_grupo,
        sample_number_sand: item.sample_number_sand,
        batch_number_sand: item.batch_number_sand,
        sand_type: item.sand_type,
        selection: item.selection,
        date_collect: moment(item.date_collect).format('DD-MM-YYYY'),
        photo: item.photo,
      }))
      setData(data)
      setRole(user.role.name)
    }
  }, [sedimentos, user])
  return (
    <CContainer lg>
      <CRow className="mb-4 mt-4">
        {/* } <CCol className="d-flex justify-content-end"> */}
        {ADMIN.includes(role) && (
          <CCol>
            <CButton
              className="main-button"
              onClick={() => {
                setAction('add')
                setVisible(true)
              }}
            >
              Adicionar
            </CButton>
          </CCol>
        )}
      </CRow>
      <div className="table-responsive">
        <ReactDatatable
          config={config(user && user.role.name)}
          records={data}
          loading={loading}
          columns={columns(destroy, changeItem, showImage, role)}
        />
      </div>

      {action === 'add' ? (
        <AddSedimentoDialog visible={visible} setVisible={setVisible} />
      ) : action === 'edit' ? (
        <EditSedimentoDialog visible={visible} setVisible={setVisible} item={currentItem} />
      ) : (
        ''
      )}
      <ModalImage visible={imageShow} setVisible={setShowImage}>
        <GalleryImage items={images} />
      </ModalImage>
    </CContainer>
  )
}
export default Sedimento
