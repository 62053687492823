import React from 'react'
import ContentLoader from 'react-content-loader'

const CardLoader = () => (
    <div className="col-lg-3 react-loader">
      <ContentLoader>
        <rect x="8" y="10" rx="10" ry="5" width="100%" height="80" />
        <rect x="8" y="100" rx="4" ry="4" width="150" height="13" />
        <rect x="8" y="120" rx="3" ry="3" width="100" height="10" />
      </ContentLoader>
    </div>
  )
export default CardLoader
