import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { retrieveGruposand } from 'src/store/reducers/gruposandSlice'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import Swal from 'sweetalert2'
import { updateProjeto } from 'src/store/reducers/projetoSlice'
import SwalAlert from 'src/utils/SwalAlert'
import { convert } from 'src/utils/Date'
import { ADMIN_PRAIA, SUPER_ADMIN } from 'src/utils/permission'
import UploadService from '../../../service/Upload'

function EditProjetoDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch()
  const [grupoOptions, setGrupoOption] = useState([])
  const [praiaOptions, setPraiaOption] = useState([])

  const gruposands = useSelector((state) => state.gruposands)
  const praias = useSelector((state) => state.praias)
  const { user } = useSelector((state) => state.account);

  const [start_date, setStartDate] = useState('')
  const [end_date, setEndDate] = useState('')
  const [name, setName] = useState()
  const [objective, setObjective] = useState()
  const [praia, setPraia] = useState('')
  const [gruposand, setGrupo] = useState('')
  const [background, setBackground] = useState()
  const [planned_activities, setPActivities] = useState()
  const [resources_needed, setResourcesN] = useState()
  const [how_will_we_know_we_achieved_our_objective, setObjectiveA] = useState()
  const [images, setImage] = useState(null)

  const saveProjeto = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    let img
    if (images) {
      formData.append('files', images[0])
      const { data } = await UploadService.upload(formData)
      img = data
    }
    const data = {
      start_date,
      end_date,
      name,
      objective,
      praia,
      gruposand,
      background,
      planned_activities,
      resources_needed,
      how_will_we_know_we_achieved_our_objective,
      img,
    }
    console.log(data)

    dispatch(
      updateProjeto({
        data,
        id: item.id,
      }),
    )
      .then(() => {
        setVisible(false)
        SwalAlert.success()
      })
      .catch(() => {
        SwalAlert.error()
      })
  }

  useEffect(() => {
    if (item) {
      const praia = praias.find((praia) => praia.name_praia === item.praia)
      const gruposand = gruposands.find((grupo) => grupo.name_grupo === item.grupo)
      setStartDate(convert(item.data_inicio))
      setEndDate(convert(item.data_fim))
      setPraia(praia.id)
      setGrupo(gruposand.id)
      setName(item.nome)
      setObjective(item.objetivo)
      setBackground(item.fundo)
      setPActivities(item.atividade)
      setResourcesN(item.recurso)
      setObjectiveA(item.objetivo_atingido)
    }
  }, [item, praias, gruposands])

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }))
    setGrupoOption(options)
  }, [gruposands])

  useEffect(() => {
    let filtered = praias

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id)
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }))
    setPraiaOption(options)
  }, [praias, user])

  useEffect(() => {
    dispatch(retrieveGruposand())
    dispatch(retrievePraias())
  }, [dispatch])
  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar Projeto</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={saveProjeto}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Praia</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={praiaOptions}
                  style={{ width: '100%' }}
                  value={praia}
                  onChange={setPraia}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Grupo</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={grupoOptions}
                  style={{ width: '100%' }}
                  defaultValue={gruposand}
                  value={gruposand}
                  onChange={setGrupo}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Projeto</CFormLabel>
                <CFormInput
                  type="text"
                  id="item"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Objetivo</CFormLabel>
                <CFormInput
                  type="text"
                  id="item"
                  value={objective}
                  onChange={(e) => setObjective(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Data de Inicio</CFormLabel>
                  </CCol>
                </CRow>
                <DatePicker
                  className="w-100"
                  format="dd-MM-yyyy"
                  defaultvalue={start_date}
                  value={start_date}
                  onChange={setStartDate}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Data de Fim</CFormLabel>
                  </CCol>
                </CRow>
                <DatePicker
                  className="w-100"
                  format="dd-MM-yyyy"
                  defaultvalue={end_date}
                  value={end_date}
                  onChange={setEndDate}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Fundos(CVE)</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  value={background}
                  onChange={(e) => setBackground(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Atividade Planeadas</CFormLabel>
                <CFormTextarea
                  defaultValue={planned_activities}
                  value={planned_activities}
                  onChange={(e) => setPActivities(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Recursos Necessários</CFormLabel>
                <CFormTextarea
                  defaultValue={resources_needed}
                  value={resources_needed}
                  onChange={(e) => setResourcesN(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Objetivos Atingidos</CFormLabel>
                <CFormTextarea
                  defaultValue={how_will_we_know_we_achieved_our_objective}
                  value={how_will_we_know_we_achieved_our_objective}
                  onChange={(e) => setObjectiveA(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto</CFormLabel>
                <CFormInput type="file" id="item" onChange={(e) => setImage(e.target.files)} />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
EditProjetoDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
}

export default EditProjetoDialog
