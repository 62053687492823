import React from 'react'
import { Marker, Popup } from 'react-leaflet'
import L from 'leaflet'

const URL = process.env.REACT_APP_SERVER_URL

const markerIcon = (icon) =>
  new L.icon({
    iconUrl: icon,
    iconSize: [16, 16],
    iconAnchor: [7.5, 15],
  })

function RenderItem({ itemsPraias, setVisible, setCurrentItem }) {
  return itemsPraias.map((item) => (
    <Marker
      key={item.id}
      icon={markerIcon(URL + item.iten.img?.url)}
      position={[item.coordinate.lat, item.coordinate.lng]}
      eventHandlers={{
        click: () => {
          setCurrentItem(item)
          setVisible(true)
        },
      }}
     />
  ))
}

export default RenderItem
