import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MapContainer, TileLayer, Marker, Popup, LayersControl } from 'react-leaflet'

import { useDispatch } from 'react-redux'
import { getMap } from 'src/store/reducers/mapSlice'
import Draw from './Draw'

function Mapa({ children, style }) {
  const dispatch = useDispatch()
  const [mapa, setMap] = useState(undefined)

  useEffect(() => {
    if (mapa) {
      dispatch(getMap(mapa))
    }
  }, [mapa, dispatch])
  return (
    <MapContainer
      style={style}
      whenCreated={setMap}
      center={[16.130132, -23.928763]}
      zoom={8}
      maxZoom={18}
    >
      <LayersControl position="bottomright">
        <LayersControl.BaseLayer checked name="Mapa">
          <TileLayer
            maxZoom={20}
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Satelite">
          <TileLayer
            maxZoom={18}
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
        </LayersControl.BaseLayer>
      </LayersControl>

      {children}
    </MapContainer>
  )
}
Mapa.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object.isRequired,
}
export default Mapa
