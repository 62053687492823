import React, { useEffect, useState } from 'react';
import {
  CButton,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import UploadService from 'src/service/Upload';
import { retrieveGruposand } from 'src/store/reducers/gruposandSlice';
import { retrieveRoles } from 'src/store/reducers/roleSlice';
import { createUser } from 'src/store/reducers/userSlice';
import { ADMIN_PRAIA, SUPER_ADMIN } from 'src/utils/permission';
import { createItem } from '../../../store/reducers/itemSlice';

function AddUserDialog({ visible, setVisible }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.account);
  const gruposands = useSelector((state) => state.gruposands);
  const roles = useSelector((state) => state.roles);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [gruposand, setGruposand] = useState('');
  const [role, setRole] = useState('');

  const initState = () => {};

  const saveItem = async () => {
    let grupoId = gruposand;

    if (user.role.name === ADMIN_PRAIA) {
      grupoId = user.gruposand.id;
    }

    if (role == 3) {
      grupoId = null;
    }

    dispatch(
      createUser({
        username,
        email,
        password,
        confirmed: true,
        blocked: false,
        role,
        gruposand: grupoId,
      })
    )
      .unwrap()
      .then(() => {
        Swal.fire({
          text: 'Utilizador criado com sucesse!',
          icon: 'success',
          confirmButtonColor: '#FF7F47',
          confirmButtonText: 'Ok!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      })
      .catch(() => {
        Swal.fire({
          text: 'Utilizador não adicionado!',
          icon: 'error',
          confirmButtonColor: '#FF7F47',
          confirmButtonText: 'Ok!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      });
  };

  useEffect(() => {
    dispatch(retrieveGruposand());
    dispatch(retrieveRoles());
  }, [dispatch]);
  console.log(roles);
  return (
    <CModal
      backdrop="static"
      alignment="center"
      visible={visible}
      size="lg"
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar utilizador</CModalTitle>
      </CModalHeader>

      <CModalBody>
        <CRow>
          <CCol lg={6}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">
                Username <strong className="text-danger">*</strong>
              </CFormLabel>
              <CFormInput
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                type="text"
                id="nome"
              />
            </div>
          </CCol>

          <CCol lg={6}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">
                Email <strong className="text-danger">*</strong>
              </CFormLabel>
              <CFormInput
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                id="nome"
              />
            </div>
          </CCol>
          <CCol lg={6}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">
                Palavra-passe <strong className="text-danger">*</strong>
              </CFormLabel>
              <CFormInput
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                id="nome"
              />
            </div>
          </CCol>
          <CCol lg={6}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">
                Confirmar palavra-passe <strong className="text-danger">*</strong>
              </CFormLabel>
              <CFormInput type="text" id="nome" />
            </div>
          </CCol>
          {user && user.role.name === SUPER_ADMIN && (
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="nome">Grupo</CFormLabel>
                <CFormSelect
                  aria-label="Default select example"
                  value={gruposand}
                  onChange={(e) => setGruposand(e.target.value)}
                >
                  <option value="" disabled>
                    Selecionar grupo
                  </option>
                  {gruposands.map((grupo) => (
                    <option key={grupo.id} value={grupo.id}>
                      {grupo.name_grupo}
                    </option>
                  ))}
                </CFormSelect>
              </div>
            </CCol>
          )}
          {user.role.name === SUPER_ADMIN ? (
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="nome">Role</CFormLabel>
                <CFormSelect
                  aria-label="Default select example"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="" disabled>
                    Selecionar role
                  </option>
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </CFormSelect>
              </div>
            </CCol>
          ) : (
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="nome">Role</CFormLabel>
                <CFormSelect
                  aria-label="Default select example"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="" disabled>
                    Selecionar role
                  </option>
                  {roles
                    .filter((role) => role.name != SUPER_ADMIN)
                    .map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                </CFormSelect>
              </div>
            </CCol>
          )}
        </CRow>
      </CModalBody>

      <CModalFooter>
        <CButton color="secondary" onClick={() => setVisible(false)}>
          Cancelar
        </CButton>

        <CButton onClick={saveItem} className="main-button" color="primary">
          Adicionar
        </CButton>
      </CModalFooter>
    </CModal>
  );
}
AddUserDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default AddUserDialog;
