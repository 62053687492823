import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ContagemAtividadeService from '../../service/monitorizacao/ContagemAtividade'

export const createContagemAtividade = createAsyncThunk(
  'contagemAtividade/create',
  async (data) => {
    const response = await ContagemAtividadeService.create(data)
    return response.data
  },
)
export const retrieveContagemAtividade = createAsyncThunk(
  'contagemAtividade/retrieve',
  async () => {
    const response = await ContagemAtividadeService.retrieve()
    return response.data
  },
)

export const deleteContagemAtividade = createAsyncThunk('contagemAtividade/delete', async (id) => {
  const response = await ContagemAtividadeService.delete(id)
  return response.data
})

export const updateContagemAtividade = createAsyncThunk(
  'contagemAtividade/update',
  async ({ data, id }) => {
    const response = await ContagemAtividadeService.update(data, id)
    return response.data
  },
)

const initialState = []

const contagemAtividadeSlice = createSlice({
  name: 'ContagemAtividade',
  initialState,
  extraReducers: {
    [createContagemAtividade.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveContagemAtividade.fulfilled]: (state, action) => [...action.payload],
    [updateContagemAtividade.fulfilled]: (state, action) => {
      const index = state.findIndex(
        (ContagemAtividade) => ContagemAtividade.id === action.payload.id,
      )
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteContagemAtividade.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default contagemAtividadeSlice.reducer
