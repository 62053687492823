import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { updateResultadoAgua } from 'src/store/reducers/resultadoAguaSlice'
import SwalAlert from 'src/utils/SwalAlert'
import { convert } from 'src/utils/Date'

import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'

function EditResultadoDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch()

  const amostras = useSelector((state) => state.amostras)
  const [phOptions, setPhOption] = useState([])

  const [amostra, setAmostra] = useState('')
  const [lote, setLote] = useState('')
  const [date_resultado, setDate_resultado] = useState('')
  const [dissolved_oxygen, setDissolved_oxygen] = useState('')
  const [dissolved_oxygen_demand_BOD_ppm, setDissolved_oxygen_demand_BOD_ppm] = useState('')
  const [fecal_coliform, setFecal_coliform] = useState('')
  const [Counting_enterococcos, setCounting_enterococcos] = useState('')
  const [Counting_escherichina, setCounting_escherichina] = useState('')
  const [ph, setPh] = useState('')
  const [nitrate_ppm, setNitrate_ppm] = useState('')
  const [phosphate_ppm, setPhosphate_ppm] = useState('')
  const [salinity_ppt, setSalinity_ppt] = useState('')
  const [conductivity, setConductivity] = useState('')
  const [temperature, setTemperature] = useState('')
  const [turbidity_jtu, setTurbidity_jtu] = useState('')
  const [
    other_observation_eg_smell_colour_of_water,
    setOther_observation_eg_smell_colour_of_water,
  ] = useState('')

  const saveResultado = async (e) => {
    e.preventDefault()

    const data = {
      date_resultado,
      dissolved_oxygen,
      dissolved_oxygen_demand_BOD_ppm,
      fecal_coliform,
      Counting_enterococcos,
      Counting_escherichina,
      ph,
      nitrate_ppm,
      phosphate_ppm,
      salinity_ppt,
      conductivity,
      temperature,
      turbidity_jtu,
      other_observation_eg_smell_colour_of_water,
    }

    dispatch(
      updateResultadoAgua({
        data,
        id: item.id,
      }),
    )
      .unwrap()
      .then(() => {
        setVisible(false)
        SwalAlert.success()
      })
      .catch(() => {
        SwalAlert.success()
      })
  }

  useEffect(() => {
    if (item) {
      const amostra = amostras.find((amostra) => amostra.sample_number === item.amostra)
      const lote = amostras.find((amostra) => amostra.Batch_number === item.lote)

      setAmostra(amostra.sample_number)
      setLote(lote.Batch_number)
      setDate_resultado(convert(item.date_resultado))
      setDissolved_oxygen(item.dissolved_oxygen)
      setDissolved_oxygen_demand_BOD_ppm(item.dissolved_oxygen_demand_BOD_ppm)
      setFecal_coliform(item.fecal_coliform)
      setCounting_enterococcos(item.Counting_enterococcos)
      setCounting_escherichina(item.Counting_escherichina)
      setPh(item.ph)
      setNitrate_ppm(item.nitrate_ppm)
      setPhosphate_ppm(item.phosphate_ppm)
      setSalinity_ppt(item.salinity_ppt)
      setConductivity(item.conductivity)
      setTemperature(item.temperature)
      setTurbidity_jtu(item.turbidity_jtu)
      setOther_observation_eg_smell_colour_of_water(item.other_observation_eg_smell_colour_of_water)
    }
  }, [item, amostra])

  useEffect(() => {
    const options = [
      { label: '0 - maior grau de acidez', value: '0' },
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '6', value: 'seis' },
      { label: '7 - ph neutro', value: '7' },
      { label: '8', value: '8' },
      { label: '9', value: '9' },
      { label: '10', value: '10' },
      { label: '11', value: '11' },
      { label: '12', value: '12' },
      { label: '13', value: '13' },
      { label: '14 - maior grau de alcanilidade', value: '14' },
    ]
    setPhOption(options)
  }, [])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar Resultado das Amostras </CModalTitle>
      </CModalHeader>
      <CForm onSubmit={saveResultado}>
        <CModalBody>
          <CRow>
            <CCol lg={12}>
              <div className="mb-3">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 100 }}>
                    <TableCell>
                      <center>
                        <i style={{ fontSize: 16 }}>Número de Amostra :</i> &nbsp;
                        {amostra}
                      </center>
                    </TableCell>
                    <TableCell>
                      <i style={{ fontSize: 16 }}> Lote :</i>&nbsp;
                      {lote}
                    </TableCell>
                  </Table>
                </TableContainer>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Data</CFormLabel>
                  </CCol>
                </CRow>
                <DatePicker
                  className="w-100"
                  format="dd-MM-yyyy"
                  defaultValue={date_resultado}
                  value={date_resultado}
                  onChange={setDate_resultado}
                />
              </div>
            </CCol>
            <p1 style={{ color: 'darkblue' }}> ANÁLISE MICROBIOLÓGICA DA AMOSTRA</p1> <br />
            <br />
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Oxigênio dissolvido</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={dissolved_oxygen}
                  onChange={(e) => setDissolved_oxygen(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Demanda Bioquímica de Oxigênio</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={dissolved_oxygen_demand_BOD_ppm}
                  onChange={(e) => setDissolved_oxygen_demand_BOD_ppm(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Bacterias coliformes fecais</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={fecal_coliform}
                  onChange={(e) => setFecal_coliform(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Contagem de Enterococcos fecais- Filtração por Membrana
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={Counting_enterococcos}
                  onChange={(e) => setCounting_enterococcos(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Contagem de Escherichina coli- Filtração por Membrana
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={Counting_escherichina}
                  onChange={(e) => setCounting_escherichina(e.target.value)}
                />
              </div>
            </CCol>
            <p1 style={{ color: 'darkblue' }}> ANÁLISE FÍSICO-QUÍMICO DA AMOSTRA</p1>
            <br />
            <br />
            <CCol lg={4}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Ph</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={phOptions}
                  style={{ width: '100%' }}
                  value={ph}
                  onChange={setPh}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Nitratos</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={nitrate_ppm}
                  onChange={(e) => setNitrate_ppm(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Fosfato</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={phosphate_ppm}
                  onChange={(e) => setPhosphate_ppm(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Salinidade</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={salinity_ppt}
                  onChange={(e) => setSalinity_ppt(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Condutividade</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={conductivity}
                  onChange={(e) => setConductivity(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Temperatura</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={temperature}
                  onChange={(e) => setTemperature(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Turbidez</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={turbidity_jtu}
                  onChange={(e) => setTurbidity_jtu(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={12}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Mais Observações</CFormLabel>
                <CFormTextarea
                  cols={50}
                  rows={6}
                  style={{ resize: 'none' }}
                  value={other_observation_eg_smell_colour_of_water}
                  onChange={(e) => setOther_observation_eg_smell_colour_of_water(e.target.value)}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
EditResultadoDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
}

export default EditResultadoDialog
