import React, { useEffect, useState } from 'react'
import { CCol, CContainer, CFormSelect, CRow } from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux'
import { retrieveIlhas } from 'src/store/reducers/ilhaSlice'
import { SelectPicker } from 'rsuite'
import { selectPraia } from 'src/store/reducers/selectedPraiaSlice'

const SelectPraia = () => {
  const dispatch = useDispatch()
  const ilhas = useSelector((state) => state.ilhas)
  const praias = useSelector((state) => state.praias)
  const [filteredPraias, setFilteredPraias] = useState([])
  const [newPraias, setNewPraias] = useState([])
  const [ilhaOptions, setIlhaOptions] = useState([])
  const [praiaOptions, setPraiaOptions] = useState([])
  const [ilhaId, setIlhaId] = useState(null)
  const [praia, setPraia] = useState(null)

  useEffect(() => {
    dispatch(selectPraia(praia))
  }, [praia, dispatch])

  useEffect(() => {
    const options = ilhas.map((item) => ({
      value: item.id,
      label: item.name_ilha,
    }))
    setIlhaOptions(options)
  }, [ilhas])

  // filter all beaches with coordinate
  useEffect(() => {
    const filtered = praias.filter((item) => item.coordinates)
    setNewPraias(filtered)
    setFilteredPraias(filtered)
  }, [praias])

  useEffect(() => {
    const options = filteredPraias.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }))
    setPraiaOptions(options)
  }, [filteredPraias])

  useEffect(() => {
    if (ilhaId) {
      const options = newPraias.filter((item) => item.concelho.ilha === ilhaId)
      setFilteredPraias(options)
    }
  }, [ilhaId, newPraias])

  useEffect(() => {
    dispatch(retrieveIlhas())
  }, [dispatch])
  return (
    <CContainer fluid className="mb-3" style={{ padding: 0 }}>
      <CRow style={{ padding: 0 }} xs={{ gutterY: 2 }}>
        <CCol sm={6} md={5} lg={6}>
          <SelectPicker
            data={ilhaOptions}
            onChange={setIlhaId}
            style={{ width: '100%' }}
            placeholder="Selecionar ilha"
          />
        </CCol>
        <CCol lg={6}>
          <SelectPicker
            data={praiaOptions}
            onChange={setPraia}
            style={{ width: '100%' }}
            placeholder="Selecionar praia"
          />
        </CCol>
      </CRow>
    </CContainer>
  )
}
export default SelectPraia
