import React from 'react'
import { cilPen, cilTrash, cilImage } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { ADMIN, OBSERVADOR } from 'src/utils/permission'

export const config = (permission) => ({
  page_size: 10,
  length_menu: [10, 20, 50],
  show_filter: true,
  show_pagination: true,
  pagination: 'advance',
  filename: 'AtividadeHumana',
  button: {
    csv: ADMIN.includes(permission),
    print: ADMIN.includes(permission),
  },
  language: {
    length_menu: 'Mostrar _MENU_ registros por página',
    filter: 'Pesquisar ...',
    info: 'Mostrando _START_ a _END_ de _TOTAL_ entradas',
    pagination: {
      previous: 'Anterior',
      next: 'Próximo',
    },
  },
})

export const columns = (destroy, edit, showImage, permission) => [
  {
    key: 'index',
    text: '#',
    className: 'index',
    width: 20,
    align: 'center',
  },
  {
    key: 'data',
    text: 'Data',
    className: 'name',
    align: 'left',
    width: 200,
  },
  {
    key: 'praia',
    text: 'Praia',
    className: 'address',
    align: 'left',
  },
  {
    key: 'grupo',
    text: 'Grupo',
    className: 'address',
    align: 'left',
  },
  {
    key: 'atividade',
    text: 'Atividade',
    className: 'address',
    align: 'left',
  },
  {
    key: 'time_start',
    text: 'Tempo Inicio',
    className: 'address',
    align: 'left',
  },
  {
    key: 'time_end',
    text: 'Tempo Fim',
    className: 'address',
    align: 'left',
  },

  {
    key: 'image',
    text: 'Foto',
    className: 'address',
    align: 'left',
    width: 50,
    cell: (record, index) => record.image ? (
        <button className="btn btn-secondary btn-sm" onClick={() => showImage(record, index)}>
          <CIcon icon={cilImage} />
        </button>
      ) : (
        'N/A'
      ),
  },
  {
    key: 'action',
    text: 'Action',
    className: 'postcode',
    align: 'center',
    width: 100,
    cell: (record, index) => (
        permission !== OBSERVADOR && (
          <>
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => edit(record, index)}
              style={{ marginRight: '5px' }}
            >
              <CIcon name={cilPen} />
            </button>
            {ADMIN.includes(permission) && (
              <button className="btn btn-danger btn-sm" onClick={() => destroy(record, index)}>
                <CIcon name={cilTrash} />
              </button>
            )}
          </>
        )
      ),
  },
]
