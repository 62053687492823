import { cilSave, cilXCircle } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CHeaderDivider,
  CRow,
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, SelectPicker } from 'rsuite'
import { retrieveClassificacaos } from 'src/store/reducers/classificacaoSlice'
import { retrieveConcelhos } from 'src/store/reducers/concelhoSlice'
import { retrieveGruposand } from 'src/store/reducers/gruposandSlice'
import { createPraia } from 'src/store/reducers/praiaSlice'
import { time } from 'src/utils/Date'
import { ADMIN_PRAIA, SUPER_ADMIN } from 'src/utils/permission'
import SwalAlert from 'src/utils/SwalAlert'
import UploadService from 'src/service/Upload'
import PropTypes from 'prop-types'

function AddInfo({ setVisible }) {
  const dispatch = useDispatch()
  const { coordinate, layerId, map } = useSelector((state) => state.map)
  const ilhas = useSelector((state) => state.ilhas)
  const { user } = useSelector((state) => state.account)
  const concelhos = useSelector((state) => state.concelhos)
  const gruposands = useSelector((state) => state.gruposands)
  const classificacaos = useSelector((state) => state.classificacaos)

  const [ilhaOptions, setIlhaOptions] = useState([])
  const [grupoOptions, setGrupoOptions] = useState([])
  const [concelhoOptions, setConcelhoOptions] = useState([])
  const [classificacaoOptions, setClassifcacaoOptions] = useState([])
  const [images, setImages] = useState([])
  const [ilhaId, setIlhaId] = useState(null)

  const { register, handleSubmit, control, reset } = useForm()

  const removeLayer = () => {
    map.eachLayer((layer) => {
      if (layer._leaflet_id === layerId) {
        map.removeLayer(layer)
      }
    })
  }

  const savePraia = async (data) => {
    let end;
      let start;
      let image = []

    if (data.time_end && data.time_start) {
      end = time(data.time_end)
      start = time(data.time_start)
    }

    if (user.role.name === ADMIN_PRAIA) {
      data.gruposand = user.gruposand.id
    }

    const formData = new FormData()
    if (images.length) {
      for (const key of Object.keys(images)) {
        formData.append('files', images[key])
      }
      const { data } = await UploadService.upload(formData)

      image = data
    }

    const newData = {
      ...data,
      coordinates: coordinate,
      time_end: end,
      time_start: start,
      image_praia: image,
    }
    console.log(newData)

    dispatch(createPraia(newData))
      .unwrap()
      .then(() => {
        removeLayer()
        setVisible(false)
        SwalAlert.success()
      })
      .catch(() => {
        SwalAlert.error()
      })
  }
  // convert island to valid selected options
  useEffect(() => {
    const options = ilhas.map((ilha) => ({
      value: ilha.id,
      label: ilha.name_ilha,
    }))
    setIlhaOptions(options)
  }, [ilhas])

  // convert concelhos to valid selected options
  useEffect(() => {
    const options = concelhos.map((concelho) => ({
      value: concelho.id,
      label: concelho.name_concelho,
    }))
    setConcelhoOptions(options)
  }, [concelhos])

  // convert grupo to valid selected options
  useEffect(() => {
    const options = gruposands.map((grupo) => ({
      value: grupo.id,
      label: grupo.name_grupo,
    }))
    setGrupoOptions(options)
  }, [gruposands])

  // convert classficacao to valid selected options
  useEffect(() => {
    const options = classificacaos.map((classificacao) => ({
      value: classificacao.id,
      label: classificacao.rate,
    }))
    setClassifcacaoOptions(options)
  }, [classificacaos])

  // select concelho according to seleted island
  useEffect(() => {
    if (ilhaId) {
      const newConcelhos = concelhos.filter((concelho) => concelho.ilha.id === ilhaId)
      const options = newConcelhos.map((concelho) => ({
        value: concelho.id,
        label: concelho.name_concelho,
      }))
      setConcelhoOptions(options)
    }
  }, [concelhos, ilhaId])

  useEffect(() => {
    dispatch(retrieveConcelhos())
    dispatch(retrieveGruposand())
    dispatch(retrieveClassificacaos())
  }, [dispatch])

  return (
    <CContainer className="mt-3">
      <CForm onSubmit={handleSubmit(savePraia)}>
        <CRow>
          <CCol lg={3}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">
                Nome <strong className="text-danger">*</strong>
              </CFormLabel>
              <CFormInput type="text" id="nome" {...register('name_praia')} />
            </div>
          </CCol>
          <CCol lg={3}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">
                Ilha <strong className="text-danger">*</strong>
              </CFormLabel>

              <SelectPicker
                value={ilhaId}
                onChange={setIlhaId}
                data={ilhaOptions}
                style={{ width: '100%' }}
              />
            </div>
          </CCol>
          <CCol lg={3}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">
                Concelho <strong className="text-danger">*</strong>
              </CFormLabel>
              <Controller
                name="concelho"
                control={control}
                render={({ field }) => (
                  <SelectPicker {...field} data={concelhoOptions} style={{ width: '100%' }} />
                )}
              />
            </div>
          </CCol>
          <CCol lg={3}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">Zona</CFormLabel>
              <CFormInput type="text" id="nome" {...register('district')} />
            </div>
          </CCol>
          <CCol lg={3}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">Horário de entrada</CFormLabel>
              <Controller
                name="time_start"
                control={control}
                render={({ field }) => (
                  <DatePicker format="HH:mm:ss" className="w-100" {...field} />
                )}
              />
            </div>
          </CCol>
          <CCol lg={3}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">Horário de saída</CFormLabel>
              <Controller
                name="time_end"
                control={control}
                render={({ field }) => (
                  <DatePicker format="HH:mm:ss" className="w-100" {...field} />
                )}
              />
            </div>
          </CCol>
          <CCol lg={3}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">Salva vidas</CFormLabel>
              <CFormInput type="number" id="nome" {...register('saves_lives')} />
            </div>
          </CCol>
          <CCol lg={3}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">
                Classificação <strong className="text-danger">*</strong>
              </CFormLabel>
              <Controller
                name="classificacao"
                control={control}
                render={({ field }) => (
                  <SelectPicker {...field} data={classificacaoOptions} style={{ width: '100%' }} />
                )}
              />
            </div>
          </CCol>
          {user && user.role.name === SUPER_ADMIN && (
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="nome">Grupo</CFormLabel>
                <Controller
                  name="gruposand"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker {...field} data={grupoOptions} style={{ width: '100%' }} />
                  )}
                />
              </div>
            </CCol>
          )}

          <CCol lg={5}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">
                Descrição <strong className="text-danger">*</strong>
              </CFormLabel>
              <CFormTextarea
                id="exampleFormControlTextarea1"
                rows="1"
                {...register('description')}
               />
            </div>
          </CCol>
          <CCol lg={4}>
            <div className="mb-3">
              <CFormLabel htmlFor="formFileMultiple">Fotos</CFormLabel>
              <CFormInput
                type="file"
                id="formFileMultiple"
                multiple
                onChange={(e) => setImages(e.target.files)}
              />
            </div>
          </CCol>
        </CRow>

        <CHeaderDivider />
        <CRow className="d-flex justify-content-end">
          <CCol lg={2}>
            <CButton color="secondary" className="w-100">
              <CIcon icon={cilXCircle} className="me-2" />
              Cancelar
            </CButton>
          </CCol>
          <CCol lg={2}>
            <CButton type="submit" className="main-button w-100" color="primary">
              <CIcon icon={cilSave} className="me-2" />
              Salvar
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </CContainer>
  )
}
AddInfo.propTypes = {
  setVisible: PropTypes.func,
}
export default AddInfo
