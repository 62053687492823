import axiosService from '../../axios'

class MedicaoOndaService {
  create(data) {
    return axiosService.post('/medicaoondas', data)
  }

  retrieve() {
    return axiosService.get('/medicaoondas')
  }

  update(data, id) {
    return axiosService.put(`/medicaoondas/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/medicaoondas/${id}`)
  }
}

export default new MedicaoOndaService()
