import axiosService from '../../axios'

class ErosaoService {
  create(data) {
    return axiosService.post('/erosaos', data)
  }

  retrieve() {
    return axiosService.get('/erosaos')
  }

  update(data, id) {
    return axiosService.put(`/erosaos/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/erosaos/${id}`)
  }
}

export default new ErosaoService()
