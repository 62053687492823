import axiosService from '../axios'

class ItemPraiaService {
  retrieve() {
    return axiosService.get('/items-praias')
  }

  create(data) {
    return axiosService.post('/items-praias', data)
  }

  update(id, data) {
    return axiosService.put(`/items-praias/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/items-praias/${id}`)
  }
}

export default new ItemPraiaService()
