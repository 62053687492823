import React, { useState, useEffect } from 'react'
import { CModal, CModalHeader, CModalTitle } from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { convert } from 'src/utils/Date'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import moment from 'moment'

function VerMaisResultadoBDialog({ visible, setVisible, item }) {
  const sedimentos = useSelector((state) => state.sedimentos)

  const [sedimento, setSedimento] = useState('')
  const [lote, setLote] = useState('')
  const [collect_date_sand, setCollect_date_sand] = useState('')
  const [dissolved_oxygen_sand, setDissolved_oxygen_sand] = useState('')
  const [dissolved_oxygen_demand_BOD_sand, setDissolved_oxygen_demand_BOD_sand] = useState('')
  const [yeast, setYeast] = useState('')
  const [filamentous_fungi, setFilamentous_fungi] = useState('')
  const [protozoa, setProtozoa] = useState('')
  const [fecal_coliform_sand, setFecal_coliform_sand] = useState('')
  const [Counting_enterococcos_sand, setCounting_enterococcos_sand] = useState('')
  const [Counting_escherichina_sand, setCounting_escherichina_sand] = useState('')
  const [nitrate_sand, setNitrate_sand] = useState('')
  const [phosphate_sand, setPhosphate_sand] = useState('')
  const [salinity_sand, setSalinity_sand] = useState('')
  const [conductivity_sand, setConductivity_sand] = useState('')
  const [sand_temperature, setSand_temperature] = useState('')
  const [turbidity_sand, setTurbidity_sand] = useState('')
  const [ph, setPh] = useState('')

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#FFF3E0',

      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  useEffect(() => {
    if (item) {
      const sedimento = sedimentos.find(
        (sedimento) => sedimento.sample_number_sand === item.amostra,
      )
      const lote = sedimentos.find((sedimento) => sedimento.batch_number_sand === item.lote)

      setSedimento(sedimento)
      setLote(lote)
      setCollect_date_sand(item.collect_date_sand)
      setDissolved_oxygen_sand(item.dissolved_oxygen_sand)
      setDissolved_oxygen_demand_BOD_sand(item.dissolved_oxygen_demand_BOD_sand)
      setYeast(item.yeast)
      setFilamentous_fungi(item.filamentous_fungi)
      setProtozoa(item.protozoa)
      setFecal_coliform_sand(item.fecal_coliform_sand)
      setCounting_enterococcos_sand(item.Counting_enterococcos_sand)
      setCounting_escherichina_sand(item.Counting_escherichina_sand)
      setNitrate_sand(item.nitrate_sand)
      setPhosphate_sand(item.phosphate_sand)
      setSalinity_sand(item.salinity_sand)
      setConductivity_sand(item.conductivity_sand)
      setSand_temperature(item.sand_temperature)
      setTurbidity_sand(item.turbidity_sand)
      setPh(item.ph)
    }
  }, [item, sedimento])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Resultado Completo da Amostra </CModalTitle>
      </CModalHeader>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 100 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <strong>Data do Resultado:</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {moment(collect_date_sand).format('DD-MM-YYYY')}
              </StyledTableCell>

              <StyledTableCell align="left">&nbsp;&nbsp;&nbsp;&nbsp;</StyledTableCell>
              <StyledTableCell>&nbsp;&nbsp;&nbsp;&nbsp; </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell align="right">&nbsp;</TableCell>
            <TableCell align="right" />
            <TableCell align="right" />
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell>
                {' '}
                <strong>Parametros </strong>
              </TableCell>
              <TableCell align="center">
                <strong>Unidade</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Quantidade</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell align="left">Oxigênio dissolvido</TableCell>
            <TableCell align="center">(mg/l)</TableCell>
            <TableCell align="center">{dissolved_oxygen_sand}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Demanda Bioquímica de Oxigênio (DBQ)</TableCell>
            <TableCell align="center">(mg/l)</TableCell>
            <TableCell align="center">{dissolved_oxygen_demand_BOD_sand}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Nitrato</TableCell>
            <TableCell align="center">(mg/l)</TableCell>
            <TableCell align="center">{nitrate_sand}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Fosfato</TableCell>
            <TableCell align="center">--</TableCell>
            <TableCell align="center">{phosphate_sand}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Leveduras</TableCell>
            <TableCell align="center">--</TableCell>
            <TableCell align="center">{yeast}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Fungos Filamentosos</TableCell>
            <TableCell align="center">--</TableCell>
            <TableCell align="center">{filamentous_fungi}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Protozoarios e Helmintos</TableCell>
            <TableCell align="center">--</TableCell>
            <TableCell align="center">{protozoa}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Bactérias coliformes fecais</TableCell>
            <TableCell align="center">--</TableCell>
            <TableCell align="center">{fecal_coliform_sand}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">
              Contagem de Enterococcos fecais- Filtração por Membrana
            </TableCell>
            <TableCell align="center">--</TableCell>
            <TableCell align="center">{Counting_enterococcos_sand}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">
              Contagem de Escherichina coli- Filtração por Membrana
            </TableCell>
            <TableCell align="center">--</TableCell>
            <TableCell align="center">{Counting_escherichina_sand}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Ph</TableCell>
            <TableCell align="center">--</TableCell>
            <TableCell align="center">{ph}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Salinidade</TableCell>
            <TableCell align="center">(g/kg)</TableCell>
            <TableCell align="center">{salinity_sand}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Condutividade</TableCell>
            <TableCell align="center">(uS/cm)</TableCell>
            <TableCell align="center">{conductivity_sand}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Temperatura</TableCell>
            <TableCell align="center">(°C)</TableCell>
            <TableCell align="center">{sand_temperature}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Turbidez</TableCell>
            <TableCell align="center">--</TableCell>
            <TableCell align="center">{turbidity_sand}</TableCell>
          </TableBody>
        </Table>
      </TableContainer>
    </CModal>
  )
}
VerMaisResultadoBDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
}

export default VerMaisResultadoBDialog
