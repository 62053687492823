import React, { useState, useEffect } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { cilSave, cilXCircle } from "@coreui/icons";
import { SelectPicker, DatePicker } from "rsuite";
import { retrieveGruposand } from "src/store/reducers/gruposandSlice";
import { retrievePraias } from "src/store/reducers/praiaSlice";
import { retrieveFlora } from "src/store/reducers/floraSlice";
import Swal from "sweetalert2";
import { updateFloraPraia } from "src/store/reducers/floraPraiaSlice";
import SwalAlert from "src/utils/SwalAlert";
import { ADMIN_PRAIA, SUPER_ADMIN } from "src/utils/permission";
import UploadService from "../../../service/Upload";

function EditFloraPraiaDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch();
  const [grupoOptions, setGrupoOption] = useState([]);
  const [praiaOptions, setPraiaOption] = useState([]);
  const [floraOptions, setFloraOption] = useState([]);
  const gruposands = useSelector((state) => state.gruposands);
  const praias = useSelector((state) => state.praias);
  const floras = useSelector((state) => state.floras);
  const { user } = useSelector((state) => state.account);

  const [date, setDate] = useState(new Date());
  const [praia, setPraia] = useState("");
  const [gruposand, setGrupo] = useState();
  const [flora, setFlora] = useState();
  const [quantity, setQuantity] = useState();
  const [size_m, setSizeM] = useState();
  const [observations, setObservation] = useState();
  const [images, setImage] = useState(null);

  const saveFloraPraia = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    let img;
    if (images) {
      formData.append("files", images[0]);
      const { data } = await UploadService.upload(formData);
      img = data;
    }
    const data = {
      date,
      praia,
      gruposand,
      flora,
      size_m,
      quantity,
      observations,
      img,
    };

    dispatch(
      updateFloraPraia({
        data,
        id: item.id,
      })
    )
      .unwrap()
      .then(() => {
        setVisible(false);
        SwalAlert.success();
      })
      .catch(() => {
        SwalAlert.success();
      });
  };

  useEffect(() => {
    if (item) {
      const praia = praias.find((praia) => praia.name_praia === item.praia);
      const gruposand = gruposands.find(
        (grupo) => grupo.name_grupo === item.grupo
      );
      const flora = floras.find((flora) => flora.species === item.flora);
      setPraia(praia.id);
      setGrupo(gruposand.id);
      setQuantity(item.quantidade);
      setObservation(item.observacao);
      setFlora(flora.id);
      setSizeM(item.tamanho);
    }
  }, [item, praias, gruposands, floras]);

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }));
    setGrupoOption(options);
  }, [gruposands]);

  useEffect(() => {
    let filtered = praias;

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id);
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }));
    setPraiaOption(options);
  }, [praias, user]);

  useEffect(() => {
    const options = floras.map((item) => ({
      value: item.id,
      label: item.species,
    }));
    setFloraOption(options);
  }, [floras]);

  useEffect(() => {
    dispatch(retrieveGruposand());
    dispatch(retrievePraias());
    dispatch(retrieveFlora());
  }, [dispatch]);
  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar Flora Praia</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={saveFloraPraia}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Praia</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={praiaOptions}
                  style={{ width: "100%" }}
                  value={praia}
                  onChange={setPraia}
                />
              </div>
            </CCol>
            {SUPER_ADMIN.includes(user?.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">Grupo</CFormLabel>
                    </CCol>
                  </CRow>
                  <SelectPicker
                    data={grupoOptions}
                    style={{ width: "100%" }}
                    defaultValue={gruposand}
                    value={gruposand}
                    onChange={setGrupo}
                  />
                </div>
              </CCol>
            )}
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Flora</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={floraOptions}
                  style={{ width: "100%" }}
                  value={flora}
                  onChange={setFlora}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Data</CFormLabel>
                  </CCol>
                </CRow>
                <DatePicker
                  className="w-100"
                  format="dd-MM-yyyy"
                  value={date}
                  onChange={setDate}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Tamanho</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  required
                  value={size_m}
                  onChange={(e) => setSizeM(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Quantidade</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Observação</CFormLabel>
                <CFormTextarea
                  defaultValue={observations}
                  value={observations}
                  onChange={(e) => setObservation(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto</CFormLabel>
                <CFormInput
                  type="file"
                  id="item"
                  onChange={(e) => setImage(e.target.files)}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2"/>
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2"/>
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  );
}

EditFloraPraiaDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
};

export default EditFloraPraiaDialog;
