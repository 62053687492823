import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { CChartBar } from '@coreui/react-chartjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { retrieveMedicaoOnda } from 'src/store/reducers/medicaoOndaSlice'
import { useTranslation } from 'react-i18next'

const initialMonth = {
  total: 0,
  soma: 0,
}

function ChartVariacaoAlturaOndas({ praia, year }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const medicaoOnda = useSelector((state) => state.medicaoOnda)

  const [data, setData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

  const [jan, setJan] = useState(initialMonth)
  const [fev, setFev] = useState(initialMonth)
  const [mar, setMar] = useState(initialMonth)
  const [abr, setAbr] = useState(initialMonth)
  const [mai, setMai] = useState(initialMonth)
  const [jun, setJun] = useState(initialMonth)
  const [jul, setJul] = useState(initialMonth)
  const [ago, setAgo] = useState(initialMonth)
  const [set, setSet] = useState(initialMonth)
  const [out, setOut] = useState(initialMonth)
  const [nov, setNov] = useState(initialMonth)
  const [dez, setDez] = useState(initialMonth)

  const updateState = (setState, size) => {
    setState((prevState) => ({
      ...prevState,
      total: prevState.total + 1,
      soma: prevState.soma + size,
    }))
  }
  const media = (total, somaTotal) => somaTotal / total
  useEffect(() => {
    setJan(initialMonth)
    setFev(initialMonth)
    setMar(initialMonth)
    setAbr(initialMonth)
    setMai(initialMonth)
    setJun(initialMonth)
    setJul(initialMonth)
    setAgo(initialMonth)
    setSet(initialMonth)
    setOut(initialMonth)
    setNov(initialMonth)
    setDez(initialMonth)

    const data = medicaoOnda.filter((resultado) => resultado.praia.id === praia)

    data.map((onda) => {
      if (moment(onda.date).format('YYYY') === year.toString()) {
        const month = moment(onda.date).format('MMMM')

        switch (month) {
          case 'January':
            updateState(setJan, onda.wave_heigth)
            break
          case 'February':
            updateState(setFev, onda.wave_heigth)
            break
          case 'March':
            updateState(setMar, onda.wave_heigth)
            break
          case 'April':
            updateState(setAbr, onda.wave_heigth)
            break
          case 'May':
            updateState(setMai, onda.wave_heigth)
            break
          case 'June':
            updateState(setJun, onda.wave_heigth)
            break
          case 'July':
            updateState(setJul, onda.wave_heigth)
            break
          case 'August':
            updateState(setAgo, onda.wave_heigth)
            break
          case 'September':
            updateState(setSet, onda.wave_heigth)
            break
          case 'October':
            updateState(setOut, onda.wave_heigth)
            break
          case 'November':
            updateState(setNov, onda.wave_heigth)
            break
          case 'December':
            updateState(setDez, onda.wave_heigth)
            break
          default: {
          }
        }
      }
    })
  }, [medicaoOnda, praia, year])

  useEffect(() => {
    setData([
      media(jan.total, jan.soma),
      media(fev.total, fev.soma),
      media(mar.total, mar.soma),
      media(abr.total, abr.soma),
      media(mai.total, mai.soma),
      media(jun.total, jun.soma),
      media(jul.total, jul.soma),
      media(ago.total, ago.soma),
      media(set.total, set.soma),
      media(out.total, jan.soma),
      media(nov.total, nov.soma),
      media(dez.total, dez.soma),
    ])
  }, [jan, fev, mar, abr, mai, jun, jul, ago, set, out, nov, dez])

  useEffect(() => {
    dispatch(retrieveMedicaoOnda())
  }, [dispatch])

  return (
    <CCard className="mb-4">
      <CCardHeader className="fw-bold">{t('ALTURA_ONDAS')}</CCardHeader>
      <CCardBody>
        <CChartBar
          data={{
            labels: [
              t('MONTH.JAN'),
              t('MONTH.FEV'),
              t('MONTH.MAR'),
              t('MONTH.ABR'),
              t('MONTH.MAI'),
              t('MONTH.JUN'),
              t('MONTH.JUL'),
              t('MONTH.AGO'),
              t('MONTH.SET'),
              t('MONTH.OUT'),
              t('MONTH.NOV'),
              t('MONTH.DEZ'),
            ],
            datasets: [
              {
                label: t('MEDIA'),
                backgroundColor: '#FF7F47',
                borderRadius: 10,
                data,
              },
            ],
          }}
          labels="months"
        />
      </CCardBody>
    </CCard>
  )
}

ChartVariacaoAlturaOndas.propTypes = {
  praia: PropTypes.any,
  year: PropTypes.any,
}
export default ChartVariacaoAlturaOndas
