import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import TartarugaService from '../../service/parametrizacao/Tartaruga'

export const createTartaruga = createAsyncThunk('tartaruga/create', async (data) => {
  const response = await TartarugaService.create(data)
  return response.data
})
export const retrieveTartaruga = createAsyncThunk('tartaruga/retrieve', async () => {
  const response = await TartarugaService.retrieve()
  return response.data
})

export const updateTartaruga = createAsyncThunk('tartaruga/update', async ({ data, id }) => {
  const response = await TartarugaService.update(data, id)
  return response.data
})
export const deleteTartaruga = createAsyncThunk('tartaruga/delete', async (id) => {
  const response = await TartarugaService.delete(id)
  return response.data
})

const initialState = []

const tartarugaSlice = createSlice({
  name: 'Tartaruga',
  initialState,
  extraReducers: {
    [createTartaruga.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveTartaruga.fulfilled]: (state, action) => [...action.payload],
    [updateTartaruga.fulfilled]: (state, action) => {
      const index = state.findIndex((tartaruga) => tartaruga.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteTartaruga.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default tartarugaSlice.reducer
