import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        langEn: 'English',
        langPt: 'Portuguese',
        headerList: {
          home: 'Home',
          resource: 'Resources',
          about: 'About us',
          project: 'Projects & Initiatives',
          contact: 'Contacts',
          login: 'Login',
        },
        loginTitle: 'Login',
        loginSubtitle: 'Enter your credencials to continue',
        loginUserPlaceHolder: 'Useraname',
        loginPassPlaceHolder: 'Password',
        loginButton: 'Login',
        loginForgotPass: 'Forgot-password?',
        user: 'Users',
        praia: {
          name: 'Beach',
          addInfo: 'Add info',
          addItem: 'Add items',
          problems: 'Problems',
          projects: 'Projects & Intiativas',
        },
        sandwatch: {
          name: 'Sandwatch',
          user: 'Users',
          group: 'Grups',
        },
        monitorizacao: {
          name: 'Monitoring',
          agua: 'Water',
          sedimento: 'Sediments',
          erosao: 'Erosion & Accretion',
          fauna: 'Fauna',
          flora: 'Flora',
          ninho: 'Turtle nest',
          atividade: 'Humans Activities',
          diario: 'Beach diary',
          medicao: 'Measurements',
          ficha: 'Cleaning Sheet',
          detrito: 'Detritus',
        },
        parametrizacao: {
          name: 'Parameterization',
          actividade: 'Activities',
          fauna: 'Fauna',
          flora: 'Flora',
          tartaruga: 'Turtle',
          item: 'Item',
          detrito: 'Detritus',
        },

        SECAO1: {
          TITULO: 'Sandwatch Foundation',
          CONTEUDO: `Sandwatch aims to modify children's lifestyle and habits,
            youth and adults on a community-wide basis and to develop
            awareness of the fragility of the marine and coastal environment and the
            need to use it wisely. It is an educational process through
            from which school students and community members learn and work
            together to scientifically monitor their beach environments, so
             to critically assess problems and conflicts. Then they develop
             and implement sustainable activities to address these issues, improve
             the beach environment and build resilience to climate change.`,
        },
        SECAO2: {
          TITULO: 'Sandwatch',
          CONTEUDO: `Sandwatch is a network of school volunteers (students, teachers and principals),
            youth groups, non-governmental and community organizations working together to
            monitor and improve your beach environments.`,
            CONTEUDO2: `The network is coordinated by the Sandwatch Foundation, a non-profit organization that presents an opportunity for people and ecosystems to respond to climate variability and climate change. Beaches are among the ecosystems at risk as they face rising sea levels and changes in storms and extreme events`,
            CONTEUDO3: `Through Sandwatch, people can contribute to building ecosystem resilience and adapting to climate change`,
            CONTEUDO4: `Sandwatch receives support from UNESCO through the education and science sectors, as well as from the University of Puerto Rico, through the Grant College program. Many other regional, national, governmental and non-governmental organizations have also supported this program over the years`,
          
        },
        SECAO3: {
          TITULO: 'CAPE VERDE NATIONAL COMMISSION FOR UNESCO',
          CONTEUDO: `The role of the Cape Verde National Commission for UNESCO is to involve the various ministerial departments, services, institutions, organizations and individuals working to promote Education, Science, Culture and Information in UNESCO's activities, so that member states can:`,
          CONTEUDO2: `To contribute to the maintenance of peace and security and the common prosperity of mankind by participating in UNESCO activities aimed at promoting the mutual knowledge and understanding of nations, giving a vigorous impetus to popular education and the dissemination of culture, and helping to preserve, promote and disseminate knowledge;`,
          CONTEUDO3: `To play an increasing role in UNESCO's action, particularly in the elaboration and execution of its programs.`,
          CONTEUDO4: `Sandwatch activities in Cape Verde began in 2011 under the coordination of the Cape Verde National Commission for UNESCO. Participating in the program are primary and secondary schools from the UNESCO Associated Schools network on the islands of Santiago, Santo-Antão, Sal and São-Vicente.`,
          CONTEUDO5: `The CNU, with funding from UNESCO's regional office in Dakar, and through a competition aimed at academies, whose winner was a team made up of teachers and students from the University of Cape Verde, carried out the project to create this platform to enable the recording of information collected on beaches at national level, the analysis and monitoring of the information collected and the dissemination of information and initiatives to the general public.`,
        
        },
        SECAO4: {
          TITULO: 'International',
          CONTEUDO: `Sandwatch started in the Caribbean in 1999 and has grown into an international activity involving
            islands as far away as the Cook Islands in the Pacific, Seychelles in the Indian Ocean and the Bahamas in the Caribbean,
            as well as countries in Europe, Africa, Asia and South America.`,
        },
        SECAO5: {
          TITULO: 'Climate changes',
          CONTEUDO: `Sandwatch presents an opportunity for people and ecosystems to respond
            to climate variability and climate change. Beaches are among the ecosystems at risk,
            as they face rising sea levels and changes in storms and extreme events. through the
            Sandwatch, people can contribute to building ecosystem resilience and adapting to
            climate changes.`,
        },
        SECAO6: {
          TITULO: 'Sustainable development objective',
          CONTEUDO: `Sandwatch is a practical example of education for sustainable development and seeks
            Empower people of all ages to take responsibility for creating and enjoying a future
            sustainable. In particular, it develops skills related to critical thinking and problem solving.
            conflicts, and instills a sense of care for beaches and the environment. Sandwatch also contributes
             for the implementation of the Path of Accelerated Action Modalities for the Development of Small
             Islands (SAMOA) and the Sustainable Development Goals 2016-2030.`,
        },
        BANNER: {
          TITULO: 'About Us',
          LINK2: 'about us',
          LINK1: 'Home',
        },
        VELOCIDADE_ONDA: 'Variation of wave speed',
        ALTURA_ONDAS: 'wave height',
        QUANTIDADE_DETRITO: 'Amount of detrits',
        TAMANHO_SEDIMENTO: 'Sediment Size',
        PERCENTAGEM_SEDIMENTO: 'Sediment Size Percent',
        QUANTIDADE_ATIVIDADES: 'Number of Activities',
        MONTH: {
          JAN: 'January',
          FEV: 'February',
          MAR: 'March',
          ABR: 'April',
          MAI: 'May',
          JUN: 'June',
          JUL: 'July',
          AGO: 'August',
          SET: 'September',
          OUT: 'October',
          NOV: 'November',
          DEZ: 'December',
        },
        VELOCIDADE: 'Velocity (m/s)',
        DIRECAO: 'Direction in degrees ()',
        MEDIA: 'Averrage',
        QUANTIDADE: 'Quantity',
        MADEIRA: 'Wood',
        PLASTICO: 'plastic',
        VIDRO: 'glass',
        PAPEL: 'Paper',
        CORDA: 'rope',
        ISOPOR: 'polystyrene',
        ALUMINIO: 'aluminum',
        BORRACHA: 'Rubber',
        BOLAS: 'tar balls',
        CIGARRO: 'cigarette butts',
        NATURAL: 'Natural',
        PRAIA: 'Select beach',
        YEAR: 'Select year',
        PRAIA: 'Selecionar praia',
        YEAR: 'Selecionar ano',
        BANHO: 'Sea bath',
        TOMAR_SOL: 'Sunbathe',
        CAMINHANDO_CORRENDO: 'walking and or running',
        PIQUENIQUES: 'picnics',
        PESCARIA: 'Fishing',
        JOGANDO: 'Playing',
        PASSEIO: 'Horse rides',
        SENTADO: 'Seated',
        OUTROS: 'Others',
      },
    },
    pt: {
      translation: {
        langEn: 'Inglês',
        langPt: 'Português',
        headerList: {
          home: 'Início',
          resource: 'Recursos',
          about: 'Sobre Nós',
          project: 'Projectos & Iniciativas',
          contact: 'Contactos',
          login: 'Entrar',
          group: 'Groups',
        },
        loginTitle: 'Entrar',
        loginSubtitle: 'Introduza suas credenciais para continuar',
        loginUserPlaceHolder: 'Utilizador',
        loginPassPlaceHolder: 'Palavra-passe',
        loginButton: 'Entrar',
        loginForgotPass: 'Esqueceu palavra-passe?',
        praia: {
          name: 'Praia',
          addInfo: 'Adicionar info',
          addItem: 'Adicionar items',
          problems: 'Problemas',
          projects: 'Projectos & Inciativas',
        },
        sandwatch: {
          name: 'Sandwatch',
          user: 'Utilizadores',
          group: 'Grupos',
        },
        monitorizacao: {
          name: 'Monitorização',
          agua: 'Água',
          sedimento: 'Sedimentos',
          erosao: 'Erosão & Acreção',
          fauna: 'Fauna',
          flora: 'Flora',
          ninho: 'Ninho Tartaruga',
          atividade: 'Actividade Humanas',
          diario: 'Diário de praia',
          medicao: 'Medições',
          ficha: 'Ficha de Limpeza',
          detrito: 'Detrito',
        },
        parametrizacao: {
          name: 'Parametrização',
          actividade: 'Actividade',
          fauna: 'Fauna',
          flora: 'Flora',
          tartaruga: 'Tartaruga',
          item: 'Item',
          detrito: 'Detrito',
        },
        SECAO1: {
          TITULO: 'Fundacção Sandwatch',
          CONTEUDO: `Sandwatch visa modificar o estilo de vida e hábitos das crianças,
                    jovens e adultos em uma base de toda a comunidade e para desenvolver
                    a consciência da fragilidade do ambiente marinho e costeiro e da
                    necessidade de usá-la sabiamente. É um processo educacional por meio
                    do qual alunos de escolas e membros da comunidade aprendem e trabalham
                    juntos para monitorar cientificamente seus ambientes de praia, de forma
                     a avaliar criticamente os problemas e conflitos. Em seguida, eles desenvolvem
                     e implementam atividades sustentáveis ​​para lidar com essas questões, melhorar
                     o ambiente da praia e criar resiliência às mudanças climáticas.`,
                    
        },
        SECAO2: {
          TITULO: 'Sandwatch',
          CONTEUDO: `Sandwatch é uma rede de voluntários de escolas (alunos, professores e diretores),
                    grupos de jovens, organizações não-governamentais e comunitárias que trabalham em conjunto para
                    monitorar e melhorar seus ambientes de praia.`,
          CONTEUDO2: `A rede é coordenada pela Sandwatch Foundation, uma organização sem fins lucrativos que apresenta uma oportunidade para que as pessoas e os ecossistemas respondam à variabilidade climática e às mudanças climáticas. As praias estão entre os ecossistemas em risco, uma vez que enfrentam o aumento do nível do mar e mudanças em tempestades e eventos extremos.`,
          CONTEUDO3: `Através do Sandwatch, as pessoas podem contribuir para a construção da resiliência dos ecossistemas e adaptação às mudanças climáticas.`,
          CONTEUDO4: `Sandwatch recebe apoio da UNESCO através dos sectores da educação e da ciência, bem como da Universidade de Puerto Rico, através do programa da faculdade Grant. Muitas outras organizações regionais, nacionais, governamentais e não governamentais também têm apoiado este programa ao longo dos anos.`           
        },
        SECAO3: {
          TITULO: 'COMISSÃO NACIONAL DE CABO VERDE PARA UNESCO',
          CONTEUDO: `A Comissão Nacional de Cabo Verde para a UNESCO tem por função associar às atividades da UNESCO os diversos departamentos ministeriais, os serviços, as instituições, as organizações e os indivíduos que trabalham para a promoção da Educação, da Ciência, da Cultura e da Informação, de modo a que os Estados membros possam:`,
          CONTEUDO2: `Contribuir para a manutenção da paz e segurança e da prosperidade comum da Humanidade, participando nas atividades da UNESCO que visam promover o conhecimento e a compreensão mútuos das nações, imprimir um vigoroso impulso à educação popular e à difusão da cultura e ajudar à preservação, à promoção e à difusão do saber;`,
          CONTEUDO3: `Desempenhar um papel cada vez maior na ação da UNESCO, particularmente na elaboração e na execução dos seus programas.`,
          CONTEUDO4: `As actividades do Sandwatch em Cabo Verde começaram em 2011 sob a coordenação da Comissão Nacional de Cabo Verde para a UNESCO. Participam do programa escolas primárias e secundárias da rede de Escolas Associadas da UNESCO, nas ilhas de Santiago, Santo-Antão, Sal e São-Vicente.`,
          CONTEUDO5: `A CNU, com um financiamento do escritório regional da UNESCO em Dakar, e através de um concurso dirigido às academias, que teve como vencedora uma equipa composta por professores e alunos da Universidade de Cabo Verde, levou a cabo o projeto de criação desta plataforma para possibilitar o registo das informações recolhidas sobre as praias, a nível nacional, a análise e monitorização das informações recolhidas e a divulgação das informações e iniciativas para o público em geral.`,
        },
        SECAO4: {
          TITULO: 'Internacional',
          CONTEUDO: `Sandwatch começou no Caribe em 1999 e se tornou umaatividade internacionalenvolvendo
          ilhas tão distantes como as Ilhas Cook noPacífico, Seychelles noOceano Índicoe Bahamas noCaribe,
          bem como países naEuropa, África, Ásia e América do Sul.`,
        },
        SECAO5: {
          TITULO: 'Mudanças Climáticas',
          CONTEUDO: `Sandwatch apresenta uma oportunidade para as pessoas e os ecossistemas para responder
          à variabilidade climática e da mudança climática . As praias estão entre os ecossistemas em risco,
          pois enfrentam a elevação do nível do mar e mudanças em tempestades e eventos extremos. Por meio do
          Sandwatch, as pessoas podem contribuir para construir a resiliência do ecossistema e se adaptar às
          mudanças climáticas.`,
        },
        SECAO6: {
          TITULO: 'Objective de desenvolvimento sustentável',
          CONTEUDO: `Sandwatch é um exemplo prático de educação para o desenvolvimento sustentável e procura
          pessoas Empower de todas as idades para assumir a responsabilidade de criar e desfrutar de um futuro
          sustentável. Em particular, desenvolve habilidades relacionadas ao pensamento crítico e resolução de
          conflitos, e incute um senso de cuidado com as praias e o meio ambiente. O Sandwatch também contribui
           para a implementação do Caminho de Modalidades de Ação Aceleradas para o Desenvolvimento de Pequenas
           Ilhas (SAMOA) e os Objetivos de Desenvolvimento Sustentável 2016-2030 .`,
        },
        BANNER: {
          TITULO: 'Sobre Nós',
          LINK2: 'sobre-nos',
          LINK1: 'Inicio',
        },
        VELOCIDADE_ONDA: 'Variacao de velocidade de onda',
        ALTURA_ONDAS: 'Altura de ondas',
        QUANTIDADE_DETRITO: 'Quantidade detritos',
        TAMANHO_SEDIMENTO: 'Tamanho sedimentos',
        PERCENTAGEM_SEDIMENTO: 'Percentagem tamanho de sedimentos',
        QUANTIDADE_ATIVIDADES: 'Quantidade de Atividades',
        MONTH: {
          JAN: 'Janeiro',
          FEV: 'Fevereiro',
          MAR: 'Marco',
          ABR: 'Abril',
          MAI: 'Maio',
          JUN: 'Junho',
          JUL: 'Julho',
          AGO: 'Agosto',
          SET: 'Setembro',
          OUT: 'Outubro',
          NOV: 'Novembro',
          DEZ: 'Dezembro',
        },
        VELOCIDADE: 'Velocidade (m/s)',
        DIRECAO: 'Direcao em graus ()',
        MEDIA: 'Media',
        QUANTIDADE: 'Quantidade',
        MADEIRA: 'Madeira',
        PLASTICO: 'Plastico',
        VIDRO: 'Vidro',
        PAPEL: 'Papel',
        CORDA: 'Corda',
        ISOPOR: 'Isopor',
        ALUMINIO: 'Aluminio',
        BORRACHA: 'Borracha',
        BOLAS: 'Bolas de alcatrao',
        CIGARRO: 'Pontas de cigarro',
        NATURAL: 'Natural',
        PRAIA: 'Selecionar praia',
        YEAR: 'Selecionar ano',
        BANHO: 'Banho de mar',
        TOMAR_SOL: 'Tomar sol',
        CAMINHANDO_CORRENDO: 'Caminhando e ou correndo',
        PIQUENIQUES: 'Piqueniques',
        PESCARIA: 'Pescaria',
        JOGANDO: 'Jogando',
        PASSEIO: 'Passeios a cavalo',
        SENTADO: 'Sentado',
        OUTROS: 'Outros',
      },
    },
  },
  lng: 'pt',
  fallback: 'pt',
  debug: true,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
