import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

//Import Images
import CaboVerde from "../../../assets/images/ilhas/CaboVerde.png";
import semPraia from "../../../assets/images/praias/banner-3.png";

import { useDispatch, useSelector } from "react-redux";
import { retrieveIlhas } from "src/store/reducers/ilhaSlice";
import { retrieveConcelhos } from "src/store/reducers/concelhoSlice";
import { retrievePraias } from "src/store/reducers/praiaSlice";
import CardLoader from "src/components/Loader/Card";
import { setLocale } from "yup";
import PraiaCard from "src/components/PraiaCard";

function Praias() {
  const dispatch = useDispatch();

  const ilhas = useSelector((state) => state.ilhas);
  // const praias = useSelector((state) => state.praias)
  const concelhos = useSelector((state) => state.concelhos);
  const [ilha, setIlha] = useState(null);
  const [isloading, setLoading] = useState(true);

  useEffect(() => {
    if (concelhos.length && ilhas.length) {
      // agrupar praias por ilha
      const data = ilhas.reduce((accumulador, ilha) => {
        let datas = {
          ilha: { name: ilha.name_ilha, image: ilha.image[0] },
          praias: [],
        };

        concelhos.forEach((concelho) => {
          ilha.concelhos.forEach((ilhaConcelho) => {
            if (concelho.id === ilhaConcelho.id) {
              datas = {
                ...datas,
                praias: [...datas.praias, ...concelho.praias],
              };
            }
          });
        });
        accumulador.push(datas);
        return accumulador;
      }, []);

      //adiciona o nome do concelho na info data praia
      const newData = data.reduce((accumulator, item) => {
        const newPraias = item.praias.map((praia) => {
          const concelho = concelhos.find(
            (concelho) => concelho.id === praia.concelho
          );
          return {
            ...praia,
            concelho: concelho.name_concelho,
          };
        });

        const data = {
          ...item,
          praias: newPraias,
        };
        accumulator.push(data);
        return accumulator;
      }, []);

      setIlha(newData);
    }
  }, [ilhas, concelhos]);

  useEffect(() => {
    dispatch(retrieveIlhas())
      .unwrap() 
      .then(() =>
        dispatch(retrieveConcelhos())
          .unwrap()
          .then(() => setLoading(false))
      );
  }, [dispatch]);

  console.log(ilha);
  /* scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    } */

  const ApiUrl = process.env.REACT_APP_SERVER_URL;

  const destinationsOptions = {
    stagePadding: 1,
    items: 3,
    loop: true,
    margin: 20,
    smartSpeed: 1500,
    autoplay: false,
    dots: false,
    nav: true,
    navText: [
      '<i class="fas fa-chevron-left"></i>',
      '<i class="fas fa-chevron-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
        nav: false,
        dots: false,
      },
      600: {
        items: 2,
        nav: false,
        dost: false,
      },
      1000: {
        items: 3,
        nav: true,
        loop: true,
      },
    },
  };
  return (
    <>
      {/* =============== Praias area start =============== */}
      <div className="destinations-area  mt-5">
        <div className="container">
          {isloading && <p>loading..</p>}

          {isloading ? (
            <CardLoader />
          ) : ilha ? (
            ilha.map(({ ilha, praias }, i) => (
              <div className="row" key={i}>
                <div className="col-lg-3 col-md-3">
                  <div className="package-slider-wrap ">
                    <img
                      src={ilha.image ? ApiUrl + ilha.image.url : CaboVerde}
                      alt=""
                      className="img-fluid rounded"
                    />

                    <div className="pakage-overlay">
                      <strong>{ilha.name}</strong>
                    </div>
                  </div>
                </div>

                <div className="col-lg-9 col-md-9">
                  <OwlCarousel
                    className="row owl-carousel destinations-1"
                    {...destinationsOptions}
                  >
                    {praias.length ? (
                      praias.map((praia) => (
                        <PraiaCard
                          key={praia.id}
                          praia={praia}
                          ilha={ilha.name}
                        />
                      ))
                    ) : (
                      <h5>Nenhuma Praia foi encontrada!</h5>
                    )}
                  </OwlCarousel>
                </div>
              </div>
            ))
          ) : (
            <h3>Nenhuma Ilha foi encontrada!</h3>
          )}
        </div>
      </div>
    </>
  );
}

export default Praias;
