import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  map: undefined,
  coordinate: null,
  layerId: null,
  groupPraia: true,
  groupItem: true,
}

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    getMap: (state, action) => {
      state.map = action.payload
    },
    addCoordinate: (state, action) => {
      state.coordinate = action.payload
    },
    addLayerId: (state, action) => {
      state.layerId = action.payload
    },
    handleGroupPraia: (state, action) => {
      state.groupPraia = action.payload
    },
    handleGroupItem: (state, action) => {
      state.groupItem = action.payload
    },
  },
})

export const { getMap, addCoordinate, addLayerId, handleGroupPraia, handleGroupItem } =
  mapSlice.actions

export default mapSlice.reducer
