import axiosService from '../axios'

class ConcelhoService {
  retrieve() {
    return axiosService.get('/classificacaos')
  }

  update(data, id) {
    return axiosService.put(`/classificacaos/${id}`, data)
  }
}
export default new ConcelhoService()
