import { CContainer, CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react'
import React, { useState } from 'react'
import TableWrapper from 'src/components/TableWrapper'
import Recolha from './Recolha'
import ResultadoG from './ResultadoG'
import ResultadoB from './ResultadoB'

function Sedimento() {
  const [activeKey, setActiveKey] = useState(1)
  return (
    <CContainer>
      <TableWrapper title="Sedimentos">
        <CNav variant="tabs">
          <CNavItem>
            <CNavLink
              className="tab-nav-link"
              active={activeKey === 1}
              onClick={() => setActiveKey(1)}
              href="#"
            >
              Recolha
            </CNavLink>
          </CNavItem>

          <CNavItem>
            <CNavLink
              className="tab-nav-link"
              active={activeKey === 2}
              onClick={() => setActiveKey(2)}
              href="#"
            >
              Resultado Granulométrico
            </CNavLink>
          </CNavItem>

          <CNavItem>
            <CNavLink
              className="tab-nav-link"
              active={activeKey === 3}
              onClick={() => setActiveKey(3)}
              href="#"
            >
              Resultado Microbiologico
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          <CTabPane visible={activeKey === 1}>
            <Recolha />
          </CTabPane>

          <CTabPane visible={activeKey === 2}>
            <ResultadoG />
          </CTabPane>

          <CTabPane visible={activeKey === 3}>
            <ResultadoB />
          </CTabPane>
        </CTabContent>
      </TableWrapper>
    </CContainer>
  )
}
export default Sedimento
