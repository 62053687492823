import * as Yup from 'yup'

export const addSchema = Yup.object({
  praia: Yup.string().required('Campo obrigatorio'),
  // gruposand: Yup.string().required('Campo obrigatorio'),
  date: Yup.string().required('Campo obrigatorio'),
  wave_heigth: Yup.number('Adicionar apenas numero')
    .positive()
    .min(1, 'Valor minimo 1')
    .required('Campo obrigatorio'),
  wave_direction: Yup.number('Adicionar apenas numero')
    .positive()
    .min(1, 'Valor minimo 1')
    .required('Campo obrigatorio'),
  wave_period: Yup.number('Adicionar apenas numeros')
    .positive()
    .min(1, 'Valor minimo 1')
    .required('Campo obrigatorio'),
  observations: Yup.string(),
})
