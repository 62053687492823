import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import FaunaPraiaService from '../../service/monitorizacao/Fauna'

export const createFaunaPraia = createAsyncThunk('faunaPraia/create', async (data) => {
  const response = await FaunaPraiaService.create(data)
  return response.data
})
export const retrieveFaunaPraia = createAsyncThunk('faunaPraia/retrieve', async () => {
  const response = await FaunaPraiaService.retrieve()
  return response.data
})

export const updateFaunaPraia = createAsyncThunk('faunaPraia/update', async ({ data, id }) => {
  const response = await FaunaPraiaService.update(data, id)
  return response.data
})
export const deleteFaunaPraia = createAsyncThunk('faunaPraia/delete', async (id) => {
  const response = await FaunaPraiaService.delete(id)
  return response.data
})

const initialState = []

const faunaPraiaSlice = createSlice({
  name: 'FaunaPraia',
  initialState,
  extraReducers: {
    [createFaunaPraia.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveFaunaPraia.fulfilled]: (state, action) => [...action.payload],
    [updateFaunaPraia.fulfilled]: (state, action) => {
      const index = state.findIndex((FaunaPraia) => FaunaPraia.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteFaunaPraia.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default faunaPraiaSlice.reducer
