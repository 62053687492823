import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ActividadeService from '../../service/parametrizacao/Actividades'

export const createActividade = createAsyncThunk('actividade/create', async (data) => {
  const response = await ActividadeService.create(data)
  return response.data
})
export const retrieveActividade = createAsyncThunk('actividade/retrieve', async () => {
  const response = await ActividadeService.retrieve()
  return response.data
})

export const updateActividade = createAsyncThunk('actividade/update', async ({ data, id }) => {
  const response = await ActividadeService.update(data, id)
  return response.data
})
export const deleteActividade = createAsyncThunk('actividade/delete', async (id) => {
  const response = await ActividadeService.delete(id)
  return response.data
})

const initialState = []

const actividadeSlice = createSlice({
  name: 'actividade',
  initialState,
  extraReducers: {
    [createActividade.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveActividade.fulfilled]: (state, action) => [...action.payload],
    [updateActividade.fulfilled]: (state, action) => {
      const index = state.findIndex((actividade) => actividade.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteActividade.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default actividadeSlice.reducer
