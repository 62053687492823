import { configureStore } from '@reduxjs/toolkit'
import { rootReducer } from './rootReducer'

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
})

/* const store = createStore(changeState) */
export default store
