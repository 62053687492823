export const convert = (date) => {
  const newDate = date.split('-')
  return new Date(`${newDate[2]}/${newDate[1]}/${newDate[0]}`)
}

export const dateTime = (time) => {
  let newTime = null
  if (time) {
    newTime = new Date(`2011-04-11T${time.split('.')[0]}Z`)
  }
  return newTime
}

export const time = (date) => {
  let hour = date.getHours()
  let min = date.getMinutes()
  let seconds = date.getSeconds()

  if (hour.toString().length === 1) {
    hour = `0${hour}`
  }
  if (min.toString().length === 1) {
    min = `0${min}`
  }

  if (seconds.toString().length === 1) {
    seconds = `0${seconds}`
  }
  return `${hour}:${min}:${seconds}.000`
}
