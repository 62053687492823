import * as Yup from 'yup'

export const schema = Yup.object({
  praia: Yup.string().required('Campo obrigatório'),
  // gruposand: Yup.string().required('Campo obrigatório'),
  fauna: Yup.string().required('Campo obrigatório'),
  date: Yup.string().required('Campo obrigatório'),
  size_m: Yup.number().positive('33...').min(0.1, 'Valor minimo 0,1').required('Campo obrigatório'),
  quantity: Yup.number('Adicionar apenas numeros').positive().min(1, 'Valor minimo 1'),
  observations: Yup.string(),
})
