import React, { useState, useEffect } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { cilSave, cilXCircle } from "@coreui/icons";
import { SelectPicker, DatePicker } from "rsuite";
import { retrieveGruposand } from "src/store/reducers/gruposandSlice";
import { retrievePraias } from "src/store/reducers/praiaSlice";
import Swal from "sweetalert2";
import { updateContagemAtividade } from "src/store/reducers/contagemAtividadeSlice";
import SwalAlert from "src/utils/SwalAlert";
import { convert } from "src/utils/Date";
import { ADMIN_PRAIA, SUPER_ADMIN } from "src/utils/permission";

function EditContagemAtividadeDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch();
  const [grupoOptions, setGrupoOption] = useState([]);
  const [praiaOptions, setPraiaOption] = useState([]);
  const gruposands = useSelector((state) => state.gruposands);
  const praias = useSelector((state) => state.praias);
  const { user } = useSelector((state) => state.account);

  const [date, setDate] = useState();
  const [praia, setPraia] = useState("");
  const [gruposand, setGrupo] = useState();
  const [time, setTime] = useState();
  const [sea_bathing, setSea_bathing] = useState();
  const [sunbathing, setSunbathing] = useState();
  const [walking_and_or_running, setWalking_and_or_running] = useState();
  const [picnicking, setPicnicking] = useState();
  const [fishing, setFishing] = useState();
  const [playing, setPlaying] = useState();
  const [windsurfing_kitsurfing, setWindsurfing_kitsurfing] = useState();
  const [horse_riding, setHorse_riding] = useState();
  const [sitting, setSitting] = useState();
  const [other, setOther] = useState();
  const [total, setTotal] = useState();
  const initState = () => {};

  const saveContagemAtividade = async (e) => {
    e.preventDefault();

    const soma =
      parseInt(sea_bathing) +
      parseInt(sunbathing) +
      parseInt(walking_and_or_running) +
      parseInt(picnicking) +
      parseInt(fishing) +
      parseInt(playing) +
      parseInt(windsurfing_kitsurfing) +
      parseInt(horse_riding) +
      parseInt(sitting) +
      parseInt(other);

    const data = {
      date,
      praia,
      gruposand,
      time,
      sea_bathing,
      sunbathing,
      walking_and_or_running,
      picnicking,
      fishing,
      playing,
      windsurfing_kitsurfing,
      horse_riding,
      sitting,
      other,
      total: soma,
    };

    console.log(data);
    dispatch(
      updateContagemAtividade({
        data,
        id: item.id,
      })
    )
      .unwrap()
      .then(() => {
        setVisible(false);
        SwalAlert.success();
      })
      .catch(() => {
        SwalAlert.error();
      });
  };

  useEffect(() => {
    if (item) {
      const praia = praias.find((praia) => praia.name_praia === item.praia);
      const gruposand = gruposands.find(
        (grupo) => grupo.name_grupo === item.grupo
      );

      setPraia(praia.id);
      setGrupo(gruposand.id);
      setTime(item.time);
      setSea_bathing(item.sea_bathing);
      setSunbathing(item.sunbathing);
      setWalking_and_or_running(item.walking_and_or_running);
      setPicnicking(item.picnicking);
      setFishing(item.fishing);
      setPlaying(item.playing);
      setWindsurfing_kitsurfing(item.windsurfing_kitsurfing);
      setHorse_riding(item.horse_riding);
      setSitting(item.sitting);
      setOther(item.other);
      setTotal(item.total);
      setDate(convert(item.date));
    }
  }, [item, praias, gruposands]);

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }));
    setGrupoOption(options);
  }, [gruposands]);

  useEffect(() => {
    let filtered = praias;;

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id);
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }));
    setPraiaOption(options);
  }, [praias, user]);

  useEffect(() => {
    dispatch(retrieveGruposand());
    dispatch(retrievePraias());
  }, [dispatch]);

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar Contagem Atividade Humana</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={saveContagemAtividade}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Praia</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={praiaOptions}
                  style={{ width: "100%" }}
                  value={praia}
                  onChange={setPraia}
                />
              </div>
            </CCol>
            {SUPER_ADMIN.includes(user.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">Grupo</CFormLabel>
                    </CCol>
                  </CRow>
                  <SelectPicker
                    data={grupoOptions}
                    style={{ width: "100%" }}
                    defaultValue={gruposand}
                    value={gruposand}
                    onChange={setGrupo}
                  />
                </div>
              </CCol>
            )}
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <DatePicker
                  className="w-100"
                  format="dd-MM-yyyy"
                  value={date}
                  defaultValue={date}
                  onChange={setDate}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Tempo <strong className="text-danger">*</strong>
                </CFormLabel>

                <CFormInput
                  step={2}
                  type="time"
                  id="item"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Banho de mar</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="sea_bathing"
                  value={sea_bathing}
                  onChange={(e) => setSea_bathing(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Tomar sol</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="sunbathing"
                  value={sunbathing}
                  onChange={(e) => setSunbathing(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Caminhando e ou correndo</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="walking_and_or_running"
                  value={walking_and_or_running}
                  onChange={(e) => setWalking_and_or_running(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Piqueniques</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="picnicking"
                  value={picnicking}
                  onChange={(e) => setPicnicking(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Pescaria</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="fishing"
                  value={fishing}
                  onChange={(e) => setFishing(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Jogando</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="playing"
                  value={playing}
                  onChange={(e) => setPlaying(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Surfando</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="windsurfing_kitsurfing"
                  value={windsurfing_kitsurfing}
                  onChange={(e) => setWindsurfing_kitsurfing(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Passeios a cavalo</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="horse_riding"
                  value={horse_riding}
                  onChange={(e) => setHorse_riding(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Sentado</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="sitting"
                  value={sitting}
                  onChange={(e) => setSitting(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Outros</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="other"
                  value={other}
                  onChange={(e) => setOther(e.target.value)}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Editar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  );
}
EditContagemAtividadeDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
};

export default EditContagemAtividadeDialog;
