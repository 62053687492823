import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProjetoService from '../../service/Projeto'

export const createProjeto = createAsyncThunk('projeto/create', async (data) => {
  const response = await ProjetoService.create(data)
  return response.data
})
export const retrieveProjeto = createAsyncThunk('projeto/retrieve', async () => {
  const response = await ProjetoService.retrieve()
  return response.data
})

export const updateProjeto = createAsyncThunk('projeto/update', async ({ data, id }) => {
  const response = await ProjetoService.update(data, id)
  return response.data
})
export const deleteProjeto = createAsyncThunk('projeto/delete', async (id) => {
  const response = await ProjetoService.delete(id)
  return response.data
})

const initialState = []

const projetoSlice = createSlice({
  name: 'Projeto',
  initialState,
  extraReducers: {
    [createProjeto.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveProjeto.fulfilled]: (state, action) => [...action.payload],
    [updateProjeto.fulfilled]: (state, action) => {
      const index = state.findIndex((Projeto) => Projeto.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteProjeto.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default projetoSlice.reducer
