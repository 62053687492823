import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import IlhaService from '../../service/Ilha'

export const retrieveIlhas = createAsyncThunk('ilha/retrieve', async () => {
  const response = await IlhaService.retrieve()
  return response.data
})

const initialState = []

const ilhaSlice = createSlice({
  name: 'ilha',
  initialState,
  extraReducers: {
    [retrieveIlhas.fulfilled]: (state, action) => [...action.payload],
  },
})

export default ilhaSlice.reducer
