import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MedicaoCorrenteService from '../../service/monitorizacao/MedicaoCorrente'

export const createMedicaoCorrente = createAsyncThunk('medicaoCorrente/create', async (data) => {
  const response = await MedicaoCorrenteService.create(data)
  return response.data
})
export const retrieveMedicaoCorrente = createAsyncThunk('medicaoCorrente/retrieve', async () => {
  const response = await MedicaoCorrenteService.retrieve()
  return response.data
})

export const deleteMedicaoCorrente = createAsyncThunk('medicaoCorrente/delete', async (id) => {
  const response = await MedicaoCorrenteService.delete(id)
  return response.data
})

export const updateMedicaoCorrente = createAsyncThunk(
  'medicaoCorrente/update',
  async ({ data, id }) => {
    const response = await MedicaoCorrenteService.update(data, id)
    return response.data
  },
)

const initialState = []

const medicaoCorrenteSlice = createSlice({
  name: 'MedicaoCorrente',
  initialState,
  extraReducers: {
    [createMedicaoCorrente.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveMedicaoCorrente.fulfilled]: (state, action) => [...action.payload],
    [updateMedicaoCorrente.fulfilled]: (state, action) => {
      const index = state.findIndex((MedicaoCorrente) => MedicaoCorrente.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteMedicaoCorrente.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default medicaoCorrenteSlice.reducer
