import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import PraiaService from '../../service/Praia'

export const createPraia = createAsyncThunk('praia/create', async (data) => {
  const response = await PraiaService.create(data)
  return response.data
})

export const retrievePraias = createAsyncThunk('praia/retrieve', async () => {
  const response = await PraiaService.retrieve()
  return response.data
})

export const updatePraia = createAsyncThunk('praia/update', async ({ data, id }) => {
  const response = await PraiaService.update(data, id)
  return response.data
})
export const deletePraia = createAsyncThunk('praia/delete', async (id) => {
  const response = await PraiaService.delete(id)
  return response.data
})

const initialState = []

const praiaSlice = createSlice({
  name: 'praia',
  initialState,
  extraReducers: {
    [createPraia.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrievePraias.fulfilled]: (state, action) => [...action.payload],
    [updatePraia.fulfilled]: (state, action) => {
      const index = state.findIndex((item) => item.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deletePraia.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default praiaSlice.reducer
