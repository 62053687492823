/* eslint-disable react/prop-types */
/**
 *
 * PrivateRoute
 * Higher Order Component that blocks navigation when the user is not logged in
 * and redirect the user to login page
 *
 * Wrap your protected routes to secure your container
 */

import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import Footers from 'src/components/Footer/footers'
import PublicHeader from 'src/components/header/PublicHeader'
import Loader from 'src/components/Loader/Public'

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !localStorage.getItem('authToken') ? (
        <>
          <PublicHeader />

          <Component {...props} />

          <Footers />
        </>
      ) : (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: { from: props.location },
          }}
        />
      )
    }
  />
)

export default PublicRoute
