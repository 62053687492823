import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: true,
}

const utilsSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    changeLoad: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { changeLoad } = utilsSlice.actions

export default utilsSlice.reducer
