import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ClassificacaoService from '../../service/Classificacao'

export const retrieveClassificacaos = createAsyncThunk('classificacao/retrieve', async () => {
  const response = await ClassificacaoService.retrieve()
  return response.data
})

export const updateClassificacao = createAsyncThunk('lassificacao/update', async ({ data, id }) => {
  const response = await ClassificacaoService.update(data, id)
  return response.data
})

const initialState = []

const classificacaoSlice = createSlice({
  name: 'Classificacao',
  initialState,
  extraReducers: {
    [retrieveClassificacaos.fulfilled]: (state, action) => [...action.payload],
    [updateClassificacao.fulfilled]: (state, action) => {
      const index = state.findIndex((item) => item.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
  },
})

export default classificacaoSlice.reducer
