import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CHeaderDivider,
  CRow,
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { cilSave, cilXCircle } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { DatePicker, SelectPicker } from 'rsuite'
import { useDispatch, useSelector } from 'react-redux'
import { retrieveConcelhos } from 'src/store/reducers/concelhoSlice'
import { retrieveGruposand } from 'src/store/reducers/gruposandSlice'
import { retrieveClassificacaos } from 'src/store/reducers/classificacaoSlice'
import { current } from '@reduxjs/toolkit'
import { updatePraia } from 'src/store/reducers/praiaSlice'
import SwalAlert from 'src/utils/SwalAlert'
import { dateTime, time } from 'src/utils/Date'
import { SUPER_ADMIN } from 'src/utils/permission'
import UploadService from 'src/service/Upload'

function EditPraia({ currentPraia, setVisible }) {
  const dispatch = useDispatch()

  const [name_praia, setNamepraia] = useState('')
  const [time_end, setEnd] = useState(null)
  const [time_start, setStart] = useState(null)
  const [gruposand, setGrupo] = useState('')
  const [district, setDistrict] = useState('')
  const [concelho, setConcelho] = useState('')
  const [saves_lives, setSaveLife] = useState('')
  const [description, setDescription] = useState('')
  const [classificacao, setClassificacao] = useState('')
  const [imagePraia, setImage] = useState([])

  const ilhas = useSelector((state) => state.ilhas)
  const { user } = useSelector((state) => state.account)
  const concelhos = useSelector((state) => state.concelhos)
  const gruposands = useSelector((state) => state.gruposands)
  const classificacaos = useSelector((state) => state.classificacaos)
  const [images, setImages] = useState([])
  const [ilhaOptions, setIlhaOptions] = useState([])
  const [grupoOptions, setGrupoOptions] = useState([])
  const [concelhoOptions, setConcelhoOptions] = useState([])
  const [classificacaoOptions, setClassifcacaoOptions] = useState([])

  const [ilhaId, setIlhaId] = useState(null)

  const editPraia = async (e) => {
    e.preventDefault()

    let end;
      let start;
      let image = []

    if (time_end && time_start) {
      end = time(time_end)
      start = time(time_start)
    }

    const formData = new FormData()

    if (images.length) {
      for (const key of Object.keys(images)) {
        formData.append('files', images[key])
      }

      const { data } = await UploadService.upload(formData)
      image = data
    }

    const data = {
      name_praia,
      concelho,
      gruposand,
      time_end: end,
      time_start: start,
      classificacao,
      district,
      saves_lives,
      description,
      image_praia: [...imagePraia, ...image],
    }
    console.log(data)
    dispatch(updatePraia({ data, id: currentPraia.id }))
      .unwrap()
      .then(() => {
        SwalAlert.success()
        setVisible(false)
      })
      .catch(() => {
        SwalAlert.error()
      })
  }

  useEffect(() => {
    setNamepraia(currentPraia.name_praia)
    setDistrict(currentPraia.district)
    setConcelho(currentPraia.concelho.id)
    setSaveLife(currentPraia.saves_lives)
    setDescription(currentPraia.description)
    setStart(dateTime(currentPraia.time_start))
    setEnd(dateTime(currentPraia.time_end))
    setImage(currentPraia.image_praia)
    if (currentPraia.classificacao) {
      setClassificacao(currentPraia.classificacao.id)
    }
    if (currentPraia.gruposand) {
      setGrupo(currentPraia.gruposand.id)
    }
  }, [currentPraia])

  // convert island to valid selected options
  useEffect(() => {
    const options = ilhas.map((ilha) => ({
      value: ilha.id,
      label: ilha.name_ilha,
    }))
    setIlhaOptions(options)
  }, [ilhas])

  // convert concelhos to valid selected options
  useEffect(() => {
    const options = concelhos.map((concelho) => ({
      value: concelho.id,
      label: concelho.name_concelho,
    }))
    setConcelhoOptions(options)
  }, [concelhos])

  // convert grupo to valid selected options
  useEffect(() => {
    const options = gruposands.map((grupo) => ({
      value: grupo.id,
      label: grupo.name_grupo,
    }))
    setGrupoOptions(options)
  }, [gruposands])

  // convert classficacao to valid selected options
  useEffect(() => {
    const options = classificacaos.map((classificacao) => ({
      value: classificacao.id,
      label: classificacao.rate,
    }))
    setClassifcacaoOptions(options)
  }, [classificacaos])

  // select concelho according to seleted island
  useEffect(() => {
    if (ilhaId) {
      const newConcelhos = concelhos.filter((concelho) => concelho.ilha.id === ilhaId)
      const options = newConcelhos.map((concelho) => ({
        value: concelho.id,
        label: concelho.name_concelho,
      }))
      setConcelhoOptions(options)
    }
  }, [concelhos, ilhaId])

  useEffect(() => {
    dispatch(retrieveConcelhos())
    dispatch(retrieveGruposand())
    dispatch(retrieveClassificacaos())
  }, [dispatch])

  return (
    <CForm onSubmit={editPraia}>
      <CRow className="pt-4">
        <CCol lg={3}>
          <div className="mb-3">
            <CFormLabel htmlFor="nome">
              Nome <strong className="text-danger">*</strong>
            </CFormLabel>
            <CFormInput
              type="text"
              id="nome"
              onChange={(e) => setNamepraia(e.target.value)}
              value={name_praia}
            />
          </div>
        </CCol>
        <CCol lg={3}>
          <div className="mb-3">
            <CFormLabel htmlFor="nome">
              Ilha <strong className="text-danger">*</strong>
            </CFormLabel>
            <SelectPicker data={ilhaOptions} style={{ width: '100%' }} />
          </div>
        </CCol>
        <CCol lg={3}>
          <div className="mb-3">
            <CFormLabel htmlFor="nome">
              Concelho <strong className="text-danger">*</strong>
            </CFormLabel>
            <SelectPicker
              value={concelho}
              onChange={setConcelho}
              data={concelhoOptions}
              style={{ width: '100%' }}
            />
          </div>
        </CCol>
        <CCol lg={3}>
          <div className="mb-3">
            <CFormLabel htmlFor="nome">Zona</CFormLabel>
            <CFormInput
              type="text"
              id="nome"
              onChange={(e) => setDistrict(e.target.value)}
              value={district}
            />
          </div>
        </CCol>
        <CCol lg={3}>
          <div className="mb-3">
            <CFormLabel htmlFor="nome">Horário de entrada</CFormLabel>
            <DatePicker
              className="w-100"
              format="HH:mm:ss"
              defaultValue={time_start}
              value={time_start}
              onChange={setStart}
            />
          </div>
        </CCol>
        <CCol lg={3}>
          <div className="mb-3">
            <CFormLabel htmlFor="nome">Horário de saída</CFormLabel>
            <DatePicker
              className="w-100"
              format="HH:mm:ss"
              defaultValue={time_end}
              value={time_end}
              onChange={setEnd}
            />
          </div>
        </CCol>
        <CCol lg={3}>
          <div className="mb-3">
            <CFormLabel htmlFor="nome">Salva vidas</CFormLabel>
            <CFormInput
              type="number"
              value={saves_lives}
              onChange={(e) => setSaveLife(e.target.value)}
              id="nome"
            />
          </div>
        </CCol>
        <CCol lg={3}>
          <div className="mb-3">
            <CFormLabel htmlFor="nome">
              Classificação <strong className="text-danger">*</strong>
            </CFormLabel>
            <SelectPicker
              value={classificacao}
              onChange={setClassificacao}
              data={classificacaoOptions}
              style={{ width: '100%' }}
            />
          </div>
        </CCol>
        {user && user.role.name === SUPER_ADMIN && (
          <CCol lg={3}>
            <div className="mb-3">
              <CFormLabel htmlFor="nome">Grupo</CFormLabel>
              <SelectPicker
                value={gruposand}
                data={grupoOptions}
                onChange={setGrupo}
                style={{ width: '100%' }}
              />
            </div>
          </CCol>
        )}
        <CCol lg={3}>
          <div className="mb-3">
            <CFormLabel htmlFor="formFileMultiple">Fotos</CFormLabel>
            <CFormInput
              type="file"
              id="formFileMultiple"
              multiple
              onChange={(e) => setImages(e.target.files)}
            />
          </div>
        </CCol>
        <CCol lg={6}>
          <div className="mb-3">
            <CFormLabel htmlFor="nome">
              Descrição <strong className="text-danger">*</strong>
            </CFormLabel>
            <CFormTextarea
              id="exampleFormControlTextarea1"
              rows="1"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
             />
          </div>
        </CCol>

        {/*  <CCol lg={12}>
          <div className="mb-3">
            <h5>Informação histórica</h5>
          </div>
        </CCol>
        <CCol lg={6}>
          <div className="mb-3">
            <CFormLabel htmlFor="formFileMultiple">Titulo</CFormLabel>
            <CFormInput type="text" id="nome" />
          </div>
        </CCol>
        <CCol lg={6}>
          <div className="mb-3">
            <CFormLabel htmlFor="formFileMultiple">Data</CFormLabel>
            <CFormInput type="date" id="nome" />
          </div>
        </CCol>
        <CCol lg={6}>
          <div className="mb-3">
            <CFormLabel htmlFor="formFileMultiple">Fotos</CFormLabel>
            <CFormInput type="file" id="formFileMultiple" multiple />
          </div>
        </CCol>
        <CCol lg={6}>
          <div className="mb-3">
            <CFormLabel htmlFor="formFileMultiple">Fonte</CFormLabel>
            <CFormTextarea id="exampleFormControlTextarea1" rows="1"></CFormTextarea>
          </div>
        </CCol> */}
      </CRow>
      <CHeaderDivider />
      <CRow className="d-flex justify-content-end">
        <CCol lg={2}>
          <CButton onClick={() => setVisible(false)} color="secondary" className="w-100">
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>
        </CCol>
        <CCol lg={2}>
          <CButton type="submit" className="main-button w-100" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  )
}

EditPraia.propTypes = {
  currentPraia: PropTypes.object,
  setVisible: PropTypes.func,
}
export default EditPraia
