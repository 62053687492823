import { CButton, CCol, CContainer, CHeaderDivider, CRow } from '@coreui/react'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectPicker } from 'rsuite'
import { updatePraia } from 'src/store/reducers/praiaSlice'
import SwalAlert from 'src/utils/SwalAlert'
import Swal from 'sweetalert2'
import PropTypes from 'prop-types'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { ADMIN_PRAIA } from 'src/utils/permission'

function AddCoordenate({ setVisible }) {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const praias = useSelector((state) => state.praias)
  const ilhas = useSelector((state) => state.ilhas)
  const { coordinate, layerId, map } = useSelector((state) => state.map)
  const [praiaOptions, setPraiaOptions] = useState([])
  const [ilhaOptions, setIlhaOptions] = useState([])
  const [filteredPraias, setFilteredPraias] = useState([])
  const [newPraias, setNewPraias] = useState([])
  const [ilhaId, setIlhaId] = useState(null)
  const [praia, setPraia] = useState(null)

  const removeLayer = () => {
    map.eachLayer((layer) => {
      if (layer._leaflet_id === layerId) {
        map.removeLayer(layer)
      }
    })
  }

  const saveCoordinate = () => {
    if (praia) {
      let grupo = null

      if (user.role.name === ADMIN_PRAIA) {
        grupo = user.gruposand.id
      }

      dispatch(updatePraia({ data: { coordinates: coordinate, gruposand: grupo }, id: praia }))
        .unwrap()
        .then((e) => {
          removeLayer()
          setVisible(false)
          SwalAlert.success()
        })
        .catch((error) => {
          SwalAlert.error()
        })
    } else {
      Swal.fire({
        text: 'Por favor seleciona uma praia!',
        icon: 'info',
        confirmButtonColor: '#FF7F47',
        confirmButtonText: 'Ok!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      })
    }
  }

  // filter all beaches without coordinate
  useEffect(() => {
    const filtered = praias.filter((item) => item.coordinates === null)
    setNewPraias(filtered)
    setFilteredPraias(filtered)
  }, [praias])

  useEffect(() => {
    const options = filteredPraias.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }))
    setPraiaOptions(options)
  }, [filteredPraias])

  useEffect(() => {
    if (ilhaId) {
      const options = newPraias.filter((item) => item.concelho.ilha === ilhaId)
      setFilteredPraias(options)
    }
  }, [ilhaId, newPraias])

  useEffect(() => {
    const options = filteredPraias.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }))
    setPraiaOptions(options)
  }, [filteredPraias])

  useEffect(() => {
    const options = ilhas.map((item) => ({
      value: item.id,
      label: item.name_ilha,
    }))
    setIlhaOptions(options)
  }, [ilhas])

  return (
    <CContainer>
      <CRow>
        <CCol lg={6}>
          <SelectPicker
            data={ilhaOptions}
            onChange={setIlhaId}
            placeholder="Selecionar ilha"
            className="w-100 mt-3"
          />
        </CCol>
        <CCol lg={6}>
          <SelectPicker
            data={praiaOptions}
            onChange={setPraia}
            placeholder="Selecionar praia"
            className="w-100 mt-3"
          />
        </CCol>
      </CRow>
      <CHeaderDivider className="mt-3" />
      <CRow className="d-flex justify-content-end">
        <CCol lg={3}>
          <CButton onClick={() => setVisible(false)} color="secondary" className="w-100">
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>
        </CCol>
        <CCol lg={3}>
          <CButton
            onClick={saveCoordinate}
            type="submit"
            className="main-button w-100"
            color="primary"
          >
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CCol>
      </CRow>
    </CContainer>
  )
}
AddCoordenate.propTypes = {
  setVisible: PropTypes.func,
}
export default AddCoordenate
