import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import { cilChevronCircleLeftAlt } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import Find from './Find'

class MainBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: new Date(),
    }
  }

  changeDatepickerHandeller = (date) => {
    this.setState({ startDate: date })
  }

  render() {
    const mainBannerOptions = {
      items: 1,
      loop: true,
      margin: 0,
      smartSpeed: 700,
      dots: false,
      nav: true,
      autoplay: 4000,
      autoplayTimeout: 4000,
      autoplayHoverPause: true,
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: false,
        },
        600: {
          items: 1,
          nav: false,
          dost: false,
        },
        1000: {
          items: 1,
          nav: true,
          loop: true,
        },
      },
    }
    return (
      <>
        {/* ===============  Main banner area start =============== */}
        <div className="main-banner">
          <OwlCarousel className="banner-slider owl-carousel" {...mainBannerOptions}>
            <div className="slider-item slider-item-1">
              <div className="container">
                <div
                  className="slider-content wow fadeInLeft animated"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  {/* <h2>Praias de Cabo Verde</h2>
                                <h5>Santiago</h5> */}
                  {/* <div className="banner-btn">
                                    <Link to={`${process.env.PUBLIC_URL}/package`} className="btn-common">Ver Mas</Link>
                                </div> */}
                </div>
              </div>
            </div>
            <div className="slider-item slider-item-2">
              <div className="container">
                <div
                  className="slider-content wow fadeInLeft animated"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  {/*  <h2>Praias de Cabo Verde</h2>
                                <h5>Boa Vista</h5> */}
                  {/* {<div className="banner-btn">
                                    <Link to={`${process.env.PUBLIC_URL}/package`} className="btn-common">Ver Mas</Link>
                                </div>} */}
                </div>
              </div>
            </div>
            <div className="slider-item slider-item-3">
              <div className="container">
                <div
                  className="slider-content wow fadeInLeft animated"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  {/*  <h2>Praias de Cabo Verde</h2>
                                <h5>Sal</h5> */}
                  {/* <div className="banner-btn">
                                    <Link to={`${process.env.PUBLIC_URL}/package`} className="btn-common">Ver Mas</Link>
                                </div> */}
                </div>
              </div>
            </div>
            <div className="slider-item slider-item-4">
              <div className="container">
                <div
                  className="slider-content wow fadeInLeft animated"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  {/* <h2>Praias de Cabo Verde</h2>
                                <h5>Santiago</h5> */}
                  {/* <div className="banner-btn">
                                    <Link to={`${process.env.PUBLIC_URL}/package`} className="btn-common">Ver Mas</Link>
                                </div> */}
                </div>
              </div>
            </div>
            <div className="slider-item slider-item-5">
              <div className="container">
                <div
                  className="slider-content wow fadeInLeft animated"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  {/*  <h2>Praias de Cabo Verde</h2>
                                <h5>Boa Vista</h5> */}
                  {/* {<div className="banner-btn">
                                    <Link to={`${process.env.PUBLIC_URL}/package`} className="btn-common">Ver Mas</Link>
                                </div>} */}
                </div>
              </div>
            </div>
            <div className="slider-item slider-item-6">
              <div className="container">
                <div
                  className="slider-content wow fadeInLeft animated"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  {/*  <h2>Praias de Cabo Verde</h2>
                                <h5>Sal</h5> */}
                  {/* <div className="banner-btn">
                                    <Link to={`${process.env.PUBLIC_URL}/package`} className="btn-common">Ver Mas</Link>
                                </div> */}
                </div>
              </div>
            </div>
            <div className="slider-item slider-item-7">
              <div className="container">
                <div
                  className="slider-content wow fadeInLeft animated"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  {/* <h2>Praias de Cabo Verde</h2>
                                <h5>Santiago</h5> */}
                  {/* <div className="banner-btn">
                                    <Link to={`${process.env.PUBLIC_URL}/package`} className="btn-common">Ver Mas</Link>
                                </div> */}
                </div>
              </div>
            </div>
            <div className="slider-item slider-item-8">
              <div className="container">
                <div
                  className="slider-content wow fadeInLeft animated"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  {/*  <h2>Praias de Cabo Verde</h2>
                                <h5>Boa Vista</h5> */}
                  {/* {<div className="banner-btn">
                                    <Link to={`${process.env.PUBLIC_URL}/package`} className="btn-common">Ver Mas</Link>
                                </div>} */}
                </div>
              </div>
            </div>
            <div className="slider-item slider-item-9">
              <div className="container">
                <div
                  className="slider-content wow fadeInLeft animated"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  {/*  <h2>Praias de Cabo Verde</h2>
                                <h5>Sal</h5> */}
                  {/* <div className="banner-btn">
                                    <Link to={`${process.env.PUBLIC_URL}/package`} className="btn-common">Ver Mas</Link>
                                </div> */}
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
        {/* ===============  Main banner area end =============== */}

        {/* ===============  findfrom area start ============= */}

        <Find />

        {/* ===============  findfrom area end =============== */}
      </>
    )
  }
}

export default MainBanner
