import axiosService from '../../axios'

class TartarugaService {
  create(data) {
    return axiosService.post('/tartarugas', data)
  }

  retrieve() {
    return axiosService.get('/tartarugas')
  }

  update(data, id) {
    return axiosService.put(`tartarugas/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`tartarugas/${id}`)
  }
}

export default new TartarugaService()
