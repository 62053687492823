import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { CChartBar } from '@coreui/react-chartjs'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { retrieveDetritoPraia } from 'src/store/reducers/detritoPraiaSlice'
import PropTypes from 'prop-types'

const initialState = [0, 0]

const initialMonth = {
  madeira: 0,
  plastico: 0,
  vidro: 0,
  papel: 0,
  corda: 0,
  natural: 0,
  isopor: 0,
  aluminio: 0,
  borracha: 0,
  bola: 0,
  cigarro: 0,
}

function ChartDetrito({ praia, year }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const detritos = useSelector((state) => state.detritosPraias)

  const [dataJan, setDataJan] = useState(initialState)
  const [dataFev, setDataFev] = useState(initialState)
  const [dataMar, setDataMar] = useState(initialState)
  const [dataAbr, setDataAbr] = useState(initialState)
  const [dataMai, setDataMai] = useState(initialState)
  const [dataJun, setDataJun] = useState(initialState)
  const [dataJul, setDataJul] = useState(initialState)
  const [dataAgo, setDataAgo] = useState(initialState)
  const [dataSet, setDataSet] = useState(initialState)
  const [dataOut, setDataOut] = useState(initialState)
  const [dataNov, setDataNov] = useState(initialState)
  const [dataDez, setDataDez] = useState(initialState)

  const [jan, setJan] = useState(initialMonth)
  const [fev, setFev] = useState(initialMonth)
  const [mar, setMar] = useState(initialMonth)
  const [abr, setAbr] = useState(initialMonth)
  const [mai, setMai] = useState(initialMonth)
  const [jun, setJun] = useState(initialMonth)
  const [jul, setJul] = useState(initialMonth)
  const [ago, setAgo] = useState(initialMonth)
  const [set, setSet] = useState(initialMonth)
  const [out, setOut] = useState(initialMonth)
  const [nov, setNov] = useState(initialMonth)
  const [dez, setDez] = useState(initialMonth)

  const checkDetrito = (item, detrito, setState) => {
    switch (detrito) {
      case 'Madeira':
        setState((prevState) => ({
          ...prevState,
          madeira: prevState.madeira + item.quantity,
        }))
        break
      case 'Plástico':
        setState((prevState) => ({
          ...prevState,
          plastico: prevState.plastico + item.quantity,
        }))
        break
      case 'Vidro':
        setState((prevState) => ({
          ...prevState,
          vidro: prevState.vidro + item.quantity,
        }))
        break
      case 'Papel':
        setState((prevState) => ({
          ...prevState,
          papel: prevState.papel + item.quantity,
        }))
        break
      case 'Corda':
        setState((prevState) => ({
          ...prevState,
          corda: prevState.corda + item.quantity,
        }))
        break
      case 'Natural':
        setState((prevState) => ({
          ...prevState,
          natural: prevState.natural + item.quantity,
        }))
        break
      case 'Isopor(esferovite)':
        setState((prevState) => ({
          ...prevState,
          isopor: prevState.isopor + item.quantity,
        }))
        break
      case 'Alumínio':
        setState((prevState) => ({
          ...prevState,
          aluminio: prevState.aluminio + item.quantity,
        }))
        break
      case 'Borracha':
        setState((prevState) => ({
          ...prevState,
          borracha: prevState.borracha + item.quantity,
        }))
        break
      case 'Bolas de alcatrão':
        setState((prevState) => ({
          ...prevState,
          bola: prevState.bola + item.quantity,
        }))
        break
      case 'Pontas de cigarros':
        setState((prevState) => ({
          ...prevState,
          cigarro: prevState.cigarro + item.quantity,
        }))
        break
      default: {
      }
    }
  }

  useEffect(() => {
    setJan(initialMonth)
    setFev(initialMonth)
    setMar(initialMonth)
    setAbr(initialMonth)
    setMai(initialMonth)
    setJun(initialMonth)
    setJul(initialMonth)
    setAgo(initialMonth)
    setSet(initialMonth)
    setOut(initialMonth)
    setNov(initialMonth)
    setDez(initialMonth)

    const data = detritos.filter((item) => item.praia.id === praia)

    data.map((detrito) => {
      if (moment(detrito.date).format('YYYY') === year.toString()) {
        const month = moment(detrito.date).format('MMMM')
        const dt = detrito.detrito.name_detrito

        switch (month) {
          case 'January':
            checkDetrito(detrito, dt, setJan)
            break
          case 'February':
            checkDetrito(detrito, dt, setFev)
            break
          case 'March':
            checkDetrito(detrito, dt, setMar)
            break
          case 'April':
            checkDetrito(detrito, dt, setAbr)
            break
          case 'May':
            checkDetrito(detrito, dt, setMai)
            break
          case 'June':
            checkDetrito(detrito, dt, setJun)
            break
          case 'July':
            checkDetrito(detrito, dt, setJul)
            break
          case 'August':
            checkDetrito(detrito, dt, setAgo)
            break
          case 'September':
            checkDetrito(detrito, dt, setSet)
            break
          case 'October':
            checkDetrito(detrito, dt, setOut)
            break
          case 'November':
            checkDetrito(detrito, dt, setNov)
            break
          case 'December':
            checkDetrito(detrito, dt, setDez)
            break

          default: {
          }
        }
      }
    })
  }, [praia, detritos, year])

  useEffect(() => {
    setDataJan([
      jan.madeira,
      jan.plastico,
      jan.vidro,
      jan.papel,
      jan.corda,
      jan.natural,
      jan.isopor,
      jan.aluminio,
      jan.borracha,
      jan.bola,
      jan.cigarro,
    ])
    setDataFev([
      fev.madeira,
      fev.plastico,
      fev.vidro,
      fev.papel,
      fev.corda,
      fev.natural,
      fev.isopor,
      fev.aluminio,
      fev.borracha,
      fev.bola,
      fev.cigarro,
    ])
    setDataMar([
      mar.madeira,
      mar.plastico,
      mar.vidro,
      mar.papel,
      mar.corda,
      mar.natural,
      mar.isopor,
      mar.aluminio,
      mar.borracha,
      mar.bola,
      mar.cigarro,
    ])
    setDataAbr([
      abr.madeira,
      abr.plastico,
      abr.vidro,
      abr.papel,
      abr.corda,
      abr.natural,
      abr.isopor,
      abr.aluminio,
      abr.borracha,
      abr.bola,
      abr.cigarro,
    ])
    setDataMai([
      mai.madeira,
      mai.plastico,
      mai.vidro,
      mai.papel,
      mai.corda,
      mai.natural,
      mai.isopor,
      mai.aluminio,
      mai.borracha,
      mai.bola,
      mai.cigarro,
    ])
    setDataJun([
      jun.madeira,
      jun.plastico,
      jun.vidro,
      jun.papel,
      jun.corda,
      jun.natural,
      jun.isopor,
      jun.aluminio,
      jun.borracha,
      jun.bola,
      jun.cigarro,
    ])
    setDataJul([
      jul.madeira,
      jul.plastico,
      jul.vidro,
      jul.papel,
      jul.corda,
      jul.natural,
      jul.isopor,
      jul.aluminio,
      jul.borracha,
      jul.bola,
      jul.cigarro,
    ])
    setDataAgo([
      ago.madeira,
      ago.plastico,
      ago.vidro,
      ago.papel,
      ago.corda,
      ago.natural,
      ago.isopor,
      ago.aluminio,
      ago.borracha,
      ago.bola,
      ago.cigarro,
    ])
    setDataSet([
      set.madeira,
      set.plastico,
      set.vidro,
      set.papel,
      set.corda,
      set.natural,
      set.isopor,
      set.aluminio,
      set.borracha,
      set.bola,
      set.cigarro,
    ])
    setDataOut([
      out.madeira,
      out.plastico,
      out.vidro,
      out.papel,
      out.corda,
      out.natural,
      out.isopor,
      out.aluminio,
      out.borracha,
      out.bola,
      out.cigarro,
    ])
    setDataNov([
      nov.madeira,
      nov.plastico,
      nov.vidro,
      nov.papel,
      nov.corda,
      nov.natural,
      nov.isopor,
      nov.aluminio,
      nov.borracha,
      nov.bola,
      nov.cigarro,
    ])
    setDataDez([
      dez.madeira,
      dez.plastico,
      dez.vidro,
      dez.papel,
      dez.corda,
      dez.natural,
      dez.isopor,
      dez.aluminio,
      dez.borracha,
      dez.bola,
      dez.cigarro,
    ])
  }, [jan, fev, mar, abr, mai, jun, jul, ago, set, out, nov, dez])

  useEffect(() => {
    dispatch(retrieveDetritoPraia())
  }, [dispatch])

  return (
    <CCard className="mb-4">
      <CCardHeader className="fw-bold">{t('QUANTIDADE_DETRITO')}</CCardHeader>
      <CCardBody>
        <CChartBar
          data={{
            labels: [
              t('MADEIRA'),
              t('PLASTICO'),
              t('VIDRO'),
              t('CORDA'),
              t('PAPEL'),
              t('NATURAL'),
              t('ISOPOR'),
              t('ALUMINIO'),
              t('BORRACHA'),
              t('BOLAS'),
              t('CIGARRO'),
            ],
            datasets: [
              {
                label: 'Jan',
                backgroundColor: '#FF7F47',
                borderRadius: 10,
                data: dataJan,
              },
              {
                label: 'Fev',
                backgroundColor: '#128F8B',
                data: dataFev,
              },
              {
                label: 'Mar',
                backgroundColor: '#9219FB',
                borderRadius: 10,
                data: dataMar,
              },
              {
                label: 'Abr',
                backgroundColor: '#17A2E3',
                data: dataAbr,
              },
              {
                label: 'Mai',
                backgroundColor: '#25FA30',
                data: dataMai,
              },
              {
                label: 'Jun',
                backgroundColor: '#1A65E6',
                data: dataJun,
              },
              {
                label: 'Jul',
                backgroundColor: '#a52a2a',
                data: dataJul,
              },
              {
                label: 'Ago',
                backgroundColor: '#FA14A9',
                data: dataAgo,
              },
              {
                label: 'Set',
                backgroundColor: '#FFE80A',
                data: dataSet,
              },
              {
                label: 'Out',
                backgroundColor: '#1100FA',
                data: dataOut,
              },
              {
                label: 'Nov',
                backgroundColor: '#75578F',
                data: dataNov,
              },
              {
                label: 'Dez',
                backgroundColor: '#FF0DF6',
                data: dataDez,
              },
            ],
          }}
          labels="months"
        />
      </CCardBody>
    </CCard>
  )
}

ChartDetrito.propTypes = {
  praia: PropTypes.any,
  year: PropTypes.any,
}
export default ChartDetrito
