import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  // CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { retrieveGruposand } from 'src/store/reducers/gruposandSlice'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import { createErosao } from 'src/store/reducers/erosaoSlice'
import SwalAlert from 'src/utils/SwalAlert'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ADMIN_PRAIA, SUPER_ADMIN } from 'src/utils/permission'
import { schema } from './validation'
import UploadService from '../../../service/Upload'

function AddErosaoDialog({ visible, setVisible }) {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const gruposands = useSelector((state) => state.gruposands)
  const praias = useSelector((state) => state.praias)

  const [grupoOptions, setGrupoOption] = useState([])
  const [praiaOptions, setPraiaOption] = useState([])

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const [images, setImage] = useState(null)

  /* save projeto */
  const saveErosao = async (data) => {
    const formData = new FormData()
    let point_image
    if (images) {
      formData.append('files', images[0])
      const { data } = await UploadService.upload(formData)
      point_image = data
    }

    const values = {
      ...data,
      praia: parseInt(data.praia, 10),
      gruposand: parseInt(data.gruposand, 10),
      measurement_date: new Date(data.measurement_date),
      point_image,
    }

    if (ADMIN_PRAIA.includes(user.role.name)) {
      values.gruposand = user.gruposand.id
    }
    console.log(values)
    dispatch(createErosao({ ...data, ...values, point_image }))
      .unwrap()
      .then(() => {
        SwalAlert.success()
        reset({
          praia: '',
          gruposand: '',
          profile_type: '',
          measured_width: '',
          measured_heigth: '',
          measurement_date: '',
          point_description: '',
          point_distance: '',
          point_location: '',
        })
      })
      .catch((err) => {
        SwalAlert.error()
      })
  }

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }))
    setGrupoOption(options)
  }, [gruposands])

  useEffect(() => {
    let filtered = praias;

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id)
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }))
    setPraiaOption(options)
  }, [praias, user])

  useEffect(() => {
    dispatch(retrieveGruposand())
    dispatch(retrievePraias())
  }, [dispatch])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar Medições</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={handleSubmit(saveErosao)}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Praia <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="praia"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker {...field} data={praiaOptions} style={{ width: '100%' }} />
                  )}
                />
                <small className="text-danger">{errors.praia?.message}</small>
              </div>
            </CCol>
            {SUPER_ADMIN.includes(user.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">
                        Grupo <strong className="text-danger">*</strong>
                      </CFormLabel>
                    </CCol>
                  </CRow>
                  <Controller
                    name="gruposand"
                    control={control}
                    render={({ field }) => (
                      <SelectPicker {...field} data={grupoOptions} style={{ width: '100%' }} />
                    )}
                  />
                  <small className="text-danger">{errors.gruposand?.message}</small>
                </div>
              </CCol>
            )}
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Tipo de Perfil da Praia</CFormLabel>
                <CFormInput type="text" id="item" {...register('profile_type')} />
                <small className="text-danger">{errors.profile_type?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data de Medição <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="measurement_date"
                  control={control}
                  render={({ field }) => <DatePicker className="w-100" {...field} />}
                />
                <small className="text-danger">{errors.measurement_date?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Largura da Praia(Km) <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput type="number" step="0.001" id="item" {...register('measured_width')} />
                <small className="text-danger">{errors.measured_width?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Comprimento da Praia(Km) <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput type="number" step="0.001" id="item" {...register('measured_heigth')} />
                <small className="text-danger">{errors.measured_heigth?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Descrição do Ponto</CFormLabel>
                <CFormInput type="text" id="item" {...register('point_description')} />
                <small className="text-danger">{errors.point_description?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Distância do Ponto(Km)<strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput type="number" step="0.001" id="item" {...register('point_distance')} />
                <small className="text-danger">{errors.point_distance?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Coordenada do Ponto</CFormLabel>
                <CFormInput type="text" id="item" {...register('point_location')} />
                <small className="text-danger">{errors.point_location?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto</CFormLabel>
                <CFormInput type="file" id="item" onChange={(e) => setImage(e.target.files)} />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
AddErosaoDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default AddErosaoDialog
