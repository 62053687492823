import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import TableWrapper from 'src/components/TableWrapper'
import ReactDatatable from '@ashvin27/react-datatable'
import { deleteFaunaPraia, retrieveFaunaPraia } from 'src/store/reducers/faunaPraiaSlice'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'
import SwalAlert from 'src/utils/SwalAlert'
import ModalImage from 'src/components/Modal/ImageModal'
import GalleryImage from 'src/components/ImageGallery'
import { ADMIN, SUPER_ADMIN } from 'src/utils/permission'
import EditFaunaPraiaDialog from './EditFaunaPraiaDialog'
import AddFaunaPraiaDialog from './AddFaunaPraiaDialog'
import { columns, config } from './tableConf'

function FaunaPraia() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const faunasPraias = useSelector((state) => state.faunasPraias)
  const [currentItem, setCurrentItem] = useState(null)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')
  const [data, setData] = useState([])
  const [images, setImages] = useState(null)
  const [imageShow, setShowImage] = useState(false)
  const [role, setRole] = useState(null)

  const destroy = (record, index) => {
    SwalAlert.warning().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteFaunaPraia(record.id))
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success')
      }
    })
  }

  const changeItem = (record, index) => {
    setAction('')
    setCurrentItem(record)
    setVisible(true)
  }

  const showImage = (record, index) => {
    setImages(record.image)
    setShowImage(true)
  }

  useEffect(() => {
    dispatch(retrieveFaunaPraia())
      .unwrap()
      .then(() => setLoading(false))
  }, [dispatch])

  useEffect(() => {
    if (user) {
      let datas = faunasPraias

      if (user.role.name !== SUPER_ADMIN) {
        datas = faunasPraias.filter((fauna) => fauna.gruposand.id === user.gruposand.id)
      }
      const data = datas.map((item, index) => ({
        index: index + 1,
        id: item.id,
        date: moment(item.date).format('DD-MM-YYYY'),
        praia: item.praia.name_praia,
        grupo: item.gruposand.name_grupo,
        fauna: item.fauna && item.fauna.species,
        tamanho: item.size_m,
        quantidade: item.quantity,
        observacao: item.observations,
        image: item.img,
      }))
      setData(data)
      setRole(user.role.name)
    }
  }, [faunasPraias, user])
  return (
    <CContainer lg>
      <TableWrapper title="Fuanas">
        <CRow className="mb-4 ">
          {ADMIN.includes(role) && (
            <CCol>
              <CButton
                className="main-button"
                onClick={() => {
                  setAction('add')
                  setVisible(true)
                }}
              >
                Adicionar
              </CButton>
            </CCol>
          )}
        </CRow>
        <div className="table-responsive">
          <ReactDatatable
            config={config(user && role)}
            records={data}
            loading={loading}
            columns={columns(destroy, changeItem, showImage, role)}
          />
        </div>
      </TableWrapper>
      {action === 'add' ? (
        <AddFaunaPraiaDialog visible={visible} setVisible={setVisible} />
      ) : (
        <EditFaunaPraiaDialog visible={visible} setVisible={setVisible} item={currentItem} />
      )}
      <ModalImage visible={imageShow} setVisible={setShowImage}>
        <GalleryImage items={images} />
      </ModalImage>
    </CContainer>
  )
}
export default FaunaPraia
