import axiosService from '../axios'

class UserService {
  create(data) {
    return axiosService.post('/users', data)
  }

  retrieve() {
    return axiosService.get('/users')
  }

  update(data, id) {
    return axiosService.put(`users/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`users/${id}`)
  }
}

export default new UserService()
