import axiosService from '../../axios'

class FloraService {
  create(data) {
    return axiosService.post('/floras', data)
  }

  retrieve() {
    return axiosService.get('/floras')
  }

  update(data, id) {
    return axiosService.put(`floras/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`floras/${id}`)
  }
}

export default new FloraService()
