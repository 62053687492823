import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTableRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import { convert } from 'src/utils/Date'

function SeeContagemAtividadeDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch()
  const [grupoOptions, setGrupoOption] = useState([])
  const [praiaOptions, setPraiaOption] = useState([])
  const gruposands = useSelector((state) => state.gruposands)
  const praias = useSelector((state) => state.praias)

  const [date, setDate] = useState()
  const [praia, setPraia] = useState('')
  const [gruposand, setGrupo] = useState()
  const [time, setTime] = useState()
  const [sea_bathing, setSea_bathing] = useState()
  const [sunbathing, setSunbathing] = useState()
  const [walking_and_or_running, setWalking_and_or_running] = useState()
  const [picnicking, setPicnicking] = useState()
  const [fishing, setFishing] = useState()
  const [playing, setPlaying] = useState()
  const [windsurfing_kitsurfing, setWindsurfing_kitsurfing] = useState()
  const [horse_riding, setHorse_riding] = useState()
  const [sitting, setSitting] = useState()
  const [other, setOther] = useState()
  const [total, setTotal] = useState()
  const initState = () => {}

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#FFF3E0',

      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  useEffect(() => {
    if (item) {
      const praia = praias.find((praia) => praia.name_praia === item.praia)
      const gruposand = gruposands.find((grupo) => grupo.name_grupo === item.grupo)

      setPraia(praia.name_praia)
      setGrupo(gruposand.name_grupo)
      setTime(item.time)
      setSea_bathing(item.sea_bathing)
      setSunbathing(item.sunbathing)
      setWalking_and_or_running(item.walking_and_or_running)
      setPicnicking(item.picnicking)
      setFishing(item.fishing)
      setPlaying(item.playing)
      setWindsurfing_kitsurfing(item.windsurfing_kitsurfing)
      setHorse_riding(item.horse_riding)
      setSitting(item.sitting)
      setOther(item.other)
      setTotal(item.total)
      setDate(convert(item.date))
    }
  }, [item, praias, gruposands])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Info Contagem Atividade Humana</CModalTitle>
      </CModalHeader>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 100 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <strong>Praia :</strong> &nbsp; {praia}
              </StyledTableCell>

              <StyledTableCell align="center">
                <strong>Grupo :</strong>&nbsp; {gruposand}
              </StyledTableCell>

              <StyledTableCell align="center">
                <strong>Data:</strong>&nbsp;
                {moment(date).format('DD-MM-YYYY')}
              </StyledTableCell>

              <StyledTableCell align="center">
                <strong>Time :</strong>&nbsp; {time}
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableCell align="right">&nbsp;</TableCell>
            <TableCell align="right"> </TableCell>
            <TableCell align="right"> </TableCell>
            <TableCell align="right"> </TableCell>
          </TableBody>
        </Table>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <strong>Atividade </strong>{' '}
              </StyledTableCell>
              <StyledTableCell>
                <strong>Quantidade</strong>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Banho de mar </TableCell>
              <TableCell>{sea_bathing}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell> Tomar sol </TableCell>
              <TableCell>{sunbathing}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell> Caminhando e ou correndo </TableCell>
              <TableCell>{walking_and_or_running}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell> Piqueniques </TableCell>
              <TableCell>{picnicking}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell> Pescaria </TableCell>
              <TableCell>{fishing}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell> Jogando </TableCell>
              <TableCell>{playing}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell> Passeios a cavalo </TableCell>
              <TableCell>{horse_riding}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell> Sentado </TableCell>
              <TableCell>{sitting}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell> Outros </TableCell>
              <TableCell>{other}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell> Total </TableCell>
              <TableCell>{total}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </CModal>
  )
}
SeeContagemAtividadeDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
}

export default SeeContagemAtividadeDialog
