import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { JWTProvider } from './context/JwtContext';
import DefaultLayout from './layout/DefaultLayout';
import routes from './routes';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRouter';
import './scss/style.scss';

import Projeto from './views/pages/ProjetoEIniciativa';

// Pages
/* const Home = React.lazy(() => import('./views/pages/Home/'))
const AboutUs = React.lazy(() => import('./views/pages/About'))
const Contacto = React.lazy(() => import('./views/pages/Contacto'))
const PraiaDetails = React.lazy(() => import('./views/pages/PraiaDetails'))
const ProjetoDetails = React.lazy(() => import('./views/pages/ProjetoDetails'))
const Devs = React.lazy(() => import('./views/pages/Devs'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Search = React.lazy(() => import('./views/pages/Search'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500')) */

import Home from './views/pages/Home';
import AboutUs from './views/pages/About';
import Contacto from './views/pages/Contacto';
import PraiaDetails from './views/pages/PraiaDetails';
import ProjetoDetails from './views/pages/ProjetoDetails';
import Devs from './views/pages/Devs';
import Login from './views/pages/login/Login';
import Search from './views/pages/Search';
import Page404 from './views/pages/page404/Page404';
import Page500 from './views/pages/page500/Page500';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <JWTProvider>
          <Switch>
            <PublicRoute exact path="/" name="Home" component={Home} />
            <PublicRoute path="/sobre-nos" name="Login Page" component={AboutUs} />
            <PublicRoute path="/contatos" name="Login Page" component={Contacto} />
            <PublicRoute path="/search/:query" name="Search Page" component={Search} />
            <PublicRoute
              path="/praia-details/:id"
              name="Praia details Page"
              component={PraiaDetails}
            />
            <PublicRoute path="/login" name="Login Page" component={Login} />
            <PublicRoute path="/500" name="Page 500" component={Page500} />
            <PublicRoute
              path="/projectos-iniciativas"
              name="Projetos&Iniciativa Page"
              component={Projeto}
            />
            <PublicRoute
              path="/projectos-iniciativas-details/:id"
              name="Projetos&Iniciativa Details Page"
              component={ProjetoDetails}
            />
            <PublicRoute path="/devs" name="Devs Page" component={Devs} />

            <DefaultLayout>
              {routes.map(
                (route, idx) =>
                  route.component && (
                    <PrivateRoute
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      component={route.component}
                    />
                  )
              )}
            </DefaultLayout>

            <PublicRoute path="*" name="Page 404" component={Page404} />
          </Switch>
        </JWTProvider>
      </BrowserRouter>
    );
  }
}

export default App;
