import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { updateResultadoB } from 'src/store/reducers/resultadoBSlice'
import SwalAlert from 'src/utils/SwalAlert'
import { convert } from 'src/utils/Date'

import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'

function EditResultadoBDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch()

  const sedimentos = useSelector((state) => state.sedimentos)

  const [sedimento, setSedimento] = useState('')
  const [lote, setLote] = useState('')
  const [collect_date_sand, setCollect_date_sand] = useState('')
  const [dissolved_oxygen_sand, setDissolved_oxygen_sand] = useState('')
  const [dissolved_oxygen_demand_BOD_sand, setDissolved_oxygen_demand_BOD_sand] = useState('')
  const [yeast, setYeast] = useState('')
  const [filamentous_fungi, setFilamentous_fungi] = useState('')
  const [protozoa, setProtozoa] = useState('')
  const [fecal_coliform_sand, setFecal_coliform_sand] = useState('')
  const [Counting_enterococcos_sand, setCounting_enterococcos_sand] = useState('')
  const [Counting_escherichina_sand, setCounting_escherichina_sand] = useState('')
  const [nitrate_sand, setNitrate_sand] = useState('')
  const [phosphate_sand, setPhosphate_sand] = useState('')
  const [salinity_sand, setSalinity_sand] = useState('')
  const [conductivity_sand, setConductivity_sand] = useState('')
  const [sand_temperature, setSand_temperature] = useState('')
  const [turbidity_sand, setTurbidity_sand] = useState('')
  const [ph, setPh] = useState('')

  const saveResultadoG = async (e) => {
    e.preventDefault()

    const data = {
      collect_date_sand,
      dissolved_oxygen_sand,
      dissolved_oxygen_demand_BOD_sand,
      yeast,
      filamentous_fungi,
      protozoa,
      fecal_coliform_sand,
      Counting_enterococcos_sand,
      Counting_escherichina_sand,
      nitrate_sand,
      phosphate_sand,
      salinity_sand,
      conductivity_sand,
      sand_temperature,
      turbidity_sand,
      ph,
    }

    dispatch(
      updateResultadoB({
        data,
        id: item.id,
      }),
    )
      .unwrap()
      .then(() => {
        setVisible(false)
        SwalAlert.success()
      })
      .catch(() => {
        SwalAlert.success()
      })
  }

  useEffect(() => {
    if (item) {
      const sedimento = sedimentos.find(
        (sedimento) => sedimento.sample_number_sand === item.amostra,
      )
      const lote = sedimentos.find((sedimento) => sedimento.batch_number_sand === item.lote)
      console.log(sedimento)
      setSedimento(sedimento.sample_number_sand)
      setLote(lote.batch_number_sand)
      setCollect_date_sand(convert(item.collect_date_sand))
      setDissolved_oxygen_sand(item.dissolved_oxygen_sand)
      setDissolved_oxygen_demand_BOD_sand(item.dissolved_oxygen_demand_BOD_sand)
      setYeast(item.yeast)
      setFilamentous_fungi(item.filamentous_fungi)
      setProtozoa(item.protozoa)
      setFecal_coliform_sand(item.fecal_coliform_sand)
      setCounting_enterococcos_sand(item.Counting_enterococcos_sand)
      setCounting_escherichina_sand(item.Counting_escherichina_sand)
      setNitrate_sand(item.nitrate_sand)
      setPhosphate_sand(item.phosphate_sand)
      setSalinity_sand(item.salinity_sand)
      setConductivity_sand(item.conductivity_sand)
      setSand_temperature(item.sand_temperature)
      setTurbidity_sand(item.turbidity_sand)
      setPh(item.ph)
    }
  }, [item, sedimento])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar Resultado de Análise Microbiológico das Amostras de Areia </CModalTitle>
      </CModalHeader>
      <CForm onSubmit={saveResultadoG}>
        <CModalBody>
          <CRow>
            <CCol lg={12}>
              <div className="mb-3">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 100 }}>
                    <TableCell>
                      <center>
                        <i style={{ fontSize: 16 }}>Número de Amostra :</i> &nbsp;
                        {sedimento}
                      </center>
                    </TableCell>
                    <TableCell>
                      <i style={{ fontSize: 16 }}> Lote :</i>&nbsp;
                      {lote}
                    </TableCell>
                  </Table>
                </TableContainer>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Data</CFormLabel>
                  </CCol>
                </CRow>
                <DatePicker
                  className="w-100"
                  format="dd-MM-yyyy"
                  defaultValue={collect_date_sand}
                  value={collect_date_sand}
                  onChange={setCollect_date_sand}
                />
              </div>
            </CCol>
            <p1 style={{ color: 'darkblue' }}> ANÁLISE MICROBIOLÓGICA DA AMOSTRA</p1> <br />
            <br />
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Oxigênio dissolvido</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={dissolved_oxygen_sand}
                  onChange={(e) => setDissolved_oxygen_sand(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Demanda Bioquímica de Oxigênio (DBQ)</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={dissolved_oxygen_demand_BOD_sand}
                  onChange={(e) => setDissolved_oxygen_demand_BOD_sand(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Leveduras</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={yeast}
                  onChange={(e) => setYeast(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Fungos Filamentosos</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={filamentous_fungi}
                  onChange={(e) => setFilamentous_fungi(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Protozoarios e Helmintos</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={protozoa}
                  onChange={(e) => setProtozoa(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Bactérias Coliformes</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={fecal_coliform_sand}
                  onChange={(e) => setFecal_coliform_sand(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Contagem de Enterococcos fecais- Filtração por Membrana
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={Counting_enterococcos_sand}
                  onChange={(e) => setCounting_enterococcos_sand(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Contagem de Escherichina coli- Filtração por Membrana
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={Counting_escherichina_sand}
                  onChange={(e) => setCounting_escherichina_sand(e.target.value)}
                />
              </div>
            </CCol>
            <p1 style={{ color: 'darkblue' }}> ANÁLISE FÍSICO-QUÍMICO DA AMOSTRA</p1>
            <br />
            <br />
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Nitratos</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={nitrate_sand}
                  onChange={(e) => setNitrate_sand(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Fosfato</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={phosphate_sand}
                  onChange={(e) => setPhosphate_sand(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Salinidade</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={salinity_sand}
                  onChange={(e) => setSalinity_sand(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Condutividade</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={conductivity_sand}
                  onChange={(e) => setConductivity_sand(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Temperatura</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={sand_temperature}
                  onChange={(e) => setSand_temperature(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Turbidez</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={turbidity_sand}
                  onChange={(e) => setTurbidity_sand(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Ph</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  step="0.001"
                  id="item"
                  value={ph}
                  onChange={(e) => setPh(e.target.value)}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
EditResultadoBDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
}

export default EditResultadoBDialog
