import axiosService from '../axios'

class AuthService {
  login(data) {
    return axiosService.post('auth/local', data)
  }

  me(token) {
    return axiosService.get('users/me', {
      headers: {
        Authorization: `Bearer ${  token}`,
      },
    })
  }

  update(data, id) {
    return axiosService.put(`/users/${id}`, data)
  }
}
export default new AuthService()
