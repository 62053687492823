import React, { useState, useEffect } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  // CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { cilSave, cilXCircle } from "@coreui/icons";
import { SelectPicker, DatePicker } from "rsuite";
import { retrieveGruposand } from "src/store/reducers/gruposandSlice";
import { retrievePraias } from "src/store/reducers/praiaSlice";
// import Swal from "sweetalert2";
import { updateErosao } from "src/store/reducers/erosaoSlice";
import SwalAlert from "src/utils/SwalAlert";
import { convert } from "src/utils/Date";
import { ADMIN_PRAIA, SUPER_ADMIN } from "src/utils/permission";
import UploadService from "../../../service/Upload";

function EditErosaoDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch();
  const [grupoOptions, setGrupoOption] = useState([]);
  const [praiaOptions, setPraiaOption] = useState([]);
  const gruposands = useSelector((state) => state.gruposands);
  const praias = useSelector((state) => state.praias);
  const { user } = useSelector((state) => state.account);

  const [measurement_date, setMDate] = useState("");
  const [profile_type, setProfileT] = useState();
  const [measured_width, setMWidth] = useState();
  const [measured_heigth, setMHeight] = useState();
  const [praia, setPraia] = useState("");
  const [gruposand, setGrupo] = useState("");
  const [point_description, setPointDesc] = useState();
  const [point_distance, setPointDist] = useState();
  const [point_location, setPointL] = useState();
  const [images, setImage] = useState(null);

  const saveErosao = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    let point_image;
    if (images) {
      formData.append("files", images[0]);
      const { data } = await UploadService.upload(formData);
      point_image = data;
    }
    const data = {
      praia,
      gruposand,
      profile_type,
      measured_width,
      measured_heigth,
      measurement_date,
      point_description,
      point_distance,
      point_location,
      point_image,
    };

    dispatch(
      updateErosao({
        data,
        id: item.id,
      })
    )
      .then(() => {
        setVisible(false);
        SwalAlert.success();
      })
      .catch(() => {
        SwalAlert.error();
      });
  };

  useEffect(() => {
    if (item) {
      const praia = praias.find((praia) => praia.name_praia === item.praia);
      const gruposand = gruposands.find(
        (grupo) => grupo.name_grupo === item.grupo
      );
      setMDate(convert(item.data_medicao));
      setPraia(praia.id);
      setGrupo(gruposand.id);
      setProfileT(item.tipo_perfil);
      setMWidth(item.largura);
      setMHeight(item.comprimento);
      setPointDesc(item.descricao);
      setPointDist(item.distancia);
      setPointL(item.localizacao);
    }
  }, [item, praias, gruposands]);

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }));
    setGrupoOption(options);
  }, [gruposands]);

  useEffect(() => {
    let filtered = praias;
    ;

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id);
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }));
    setPraiaOption(options);
  }, [praias, user]);

  useEffect(() => {
    dispatch(retrieveGruposand());
    dispatch(retrievePraias());
  }, [dispatch]);
  return (
    <CModal backdrop="static"
            alignment="center"
            size="lg"
            visible={visible}
            onClose={
              () => setVisible(false)}>
      <CModalHeader>
        <CModalTitle> Editar Medição < /CModalTitle> </CModalHeader>
      <CForm onSubmit={saveErosao}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item"> Praia < /CFormLabel> </CCol> </CRow> <SelectPicker data={praiaOptions}
                                                                                                   style={
                                                                                                     { width: "100%" }}
                                                                                                   value={praia}
                                                                                                   onChange={setPraia}
              /></div>
            </CCol> {
            SUPER_ADMIN.includes(user?.role.name) && (<
                CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item"> Grupo < /CFormLabel>
                    </CCol> </CRow> <SelectPicker data={grupoOptions}
                                                  style={
                                                    { width: "100%" }}
                                                  defaultValue={gruposand}
                                                  value={gruposand}
                                                  onChange={setGrupo}
                /></div>
              </CCol>
            )
          } <CCol lg={6}>
            <div className="mb-3">
              <CFormLabel htmlFor="item"> Tipo de Perfil da Praia < /CFormLabel> <CFormInput type="text"
                                                                                             id="item"
                                                                                             value={profile_type}
                                                                                             onChange={
                                                                                               (e) => setProfileT(e.target.value)}
            /></div>
          </CCol> <
            CCol lg={6}>
            <div className="mb-3">
              <CRow>
                <CCol>
                  <CFormLabel htmlFor="item"> Data de Medição < /CFormLabel> </CCol> </CRow> <
              DatePicker className="w-100"
                         format="dd-MM-yyyy"
                         defaultvalue={measurement_date}
                         value={measurement_date}
                         onChange={setMDate}
            /></div>
          </CCol> <CCol lg={6}>
            <div className="mb-3">
              <CFormLabel htmlFor="item"> Largura da Praia(Km) < /CFormLabel> <CFormInput type="number"
                                                                                          step="0.001"
                                                                                          id="item"
                                                                                          value={measured_width}
                                                                                          onChange={
                                                                                            (e) => setMWidth(e.target.value)}
            /></div>
          </CCol> <
            CCol lg={6}>
            <div className="mb-3">
              <CFormLabel htmlFor="item"> Comprimento da Praia(Km) < /CFormLabel> <
              CFormInput type="number"
                         step="0.001"
                         id="item"
                         value={measured_heigth}
                         onChange={
                           (e) => setMHeight(e.target.value)}
            /><
        /div>
          <
        /CCol> <
            CCol lg={6}>
            <
              div className="mb-3">
              <
                CFormLabel htmlFor="item"> Descrição do Ponto < /CFormLabel> <
              CFormInput
              type="text"
              id="item"
              value={point_description}
              onChange={
                (e) => setPointDesc(e.target.value)}
            /><
        /div>
          <
        /CCol> <
            CCol lg={6}>
            <
              div className="mb-3">
              <
                CFormLabel htmlFor="item"> Distância do Ponto < /CFormLabel> <
              CFormInput
              type="number"
              step="0.001"
              id="item"
              value={point_distance}
              onChange={
                (e) => setPointDist(e.target.value)}
            /><
        /div>
          <
        /CCol> <
            CCol lg={6}>
            <
              div className="mb-3">
              <
                CFormLabel htmlFor="item"> Coordenada do Ponto < /CFormLabel> <
              CFormInput
              type="text"
              id="item"
              value={point_location}
              onChange={
                (e) => setPointL(e.target.value)}
            /><
        /div>
          <
        /CCol> <
            CCol lg={6}>
            <
              div className="mb-3">
              <
                CFormLabel htmlFor="item"> Foto < /CFormLabel> <
              CFormInput type="file"
                         id="item"
                         onChange={
                           (e) => setImage(e.target.files)}
            /><
        /div>
          <
        /CCol> <
        /CRow> <
        /CModalBody>

        <
          CModalFooter>
          <
            CButton color="secondary"
                    onClick={
                      () => setVisible(false)}>
            <
              CIcon icon={cilXCircle}
                    className="me-2"/>
            Cancelar </CButton>

          <
            CButton type="submit"
                    className="main-button"
                    color="primary">
            <
              CIcon icon={cilSave}
                    className="me-2"/>
            Salvar </CButton> <
        /CModalFooter> <
        /CForm> <
        /CModal>
  );
}

EditErosaoDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
};

export default EditErosaoDialog;
