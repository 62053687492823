import React, { useEffect, useState } from 'react'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { Link } from 'react-router-dom'
// Import Images
import { useDispatch, useSelector } from 'react-redux'
import { retrieveProjeto } from 'src/store/reducers/projetoSlice'
import CardLoader from 'src/components/Loader/Card'
import moment from 'moment'
import PaginatedItems from 'src/components/Pagination'
import projeto_prev from '../../../assets/images/projeto_prev.png'

function ProjetoEIniciativa() {
  const dispatch = useDispatch()

  const projetos = useSelector((state) => state.projetos)
  const [currentItems, setCurrentItems] = useState(null)

  const [start_date, setStartDate] = useState(new Date())
  const [end_date, setEndDate] = useState(new Date())
  const [name, setName] = useState()
  const [objective, setObjective] = useState()
  const [praia, setPraia] = useState('')
  const [gruposand, setGrupo] = useState()
  const [background, setBackground] = useState()
  const [planned_activities, setPActivities] = useState()
  const [resources_needed, setResourcesN] = useState()
  const [how_will_we_know_we_achieved_our_objective, setObjectiveA] = useState()
  const [images, setImage] = useState(null)

  const [isloading, setLoading] = useState(true)
  const [projeto, setProjetos] = useState(null)

  useEffect(() => {
    const data = projetos.map((item, id) => ({
      id: item.id,
      name: item.name,
      objective: item.objective,
      start_date: moment(item.start_date).format('DD-MM-YYYY'),
      end_date: moment(item.end_date).format('DD-MM-YYYY'),
      praia: item.praia,
      grupo: item.gruposand,
      background: item.background,
      planned_activities: item.planned_activities,
      resources_needed: item.resources_needed,
      how_will_we_know_we_achieved_our_objective: item.how_will_we_know_we_achieved_our_objective,
      image: item.img,
    }))
    setProjetos(data)
  }, [projetos])

  useEffect(() => {
    setLoading(false)
    dispatch(retrieveProjeto())
      .unwrap()
      .then(() => setLoading(false))
  }, [dispatch])

  const ApiUrl = process.env.REACT_APP_SERVER_URL

  return (
    <>
      {/* ===============  breadcrumb area start =============== */}
      {/* <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="breadcrumb-wrap">
                <h2>Projetos & Iniciativa</h2>
                <ul className="breadcrumb-links">
                  <li>
                    <Link to={"/"}>Início</Link>
                    <i className="bx bx-chevron-right" />
                  </li>
                  <li>
                    <i className="fas fa-chevron-right"></i>
                  </li>
                  <li>Porjetos & Iniciativa</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* ===============  breadcrumb area end =============== */}
      {/* =============== Praias area start =============== */}
      <div className="projeto-wrapper pt-90">
        <div className="container">
          <h2>Projetos & Iniciativas</h2>
          <div className="row">
            {isloading ? (
              <CardLoader />
            ) : projetos.length ? (
              <PaginatedItems items={projetos} itemsPerPage={9} setCurrentItems={setCurrentItems}>
                {currentItems ? (
                  currentItems.map(
                    (
                      {
                        id,
                        name,
                        objective,
                        start_date,
                        end_date,
                        background,
                        planned_activities,
                        resources_needed,
                        how_will_we_know_we_achieved_our_objective,
                        resultadoprojeto,
                        praia,
                        gruposand,
                        img,
                      },
                      i,
                    ) => (
                      <div className="col-lg-4 col-md-6 col-sm-6" key={i}>
                        <div className="projeto-card">
                          <div className="projeto-img">
                            <img
                              src={img ? ApiUrl + img.url : projeto_prev}
                              alt=""
                              className="img-fluid"
                            />
                            <div className="projeto-date">
                              <i className="fas fa-calendar" />
                              {moment(start_date).format('DD/MM/YY')}
                            </div>
                          </div>
                          <div className="projeto-details">
                            <div className="projeto-info">
                              <Link
                                to={`/projectos-iniciativas-details/${id}`}
                                className="projeto-writer"
                              >
                                <i className="fas fa-users" />
                                {gruposand ? gruposand.name_grupo : 'Sem Grupo'}
                              </Link>
                            </div>
                            <Link
                              to={`/projectos-iniciativas-details/${id}`}
                              className="projeto-title"
                            >
                              {name}
                            </Link>
                            <div className="projeto-btn">
                              <Link
                                to={`/projectos-iniciativas-details/${id}`}
                                className="btn-common-sm"
                              >
                                Ler Mais
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  )
                ) : (
                  <h5>Nenhum Projeto foi encontrado!</h5>
                )}
              </PaginatedItems>
            ) : (
              <h5>Nenhum Projeto foi encontrado!</h5>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjetoEIniciativa
