import axiosService from '../axios'

class IlhaService {
  create(data) {
    return axiosService.post('/ilhas', data)
  }

  retrieve() {
    return axiosService.get('/ilhas')
  }

  update(data, id) {
    return axiosService.put(`ilhas/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`ilhas/${id}`)
  }
}

export default new IlhaService()
