import React, { useState, useEffect } from 'react'
import {
  CAlert,
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { createResultadoAgua } from 'src/store/reducers/resultadoAguaSlice'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import SwalAlert from 'src/utils/SwalAlert'
import { schema } from './validation'
import { retrieveAmostraAgua } from '../../../../store/reducers/amostraAguaSlice'

function AddResultadoDialog({ visible, setVisible }) {
  const dispatch = useDispatch()

  const [amostraOptions, setAmostraOption] = useState([])
  const amostras = useSelector((state) => state.amostras)

  const [phOptions, setPhOption] = useState([])

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const saveResultadoAgua = async (data) => {
    const values = {
      ...data,
      amostraagua: parseInt(data.amostraagua, 10),
      date_resultado: new Date(data.date_resultado),
    }

    dispatch(createResultadoAgua({ ...data, ...values }))
      .unwrap()
      .then(() => {
        SwalAlert.success()
        reset({
          amostraagua: '',
          date_resultado: '',
          dissolved_oxygen: '0',
          dissolved_oxygen_demand_BOD_ppm: '0',
          fecal_coliform: '0',
          Counting_enterococcos: '0',
          Counting_escherichina: '0',
          ph: '0',
          phosphate_ppm: '0',
          conductivity: '0',
          temperature: '0',
          turbidity_jtu: '0',
          nitrate_ppm: '0',
          salinity_ppt: '0',
        })
      })
      .catch((err) => {
        SwalAlert.error()
      })
  }

  useEffect(() => {
    const options = amostras.map((item) => ({
      value: item.id,
      label: item.sample_number,
    }))
    setAmostraOption(options)
  }, [amostras])

  useEffect(() => {
    const options = [
      { label: '0 - maior grau de acidez', value: '0' },
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '6', value: 'seis' },
      { label: '7 - ph neutro', value: '7' },
      { label: '8', value: '8' },
      { label: '9', value: '9' },
      { label: '10', value: '10' },
      { label: '11', value: '11' },
      { label: '12', value: '12' },
      { label: '13', value: '13' },
      { label: '14 - maior grau de alcanilidade', value: '14' },
    ]
    setPhOption(options)
  }, [])

  useEffect(() => {
    dispatch(retrieveAmostraAgua())
  }, [dispatch])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar Resultado </CModalTitle>
      </CModalHeader>
      <CForm onSubmit={handleSubmit(saveResultadoAgua)}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Número da amostra <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="amostraagua"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker {...field} data={amostraOptions} style={{ width: '100%' }} />
                  )}
                />
                <small className="text-danger">{errors.amostraagua?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="date_resultado"
                  control={control}
                  render={({ field }) => <DatePicker className="w-100" {...field} />}
                />
                <small className="text-danger">{errors.date_resultado?.message}</small>
              </div>
            </CCol>
            <p1 style={{ color: 'darkblue' }}> ANÁLISE MICROBIOLÓGICA DA AMOSTRA</p1> <br />
            <br />
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Oxigênio dissolvido</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  defaultValue={0}
                  min={0}
                  {...register('dissolved_oxygen')}
                />
                <small className="text-danger">{errors.dissolved_oxygen?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Demanda Bioquímica de Oxigênio</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  min={0}
                  defaultValue={0}
                  {...register('dissolved_oxygen_demand_BOD_ppm')}
                />
                <small className="text-danger">
                  {errors.dissolved_oxygen_demand_BOD_ppm?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Bacterias coliformes fecais</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  min={0}
                  defaultValue={0}
                  {...register('fecal_coliform')}
                />
                <small className="text-danger">{errors.fecal_coliform?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Contagem de Enterococcos fecais- Filtração por Membrana
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  min={0}
                  defaultValue={0}
                  {...register('Counting_enterococcos')}
                />
                <small className="text-danger">{errors.Counting_enterococcos?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Contagem de Escherichina coli- Filtração por Membrana
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  min={0}
                  defaultValue={0}
                  {...register('Counting_escherichina')}
                />
                <small className="text-danger">{errors.Counting_escherichina?.message}</small>
              </div>
            </CCol>
            <p1 style={{ color: 'darkblue' }}> ANÁLISE FÍSICO-QUÍMICO DA AMOSTRA</p1>
            <br />
            <br />
            <CCol lg={4}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Ph <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="ph"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker {...field} data={phOptions} style={{ width: '100%' }} />
                  )}
                />
                <small className="text-danger">{errors.ph?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Nitratos</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  min={0}
                  defaultValue={0}
                  {...register('nitrate_ppm')}
                />
                <small className="text-danger">{errors.nitrate_ppm?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Fosfato</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  min={0}
                  defaultValue={0}
                  {...register('phosphate_ppm')}
                />
                <small className="text-danger">{errors.phosphate_ppm?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Salinidade</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  min={0}
                  defaultValue={0}
                  {...register('salinity_ppt')}
                />
                <small className="text-danger">{errors.salinity_ppt?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Condutividade(uS/cm)</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  min={0}
                  defaultValue={0}
                  {...register('conductivity')}
                />
                <small className="text-danger">{errors.conductivity?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Temperatura</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  min={0}
                  defaultValue={0}
                  {...register('temperature')}
                />
                <small className="text-danger">{errors.temperature?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Turbidez</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  min={0}
                  defaultValue={0}
                  {...register('turbidity_jtu')}
                />
                <small className="text-danger">{errors.turbidity_jtu?.message}</small>
              </div>
            </CCol>
            <CCol lg={12}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Mais Observações</CFormLabel>
                <CFormTextarea
                  {...register('other_observation_eg_smell_colour_of_water')}
                  cols={50}
                  rows={6}
                  style={{ resize: 'none' }}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
AddResultadoDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default AddResultadoDialog
