import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ResultadoGService from '../../service/monitorizacao/ResultadoG'

export const createResultadoG = createAsyncThunk('resultadoGranulometrico/create', async (data) => {
  const response = await ResultadoGService.create(data)
  return response.data
})

export const retrieveResultadoG = createAsyncThunk('resultadoGranulometrico/retrieve', async () => {
  const response = await ResultadoGService.retrieve()
  return response.data
})

export const updateResultadoG = createAsyncThunk(
  'resultadoGranulometrico/update',
  async ({ data, id }) => {
    const response = await ResultadoGService.update(data, id)
    return response.data
  },
)

export const deleteResultadoG = createAsyncThunk('resultadoGranulometrico/delete', async (id) => {
  const response = await ResultadoGService.delete(id)
  return response.data
})

const initialState = []

const resultadoGSlice = createSlice({
  name: 'ResultadoG',
  initialState,
  extraReducers: {
    [createResultadoG.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveResultadoG.fulfilled]: (state, action) => [...action.payload],
    [updateResultadoG.fulfilled]: (state, action) => {
      const index = state.findIndex((ResultadoG) => ResultadoG.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteResultadoG.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default resultadoGSlice.reducer
