import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  praia: null,
}

const selectedPraiaSlice = createSlice({
  name: 'selectedPraia',
  initialState,
  reducers: {
    selectPraia: (state, action) => {
      state.praia = action.payload
    },
  },
})

export const { selectPraia } = selectedPraiaSlice.actions

export default selectedPraiaSlice.reducer
