import React, { useState, useEffect } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CModal,
  CFormTextarea,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { cilSave, cilXCircle } from "@coreui/icons";
import { SelectPicker, DatePicker } from "rsuite";
import { retrieveGruposand } from "src/store/reducers/gruposandSlice";
import { retrievePraias } from "src/store/reducers/praiaSlice";
import { createFichaLimpeza } from "src/store/reducers/fichaLimpezaSlice";
import SwalAlert from "src/utils/SwalAlert";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Alert from "@mui/material/Alert";
import { ADMIN_PRAIA, SUPER_ADMIN } from "src/utils/permission";
import { schema } from "./validation";
import UploadService from "../../../service/Upload";

function AddFichaLimpezaDialog({ visible, setVisible }) {
  const dispatch = useDispatch();

  const gruposands = useSelector((state) => state.gruposands);
  const praias = useSelector((state) => state.praias);
  const { user } = useSelector((state) => state.account);

  const [grupoOptions, setGrupoOption] = useState([]);
  const [praiaOptions, setPraiaOption] = useState([]);
  const [tipoOptions, setTipoOption] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [images, setImage] = useState(null);

  const saveFichaLimpeza = async (data) => {
    const formData = new FormData();
    let cleaning_picture;
    if (images) {
      formData.append("files", images[0]);
      const { data } = await UploadService.upload(formData);
      cleaning_picture = data;
    }

    const values = {
      ...data,
      praia: parseInt(data.praia, 10),
      gruposand: parseInt(data.gruposand, 10),
      cleaning_date: new Date(data.cleaning_date),
      cleaning_picture,
    };
    if (ADMIN_PRAIA.includes(user.role.name)) {
      values.gruposand = user.gruposand.id;
    }
    dispatch(createFichaLimpeza({ ...data, ...values, cleaning_picture }))
      .unwrap()
      .then(() => {
        SwalAlert.success();
        reset({
          praia: "",
          gruposand: "",
          note: "",
          cleaning_date: "",
          type: "",
          Estimated_total_weight_collected: "",
          time_spent_cleaning: "",
          extension_cleaned: "",
          number_of_collected_garbage: "",
          paper_bag: "0",
          plastic_bag: "0",
          balloons: "0",
          plastic_bottles: "0",
          glass_bottles: "0",
          beverage_can: "0",
          bottle_caps: "0",
          clothing: "0",
          cutlery: "0",
          food_packaging: "0",
          toy: "0",
          straw: "0",
          Cigarette: "0",
          lighters: "0",
          cigar_butts: "0",
          Tobacco_Packaging: "0",
          Home_appliances: "0",
          battery: "0",
          Construction_material: "0",
          Car_Parts: "0",
          fuel_Barrel: "0",
          Tires: "0",
          condoms: "0",
          diapers: "0",
          syringes: "0",
          pads: "0",
          Bait_packaging: "0",
          buoys: "0",
          plastic_tarpaulin: "0",
          Nylon_ropes: "0",
          Fishing_nets: "0",
          fishing_lines: "0",
          fishing_bait: "0",
          package_of_cleaning_products: "0",
          lubricating_oil: "0",
          scotch_tapes: "0",
          Traps: "0",
          crates: "0",
          wooden_pallet: "0",
        });
      })
      .catch((err) => {
        SwalAlert.error();
      });
  };

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }));
    setGrupoOption(options);
  }, [gruposands]);

  useEffect(() => {
    let filtered = praias;

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id);
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }));
    setPraiaOption(options);
  }, [praias, user]);

  useEffect(() => {
    const options = [
      { label: "Praia/Beira-mar ", value: "Praia/Beira-mar " },
      { label: "Submarina", value: "Submarina" },
    ];
    setTipoOption(options);
  }, []);

  useEffect(() => {
    dispatch(retrieveGruposand());
    dispatch(retrievePraias());
  }, [dispatch]);

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar Ficha de Limpeza da Praia</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={handleSubmit(saveFichaLimpeza)}>
        <CModalBody>
          <CRow>
            <h5 style={{ color: "#f9812a" }}>
              1. Informação do Local da Limpeza
            </h5>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      {" "}
                      <br />
                      Praia <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="praia"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker
                      {...field}
                      data={praiaOptions}
                      style={{ width: "100%" }}
                    />
                  )}
                />
                <small className="text-danger">{errors.praia?.message}</small>
              </div>
            </CCol>
            {SUPER_ADMIN.includes(user.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">
                        Grupo <strong className="text-danger">*</strong>
                      </CFormLabel>
                    </CCol>
                  </CRow>
                  <Controller
                    name="gruposand"
                    control={control}
                    render={({ field }) => (
                      <SelectPicker
                        {...field}
                        data={grupoOptions}
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                  <small className="text-danger">
                    {errors.gruposand?.message}
                  </small>
                </div>
              </CCol>
            )}
            <CCol lg={4}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data de Limpeza <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="cleaning_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker className="w-100" {...field} />
                  )}
                />
                <small className="text-danger">
                  {errors.cleaning_date?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Tipo de Limpeza <strong className="text-danger">*</strong>{" "}
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker
                      {...field}
                      data={tipoOptions}
                      style={{ width: "100%" }}
                    />
                  )}
                />
                <small className="text-danger">{errors.type?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Extensão que foi limpa (Km){" "}
                  <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  step="0.001"
                  {...register("extension_cleaned")}
                />
                <small className="text-danger">
                  {errors.extension_cleaned?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Nº de sacos de lixo enchidos{" "}
                  <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  step="0.001"
                  {...register("number_of_collected_garbage")}
                />
                <small className="text-danger">
                  {errors.number_of_collected_garbage?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Peso total coletado (Kg){" "}
                  <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  {...register("Estimated_total_weight_collected")}
                />
                <small className="text-danger">
                  {errors.Estimated_total_weight_collected?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Tempo gasto com a limpeza{" "}
                  <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput
                  step={2}
                  type="time"
                  {...register("time_spent_cleaning")}
                />
                <small className="text-danger">
                  {errors.time_spent_cleaning?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto do Espaço</CFormLabel>
                <CFormInput
                  type="file"
                  id="item"
                  onChange={(e) => setImage(e.target.files)}
                />
              </div>
            </CCol>
            <h5 style={{ color: "#f9812a" }}> 2. Animais Emaranhados</h5> <br />
            <Alert severity="info">
              {" "}
              Anote quaisquer animais encontrados emaranhado durante a limpeza.{" "}
              <br /> Registre o tipo de material em que eles estavam emaranhados
              e se foram encontrados vivos ou mortos.{" "}
            </Alert>
            <CCol lg={12}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Descrição dos Animais Emaranhados
                </CFormLabel>
                <CFormTextarea
                  {...register("note")}
                  cols={50}
                  rows={6}
                  style={{ resize: "none" }}
                />
                <small className="text-danger">{errors.note?.message}</small>
              </div>
            </CCol>
            <h5 style={{ color: "#f9812a" }}>
              3. Contagem dos Itens Coletados
            </h5>{" "}
            <br />
            <br />
            <p1 style={{ color: "darkblue" }}>
              3.1. ATIVIDADES A BEIRA-MAR E RECREATIVAS
            </p1>
            <br />
            <Alert severity="info">
              (Lixo atribuído: a pessoas que frequentam a praia, “fast food”
              esportes/jogos, festivais, lixo da rua){" "}
            </Alert>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Sacos (papel)
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("paper_bag")}
                />
                <small className="text-danger">
                  {errors.paper_bag?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Sacos (plástico)
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("plastic_bag")}
                />
                <small className="text-danger">
                  {errors.plastic_bag?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Balões
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("balloons")}
                />
                <small className="text-danger">
                  {errors.balloons?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Garrafas de Plástico
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("plastic_bottles")}
                />
                <small className="text-danger">
                  {errors.plastic_bottles?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Garrafas de Vidro</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("glass_bottles")}
                />
                <small className="text-danger">
                  {errors.glass_bottles?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Latas de Bebidas</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("beverage_can")}
                />
                <small className="text-danger">
                  {errors.beverage_can?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Tampas de garrafas</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("bottle_caps")}
                />
                <small className="text-danger">
                  {errors.bottle_caps?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Peças de Vestuários</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("clothing")}
                />
                <small className="text-danger">
                  {errors.clothing?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Copos, Talheres</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("cutlery")}
                />
                <small className="text-danger">{errors.cutlery?.message}</small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Embalagem(Comida)</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("food_packaging")}
                />
                <small className="text-danger">
                  {errors.food_packaging?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Palinhas</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("straw")}
                />
                <small className="text-danger">{errors.straw?.message}</small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Brinquedos</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("toy")}
                />
                <small className="text-danger">{errors.toy?.message}</small>
              </div>
            </CCol>
            <p1 style={{ color: "darkblue" }}>3.2. ATIVIDADES AQUÁTICAS</p1>{" "}
            <br />
            <Alert severity="info">
              (Lixo de pesca recreativa/comercial e operação de
              barcos/embarcações){" "}
            </Alert>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  {" "}
                  <br />
                  Bóias
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("buoys")}
                />
                <small className="text-danger">{errors.buoys?.message}</small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  {" "}
                  <br />
                  Lonas plásticas
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("plastic_tarpaulin")}
                />
                <small className="text-danger">
                  {errors.plastic_tarpaulin?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  {" "}
                  <br />
                  Cordas de nylon
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("Nylon_ropes")}
                />
                <small className="text-danger">
                  {errors.Nylon_ropes?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  {" "}
                  <br />
                  Redes de pesca
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("Fishing_nets")}
                />
                <small className="text-danger">
                  {errors.Fishing_nets?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Linhas de pesca</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("fishing_lines")}
                />
                <small className="text-danger">
                  {errors.fishing_lines?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Isca de pescaria</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("fishing_bait")}
                />
                <small className="text-danger">
                  {errors.fishing_bait?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Embalagens de iscas</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("Bait_packaging")}
                />
                <small className="text-danger">
                  {errors.Bait_packaging?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Paleta de madeira</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("wooden_pallet")}
                />
                <small className="text-danger">
                  {errors.wooden_pallet?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Embalagens (óleo lubrificante)
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("lubricating_oil")}
                />
                <small className="text-danger">
                  {errors.lubricating_oil?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Fitas de empacotamento</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("scotch_tapes")}
                />
                <small className="text-danger">
                  {errors.scotch_tapes?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Armadilhas de peixes/lagosta)
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("Traps")}
                />
                <small className="text-danger">{errors.Traps?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Engradados/caixotes</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("crates")}
                />
                <small className="text-danger">{errors.crates?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Embalagens de produtos de limpeza
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("package_of_cleaning_products")}
                />
                <small className="text-danger">
                  {errors.package_of_cleaning_products?.message}
                </small>
              </div>
            </CCol>
            <p1 style={{ color: "darkblue" }}>
              3.3. ATIVIDADES RELACIONADAS AO HABÍTO DE FUMAR
            </p1>
            <br />
            <Alert severity="info">
              (Lixo atribuído: a pessoas que frequentam a praia que são
              fumadoras ){" "}
            </Alert>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  {" "}
                  <br />
                  Cigarro
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("Cigarette")}
                />
                <small className="text-danger">
                  {errors.Cigarette?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  {" "}
                  <br />
                  Isqueiros
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("lighters")}
                />
                <small className="text-danger">
                  {errors.lighters?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  {" "}
                  <br />
                  Pontas de charutos
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("cigar_butts")}
                />
                <small className="text-danger">
                  {errors.cigar_butts?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  {" "}
                  <br />
                  Embalagens de Tabaco
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("Tobacco_Packaging")}
                />
                <small className="text-danger">
                  {errors.Tobacco_Packaging?.message}
                </small>
              </div>
            </CCol>
            <p1 style={{ color: "darkblue" }}> 3.4. ATIVIDADES POLUIDORAS</p1>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  {" "}
                  <br />
                  Eletrodomésticos
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("Home_appliances")}
                />
                <small className="text-danger">
                  {errors.Home_appliances?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br /> Pilhas
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("battery")}
                />
                <small className="text-danger">{errors.battery?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br /> Material de construção
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("Construction_material")}
                />
                <small className="text-danger">
                  {errors.Construction_material?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  {" "}
                  Automóveis/Peças de automóveis
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("Car_Parts")}
                />
                <small className="text-danger">
                  {errors.Car_Parts?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Tambores (tonéis)</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("fuel_Barrel")}
                />
                <small className="text-danger">
                  {errors.fuel_Barrel?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Pneus</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("Tires")}
                />
                <small className="text-danger">{errors.Tires?.message}</small>
              </div>
            </CCol>
            <p1 style={{ color: "darkblue" }}>
              {" "}
              3.5. RESÍDUOS HOSPITALARES/HIGIENE PESSOAL
            </p1>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  {" "}
                  <br />
                  Preservativos
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("condoms")}
                />
                <small className="text-danger">{errors.condoms?.message}</small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  {" "}
                  <br />
                  Fraldas
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("diapers")}
                />
                <small className="text-danger">{errors.diapers?.message}</small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  {" "}
                  <br />
                  Seringas
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("syringes")}
                />
                <small className="text-danger">
                  {errors.syringes?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  {" "}
                  <br />
                  Absorventes
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("pads")}
                />
                <small className="text-danger">{errors.pads?.message}</small>
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  );
}
AddFichaLimpezaDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default AddFichaLimpezaDialog;
