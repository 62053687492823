import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import TableWrapper from 'src/components/TableWrapper'
import ReactDatatable from '@ashvin27/react-datatable'
import {
  deleteContagemAtividade,
  retrieveContagemAtividade,
} from 'src/store/reducers/contagemAtividadeSlice'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'
import SwalAlert from 'src/utils/SwalAlert'
import { ADMIN, SUPER_ADMIN } from 'src/utils/permission'
import SeeContagemAtividadeDialog from './SeeContagemAtividadeDialog'
import EditContagemAtividadeDialog from './EditContagemAtividadeDialog'
import AddContagemAtividadeDialog from './AddContagemAtividadeDialog'
import { columns, config } from './tableConf'

function ContagemAtividade() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const contagemAtividades = useSelector((state) => state.contagemAtividades)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')
  const [currentItem, setCurrentItem] = useState(null)
  const [data, setData] = useState([])
  const [role, setRole] = useState(null)

  const destroy = (record, index) => {
    SwalAlert.warning().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteContagemAtividade(record.id))
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success')
      }
    })
  }

  const changeItem = (record, index) => {
    setAction('edit')
    setCurrentItem(record)
    setVisible(true)
  }

  const see = (record, index) => {
    setAction('')
    setCurrentItem(record)
    setVisible(true)
  }

  useEffect(() => {
    dispatch(retrieveContagemAtividade())
      .unwrap()
      .then(() => setLoading(false))
  }, [dispatch])

  useEffect(() => {
    if (user) {
      let datas = contagemAtividades

      if (user.role.name !== SUPER_ADMIN) {
        datas = contagemAtividades.filter((contagem) => contagem.gruposand.id === user.gruposand.id)
      }

      const data = datas.map((item, index) => ({
        index: index + 1,
        id: item.id,
        date: moment(item.date).format('DD-MM-YYYY'),
        praia: item.praia.name_praia,
        grupo: item.gruposand.name_grupo,
        time: item.time,
        sea_bathing: item.sea_bathing,
        sunbathing: item.sunbathing,
        walking_and_or_running: item.walking_and_or_running,
        picnicking: item.picnicking,
        fishing: item.fishing,
        playing: item.playing,
        windsurfing_kitsurfing: item.windsurfing_kitsurfing,
        horse_riding: item.horse_riding,
        sitting: item.sitting,
        other: item.other,
        total: item.total,
      }))
      setData(data)
      setRole(user.role.name)
    }
  }, [contagemAtividades, user])
  return (
    <CContainer lg>
      {ADMIN.includes(role) && (
        <CRow className="mb-4 mt-4">
          <CCol>
            <CButton
              className="main-button"
              onClick={() => {
                setAction('add')
                setVisible(true)
              }}
            >
              Adicionar
            </CButton>
          </CCol>
        </CRow>
      )}

      <ReactDatatable
        config={config(user && role)}
        records={data}
        loading={loading}
        columns={columns(destroy, changeItem, see, role)}
      />

      {action === 'add' ? (
        <AddContagemAtividadeDialog visible={visible} setVisible={setVisible} />
      ) : action === 'edit' ? (
        <EditContagemAtividadeDialog visible={visible} setVisible={setVisible} item={currentItem} />
      ) : (
        <SeeContagemAtividadeDialog visible={visible} setVisible={setVisible} item={currentItem} />
      )}
    </CContainer>
  )
}
export default ContagemAtividade
