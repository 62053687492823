import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DiarioPraiaService from '../../service/monitorizacao/Diario'

export const createDiarioPraia = createAsyncThunk('diarioPraia/create', async (data) => {
  const response = await DiarioPraiaService.create(data)
  return response.data
})
export const retrieveDiarioPraia = createAsyncThunk('diarioPraia/retrieve', async () => {
  const response = await DiarioPraiaService.retrieve()
  return response.data
})

export const updateDiarioPraia = createAsyncThunk('diarioPraia/update', async ({ data, id }) => {
  const response = await DiarioPraiaService.update(data, id)
  return response.data
})
export const deleteDiarioPraia = createAsyncThunk('diarioPraia/delete', async (id) => {
  const response = await DiarioPraiaService.delete(id)
  return response.data
})

const initialState = []

const diarioPraiaSlice = createSlice({
  name: 'DiarioPraia',
  initialState,
  extraReducers: {
    [createDiarioPraia.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveDiarioPraia.fulfilled]: (state, action) => [...action.payload],
    [updateDiarioPraia.fulfilled]: (state, action) => {
      const index = state.findIndex((DiarioPraia) => DiarioPraia.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteDiarioPraia.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default diarioPraiaSlice.reducer
