import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { retrieveGruposand } from 'src/store/reducers/gruposandSlice'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import { retrieveTartaruga } from 'src/store/reducers/TartarugaSlice'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import { convert } from 'src/utils/Date'

function SeeNinhoTartarugaDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch()

  const gruposands = useSelector((state) => state.gruposands)
  const praias = useSelector((state) => state.praias)
  const tartarugas = useSelector((state) => state.tartarugas)

  const [date, setDate] = useState()
  const [date_of_emergence_of_hatchlings, setDate_of_emergence_of_hatchlings] = useState()
  const [praia, setPraia] = useState('')
  const [gruposand, setGrupo] = useState()
  const [type_tartarugas, setTartaruga] = useState()
  const [distance_of_edge_of_the_vegetation, setDistance_of_edge_of_the_vegetation] = useState()
  const [sand_observations, setSand_observations] = useState()
  const [number_of_crab_holes_per_square_metre, setNumber_of_crab_holes_per_square_metre] =
    useState()
  const [observation_of_crab_holes, setObservation_of_crab_holes] = useState()
  const [comments, setComments] = useState()
  const [maciez, setMaciez] = useState()
  const [Img_N, setImg_Nest] = useState(null)
  const [Img_C, setImg_Crab] = useState(null)
  const [Img_S, setImg_Sand] = useState(null)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#FFF3E0',

      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  useEffect(() => {
    console.log(item)
    if (item) {
      const praia = praias.find((praia) => praia.name_praia === item.praia)
      console.log(praia.name_praia, item.praia, praia)
      const gruposand = gruposands.find((grupo) => grupo.name_grupo === item.grupo)
      const tartaruga = tartarugas.find(
        (tartaruga) => tartaruga.Especie_tartaruga === item.tartaruga,
      )
      setPraia(praia.name_praia)
      setGrupo(gruposand.name_grupo)
      setTartaruga(tartaruga.Especie_tartaruga)
      setMaciez(item.maciez)
      setNumber_of_crab_holes_per_square_metre(item.number_crab)
      setComments(item.comments)
      setObservation_of_crab_holes(item.observation_crab)
      setSand_observations(item.sand_observations)
      setDistance_of_edge_of_the_vegetation(item.distance_edge)
      setDate(convert(item.date))
      setDate_of_emergence_of_hatchlings(convert(item.date_emergence))
    }
  }, [item, praias, gruposands, tartarugas])

  useEffect(() => {
    dispatch(retrieveGruposand())
    dispatch(retrievePraias())
    dispatch(retrieveTartaruga())
  }, [dispatch])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Info Ninho Tartaruga</CModalTitle>
      </CModalHeader>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <StyledTableCell>
              <strong>Praia :</strong> &nbsp; {praia}
            </StyledTableCell>

            <StyledTableCell align="center">
              <strong>Grupo :</strong>&nbsp; {gruposand}
            </StyledTableCell>
          </TableHead>

          <TableBody>
            <TableCell align="right"> </TableCell>
            <TableCell align="right"> </TableCell>
          </TableBody>
        </Table>

        <Table>
          <TableHead>
            <TableCell align="left">
              <h6>
                <strong style={{ color: 'darkblue' }}> Maciez da Areia </strong>{' '}
              </h6>
            </TableCell>
            <TableCell align="center"> </TableCell>
          </TableHead>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <strong>Info</strong>{' '}
              </StyledTableCell>
              <StyledTableCell>
                <strong>Dados</strong>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Distância da borda da vegetação </TableCell>
              <TableCell>{distance_of_edge_of_the_vegetation}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell> Maciez </TableCell>
              <TableCell>{maciez}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell> Observações da areia </TableCell>
              <TableCell>{sand_observations}</TableCell>
            </TableRow>
          </TableHead>
        </Table>

        <Table>
          <TableHead>
            <TableCell align="right"> </TableCell>
            <TableCell align="right"> </TableCell>
          </TableHead>
          <TableHead>
            <TableCell align="left">
              <h6>
                <strong style={{ color: 'darkblue' }}> Sedimentos </strong>{' '}
              </h6>
            </TableCell>
            <TableCell align="center"> </TableCell>
          </TableHead>

          <TableHead>
            <TableRow>
              <StyledTableCell>
                <strong>Info</strong>{' '}
              </StyledTableCell>
              <StyledTableCell>
                <strong>Dados</strong>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell>Número de buracos de caranguejo por metro quadrado</TableCell>
              <TableCell>{number_of_crab_holes_per_square_metre}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell> Observação de buracos de caranguejo </TableCell>
              <TableCell>{observation_of_crab_holes}</TableCell>
            </TableRow>
          </TableHead>
        </Table>

        <Table>
          <TableHead>
            <TableCell align="right"> </TableCell>
            <TableCell align="right"> </TableCell>
          </TableHead>
          <TableHead>
            <TableCell align="left">
              <h6>
                <strong style={{ color: 'darkblue' }}> Ninhos de Tartaruga </strong>{' '}
              </h6>
            </TableCell>
            <TableCell align="center"> </TableCell>
          </TableHead>

          <TableHead>
            <TableRow>
              <StyledTableCell>
                <strong>Info</strong>{' '}
              </StyledTableCell>
              <StyledTableCell>
                <strong>Dados</strong>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Data </TableCell>
              <TableCell>{moment(date).format('DD-MM-YYYY')}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell> Data de emergência dos filhotes </TableCell>
              <TableCell>{moment(date_of_emergence_of_hatchlings).format('DD-MM-YYYY')}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell> Tipo Tartaruga </TableCell>
              <TableCell>{type_tartarugas || 'Nenhum'}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell> Observações de Ninho Tartaruga </TableCell>
              <TableCell>{comments}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </CModal>
  )
}
SeeNinhoTartarugaDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
}

export default SeeNinhoTartarugaDialog
