import * as Yup from "yup";

export const schema = Yup.object({
  praia: Yup.string().required("Campo obrigatório"),
  // gruposand: Yup.string().required('Campo obrigatório'),
  sample_number: Yup.number().required("Campo obrigatório").positive(),
  Batch_number: Yup.number().required("Campo obrigatório").positive(),
  Quantity: Yup.number().required("Campo obrigatório").positive(),
  transparency: Yup.string().required("Campo obrigatório"),
  Collection_Date: Yup.string().required("Campo obrigatório"),
  Expiration_date: Yup.string().required("Campo obrigatório"),
});
