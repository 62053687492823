import React, { useState } from 'react'
import {
  CButton,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import UploadService from 'src/service/Upload'
import { createDetrito } from 'src/store/reducers/detritoSlice'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { createItem } from '../../../store/reducers/itemSlice'

function AddDetritoDialog({ visible, setVisible }) {
  const dispatch = useDispatch()
  const [detrito, setDetrito] = useState('')
  const [images, setImage] = useState(null)

  const initState = () => {
    setDetrito('')
  }
  const saveDetrito = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.cancelBubble = true

    try {
      dispatch(createDetrito({ name_detrito: detrito })).then(() => {
        Swal.fire({
          text: 'Detrito adicionado com sucesso!',
          icon: 'success',
          confirmButtonColor: '#FF7F47',
          confirmButtonText: 'Ok!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        })
      })
      initState()
    } catch (e) {
      Swal.fire({
        text: 'Detrito não adicionado!',
        icon: 'error',
        confirmButtonColor: '#FF7F47',
        confirmButtonText: 'Ok!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      })
    }
  }

  return (
    <CModal
      backdrop="static"
      alignment="center"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CForm onSubmit={saveDetrito}>
        <CModalHeader>
          <CModalTitle>Adicionar Detrito</CModalTitle>
        </CModalHeader>

        <CModalBody>
          <div className="mb-3">
            <CFormLabel htmlFor="item">Detrito</CFormLabel>
            <CFormInput
              type="text"
              id="item"
              required
              value={detrito}
              autoComplete="off"
              onChange={(e) => setDetrito(e.target.value)}
            />
          </div>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
AddDetritoDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default AddDetritoDialog
