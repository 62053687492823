import axiosService from '../../axios'

class FaunaPraiaService {
  create(data) {
    return axiosService.post('/faunapraias', data)
  }

  retrieve() {
    return axiosService.get('/faunapraias')
  }

  update(data, id) {
    return axiosService.put(`/faunapraias/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/faunapraias/${id}`)
  }
}

export default new FaunaPraiaService()
