import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import ReactDatatable from '@ashvin27/react-datatable'
import { retrieveAmostraAgua, deleteAmostraAgua } from 'src/store/reducers/amostraAguaSlice'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'
import SwalAlert from 'src/utils/SwalAlert'
import GalleryImage from 'src/components/ImageGallery'
import ModalImage from 'src/components/Modal/ImageModal'
import { SUPER_ADMIN, ADMIN } from 'src/utils/permission'
import EditAmostraAguaDialog from './EditAmostraAguaDialog'
import AddAmostraAguaDialog from './AddAmostraAguaDialog'
import { columns, config } from './tableConf'

function AmostraAgua() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const amostras = useSelector((state) => state.amostras)
  const [currentItem, setCurrentItem] = useState(null)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')
  const [data, setData] = useState([])
  const [images, setImages] = useState(null)
  const [imageShow, setShowImage] = useState(false)
  const [role, setRole] = useState(null)

  const destroy = (record, index) => {
    SwalAlert.warning().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteAmostraAgua(record.id))
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success')
      }
    })
  }

  const changeItem = (record, index) => {
    setAction('edit')
    setCurrentItem(record)
    setVisible(true)
  }

  const showImage = (record, index) => {
    setImages(record.Sample_photo)
    setShowImage(true)
  }

  useEffect(() => {
    dispatch(retrieveAmostraAgua())
      .unwrap()
      .then(() => setLoading(false))
      .catch()
  }, [dispatch])

  useEffect(() => {
    if (user) {
      let datas = amostras

      if (user.role.name !== SUPER_ADMIN) {
        datas = amostras.filter((amostra) => amostra.gruposand.id === user.gruposand.id)
      }
      console.log(amostras)
      const data = datas.map((item, index) => ({
        index: index + 1,
        id: item.id,
        praia: item.praia.name_praia,
        grupo: item.gruposand.name_grupo,
        sample_number: item.sample_number,
        Batch_number: item.Batch_number,
        Quantity: item.Quantity,
        transparency: item.transparency,
        Collection_Date: moment(item.Collection_Date).format('DD-MM-YYYY'),
        Expiration_date: moment(item.Expiration_date).format('DD-MM-YYYY'),
        Sample_photo: item.Sample_photo,
      }))

      setData(data)
      setRole(user.role.name)
    }
  }, [amostras, user])

  return (
    <CContainer lg>
      <CRow className="mb-4 mt-4">
        {ADMIN.includes(role) && (
          <CCol>
            <CButton
              className="main-button"
              onClick={() => {
                setAction('add')
                setVisible(true)
              }}
            >
              Adicionar
            </CButton>
          </CCol>
        )}
      </CRow>
      <div className="table-responsive">
        <ReactDatatable
          config={config(user && user.role.name)}
          records={data}
          loading={loading}
          columns={columns(destroy, changeItem, showImage, role)}
        />
      </div>

      {action === 'add' ? (
        <AddAmostraAguaDialog visible={visible} setVisible={setVisible} />
      ) : action === 'edit' ? (
        <EditAmostraAguaDialog visible={visible} setVisible={setVisible} item={currentItem} />
      ) : (
        ''
      )}

      <ModalImage visible={imageShow} setVisible={setShowImage}>
        <GalleryImage items={images} />
      </ModalImage>
    </CContainer>
  )
}
export default AmostraAgua
