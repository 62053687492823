import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ImageGallery from 'react-image-gallery'

function GalleryImage({ items }) {
  const URL = process.env.REACT_APP_SERVER_URL

  const [images, setImages] = useState([{ original: '', thumbnail: '' }])
  useEffect(() => {
    if (items && !Array.isArray(items)) {
      const data = {
        original: URL + items.url,
      }
      setImages([data])
    }

    if (items && Array.isArray(items)) {
      const data = items.map((item) => ({
        original: URL + item.url,
      }))
      setImages(data)
    }
  }, [items, URL])
  return <ImageGallery showPlayButton={false} items={images} />
}

GalleryImage.propTypes = {
  items: PropTypes.array,
}
export default GalleryImage
