import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { retrieveSedimento } from 'src/store/reducers/sedimentoSlice'
import { createResultadoG } from 'src/store/reducers/resultadoGSlice'
import SwalAlert from 'src/utils/SwalAlert'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './validation'

function AddResultadoGDialog({ visible, setVisible }) {
  const dispatch = useDispatch()

  const sedimentos = useSelector((state) => state.sedimentos)

  const [sedimentoOptions, setSedimentoOption] = useState([])
  const [corOptions, setCorOption] = useState([])
  const [formaOptions, setFormaOption] = useState([])

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const saveResultadoG = async (data) => {
    const values = {
      ...data,
      amostraareia: parseInt(data.amostraareia, 10),
      sand_collect_date: new Date(data.sand_collect_date),
    }

    dispatch(createResultadoG({ ...data, ...values }))
      .unwrap()
      .then(() => {
        SwalAlert.success()
        reset({
          amostraareia: '',
          Shape: '',
          Size: '',
          Color: '',
          sand_collect_date: '',
        })
      })
      .catch((err) => {
        SwalAlert.error()
      })
  }

  useEffect(() => {
    const options = sedimentos.map((item) => ({
      value: item.id,
      label: item.sample_number_sand,
    }))
    setSedimentoOption(options)
  }, [sedimentos])

  useEffect(() => {
    const options = [
      { label: 'Negro', value: 'Negro' },
      { label: 'Castanho', value: 'Castanho' },
      { label: 'Branco', value: 'Branco' },
    ]
    setCorOption(options)
  }, [])

  useEffect(() => {
    const options = [
      { label: 'Angular', value: 'Angular' },
      { label: 'Subangular', value: 'Subangular' },
      { label: 'Subarredonda', value: 'Subarredonda' },
      { label: 'Bem Arredonda', value: 'Bem Arredonda' },
    ]
    setFormaOption(options)
  }, [])

  useEffect(() => {
    dispatch(retrieveSedimento())
  }, [dispatch])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>
          Adicionar Resultado da Análise Granulométrica das Amostras de Areia
        </CModalTitle>
      </CModalHeader>
      <CForm onSubmit={handleSubmit(saveResultadoG)}>
        <CModalBody>
          <CRow>
            <CCol lg={12}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Número da amostra <strong className="text-danger">*</strong>{' '}
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="amostraareia"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker {...field} data={sedimentoOptions} style={{ width: '100%' }} />
                  )}
                />
                <small className="text-danger">{errors.amostraareia?.message}</small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="sand_collect_date"
                  control={control}
                  render={({ field }) => <DatePicker className="w-100" {...field} />}
                />
                <small className="text-danger">{errors.sand_collect_date?.message}</small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Cor <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="Color"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker {...field} data={corOptions} style={{ width: '100%' }} />
                  )}
                />
                <small className="text-danger">{errors.Color?.message}</small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Forma <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="Shape"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker {...field} data={formaOptions} style={{ width: '100%' }} />
                  )}
                />
                <small className="text-danger">{errors.Shape?.message}</small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Tamanho(mm) <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput type="number" min={0} step="0.001" id="item" {...register('Size')} />

                <small className="text-danger">{errors.Size?.message}</small>
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
AddResultadoGDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default AddResultadoGDialog
