import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MedicaoOndaService from '../../service/monitorizacao/MedicaoOndas'

export const createMedicaoOnda = createAsyncThunk('medicaoOndas/create', async (data) => {
  const response = await MedicaoOndaService.create(data)
  return response.data
})
export const retrieveMedicaoOnda = createAsyncThunk('medicaoOnda/retrieve', async () => {
  const response = await MedicaoOndaService.retrieve()
  return response.data
})

export const deleteMedicaoOnda = createAsyncThunk('medicaoOnda/delete', async (id) => {
  const response = await MedicaoOndaService.delete(id)
  return response.data
})

export const updateMedicaoOnda = createAsyncThunk('medicaoOnda/update', async ({ data, id }) => {
  const response = await MedicaoOndaService.update(data, id)
  return response.data
})

const initialState = []

const medicaoOndaSlice = createSlice({
  name: 'MedicaoOnda',
  initialState,
  extraReducers: {
    [createMedicaoOnda.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveMedicaoOnda.fulfilled]: (state, action) => [...action.payload],
    [updateMedicaoOnda.fulfilled]: (state, action) => {
      const index = state.findIndex((MedicaoOnda) => MedicaoOnda.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteMedicaoOnda.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default medicaoOndaSlice.reducer
