import React from 'react'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import PropTypes from 'prop-types'

function ModalImage({ visible, setVisible, children }) {
  return (
    <CModal
      backdrop="static"
      alignment="center"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalBody>{children}</CModalBody>

      <CModalFooter>
        <CButton color="secondary" onClick={() => setVisible(false)}>
          Fechar
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

ModalImage.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default ModalImage
