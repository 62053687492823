import { CAvatar, CCol, CContainer, CRow, CFormLabel, CButton } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import TableWrapper from 'src/components/TableWrapper'
import { useDispatch, useSelector } from 'react-redux'
import EditProfile from './EditProfile'
import avatars from '../../assets/avatar.png'

function Profile() {
  const { user } = useSelector((state) => state.account)
  const [visible, setVisible] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)

  return (
    <CContainer lg>
      <TableWrapper title="Perfil">
        <CRow>
          <CCol lg={4}>
            <div className="w-100  d-flex justify-content-center align-content-center">
              <img
                className="rounded mx-auto d-block"
                src={user && user.foto ? process.env.REACT_APP_SERVER_URL + user.foto.url : avatars}
                width="250px"
                height="250px"
                alt="profile image"
              />
            </div>
          </CCol>
          <CCol lg={8}>
            <CRow className="mb-3">
                <CFormLabel className="col-sm-4 lab">Nome:</CFormLabel>
                <CCol sm={8}>
                  <CFormLabel>{user ? user.name : 'N/A'}</CFormLabel>
                </CCol>
              </CRow>
            <CRow className="mb-3">
                <CFormLabel className="col-sm-4 lab">Apelido:</CFormLabel>
                <CCol sm={8}>
                  <CFormLabel>{user ? user.last_name : 'N/A'}</CFormLabel>
                </CCol>
              </CRow>
            <CRow className="mb-3">
              <CFormLabel className="col-sm-4 lab">Username:</CFormLabel>
              <CCol sm={8}>
                <CFormLabel>{user ? user.username : 'N/A'}</CFormLabel>
              </CCol>
            </CRow>
            <CRow className="mb-3">
              <CFormLabel className="col-sm-4 lab">Email:</CFormLabel>
              <CCol sm={8}>
                <CFormLabel>{user ? user.email : 'N/A'}</CFormLabel>
              </CCol>
            </CRow>
            <CRow className="mb-3">
              <CFormLabel className="col-sm-4 lab">Telefone:</CFormLabel>
              <CCol sm={8}>
                <CFormLabel>{user ? user.telefone : 'N/A'}</CFormLabel>
              </CCol>
            </CRow>
            <CRow className="mb-3">
              <CFormLabel className="col-sm-4 lab">Role:</CFormLabel>
              <CCol sm={8}>
                <CFormLabel>{user ? user.role.name : 'N/A'}</CFormLabel>
              </CCol>
            </CRow>
            <CRow className="mb-3">
              <CFormLabel className="col-sm-4 lab">Grupo:</CFormLabel>
              <CCol sm={8}>
                <CFormLabel>
                  {user ? user.gruposand && user.gruposand.name_grupo : 'N/A'}
                </CFormLabel>
              </CCol>
            </CRow>

            <CButton
              className="main-button"
              onClick={() => {
                setVisible(true)
                setCurrentItem(user)
              }}
            >
              Editar
            </CButton>
          </CCol>
        </CRow>
      </TableWrapper>
      <EditProfile visible={visible} setVisible={setVisible} item={currentItem} />
    </CContainer>
  )
}

export default Profile
