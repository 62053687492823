import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProblemaService from '../../service/Problema'

export const createProblema = createAsyncThunk('problema/create', async (data) => {
  const response = await ProblemaService.create(data)
  return response.data
})
export const retrieveProblema = createAsyncThunk('problema/retrieve', async () => {
  const response = await ProblemaService.retrieve()
  return response.data
})

export const updateProblema = createAsyncThunk('problema/update', async ({ data, id }) => {
  const response = await ProblemaService.update(data, id)
  return response.data
})
export const deleteProblema = createAsyncThunk('problema/delete', async (id) => {
  const response = await ProblemaService.delete(id)
  return response.data
})

const initialState = []

const problemaSlice = createSlice({
  name: 'Problema',
  initialState,
  extraReducers: {
    [createProblema.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveProblema.fulfilled]: (state, action) => [...action.payload],
    [updateProblema.fulfilled]: (state, action) => {
      const index = state.findIndex((Projeto) => Projeto.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteProblema.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default problemaSlice.reducer
