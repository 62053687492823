import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DetritoService from '../../service/parametrizacao/Detrito'

export const createDetrito = createAsyncThunk('detrito/create', async (data) => {
  const response = await DetritoService.create(data)
  return response.data
})
export const retrieveDetrito = createAsyncThunk('detrito/retrieve', async () => {
  const response = await DetritoService.retrieve()
  return response.data
})

export const updateDetrito = createAsyncThunk('detrito/update', async ({ data, id }) => {
  const response = await DetritoService.update(data, id)
  return response.data
})
export const deleteDetrito = createAsyncThunk('detrito/delete', async (id) => {
  const response = await DetritoService.delete(id)
  return response.data
})

const initialState = []

const detritoSlice = createSlice({
  name: 'Detrito',
  initialState,
  extraReducers: {
    [createDetrito.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveDetrito.fulfilled]: (state, action) => [...action.payload],
    [updateDetrito.fulfilled]: (state, action) => {
      const index = state.findIndex((detrito) => detrito.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteDetrito.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default detritoSlice.reducer
