import axiosService from '../../axios'

class FaunaService {
  create(data) {
    return axiosService.post('/faunas', data)
  }

  retrieve() {
    return axiosService.get('/faunas')
  }

  update(data, id) {
    return axiosService.put(`faunas/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`faunas/${id}`)
  }
}

export default new FaunaService()
