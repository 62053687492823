import React, { useState, useEffect } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { cilSave, cilXCircle } from "@coreui/icons";
import { SelectPicker, DatePicker } from "rsuite";
import { retrieveGruposand } from "src/store/reducers/gruposandSlice";
import { retrievePraias } from "src/store/reducers/praiaSlice";
import { updateFichaLimpeza } from "src/store/reducers/fichaLimpezaSlice";
import SwalAlert from "src/utils/SwalAlert";
import { convert } from "src/utils/Date";
import Alert from "@mui/material/Alert";
import { ADMIN_PRAIA, SUPER_ADMIN } from "src/utils/permission";
import UploadService from "../../../service/Upload";

function EditFichaLimpezaDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch();
  const [grupoOptions, setGrupoOption] = useState([]);
  const [praiaOptions, setPraiaOption] = useState([]);
  const [tipoOptions, setTipoOption] = useState([]);

  const gruposands = useSelector((state) => state.gruposands);
  const praias = useSelector((state) => state.praias);
  const { user } = useSelector((state) => state.account);

  const [praia, setPraia] = useState("");
  const [gruposand, setGrupo] = useState();
  const [cleaning_date, setCleaning_date] = useState("");
  const [type, setType] = useState();
  const [extension_cleaned, setExtension_cleaned] = useState();
  const [number_of_collected_garbage, setNumber_of_collected_garbage] =
    useState();
  const [
    Estimated_total_weight_collected,
    setEstimated_total_weight_collected,
  ] = useState();
  const [time_spent_cleaning, setTime_spent_cleaning] = useState();
  const [images, setImage] = useState(null);
  const [note, setNote] = useState();
  const [paper_bag, setPaper_bag] = useState();
  const [plastic_bag, setPlastic_bag] = useState();
  const [balloons, setBalloons] = useState();
  const [plastic_bottles, setPlastic_bottles] = useState();
  const [glass_bottles, setGlass_bottles] = useState();
  const [beverage_can, setBeverage_can] = useState();
  const [bottle_caps, setBottle_caps] = useState();
  const [clothing, setClothing] = useState();
  const [cutlery, setCutlery] = useState();
  const [food_packaging, setFood_packaging] = useState();
  const [straw, setStraw] = useState();
  const [toy, setToy] = useState();
  const [buoys, setBuoys] = useState();
  const [plastic_tarpaulin, setPlastic_tarpaulin] = useState();
  const [Nylon_ropes, setNylon_ropes] = useState();
  const [Fishing_nets, setFishing_nets] = useState();
  const [fishing_lines, setFishing_lines] = useState();
  const [fishing_bait, setFishing_bait] = useState();
  const [Bait_packaging, setBait_packaging] = useState();
  const [wooden_pallet, setWooden_pallet] = useState();
  const [lubricating_oil, setLubricating_oil] = useState();
  const [scotch_tapes, setScotch_tapes] = useState();
  const [Traps, setTraps] = useState();
  const [crates, setCrates] = useState();
  const [package_of_cleaning_products, setPackage_of_cleaning_products] =
    useState();
  const [Cigarette, setCigarette] = useState();
  const [lighters, setLighters] = useState();
  const [cigar_butts, setCigar_butts] = useState();
  const [Tobacco_Packaging, setTobacco_Packaging] = useState();
  const [Home_appliances, setHome_appliances] = useState();
  const [battery, setBattery] = useState();
  const [Construction_material, setConstruction_material] = useState();
  const [Car_Parts, setCar_Parts] = useState();
  const [fuel_Barrel, setFuel_Barrel] = useState();
  const [Tires, setTires] = useState();
  const [condoms, setCondoms] = useState();
  const [diapers, setDiapers] = useState();
  const [syringes, setSyringes] = useState();
  const [pads, setPads] = useState();

  const saveFichaLimpeza = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    let cleaning_picture;
    if (images) {
      formData.append("files", images[0]);
      const { data } = await UploadService.upload(formData);
      cleaning_picture = data;
    }

    const data = {
      praia,
      gruposand,
      cleaning_date,
      type,
      extension_cleaned,
      number_of_collected_garbage,
      Estimated_total_weight_collected,
      time_spent_cleaning,
      cleaning_picture,
      note,
      paper_bag,
      plastic_bag,
      balloons,
      plastic_bottles,
      glass_bottles,
      beverage_can,
      bottle_caps,
      clothing,
      cutlery,
      food_packaging,
      straw,
      toy,
      buoys,
      plastic_tarpaulin,
      Nylon_ropes,
      Fishing_nets,
      fishing_lines,
      fishing_bait,
      Bait_packaging,
      wooden_pallet,
      lubricating_oil,
      scotch_tapes,
      Traps,
      crates,
      package_of_cleaning_products,
      Cigarette,
      lighters,
      cigar_butts,
      Tobacco_Packaging,
      Home_appliances,
      battery,
      Construction_material,
      Car_Parts,
      fuel_Barrel,
      Tires,
      condoms,
      diapers,
      syringes,
      pads,
    };

    dispatch(
      updateFichaLimpeza({
        data,
        id: item.id,
      })
    )
      .unwrap()
      .then(() => {
        setVisible(false);
        SwalAlert.success();
      })
      .catch(() => {
        SwalAlert.error();
      });
  };

  useEffect(() => {
    if (item) {
      const praia = praias.find((praia) => praia.name_praia === item.praia);
      const gruposand = gruposands.find(
        (grupo) => grupo.name_grupo === item.grupo
      );
      setPraia(praia.id);
      setGrupo(gruposand.id);
      setCleaning_date(convert(item.cleaning_date));
      setType(item.type);
      setExtension_cleaned(item.extension_cleaned);
      setNumber_of_collected_garbage(item.number_of_collected_garbage);
      setEstimated_total_weight_collected(
        item.Estimated_total_weight_collected
      );
      setTime_spent_cleaning(item.time_spent_cleaning);
      setNote(item.note);
      setPaper_bag(item.paper_bag);
      setPlastic_bag(item.plastic_bag);
      setBalloons(item.balloons);
      setPlastic_bottles(item.plastic_bottles);
      setGlass_bottles(item.glass_bottles);
      setBeverage_can(item.beverage_can);
      setBottle_caps(item.bottle_caps);
      setClothing(item.clothing);
      setCutlery(item.cutlery);
      setFood_packaging(item.food_packaging);
      setStraw(item.straw);
      setToy(item.toy);
      setBuoys(item.buoys);
      setPlastic_tarpaulin(item.plastic_tarpaulin);
      setNylon_ropes(item.Nylon_ropes);
      setFishing_nets(item.Fishing_nets);
      setFishing_lines(item.fishing_lines);
      setFishing_bait(item.fishing_bait);
      setBait_packaging(item.Bait_packaging);
      setWooden_pallet(item.wooden_pallet);
      setLubricating_oil(item.lubricating_oil);
      setScotch_tapes(item.scotch_tapes);
      setTraps(item.Traps);
      setCrates(item.crates);
      setPackage_of_cleaning_products(item.package_of_cleaning_products);
      setCigarette(item.Cigarette);
      setLighters(item.lighters);
      setCigar_butts(item.cigar_butts);
      setTobacco_Packaging(item.Tobacco_Packaging);
      setHome_appliances(item.Home_appliances);
      setBattery(item.battery);
      setConstruction_material(item.Construction_material);
      setCar_Parts(item.Car_Parts);
      setFuel_Barrel(item.fuel_Barrel);
      setTires(item.Tires);
      setCondoms(item.condoms);
      setDiapers(item.diapers);
      setSyringes(item.syringes);
      setPads(item.pads);
    }
  }, [item, praias, gruposands]);

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }));
    setGrupoOption(options);
  }, [gruposands]);

  useEffect(() => {
    let filtered = praias;

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id);
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }));
    setPraiaOption(options);
  }, [praias, user]);

  useEffect(() => {
    const options = [
      { label: "Praia/Beira-mar ", value: "Praia/Beira-mar " },
      { label: "Submarina", value: "Submarina" },
    ];
    setTipoOption(options);
  }, []);

  useEffect(() => {
    dispatch(retrieveGruposand());
    dispatch(retrievePraias());
  }, [dispatch]);
  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar Ficha de Limpeza</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={saveFichaLimpeza}>
        <CModalBody>
          <CRow>
            <h5 style={{ color: "#f9812a" }}>
              1. Informação do Local da Limpeza
            </h5>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      {" "}
                      <br />
                      Praia
                    </CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={praiaOptions}
                  style={{ width: "100%" }}
                  value={praia}
                  onChange={setPraia}
                />
              </div>
            </CCol>
            {SUPER_ADMIN.includes(user.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">Grupo</CFormLabel>
                    </CCol>
                  </CRow>
                  <SelectPicker
                    data={grupoOptions}
                    style={{ width: "100%" }}
                    defaultValue={gruposand}
                    value={gruposand}
                    onChange={setGrupo}
                  />
                </div>
              </CCol>
            )}
            <CCol lg={4}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Data</CFormLabel>
                  </CCol>
                </CRow>
                <DatePicker
                  className="w-100"
                  format="dd-MM-yyyy"
                  defaultValue={cleaning_date}
                  value={cleaning_date}
                  onChange={setCleaning_date}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Tipo de Limpeza</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={tipoOptions}
                  style={{ width: "100%" }}
                  value={type}
                  onChange={setType}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Extensão que foi limpa (Km)
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  step="0.001"
                  id="item"
                  value={extension_cleaned}
                  onChange={(e) => setExtension_cleaned(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Nº de sacos de lixo enchidos
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  step="0.001"
                  id="item"
                  value={number_of_collected_garbage}
                  onChange={(e) =>
                    setNumber_of_collected_garbage(e.target.value)
                  }
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Peso total coletado (Kg){" "}
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={Estimated_total_weight_collected}
                  onChange={(e) =>
                    setEstimated_total_weight_collected(e.target.value)
                  }
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Tempo gasto com a limpeza{" "}
                </CFormLabel>
                <CFormInput
                  step={2}
                  type="time"
                  id="item"
                  value={time_spent_cleaning}
                  defaultValue={time_spent_cleaning}
                  onChange={(e) => setTime_spent_cleaning(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto do Espaço</CFormLabel>
                <CFormInput
                  type="file"
                  id="item"
                  onChange={(e) => setImage(e.target.files)}
                />
              </div>
            </CCol>
            <h5 style={{ color: "#f9812a" }}> 2. Animais Emaranhados</h5> <br />
            <Alert severity="info">
              {" "}
              Anote quaisquer animais encontrados emaranhado durante a limpeza.{" "}
              <br /> Registre o tipo de material em que eles estavam emaranhados
              e se foram encontrados vivos ou mortos.{" "}
            </Alert>
            <CCol lg={12}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Descrição dos Animais Emaranhados
                </CFormLabel>
                <CFormTextarea
                  cols={50}
                  rows={6}
                  style={{ resize: "none" }}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </CCol>
            <h5 style={{ color: "#f9812a" }}>
              3. Contagem dos Itens Coletados
            </h5>{" "}
            <br />
            <br />
            <p1 style={{ color: "darkblue" }}>
              3.1. ATIVIDADES A BEIRA-MAR E RECREATIVAS
            </p1>
            <br />
            <Alert severity="info">
              (Lixo atribuído: a pessoas que frequentam a praia, “fast food”
              esportes/jogos, festivais, lixo da rua){" "}
            </Alert>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Sacos (papel){" "}
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={paper_bag}
                  onChange={(e) => setPaper_bag(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Sacos (plástico){" "}
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={plastic_bag}
                  onChange={(e) => setPlastic_bag(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Balões{" "}
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={balloons}
                  onChange={(e) => setBalloons(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Garrafas de Plástico{" "}
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={plastic_bottles}
                  onChange={(e) => setPlastic_bottles(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Garrafas de Vidro</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={glass_bottles}
                  onChange={(e) => setGlass_bottles(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Latas de Bebidas</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={beverage_can}
                  onChange={(e) => setBeverage_can(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Tampas de garrafas</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={bottle_caps}
                  onChange={(e) => setBottle_caps(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Peças de Vestuários</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={clothing}
                  onChange={(e) => setClothing(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item"> Copos, Talheres</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={cutlery}
                  onChange={(e) => setCutlery(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Embalagem(Comida)</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={food_packaging}
                  onChange={(e) => setFood_packaging(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Palinhas</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={straw}
                  onChange={(e) => setStraw(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Brinquedos</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={toy}
                  onChange={(e) => setToy(e.target.value)}
                />
              </div>
            </CCol>
            <p1 style={{ color: "darkblue" }}>3.2. ATIVIDADES AQUÁTICAS</p1>{" "}
            <br />
            <Alert severity="info">
              (Lixo de pesca recreativa/comercial e operação de
              barcos/embarcações){" "}
            </Alert>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Bóias{" "}
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={buoys}
                  onChange={(e) => setBuoys(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Lonas plásticas{" "}
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={plastic_tarpaulin}
                  onChange={(e) => setPlastic_tarpaulin(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Cordas de nylon{" "}
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={Nylon_ropes}
                  onChange={(e) => setNylon_ropes(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Redes de pesca{" "}
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={Fishing_nets}
                  onChange={(e) => setFishing_nets(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Linhas de pesca </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={fishing_lines}
                  onChange={(e) => setFishing_lines(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Isca de pescaria </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={fishing_bait}
                  onChange={(e) => setFishing_bait(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Embalagens de iscas </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={Bait_packaging}
                  onChange={(e) => setBait_packaging(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Paleta de madeira </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={wooden_pallet}
                  onChange={(e) => setWooden_pallet(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Embalagens (óleo lubrificante)
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={lubricating_oil}
                  onChange={(e) => setLubricating_oil(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Fitas de empacotamento </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={scotch_tapes}
                  onChange={(e) => setScotch_tapes(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Armadilhas de peixes/lagosta)
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={Traps}
                  onChange={(e) => setTraps(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Engradados/caixotes</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={crates}
                  onChange={(e) => setCrates(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Embalagens de produtos de limpeza
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={package_of_cleaning_products}
                  onChange={(e) =>
                    setPackage_of_cleaning_products(e.target.value)
                  }
                />
              </div>
            </CCol>
            <p1 style={{ color: "darkblue" }}>
              3.3. ATIVIDADES RELACIONADAS AO HABÍTO DE FUMAR
            </p1>
            <br />
            <Alert severity="info">
              (Lixo atribuído: a pessoas que frequentam a praia que são
              fumadoras ){" "}
            </Alert>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Cigarro{" "}
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={Cigarette}
                  onChange={(e) => setCigarette(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Isqueiros
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={lighters}
                  onChange={(e) => setLighters(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Pontas de charutos{" "}
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={cigar_butts}
                  onChange={(e) => setCigar_butts(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Embalagens de Tabaco{" "}
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={Tobacco_Packaging}
                  onChange={(e) => setTobacco_Packaging(e.target.value)}
                />
              </div>
            </CCol>
            <p1 style={{ color: "darkblue" }}> 3.4. ATIVIDADES POLUIDORAS</p1>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Eletrodomésticos
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={Home_appliances}
                  onChange={(e) => setHome_appliances(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Pilhas{" "}
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={battery}
                  onChange={(e) => setBattery(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Material de construção
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={Construction_material}
                  onChange={(e) => setConstruction_material(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Automóveis/Peças de automóveis
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={Car_Parts}
                  onChange={(e) => setCar_Parts(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Tambores (tonéis)</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={fuel_Barrel}
                  onChange={(e) => setFuel_Barrel(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Pneus</CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={Tires}
                  onChange={(e) => setTires(e.target.value)}
                />
              </div>
            </CCol>
            <p1 style={{ color: "darkblue" }}>
              {" "}
              3.5. RESÍDUOS HOSPITALARES/HIGIENE PESSOAL
            </p1>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Preservativos
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={condoms}
                  onChange={(e) => setCondoms(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Fraldas
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={diapers}
                  onChange={(e) => setDiapers(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Seringas
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={syringes}
                  onChange={(e) => setSyringes(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  <br />
                  Absorventes
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  value={pads}
                  onChange={(e) => setPads(e.target.value)}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  );
}
EditFichaLimpezaDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default EditFichaLimpezaDialog;
