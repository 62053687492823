import axiosService from '../axios'

class ProblemaService {
  create(data) {
    return axiosService.post('/problemas', data)
  }

  retrieve() {
    return axiosService.get('/problemas')
  }

  update(data, id) {
    return axiosService.put(`/problemas/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/problemas/${id}`)
  }
}

export default new ProblemaService()
