import React from 'react'

import Dashboard from './views/dashboard/Dashboard'
import AddInfo from './views/praia/AddInfo'
import AddItem from './views/praia/AddItem'
import Projeto from './views/praia/Projeto'
import Problema from './views/praia/Problema'

/* const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const AddInfo = React.lazy(() => import('./views/praia/AddInfo'))
const AddItem = React.lazy(() => import('./views/praia/AddItem'))
const Projeto = React.lazy(() => import('./views/praia/Projeto'))
const Problema = React.lazy(() => import('./views/praia/Problema')) */

import Item from './views/parametrizacao/Item'
import Actividade from './views/parametrizacao/Actividade'
import Detrito from './views/parametrizacao/Detrito'
import Fauna from './views/parametrizacao/Fauna'
import DetritoPraia from './views/monitorizacao/DetritoPraia'
import DiarioPraia from './views/monitorizacao/Diario'
import Flora from './views/parametrizacao/Flora'
import Tartaruga from './views/parametrizacao/Tartaruga'
import Profile from './views/Profile'

/* const Item = React.lazy(() => import('./views/parametrizacao/Item'))
const Actividade = React.lazy(() => import('./views/parametrizacao/Actividade'))
const Detrito = React.lazy(() => import('./views/parametrizacao/Detrito'))
const Fauna = React.lazy(() => import('./views/parametrizacao/Fauna'))
const DiarioPraia = React.lazy(() => import('./views/monitorizacao/Diario'))
const DetritoPraia = React.lazy(() => import('./views/monitorizacao/DetritoPraia'))
const Flora = React.lazy(() => import('./views/parametrizacao/Flora'))
const Tartaruga = React.lazy(() => import('./views/parametrizacao/Tartaruga'))
const Profile = React.lazy(() => import('./views/Profile')) */

import Agua from './views/monitorizacao/Agua'
import FaunaPraia from './views/monitorizacao/Fauna'
import Sedimento from './views/monitorizacao/Sedimento'
import FloraPraia from './views/monitorizacao/Flora'
import AtividadeHumana from './views/monitorizacao/AtividadesHumanas'
import FichaLimpeza from './views/monitorizacao/FichaLimpeza'
import Erosao from './views/monitorizacao/Erosao'

/* const Agua = React.lazy(() => import('./views/monitorizacao/Agua'))
const FaunaPraia = React.lazy(() => import('./views/monitorizacao/Fauna'))
const Sedimento = React.lazy(() => import('./views/monitorizacao/Sedimento'))
const FloraPraia = React.lazy(() => import('./views/monitorizacao/Flora'))
const AtividadeHumana = React.lazy(() => import('./views/monitorizacao/AtividadesHumanas'))
const FichaLimpeza = React.lazy(() => import('./views/monitorizacao/FichaLimpeza'))
const Erosao = React.lazy(() => import('./views/monitorizacao/Erosao')) */

import Medicao from './views/monitorizacao/Medicao'
import NinhoTartaruga from './views/monitorizacao/NinhoTartaruga'
import Users from './views/sandwatch/Users'
import Grupo from './views/sandwatch/Grupo'

/* const Medicao = React.lazy(() => import('./views/monitorizacao/Medicao'))
const NinhoTartaruga = React.lazy(() => import('./views/monitorizacao/NinhoTartaruga'))
const Users = React.lazy(() => import('./views/sandwatch/Users'))
const Grupo = React.lazy(() => import('./views/sandwatch/Grupo')) */

const routes = [
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/praia/adicionar-info', name: 'AddInfo', component: AddInfo },
  { path: '/praia/adicionar-items', name: 'AddItem', component: AddItem },
  { path: '/praia/problemas', name: 'Problema', component: Problema },
  { path: '/praia/projectos-iniciativas', name: 'Projeto', component: Projeto },
  { path: '/sandwatch/grupos', name: 'Grupo', component: Grupo },
  { path: '/sandwatch/utilizadores', name: 'Users', component: Users },
  { path: '/monitorizacao/sedimento', name: 'Sedimento', component: Sedimento },
  { path: '/monitorizacao/agua', name: 'Agua', component: Agua },
  { path: '/monitorizacao/erosao-acrecao', name: 'Erosao', component: Erosao },
  { path: '/monitorizacao/flora', name: 'Flora Praia', component: FloraPraia },
  { path: '/monitorizacao/fauna', name: 'Fauna Praia', component: FaunaPraia },
  { path: '/monitorizacao/ninho-tartaruga', name: 'Ninho Tartaruga', component: NinhoTartaruga },
  { path: '/monitorizacao/diario-praias', name: 'Diario Praia', component: DiarioPraia },
  { path: '/monitorizacao/atividades-humanas', name: 'Dashboard', component: AtividadeHumana },
  { path: '/monitorizacao/detrito', name: 'Detrito Praia', component: DetritoPraia },
  { path: '/monitorizacao/ficha-limpeza', name: 'FichaLimpeza', component: FichaLimpeza },
  { path: '/monitorizacao/medicao', name: 'Medicoes', component: Medicao },
  { path: '/parametrizacao/actividades', name: 'Actividade', component: Actividade },
  { path: '/parametrizacao/fauna', name: 'Fauna', component: Fauna },
  { path: '/parametrizacao/flora', name: 'Flora', component: Flora },
  { path: '/parametrizacao/tartaruga', name: 'Tartaruga', component: Tartaruga },
  { path: '/parametrizacao/item', name: 'Item', component: Item },
  { path: '/parametrizacao/detrito', name: 'Detrito', component: Detrito },
  { path: '/profile', name: 'Profile', component: Profile },
]

export default routes
