import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { CChartBar } from '@coreui/react-chartjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { retrieveResultadoG } from 'src/store/reducers/resultadoGSlice'
import PropTypes from 'prop-types'

function ChartTamanhoSedimento({ praia, year }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const resultadosG = useSelector((state) => state.resultadosG)

  const [data, setData] = useState([0, 0, 0, 0])

  useEffect(() => {
    const data = resultadosG.filter((resultado) => resultado.amostraareia.praia === praia)

    let lessThan1 = 0
    let between1and2 = 0
    let between2and4 = 0
    let greaterThan4 = 0
    const total = 0

    data.map((item) => {
      if (item.Size > 4) {
        greaterThan4 += 1
      } else if (item.Size >= 2 && item.Size < 4) {
        between2and4 += 1
      } else if (item.Size >= 1 && item.Size < 2) {
        between1and2 += 1
      } else if (item.Size < 1) {
        lessThan1 += 1
      }
    })
    setData([lessThan1, between1and2, between2and4, greaterThan4])
  }, [resultadosG, praia])

  useEffect(() => {
    dispatch(retrieveResultadoG())
  }, [dispatch])

  return (
    <CCard className="mb-4">
      <CCardHeader className="fw-bold">{t('TAMANHO_SEDIMENTO')}</CCardHeader>
      <CCardBody>
        <CChartBar
          data={{
            labels: ['<= 1mn', '1 - 2mn', '2 - 4mn', '> 4mn'],
            datasets: [
              {
                label: t('QUANTIDADE'),
                backgroundColor: '#FF7F47',
                borderRadius: 10,
                data,
              },
            ],
          }}
          labels="months"
        />
      </CCardBody>
    </CCard>
  )
}
ChartTamanhoSedimento.propTypes = {
  praia: PropTypes.any,
  year: PropTypes.any,
}

export default ChartTamanhoSedimento
