import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { CChartBar } from '@coreui/react-chartjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { retrieveMedicaoCorrente } from 'src/store/reducers/medicaoCorrenteSlice'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const initialState = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

const initialMonth = {
  vel: 0,
  dir: 0,
}

function ChartCorrente({ praia, year }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const medicaoCorrentes = useSelector((state) => state.medicaoCorrentes)

  const [dataVel, setDataVel] = useState(initialState)
  const [dataDir, setDataDir] = useState(initialState)

  const [jan, setJan] = useState(initialMonth)
  const [fev, setFev] = useState(initialMonth)
  const [mar, setMar] = useState(initialMonth)
  const [abr, setAbr] = useState(initialMonth)
  const [mai, setMai] = useState(initialMonth)
  const [jun, setJun] = useState(initialMonth)
  const [jul, setJul] = useState(initialMonth)
  const [ago, setAgo] = useState(initialMonth)
  const [set, setSet] = useState(initialMonth)
  const [out, setOut] = useState(initialMonth)
  const [nov, setNov] = useState(initialMonth)
  const [des, setDes] = useState(initialMonth)

  const calcVel = (speedAfter1mn, speedAfter5mn) => (speedAfter1mn + speedAfter5mn) / 2
  const calcDir = (dirAfter1mn, dirAfter5mn) => (dirAfter1mn + dirAfter5mn) / 2

  const updateState = (setState, vel1, vel2, dir1, dir2) => {
    setState((prevState) => ({
      ...prevState,
      vel: prevState.vel + calcVel(vel1, vel2),
      dir: prevState.dir + calcDir(dir1, dir2),
    }))
  }

  useEffect(() => {
    setJan(initialMonth)
    setFev(initialMonth)
    setMar(initialMonth)
    setAbr(initialMonth)
    setMai(initialMonth)
    setJun(initialMonth)
    setJul(initialMonth)
    setAgo(initialMonth)
    setSet(initialMonth)
    setOut(initialMonth)
    setNov(initialMonth)
    setDes(initialMonth)

    const data = medicaoCorrentes.filter((item) => item.praia.id === praia)

    data.map((corrente) => {
      if (moment(corrente.date).format('YYYY') === year.toString()) {
        const month = moment(corrente.date).format('MMMM')

        const {
          direction_after_1_minute,
          direction_after_5_minutes,
          spoed_after_1_minute,
          spoed_after_5_minutes,
        } = corrente

        switch (month) {
          case 'January':
            updateState(
              setJan,
              spoed_after_1_minute,
              spoed_after_5_minutes,
              direction_after_1_minute,
              direction_after_5_minutes,
            )
            break
          case 'February':
            updateState(
              setFev,
              spoed_after_1_minute,
              spoed_after_5_minutes,
              direction_after_1_minute,
              direction_after_5_minutes,
            )
            break
          case 'March':
            updateState(
              setMar,
              spoed_after_1_minute,
              spoed_after_5_minutes,
              direction_after_1_minute,
              direction_after_5_minutes,
            )
            break
          case 'April':
            updateState(
              setAbr,
              spoed_after_1_minute,
              spoed_after_5_minutes,
              direction_after_1_minute,
              direction_after_5_minutes,
            )
            break
          case 'May':
            updateState(
              setMai,
              spoed_after_1_minute,
              spoed_after_5_minutes,
              direction_after_1_minute,
              direction_after_5_minutes,
            )
            break
          case 'June':
            updateState(
              setJun,
              spoed_after_1_minute,
              spoed_after_5_minutes,
              direction_after_1_minute,
              direction_after_5_minutes,
            )
            break
          case 'July':
            updateState(
              setJul,
              spoed_after_1_minute,
              spoed_after_5_minutes,
              direction_after_1_minute,
              direction_after_5_minutes,
            )
            break
          case 'August':
            updateState(
              setAgo,
              spoed_after_1_minute,
              spoed_after_5_minutes,
              direction_after_1_minute,
              direction_after_5_minutes,
            )
            break
          case 'September':
            updateState(
              setSet,
              spoed_after_1_minute,
              spoed_after_5_minutes,
              direction_after_1_minute,
              direction_after_5_minutes,
            )
            break
          case 'October':
            updateState(
              setOut,
              spoed_after_1_minute,
              spoed_after_5_minutes,
              direction_after_1_minute,
              direction_after_5_minutes,
            )
            break
          case 'November':
            updateState(
              setNov,
              spoed_after_1_minute,
              spoed_after_5_minutes,
              direction_after_1_minute,
              direction_after_5_minutes,
            )
            break
          case 'December':
            updateState(
              setDes,
              spoed_after_1_minute,
              spoed_after_5_minutes,
              direction_after_1_minute,
              direction_after_5_minutes,
            )
            break

          default: {
          }
        }
      }
    })
  }, [praia, medicaoCorrentes, year])

  useEffect(() => {
    setDataVel([
      jan.vel,
      fev.vel,
      mar.vel,
      abr.vel,
      mai.vel,
      jun.vel,
      jul.vel,
      ago.vel,
      set.vel,
      out.vel,
      nov.vel,
      des.vel,
    ])
    setDataDir([
      jan.dir,
      fev.dir,
      mar.dir,
      abr.dir,
      mai.dir,
      jun.dir,
      jul.dir,
      ago.dir,
      set.dir,
      out.dir,
      nov.dir,
      des.dir,
    ])
  }, [jan, fev, mar, abr, mai, jun, jul, ago, set, out, nov, des])

  useEffect(() => {
    dispatch(retrieveMedicaoCorrente())
  }, [dispatch])

  return (
    <CCard className="mb-4">
      <CCardHeader className="fw-bold">{t('VELOCIDADE_ONDA')}</CCardHeader>
      <CCardBody>
        <CChartBar
          data={{
            labels: [
              t('MONTH.JAN'),
              t('MONTH.FEV'),
              t('MONTH.MAR'),
              t('MONTH.ABR'),
              t('MONTH.MAI'),
              t('MONTH.JUN'),
              t('MONTH.JUL'),
              t('MONTH.AGO'),
              t('MONTH.SET'),
              t('MONTH.OUT'),
              t('MONTH.NOV'),
              t('MONTH.DEZ'),
            ],
            datasets: [
              {
                label: t('DIRECAO'),
                type: 'line',
                backgroundColor: '#128F8B',
                borderWidth: 3,
                data: dataDir,
              },
              {
                label: t('VELOCIDADE'),
                type: 'bar',
                backgroundColor: '#FF7F47',

                borderRadius: 10,
                data: dataVel,
              },
            ],
          }}
          labels="months"
        />
      </CCardBody>
    </CCard>
  )
}

ChartCorrente.propTypes = {
  praia: PropTypes.any,
  year: PropTypes.any,
}
export default ChartCorrente
