import React from 'react'
import { CFooter } from '@coreui/react'

const AppFooter = () => {
  const date = new Date()
  return (
    <CFooter>
      <div>
        <a href="https://www.cvunesco.org/" target="_blank" rel="noopener noreferrer">
          Unesco
        </a>
        <span className="ms-1">&copy; {date.getFullYear()}</span>
      </div>
      <div className="ms-auto">
        <span className="me-1">Powered by</span>
        <a href="https://www.unicv.edu.cv/pt/" target="_blank" rel="noopener noreferrer">
          UNICV
        </a>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
