import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import { createProblema } from 'src/store/reducers/problemaSlice'
import SwalAlert from 'src/utils/SwalAlert'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import UploadService from '../../../service/Upload'
import { addSchema } from './validation'

function AddProblemaDialog({ visible, setVisible }) {
  const dispatch = useDispatch()

  const praias = useSelector((state) => state.praias)

  const [praiaOptions, setPraiaOption] = useState([])

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addSchema) })
  const [images, setImage] = useState(null)

  const saveProblema = async (data) => {
    const formData = new FormData()
    let image_issue
    if (images) {
      formData.append('files', images[0])
      const { data } = await UploadService.upload(formData)
      image_issue = data
    }

    const values = {
      ...data,
      praia: parseInt(data.praia, 10),
      date: new Date(data.date),
      image_issue,
    }
    dispatch(createProblema({ ...data, ...values, image_issue }))
      .unwrap()
      .then(() => {
        SwalAlert.success()
        reset({
          praia: '',
          date: '',
          issue: '',
          email: '',
        })
      })
      .catch((err) => {
        SwalAlert.error()
      })
  }
  useEffect(() => {
    const options = praias.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }))
    setPraiaOption(options)
  }, [praias])

  useEffect(() => {
    dispatch(retrievePraias())
  }, [dispatch])
  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar Problema</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={handleSubmit(saveProblema)}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Praia <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="praia"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker {...field} data={praiaOptions} style={{ width: '100%' }} />
                  )}
                />
                <small className="text-danger">{errors.praia?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => <DatePicker className="w-100" {...field} />}
                />
                <small className="text-danger">{errors.date?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Incidente <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormTextarea {...register('issue')} />
                <small className="text-danger">{errors.issue?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Email <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput type="email" id="item" {...register('email')} />
                <small className="text-danger">{errors.email?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto</CFormLabel>
                <CFormInput
                  type="file"
                  id="item"
                  accept=".png,.jpg"
                  onChange={(e) => setImage(e.target.files)}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
AddProblemaDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default AddProblemaDialog
