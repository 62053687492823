import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import SwalAlert from 'src/utils/SwalAlert'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { createGruposand } from 'src/store/reducers/gruposandSlice'

import { addSchema } from './validation'

function AddGruposandDialog({ visible, setVisible }) {
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addSchema) })

  const saveAtividadesHumana = async (data) => {
    const values = {
      ...data,
    }

    dispatch(createGruposand({ ...data, ...values }))
      .unwrap()
      .then(() => {
        SwalAlert.success()
        reset({
          name_grupo: '',
          descripition: '',
        })
      })
      .catch((err) => {
        SwalAlert.error()
      })
  }

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CForm onSubmit={handleSubmit(saveAtividadesHumana)}>
        <CModalHeader>
          <CModalTitle>Adicionar Grupo Sandwatch</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Nome<strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput type="text" id="item" {...register('name_grupo')} />
                <small className="text-danger">{errors.name_grupo?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Descrição<strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput type="text" id="item" {...register('descripition')} />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
AddGruposandDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default AddGruposandDialog
