import React, { useEffect, useState } from 'react';

import { CButton, CCol, CContainer, CRow } from '@coreui/react';
import TableWrapper from 'src/components/TableWrapper';
import ReactDatatable from '@ashvin27/react-datatable';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { deleteGruposand, retrieveGruposand } from 'src/store/reducers/gruposandSlice';
import { columns, config } from './tableConf';
import AddGruposandDialog from './AddGruposandDialog';
import EditGruposandDialog from './EditGruposandDialog';

const Grupo = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const gruposands = useSelector((state) => state.gruposands);
  const roles = useSelector((state) => state.roles);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [action, setAction] = useState('');

  const destroy = (record, index) => {
    Swal.fire({
      title: 'Eliminar Registo?',
      text: 'Ação não pode ser revertido!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF7F47',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar!',
      cancelButtonText: 'Cancelar',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteGruposand(record.id));
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success');
      }
    });
  };

  const changeItem = (record, index) => {
    setAction('');
    setCurrentItem(record);
    setVisible(true);
  };

  useEffect(() => {
    dispatch(retrieveGruposand())
      .unwrap()
      .then(() => setLoading(false))
      .catch(() => {});
  }, [dispatch]);

  useEffect(() => {
    const data = gruposands.map((grupo, index) => ({
      index: index + 1,
      id: grupo.id,
      grupo: grupo.name_grupo,
      desc: grupo.descripition,
      admin: grupo.users_permissions_users
        .filter((user) => user.role === 4)
        .map((row) => row.username)
        .join(', '),
      editor: grupo.users_permissions_users
        .filter((user) => user.role === 5)
        .map((row) => row.username)
        .join(', '),
      observador: grupo.users_permissions_users
        .filter((user) => user.role === 6)
        .map((row) => row.username)
        .join(', '),
      praia: grupo.praias.map((row) => row.name_praia).join(', '),
    }));
    setData(data);
  }, [gruposands]);

  useEffect(() => dispatch(retrieveGruposand()), [dispatch]);
  return (
    <CContainer lg>
      <TableWrapper title="Lista grupos">
        <CRow className="mb-4 ">
          <CCol>
            <CButton
              onClick={() => {
                setAction('add');
                setVisible(true);
              }}
              className="main-button"
            >
              Adicionar
            </CButton>
          </CCol>
        </CRow>
        <ReactDatatable config={config} records={data} columns={columns(destroy, changeItem)} />
      </TableWrapper>
      {action === 'add' ? (
        <AddGruposandDialog setVisible={setVisible} visible={visible} />
      ) : (
        <EditGruposandDialog visible={visible} setVisible={setVisible} item={currentItem} />
      )}
    </CContainer>
  );
};

export default Grupo;
