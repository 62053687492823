import React, { useState, useEffect } from 'react'
import { SelectPicker } from 'rsuite'
import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react'
import { CChartBar, CChartLine } from '@coreui/react-chartjs'
import { useDispatch, useSelector } from 'react-redux'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import ChartDetrito from './Detrito'
import ChartTamanhoSedimento from './Sedimentos/Tamanho'
import ChartPercentagemSedimento from './Sedimentos/Perccentagen'
import ChartCorrente from './Corrente'
import ChartMudancaTamanhoSedimentos from './Sedimentos/Mudanca'
import ChartVariacaoAlturaOndas from './Corrente/VariacaoOndas'
import ChartContagemAtividade from './Atividades/index'
import { en } from './i18n/en'
import { pt } from './i18n/pt'

/* i18n.addResourceBundle('en', 'graficos', en)
i18n.addResourceBundle('pt', 'graficos', pt) */

const Dashboard = () => {
  const dispatch = useDispatch()
  const praias = useSelector((state) => state.praias)
  const [praiaOptions, setPraiaOptions] = useState([])
  const [yearsOptions, setYearsOptions] = useState([])
  const [praia, setPraia] = useState(null)
  const { t } = useTranslation()

  const [years, setYears] = useState([])
  const [year, setYear] = useState(new Date().getFullYear())

  useEffect(() => {
    for (let i = 0; i < 15; i++) {
      setYears((prevState) => [...prevState, year - i])
    }
  }, [year])

  useEffect(() => {
    const options = praias.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }))
    setPraiaOptions(options)
  }, [praias])

  useEffect(() => {
    const options = years.map((year) => ({
      value: year,
      label: year,
    }))
    setYearsOptions(options)
  }, [years])

  useEffect(() => {
    dispatch(retrievePraias())
  }, [dispatch])

  const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  return (
    <>
      <CRow className="mb-3" style={{ position: 'absolute' }}>
        <CCol xs={6} lg={2}>
          <SelectPicker
            data={praiaOptions}
            onChange={setPraia}
            style={{ width: '100%' }}
            placeholder={t('PRAIA')}
          />
        </CCol>
        <CCol xs={6} lg={2}>
          <SelectPicker
            data={yearsOptions}
            onChange={setYear}
            value={year}
            style={{ width: '100%' }}
            placeholder={t('YEAR')}
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol xs={12} lg={6}>
          <ChartCorrente praia={praia} year={year} />
        </CCol>
        <CCol xs={12} lg={6}>
          <ChartVariacaoAlturaOndas praia={praia} year={year} />
        </CCol>
        <CCol xs={12} lg={6}>
          <ChartDetrito praia={praia} year={year} />
        </CCol>
        <CCol xs={12} lg={6}>
          <ChartTamanhoSedimento praia={praia} year={year} />
        </CCol>
        <CCol xs={12} lg={6}>
          <ChartMudancaTamanhoSedimentos praia={praia} year={year} />
        </CCol>
        <CCol xs={12} lg={6}>
          <ChartPercentagemSedimento praia={praia} />
        </CCol>
        <CCol xs={12} lg={6}>
          <ChartContagemAtividade praia={praia} />
        </CCol>
      </CRow>
    </>
  )
}

export default Dashboard
