import React, { useState, useEffect } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { cilSave, cilXCircle } from "@coreui/icons";
import { SelectPicker, DatePicker } from "rsuite";
import { retrieveGruposand } from "src/store/reducers/gruposandSlice";
import { retrievePraias } from "src/store/reducers/praiaSlice";
import Swal from "sweetalert2";
import { updateMedicaoCorrente } from "src/store/reducers/medicaoCorrenteSlice";
import { convert } from "src/utils/Date";
import SwalAlert from "src/utils/SwalAlert";
import { ADMIN_PRAIA, SUPER_ADMIN } from "src/utils/permission";
import UploadService from "../../../../service/Upload";

function EditMedicaoCorrenteDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch();
  const [grupoOptions, setGrupoOption] = useState([]);
  const [praiaOptions, setPraiaOption] = useState([]);
  const gruposands = useSelector((state) => state.gruposands);
  const praias = useSelector((state) => state.praias);
  const { user } = useSelector((state) => state.account);
  const [date, setDate] = useState();
  const [praia, setPraia] = useState("");
  const [gruposand, setGrupo] = useState();
  const [distance_after_1_minute, setDistance_after_1_minute] = useState();
  const [direction_after_1_minute, setDirection_after_1_minute] = useState();
  const [spoed_after_1_minute, setSpoed_after_1_minute] = useState();
  const [distance_after_5_minutes, setDistance_after_5_minutes] = useState();
  const [direction_after_5_minutes, setDirection_after_5_minutes] = useState();
  const [spoed_after_5_minutes, setSpoed_after_5_minutes] = useState();
  const [observations, setObservations] = useState();
  const [images, setImage] = useState(null);

  const initState = () => {
  };
  const saveMedicaoCorrente = async (e) => {
    e.preventDefault();
    console.log(
      date,
      praia,
      gruposand,
      distance_after_1_minute,
      direction_after_1_minute,
      spoed_after_1_minute,
      distance_after_5_minutes,
      direction_after_5_minutes,
      spoed_after_5_minutes,
      observations,
      images
    );

    const formData = new FormData();
    let img;
    if (images) {
      formData.append("files", images[0]);
      const { data } = await UploadService.upload(formData);
      img = data;
    }
    const data = {
      date,
      praia,
      gruposand,
      distance_after_1_minute,
      direction_after_1_minute,
      spoed_after_1_minute,
      distance_after_5_minutes,
      direction_after_5_minutes,
      spoed_after_5_minutes,
      observations,
      img,
    };
    dispatch(
      updateMedicaoCorrente({
        data,
        id: item.id,
      })
    )
      .unwrap()
      .then(() => {
        setVisible(false);
        SwalAlert.success();
      })
      .catch(() => {
        SwalAlert.error();
      });
  };

  useEffect(() => {
    if (item) {
      const praia = praias.find((praia) => praia.name_praia === item.praia);
      const gruposand = gruposands.find(
        (grupo) => grupo.name_grupo === item.grupo
      );
      setPraia(praia.id);
      setGrupo(gruposand.id);
      setDistance_after_1_minute(item.distance_after_1_minute);
      setDirection_after_1_minute(item.direction_after_1_minute);
      setSpoed_after_1_minute(item.spoed_after_1_minute);
      setDistance_after_5_minutes(item.distance_after_5_minutes);
      setDirection_after_5_minutes(item.direction_after_5_minutes);
      setSpoed_after_5_minutes(item.spoed_after_5_minutes);
      setObservations(item.observations);
      setDate(convert(item.date));
    }
  }, [item, praias, gruposands]);

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }));
    setGrupoOption(options);
  }, [gruposands]);

  useEffect(() => {
    let filtered = praias;

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id);
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }));
    setPraiaOption(options);
  }, [praias, user]);

  useEffect(() => {
    dispatch(retrieveGruposand());
    dispatch(retrievePraias());
  }, [dispatch]);

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar Medição de corrente Litorânea</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={saveMedicaoCorrente}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Praia</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={praiaOptions}
                  style={{ width: "100%" }}
                  value={praia}
                  onChange={setPraia}
                />
              </div>
            </CCol>
            {SUPER_ADMIN.includes(user?.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">Grupo</CFormLabel>
                    </CCol>
                  </CRow>
                  <SelectPicker
                    data={grupoOptions}
                    style={{ width: "100%" }}
                    defaultValue={gruposand}
                    value={gruposand}
                    onChange={setGrupo}
                  />
                </div>
              </CCol>
            )}
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Data</CFormLabel>
                  </CCol>
                </CRow>
                <DatePicker
                  className="w-100"
                  format="dd-MM-yyyy"
                  value={date}
                  defaultValue={date}
                  onChange={setDate}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Distância depois de 1 minuto(m)
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  required
                  value={distance_after_1_minute}
                  defaultValue={distance_after_1_minute}
                  onChange={(e) => setDistance_after_1_minute(e.target.value)}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Direção depois de 1 minuto(graus)
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  value={direction_after_1_minute}
                  defaultValue={direction_after_1_minute}
                  onChange={(e) => setDirection_after_1_minute(e.target.value)}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Velocidade depois de 1 minuto(cm/s)
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  value={spoed_after_1_minute}
                  defaultValue={spoed_after_1_minute}
                  onChange={(e) => setSpoed_after_1_minute(e.target.value)}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Distância depois de 5 minutos(m)
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  required
                  value={distance_after_5_minutes}
                  defaultValue={distance_after_5_minutes}
                  onChange={(e) => setDistance_after_5_minutes(e.target.value)}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Direção depois de 5 minutos
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  value={direction_after_5_minutes}
                  defaultValue={direction_after_5_minutes}
                  onChange={(e) => setDirection_after_5_minutes(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">velocidade2</CFormLabel>
                <CFormInput
                  type="number"
                  id="item"
                  value={spoed_after_5_minutes}
                  defaultValue={spoed_after_5_minutes}
                  onChange={(e) => setSpoed_after_5_minutes(e.target.value)}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Observações</CFormLabel>
                <CFormInput
                  type="text"
                  id="item"
                  value={observations}
                  defaultValue={observations}
                  onChange={(e) => setObservations(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Imagem</CFormLabel>
                <CFormInput
                  type="file"
                  id="item"
                  accept=".png,.jpg"
                  onChange={(e) => setImage(e.target.files)}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2"/>
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2"/>
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  );
}

EditMedicaoCorrenteDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
};

export default EditMedicaoCorrenteDialog;
