/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// Import Image
import logoSW_3 from '../../assets/images/logos/logo_footer.png'
import logo from '../../assets/SandwatchCV_Logo.png'
import { useTranslation } from 'react-i18next'

function Footers () {
  const { t, i18n } = useTranslation()
  //Inherited Parent options
  const scrollTop = ()  => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

    return (
      <>
        {/* ===============  Newsletter area start =============== */}

        {/* ===============  Newsletter area end =============== */}

        {/* ==============  Footer area start================= */}
        <div className="footer-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="footer-info">
                  <div className="footer-logo">
                    <img src={logo} alt="" width="120" height="120" className="img-fluid" />
                  </div>
                  <p>
                    Sandwatch é uma rede de voluntários de escolas, grupos de jovens, organizações
                    não-governamentais e comunitárias que trabalham em conjunto para monitorar e
                    melhorar seus ambientes de praia.
                  </p>
                
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="row">
                  <div className="col-lg-5 col-md-5 col-sm-7">
                   {/*  <div className="footer-links">
                      <h5 className="widget-title">Contactos</h5>
                      <div className="contact-box">
                        <span>
                          <i className="fa fa-phone" />
                        </span>
                        <div>
                          <a href="tel:+2382621489">+238 262 14 89</a>
                          <a href="tel:+2385161545">+238 516 15 45</a>
                        </div>
                      </div>
                      <div className="contact-box">
                        <span>
                          <i className="fa fa-envelope" />
                        </span>
                        <div>
                          <a href="mailto:sandwatchfoundation@gmail.com">
                            sandwatchfoundation@gmail.com
                          </a>
                          <a href="mailto:comnatcv@cvunesco.org">comnatcv@cvunesco.org</a>
                        </div>
                      </div>
                      <div className="contact-box">
                        <span>
                          <i className="fa fa-map" />
                        </span>
                        <div>
                          <Link to="#">
                            2752 Endereço da Rua <br />
                            Praia, Cabo Verde
                          </Link>
                        </div>
                      </div>
                      <div className="contact-box">
                        <span>
                          <i className="fa fa-map" />
                        </span>
                        <div>
                          <Link to="#">Achada Santo Antonio, Praia-C.P 51 A</Link>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-5">
                    <div className="footer-links">
                      <h5 className="widget-title">Sandwatch CV</h5>
                      <div className="category-list">
                        <ul>
                          <li>
                            <i className="fa fa-arrow-right" />{' '}
                            <Link
                              to={`${process.env.PUBLIC_URL}/`}
                              className="sub-item"
                              onClick={scrollTop}
                            >
                               {t('headerList.home')}
                            </Link>
                          </li>
                          <li>
                            <i className="fa fa-arrow-right" />{' '}
                            <Link
                              to={`${process.env.PUBLIC_URL}/sobre-nos`}
                              onClick={scrollTop}
                            >
                              {t('headerList.about')}
                            </Link>
                          </li>

                          <li>
                            <i className="fa fa-arrow-right" />{' '}
                            <Link
                              to={`${process.env.PUBLIC_URL}/projectos-iniciativas`}
                              onClick={scrollTop}
                            >
                               {t('headerList.project')}
                            </Link>
                          </li>
                          <li>
                            <i className="fa fa-arrow-right" />{' '}
                            <Link
                              to={`${process.env.PUBLIC_URL}/contatos`}
                              onClick={scrollTop}
                            >
                               {t('headerList.resource')}
                            </Link>
                          </li>
                          <li>
                            <i className="fa fa-arrow-right" />{' '}
                            <Link
                              to={`${process.env.PUBLIC_URL}/contatos`}
                              onClick={scrollTop}
                            >
                               {t('headerList.contact')}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="copyrigth-area">
                  <p>
                    Copyright {new Date().getFullYear()} <Link to={`#`}>SandWatch</Link> |{' '}
                    <a href="https://www.cvunesco.org">UNESCO</a> |{' '}
                    <Link to={`${process.env.PUBLIC_URL}/devs`}>Devs</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ==============  Footer area end================= */}
      </>
    )

}

export default Footers
