import React, { useState } from 'react'
import {
  CButton,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import UploadService from 'src/service/Upload'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { createItem } from '../../../store/reducers/itemSlice'

function AddItemDialog({ visible, setVisible }) {
  const dispatch = useDispatch()
  const [item, setItem] = useState('')
  const [descripition, setDesciption] = useState('')
  const [images, setImage] = useState(null)

  const initState = () => {
    setItem('')
    setDesciption('')
  }

  const saveItem = async () => {
    try {
      const formData = new FormData()
      let img

      if (images) {
        formData.append('files', images[0])
        const { data } = await UploadService.upload(formData)
        img = data
      }

      dispatch(createItem({ name_item: item, descripition, img })).then(() => {
        Swal.fire({
          text: 'Item adicionado com sucesso!',
          icon: 'success',
          confirmButtonColor: '#FF7F47',
          confirmButtonText: 'Ok!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        })
      })
      initState()
    } catch (e) {
      Swal.fire({
        text: 'Item não adicionado!',
        icon: 'error',
        confirmButtonColor: '#FF7F47',
        confirmButtonText: 'Ok!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      })
    }
  }

  return (
    <CModal
      backdrop="static"
      alignment="center"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar Item</CModalTitle>
      </CModalHeader>

      <CModalBody>
        <div className="mb-3">
          <CFormLabel htmlFor="item">Item</CFormLabel>
          <CFormInput
            type="text"
            id="item"
            value={item}
            onChange={(e) => setItem(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="desc">Descrição</CFormLabel>
          <CFormTextarea
            id="desc"
            rows="2"
            value={descripition}
            onChange={(e) => setDesciption(e.target.value)}
           />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="formFile">Imagem</CFormLabel>
          <CFormInput onChange={(e) => setImage(e.target.files)} type="file" id="formFile" />
        </div>
      </CModalBody>

      <CModalFooter>
        <CButton color="secondary" onClick={() => setVisible(false)}>
          <CIcon icon={cilXCircle} className="me-2" />
          Cancelar
        </CButton>

        <CButton onClick={saveItem} type="submit" className="main-button" color="primary">
          <CIcon icon={cilSave} className="me-2" />
          Salvar
        </CButton>
      </CModalFooter>
    </CModal>
  )
}
AddItemDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default AddItemDialog
