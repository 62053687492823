import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import ReactDatatable from '@ashvin27/react-datatable'
import { retrieveResultadoB, deleteResultadoB } from 'src/store/reducers/resultadoBSlice'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'
import SwalAlert from 'src/utils/SwalAlert'
import { ADMIN, SUPER_ADMIN } from 'src/utils/permission'
import VerMaisResultadoBDialog from './VerMaisResultadoBDialog'
import EditResultadoBDialog from './EditResultadoBDialog'
import AddResultadoBDialog from './AddResultadoBDialog'
import { columns, config } from './tableConf'

function ResultadoB() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const resultadosB = useSelector((state) => state.resultadosB)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')
  const [currentItem, setCurrentItem] = useState({})
  const [role, setRole] = useState(null)
  const [data, setData] = useState([])

  const destroy = (record, index) => {
    SwalAlert.warning().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteResultadoB(record.id))
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success')
      }
    })
  }

  const changeItem = (record, index) => {
    setAction('edit')
    setCurrentItem(record)
    setVisible(true)
  }

  const see = (record, index) => {
    setAction('')
    setCurrentItem(record)
    setVisible(true)
  }

  useEffect(() => {
    dispatch(retrieveResultadoB())
      .unwrap()
      .then(() => setLoading(false))
  }, [dispatch])

  useEffect(() => {
    let datas = resultadosB

    if (user) {
      if (user.role.name !== SUPER_ADMIN) {
        datas = resultadosB.filter(
          (resultado) => resultado.amostraareia.gruposand === user.gruposand.id,
        )
      }

      const data = datas.map((item, index) => ({
        index: index + 1,
        id: item.id,
        amostra: item.amostraareia.sample_number_sand,
        lote: item.amostraareia.batch_number_sand,
        collect_date_sand: moment(item.collect_date_sand).format('DD-MM-YYYY'),
        sand_temperature: item.sand_temperature,
        nitrate_sand: item.nitrate_sand,
        phosphate_sand: item.phosphate_sand,
        yeast: item.yeast,
        ph: item.ph,
        dissolved_oxygen_sand: item.dissolved_oxygen_sand,
        dissolved_oxygen_demand_BOD_sand: item.dissolved_oxygen_demand_BOD_sand,
        filamentous_fungi: item.filamentous_fungi,
        protozoa: item.protozoa,
        fecal_coliform_sand: item.fecal_coliform_sand,
        Counting_enterococcos_sand: item.Counting_enterococcos_sand,
        Counting_escherichina_sand: item.Counting_escherichina_sand,
        salinity_sand: item.salinity_sand,
        conductivity_sand: item.conductivity_sand,
        turbidity_sand: item.turbidity_sand,
      }))
      setData(data)
      setRole(user.role.name)
    }
  }, [resultadosB])
  return (
    <CContainer lg>
      <CRow className="mb-4 mt-4">
        {ADMIN.includes(role) && (
          <CCol>
            <CButton
              className="main-button"
              onClick={() => {
                setAction('add')
                setVisible(true)
              }}
            >
              Adicionar
            </CButton>
          </CCol>
        )}
      </CRow>
      <div className="table-responsive">
        <ReactDatatable
          config={config(user && role)}
          records={data}
          loading={loading}
          columns={columns(destroy, changeItem, see, role)}
        />
      </div>

      {action === 'add' ? (
        <AddResultadoBDialog visible={visible} setVisible={setVisible} />
      ) : action === 'edit' ? (
        <EditResultadoBDialog visible={visible} setVisible={setVisible} item={currentItem} />
      ) : (
        <VerMaisResultadoBDialog visible={visible} setVisible={setVisible} item={currentItem} />
      )}
    </CContainer>
  )
}
export default ResultadoB
