import React, { useState, useEffect } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { cilSave, cilXCircle } from "@coreui/icons";
import { SelectPicker, DatePicker } from "rsuite";
import { retrieveGruposand } from "src/store/reducers/gruposandSlice";
import { retrievePraias } from "src/store/reducers/praiaSlice";
import { retrieveFauna } from "src/store/reducers/faunaSlice";
import { createFaunaPraia } from "src/store/reducers/faunaPraiaSlice";
import SwalAlert from "src/utils/SwalAlert";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ADMIN_PRAIA, SUPER_ADMIN } from "src/utils/permission";
import { schema } from "./validation";
import UploadService from "../../../service/Upload";

function AddFaunaPraiaDialog({ visible, setVisible }) {
  const dispatch = useDispatch();

  const gruposands = useSelector((state) => state.gruposands);
  const praias = useSelector((state) => state.praias);
  const faunas = useSelector((state) => state.faunas);
  const { user } = useSelector((state) => state.account);

  const [grupoOptions, setGrupoOption] = useState([]);
  const [praiaOptions, setPraiaOption] = useState([]);
  const [faunaOptions, setFaunaOption] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [images, setImage] = useState(null);

  /* save fauna */
  const saveFaunaPraia = async (data) => {
    const formData = new FormData();
    let img;
    if (images) {
      formData.append("files", images[0]);
      const { data } = await UploadService.upload(formData);
      img = data;
    }

    const values = {
      ...data,
      praia: parseInt(data.praia, 10),
      fauna: parseInt(data.fauna, 10),
      gruposand: parseInt(data.gruposand, 10),
      date: new Date(data.date),
      img,
    };
    if (ADMIN_PRAIA.includes(user.role.name)) {
      values.gruposand = user.gruposand.id;
    }

    dispatch(createFaunaPraia({ ...data, ...values, img }))
      .unwrap()
      .then(() => {
        SwalAlert.success();
        reset({
          praia: "",
          fauna: "",
          gruposand: "",
          quantity: "",
          size_m: "",
          observations: "",
          date: "",
        });
      })
      .catch((err) => {
        SwalAlert.error();
      });
  };

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }));
    setGrupoOption(options);
  }, [gruposands]);

  useEffect(() => {
    let filtered = praias;

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id);
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }));
    setPraiaOption(options);
  }, [praias, user]);

  useEffect(() => {
    const options = faunas.map((item) => ({
      value: item.id,
      label: item.species,
    }));
    setFaunaOption(options);
  }, [faunas]);

  useEffect(() => {
    dispatch(retrieveGruposand());
    dispatch(retrievePraias());
    dispatch(retrieveFauna());
  }, [dispatch]);

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar Fauna Praia</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={handleSubmit(saveFaunaPraia)}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Praia <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="praia"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker
                      {...field}
                      data={praiaOptions}
                      style={{ width: "100%" }}
                    />
                  )}
                />
                <small className="text-danger">{errors.praia?.message}</small>
              </div>
            </CCol>
            {SUPER_ADMIN.includes(user.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">
                        Grupo <strong className="text-danger">*</strong>
                      </CFormLabel>
                    </CCol>
                  </CRow>
                  <Controller
                    name="gruposand"
                    control={control}
                    render={({ field }) => (
                      <SelectPicker
                        {...field}
                        data={grupoOptions}
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                  <small className="text-danger">
                    {errors.gruposand?.message}
                  </small>
                </div>
              </CCol>
            )}
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Fauna <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="fauna"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker
                      {...field}
                      data={faunaOptions}
                      style={{ width: "100%" }}
                    />
                  )}
                />
                <small className="text-danger">{errors.fauna?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker className="w-100" {...field} />
                  )}
                />
                <small className="text-danger">{errors.date?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Tamanho</CFormLabel>
                <CFormInput type="number" id="item" {...register("size_m")} />
                <small className="text-danger">{errors.size_m?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Quantidade <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput type="number" id="item" {...register("quantity")} />
                <small className="text-danger">
                  {errors.quantity?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Observação</CFormLabel>
                <CFormTextarea {...register("observations")} />
                <small className="text-danger">
                  {errors.observations?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto</CFormLabel>
                <CFormInput
                  type="file"
                  id="item"
                  onChange={(e) => setImage(e.target.files)}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  );
}
AddFaunaPraiaDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default AddFaunaPraiaDialog;
