import * as Yup from 'yup'

export const addSchema = Yup.object({
  praia: Yup.string().required('Campo obrigatorio'),
  // gruposand: Yup.string().required('Campo obrigatorio'),
  date: Yup.string().required('Campo obrigatorio'),
  time: Yup.string().required('Campo obrigatorio'),
  sea_bathing: Yup.string('0'),
  sunbathing: Yup.string('0'),
  walking_and_or_running: Yup.string('0'),
  picnicking: Yup.string('0'),
  fishing: Yup.string('0'),
  playing: Yup.string('0'),
  windsurfing_kitsurfing: Yup.string('0'),
  horse_riding: Yup.string('0'),
  sitting: Yup.string('0'),
  other: Yup.string('0'),
  total: Yup.string('0'),
})
