import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Swal from 'sweetalert2'
import ReactDatatable from '@ashvin27/react-datatable'
import { deleteActividade, retrieveActividade } from 'src/store/reducers/actividadeSlice'
import TableWrapper from '../../../components/TableWrapper'
import { columns, config } from './tableConf'
import AddActividadeDialog from './AddActividadeDialog'
import EditActividadeDialog from './EditActividadeDialog'

function Actividade() {
  const dispatch = useDispatch()
  const actividades = useSelector((state) => state.actividades)
  const [data, setData] = useState([])
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')
  const [currentItem, setCurrentItem] = useState({})

  const destroy = (record, index) => {
    Swal.fire({
      title: 'Eliminar Registo?',
      text: 'Ação não pode ser revertido!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF7F47',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar!',
      cancelButtonText: 'Cancelar',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteActividade(record.id))
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success')
      }
    })
  }

  const changeActividade = (record, index) => {
    setAction('')
    setCurrentItem(record)
    setVisible(true)
  }

  useEffect(() => {
    const data = actividades.map((item, index) => ({
      id: item.id,
      index: index + 1,
      name: item.nome_atividade,
    }))
    setData(data)
  }, [actividades])

  useEffect(() => {
    dispatch(retrieveActividade())
  }, [dispatch])

  return (
    <CContainer lg>
      <TableWrapper title="Lista Actividades">
        <CRow className="mb-4 ">
          <CCol>
            <CButton
              className="main-button"
              onClick={() => {
                setAction('add')
                setVisible(true)
              }}
            >
              Adicionar
            </CButton>
          </CCol>
        </CRow>
        <ReactDatatable
          config={config}
          records={data}
          columns={columns(destroy, changeActividade)}
        />
      </TableWrapper>
      {action === 'add' ? (
        <AddActividadeDialog visible={visible} setVisible={setVisible} />
      ) : (
        <EditActividadeDialog visible={visible} setVisible={setVisible} item={currentItem} />
      )}
    </CContainer>
  )
}

export default Actividade
