import axiosService from '../../axios'

class DiarioPraiaService {
  create(data) {
    return axiosService.post('/diariodepraias', data)
  }

  retrieve() {
    return axiosService.get('/diariodepraias')
  }

  update(data, id) {
    return axiosService.put(`/diariodepraias/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/diariodepraias/${id}`)
  }
}

export default new DiarioPraiaService()
