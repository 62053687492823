import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import TableWrapper from 'src/components/TableWrapper'
import ReactDatatable from '@ashvin27/react-datatable'
import { deleteProjeto, retrieveProjeto } from 'src/store/reducers/projetoSlice'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'
import SwalAlert from 'src/utils/SwalAlert'
import GalleryImage from 'src/components/ImageGallery'
import ModalImage from 'src/components/Modal/ImageModal'
import { SUPER_ADMIN, ADMIN } from 'src/utils/permission'
import EditProjetoDialog from './EditProjetoDialog'
import AddProjetoDialog from './AddProjetoDialog'
import { columns, config } from './tableConf'

function Projeto() {
  const dispatch = useDispatch()
  const projetos = useSelector((state) => state.projetos)
  const { user } = useSelector((state) => state.account)
  const [currentItem, setCurrentItem] = useState(null)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')
  const [data, setData] = useState([])
  const [images, setImages] = useState(null)
  const [imageShow, setShowImage] = useState(false)
  const [role, setRole] = useState(null)

  const destroy = (record, index) => {
    SwalAlert.warning().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProjeto(record.id))
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success')
      }
    })
  }

  const changeItem = (record, index) => {
    setAction('edit')
    setCurrentItem(record)
    setVisible(true)
  }

  const showImage = (record, index) => {
    setImages(record.image)
    setShowImage(true)
  }

  useEffect(() => {
    dispatch(retrieveProjeto())
      .unwrap()
      .then(() => setLoading(false))
      .catch()
  }, [dispatch])

  useEffect(() => {
    if (user) {
      let datas = projetos

      if (user.role.name !== SUPER_ADMIN) {
        datas = projetos.filter((projeto) => projeto.gruposand.id === user.gruposand.id)
      }

      const data = datas.map((item, index) => ({
        index: index + 1,
        id: item.id,
        nome: item.name,
        objetivo: item.objective,
        data_inicio: moment(item.start_date).format('DD-MM-YYYY'),
        data_fim: moment(item.end_date).format('DD-MM-YYYY'),
        praia: item.praia.name_praia,
        grupo: item.gruposand.name_grupo,
        fundo: item.background,
        atividade: item.planned_activities,
        recurso: item.resources_needed,
        objetivo_atingido: item.how_will_we_know_we_achieved_our_objective,
        image: item.img,
      }))
      setData(data)
      setRole(user.role.name)
    }
  }, [projetos, user])
  return (
    <CContainer lg>
      <TableWrapper title="Lista de Projetos & Iniciativas">
        <CRow className="mb-4 ">
          {ADMIN.includes(role) && (
            <CCol>
              <CButton
                className="main-button"
                onClick={() => {
                  setAction('add')
                  setVisible(true)
                }}
              >
                Adicionar
              </CButton>
            </CCol>
          )}
        </CRow>
        <div className="table-responsive">
          <ReactDatatable
            config={config(user && role)}
            records={data}
            loading={loading}
            columns={columns(destroy, changeItem, showImage, role)}
          />
        </div>
      </TableWrapper>
      {action === 'add' ? (
        <AddProjetoDialog visible={visible} setVisible={setVisible} />
      ) : (
        <EditProjetoDialog visible={visible} setVisible={setVisible} item={currentItem} />
      )}
      <ModalImage visible={imageShow} setVisible={setShowImage}>
        <GalleryImage items={images} />
      </ModalImage>
    </CContainer>
  )
}
export default Projeto
