import sidebarSlice from './reducers/sidebar'
import accountSlice from './reducers/accountSlice'
import itemSlice from './reducers/itemSlice'
import praiaSlice from './reducers/praiaSlice'
import userSlice from './reducers/userSlice'
import gruposandSlice from './reducers/gruposandSlice'
import roleSlice from './reducers/roleSlice'
import detritoSlice from './reducers/detritoSlice'
import faunaSlice from './reducers/faunaSlice'
import floraSlice from './reducers/floraSlice'
import actividadeSlice from './reducers/actividadeSlice'
import TartarugaSlice from './reducers/TartarugaSlice'
import faunaPraiaSlice from './reducers/faunaPraiaSlice'

import sedimentoSlice from './reducers/sedimentoSlice'
import floraPraiaSlice from './reducers/floraPraiaSlice'
import diarioPraiaSlice from './reducers/diarioPraiaSlice'
import atividadeHumanaSlice from './reducers/atividadeHumanaSlice'
import detritoPraiaSlice from './reducers/detritoPraiaSlice'
import contagemAtividadeSlice from './reducers/contagemAtividadeSlice'
import ilhaSlice from './reducers/ilhaSlice'
import mapSlice from './reducers/mapSlice'
import resultadoGSlice from './reducers/resultadoGSlice'
import resultadoBSlice from './reducers/resultadoBSlice'
import amostraAguaSlice from './reducers/amostraAguaSlice'
import resultadoAguaSlice from './reducers/resultadoAguaSlice'
import fichaLimpezaSlice from './reducers/fichaLimpezaSlice'
import erosaoSlice from './reducers/erosaoSlice'

import medicaoCorrenteSlice from './reducers/medicaoCorrenteSlice'
import medicaoOndaSlice from './reducers/medicaoOndaSlice'

import ninhoTartarugaSlice from './reducers/ninhoTartarugaSlice'
import projetoSlice from './reducers/projetoSlice'
import problemaSlice from './reducers/problemaSlice'

import concelhoSlice from './reducers/concelhoSlice'
import classificacaoSlice from './reducers/classificacaoSlice'
import itemPraiaSlice from './reducers/itemPraiaSlice'
import selectedPraiaSlice from './reducers/selectedPraiaSlice'
import utilsSlice from './reducers/utilsSlice'

export const rootReducer = {
  sidebar: sidebarSlice,
  account: accountSlice,
  map: mapSlice,
  praias: praiaSlice,
  users: userSlice,
  gruposands: gruposandSlice,
  roles: roleSlice,
  ilhas: ilhaSlice,
  projetos: projetoSlice,
  problemas: problemaSlice,
  concelhos: concelhoSlice,
  classificacaos: classificacaoSlice,
  itemsPraias: itemPraiaSlice,
  selectedPraia: selectedPraiaSlice,

  // parametrizacao
  detritos: detritoSlice,
  faunas: faunaSlice,
  floras: floraSlice,
  actividades: actividadeSlice,
  tartarugas: TartarugaSlice,
  items: itemSlice,

  // monitorizaçao
  amostras: amostraAguaSlice,
  resultadoAguas: resultadoAguaSlice,
  faunasPraias: faunaPraiaSlice,
  sedimentos: sedimentoSlice,

  florasPraias: floraPraiaSlice,
  diariosPraias: diarioPraiaSlice,

  atividadesHumanas: atividadeHumanaSlice,
  contagemAtividades: contagemAtividadeSlice,
  medicaoCorrentes: medicaoCorrenteSlice,

  medicaoOnda: medicaoOndaSlice,
  erosaos: erosaoSlice,

  ninhoTartarugas: ninhoTartarugaSlice,
  detritosPraias: detritoPraiaSlice,

  resultadosG: resultadoGSlice,
  resultadosB: resultadoBSlice,
  fichaLimpezas: fichaLimpezaSlice,
  utils: utilsSlice,
}
