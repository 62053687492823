import * as Yup from 'yup'

export const schema = Yup.object({
  praia: Yup.string().required('Campo obrigatório'),
  // gruposand: Yup.string().required('Campo obrigatório'),
  sand_type: Yup.string().required('Campo obrigatório'),
  sample_number_sand: Yup.number().required('Campo obrigatório').positive(),
  batch_number_sand: Yup.number().positive(),
  date_collect: Yup.string().required('Campo obrigatório'),
  selection: Yup.string().required('Campo obrigatório'),
})
