import React, { Component, useState } from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom'

function Find() {
  const history = useHistory()
  const [query, setQuery] = useState('')

  const submit = (e) => {
    e.preventDefault()
    if (query.length > 1) {
      history.push(`/search/${query}`)
    }
  }

  return (
    <>
      {/* ===============  find from praia end =============== */}
      <div className="find-form">
        <div className="container">
          <form onSubmit={submit} className="findfrom-wrapper">
            <div className="row">
              <div className="col-lg-9">
                <input
                  className="ps-4 rounded"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  type="text"
                  placeholder="Nome da Praia"
                />
              </div>
              <div className="col-lg-3">
                <div className="find-btn">
                  <button
                    type="submit"
                    style={{ width: '100%', background: 'transparent', border: 'none' }}
                  >
                    <Link to="" onClick={submit} className="main-button btn btn-small rounded">
                      <i className="fas fa-search" />
                      Procurar
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* ===============  find from praia end =============== */}
    </>
  )
}

export default withRouter(Find)
