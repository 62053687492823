import * as Yup from 'yup'

export const addSchema = Yup.object({
  praia: Yup.string().required('Campo obrigatorio'),
  // gruposand: Yup.string().required('Campo obrigatorio'),
  atividade: Yup.string().required('Campo obrigatorio'),
  data: Yup.string().required('Campo obrigatorio'),
  time_start: Yup.string().required('Campo obrigatorio'),
  time_end: Yup.string().required('Campo obrigatorio'),
})
