import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { CChartPie } from '@coreui/react-chartjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { retrieveResultadoG } from 'src/store/reducers/resultadoGSlice'
import PropTypes from 'prop-types'

function ChartPercentagemSedimento({ praia }) {
  const dispatch = useDispatch()
  const resultadosG = useSelector((state) => state.resultadosG)
  const [data, setData] = useState([0, 0, 0, 0])
  const { t } = useTranslation()
  useEffect(() => {
    const data = resultadosG.filter((resultado) => resultado.amostraareia.praia === praia)

    let lessThan1 = 0
    let between1and2 = 0
    let between2and4 = 0
    let greaterThan4 = 0
    let total = 0

    let percentagem1
    let percentagem2
    let percentagem3
    let percentagem4

    data.map((item) => {
      if (item.Size != null) {
        total += 1
      }

      if (item.Size > 4) {
        greaterThan4 += 1
      } else if (item.Size >= 2 && item.Size < 4) {
        between2and4 += 1
      } else if (item.Size >= 1 && item.Size < 2) {
        between1and2 += 1
      } else if (item.Size < 1) {
        lessThan1 += 1
      }
    })

    percentagem1 = parseFloat(((lessThan1 / total) * 100).toFixed(2))
    percentagem2 = parseFloat(((between1and2 / total) * 100).toFixed(2))
    percentagem3 = parseFloat(((between2and4 / total) * 100).toFixed(2))
    percentagem4 = parseFloat(((greaterThan4 / total) * 100).toFixed(2))

    setData([percentagem1, percentagem2, percentagem3, percentagem4])
  }, [resultadosG, praia])

  useEffect(() => {
    dispatch(retrieveResultadoG())
  }, [dispatch])

  return (
    <CCard className="mb-4">
      <CCardHeader className="fw-bold">{t('PERCENTAGEM_SEDIMENTO')}</CCardHeader>
      <CCardBody>
        <CChartPie
          width={380}
          height={380}
          options={{ maintainAspectRatio: false }}
          data={{
            labels: ['<= 1mn', '1 - 2mn', '2 - 4mn', '> 4mn'],
            datasets: [
              {
                height: 500,
                backgroundColor: ['#128F8B', '#17A2E3', '#9219FB', '#FF7F47'],
                borderRadius: 10,
                data,
              },
            ],
          }}
        />
      </CCardBody>
    </CCard>
  )
}
ChartPercentagemSedimento.propTypes = {
  praia: PropTypes.any,
  year: PropTypes.any,
}

export default ChartPercentagemSedimento
