import { CFormInput, CFormTextarea } from '@coreui/react'
import React from 'react'
import { Link } from 'react-router-dom'

function Contacto() {
  return (
    <>
      {/* ===============  breadcrumb area start =============== */}
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="breadcrumb-wrap">
                <h2>Contacto</h2>
                <ul className="breadcrumb-links">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                    <i className="bx bx-chevron-right" />
                  </li>
                  <li>
                    <i className="fas fa-chevron-right" />
                  </li>
                  <li>Contacto</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===============  breadcrumb area end =============== */}

      <div className="container">
        <div className="contact-inputs pt-100">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-details">
                <h5 className="contact-d-head">Entre em contacto conosco</h5>
                <p>
                  Ligue, converse por chat, envie um e-mail ou preencha o formulário. Converse em
                  tempo real com um membro da equipe.
                </p>
                <ul className="office-clock">
                  <li>
                    <div className="clock-icon">
                      <i className="flaticon-clock-1" />
                    </div>
                    <div className="clock-info">
                      <h5>Horario Aberto</h5>
                      <p>
                        Seg - Sex
                        <br /> 08:00Am ate 18:00PM
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="clock-icon">
                      <i className="flaticon-clock-1" />
                    </div>
                    <div className="clock-info">
                      <h5>Horario Fechado</h5>
                      <p>Fim de semana Fechado</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-form">
                <form action="#">
                  <h5 className="contact-d-head">Contacta-nos</h5>
                  <div className="row">
                    <div className="col-lg-6">
                      <CFormInput type="text" placeholder="Nome" required />
                    </div>
                    <div className="col-lg-6">
                      <CFormInput type="text" placeholder="Assunto" required />
                    </div>
                    <div className="col-lg-6">
                      <CFormInput type="email" placeholder="Email" required />
                    </div>
                    <div className="col-lg-6">
                      <CFormInput type="text" placeholder="Telefone/Móvel" required />
                    </div>
                    <div className="col-lg-12">
                      <CFormTextarea cols={30} rows={7} placeholder="Menssagem" defaultValue="" />
                    </div>
                    <div className="col-lg-12">
                      <input type="submit" defaultValue="Submit Now" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <GuideWrapper /> */}

      <div className="end" />
      <div className="contact-cards">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="contact-card">
                <div className="contact-icon">
                  <i className="fas fa-map" />
                </div>
                <div className="contact-info">
                  <h5>Endereço</h5>
                  <p>2752 Endereço da Rua Praia, Cabo Verde Achada Santo Antonio, Praia-C.P 51 A</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="contact-card">
                <div className="contact-icon">
                  <i className="fas fa-comment" />
                </div>
                <div className="contact-info">
                  <h5>Telefone &amp; Email</h5>
                  <p>+238 262 14 89 sandwatchcv@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="end" />
    </>
  )
}

export default Contacto
