import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import NinhoTartarugaService from '../../service/monitorizacao/NinhoTartaruga'

export const createNinhoTartaruga = createAsyncThunk('ninhoTartaruga/create', async (data) => {
  const response = await NinhoTartarugaService.create(data)
  return response.data
})
export const retrieveNinhoTartaruga = createAsyncThunk('ninhoTartaruga/retrieve', async () => {
  const response = await NinhoTartarugaService.retrieve()
  return response.data
})

export const updateNinhoTartaruga = createAsyncThunk(
  'ninhoTartaruga/update',
  async ({ data, id }) => {
    const response = await NinhoTartarugaService.update(data, id)
    return response.data
  },
)
export const deleteNinhoTartaruga = createAsyncThunk('ninhoTartaruga/delete', async (id) => {
  const response = await NinhoTartarugaService.delete(id)
  return response.data
})

const initialState = []

const ninhoTartarugaSlice = createSlice({
  name: 'NinhoTartaruga',
  initialState,
  extraReducers: {
    [createNinhoTartaruga.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveNinhoTartaruga.fulfilled]: (state, action) => [...action.payload],
    [updateNinhoTartaruga.fulfilled]: (state, action) => {
      const index = state.findIndex((NinhoTartaruga) => NinhoTartaruga.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteNinhoTartaruga.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default ninhoTartarugaSlice.reducer
