import React, { memo, useEffect, useState } from 'react'
import { Marker } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import L from 'leaflet'

import MarkerClusterGroup from 'react-leaflet-cluster'
import Draw from 'src/components/Map/Draw'
import { changeLoad } from 'src/store/reducers/utilsSlice'
import { ADMIN, ADMIN_PRAIA, SUPER_ADMIN } from 'src/utils/permission'
import RenderPraia from './RenderPraia'
import PraiaDialog from './PraiaDialog'
import Select from '../../../components/Map/Select'
import Mapa from '../../../components/Map'

function AddInfo() {
  const dispatch = useDispatch()
  const praias = useSelector((state) => state.praias)
  const { user } = useSelector((state) => state.account)
  const { groupPraia } = useSelector((state) => state.map)
  const [visible, setVisible] = useState(false)
  const [renderedPraia, setRenderedPraia] = useState([])
  const [currentPraia, setCurrentPraia] = useState(null)

  useEffect(() => {
    dispatch(retrievePraias())
      .unwrap()
      .then(() => {
        dispatch(changeLoad(false))
      })
  }, [dispatch])

  useEffect(() => {
    if (user) {
      let data = []

      data = praias.filter((praia) => praia.coordinates)

      if (!SUPER_ADMIN.includes(user.role.name)) {
        data = data.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id)
      }

      setRenderedPraia(data)
    }
  }, [praias, user])

  return (
    <>
      <Select />
      <Mapa style={{ height: '79vh' }}>
        {user && ADMIN.includes(user.role.name) && <Draw action="addPraia" />}

        {groupPraia ? (
          <MarkerClusterGroup chunkedLoading>
            <RenderPraia
              renderedPraia={renderedPraia}
              setVisible={setVisible}
              setCurrentPraia={setCurrentPraia}
            />
          </MarkerClusterGroup>
        ) : (
          <RenderPraia
            renderedPraia={renderedPraia}
            setVisible={setVisible}
            setCurrentPraia={setCurrentPraia}
          />
        )}
      </Mapa>
      <PraiaDialog visible={visible} setVisible={setVisible} currentPraia={currentPraia} />
    </>
  )
}

export default AddInfo
