import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DetritoPraiaService from '../../service/monitorizacao/DetritoPraia'

export const createDetritoPraia = createAsyncThunk('detritoPraia/create', async (data) => {
  const response = await DetritoPraiaService.create(data)
  return response.data
})
export const retrieveDetritoPraia = createAsyncThunk('detritoPraia/retrieve', async () => {
  const response = await DetritoPraiaService.retrieve()
  return response.data
})

export const updateDetritoPraia = createAsyncThunk('detritoPraia/update', async ({ data, id }) => {
  const response = await DetritoPraiaService.update(data, id)
  return response.data
})
export const deleteDetritoPraia = createAsyncThunk('detritoPraia/delete', async (id) => {
  const response = await DetritoPraiaService.delete(id)
  return response.data
})

const initialState = []

const detritoPraiaSlice = createSlice({
  name: 'DetritoPraia',
  initialState,
  extraReducers: {
    [createDetritoPraia.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveDetritoPraia.fulfilled]: (state, action) => [...action.payload],
    [updateDetritoPraia.fulfilled]: (state, action) => {
      const index = state.findIndex((DetritoPraia) => DetritoPraia.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteDetritoPraia.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default detritoPraiaSlice.reducer
