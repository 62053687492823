import React, { useState, useEffect } from 'react'
import { CModal, CModalHeader, CModalTitle } from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { convert } from 'src/utils/Date'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import moment from 'moment'

function VerMaisFichaLimpeza({ visible, setVisible, item }) {
  const gruposands = useSelector((state) => state.gruposands)
  const praias = useSelector((state) => state.praias)
  const [praia, setPraia] = useState('')
  const [gruposand, setGrupo] = useState()
  const [cleaning_date, setCleaning_date] = useState('')
  const [type, setType] = useState()
  const [extension_cleaned, setExtension_cleaned] = useState()
  const [number_of_collected_garbage, setNumber_of_collected_garbage] = useState()
  const [Estimated_total_weight_collected, setEstimated_total_weight_collected] = useState()
  const [time_spent_cleaning, setTime_spent_cleaning] = useState()
  const [paper_bag, setPaper_bag] = useState()
  const [plastic_bag, setPlastic_bag] = useState()
  const [balloons, setBalloons] = useState()
  const [plastic_bottles, setPlastic_bottles] = useState()
  const [glass_bottles, setGlass_bottles] = useState()
  const [beverage_can, setBeverage_can] = useState()
  const [bottle_caps, setBottle_caps] = useState()
  const [clothing, setClothing] = useState()
  const [cutlery, setCutlery] = useState()
  const [food_packaging, setFood_packaging] = useState()
  const [straw, setStraw] = useState()
  const [toy, setToy] = useState()
  const [buoys, setBuoys] = useState()
  const [plastic_tarpaulin, setPlastic_tarpaulin] = useState()
  const [Nylon_ropes, setNylon_ropes] = useState()
  const [Fishing_nets, setFishing_nets] = useState()
  const [fishing_lines, setFishing_lines] = useState()
  const [fishing_bait, setFishing_bait] = useState()
  const [Bait_packaging, setBait_packaging] = useState()
  const [wooden_pallet, setWooden_pallet] = useState()
  const [lubricating_oil, setLubricating_oil] = useState()
  const [scotch_tapes, setScotch_tapes] = useState()
  const [Traps, setTraps] = useState()
  const [crates, setCrates] = useState()
  const [package_of_cleaning_products, setPackage_of_cleaning_products] = useState()
  const [Cigarette, setCigarette] = useState()
  const [lighters, setLighters] = useState()
  const [cigar_butts, setCigar_butts] = useState()
  const [Tobacco_Packaging, setTobacco_Packaging] = useState()
  const [Home_appliances, setHome_appliances] = useState()
  const [battery, setBattery] = useState()
  const [Construction_material, setConstruction_material] = useState()
  const [Car_Parts, setCar_Parts] = useState()
  const [fuel_Barrel, setFuel_Barrel] = useState()
  const [Tires, setTires] = useState()
  const [condoms, setCondoms] = useState()
  const [diapers, setDiapers] = useState()
  const [syringes, setSyringes] = useState()
  const [pads, setPads] = useState()

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#FFF3E0',

      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  useEffect(() => {
    if (item) {
      const praia = praias.find((praia) => praia.name_praia === item.praia)
      const gruposand = gruposands.find((grupo) => grupo.name_grupo === item.grupo)

      setPraia(praia.name_praia)
      setGrupo(gruposand.name_grupo)
      setCleaning_date(convert(item.cleaning_date))
      setType(item.type)
      setExtension_cleaned(item.extension_cleaned)
      setNumber_of_collected_garbage(item.number_of_collected_garbage)
      setEstimated_total_weight_collected(item.Estimated_total_weight_collected)
      setTime_spent_cleaning(item.time_spent_cleaning)
      setPaper_bag(item.paper_bag)
      setPlastic_bag(item.plastic_bag)
      setBalloons(item.balloons)
      setPlastic_bottles(item.plastic_bottles)
      setGlass_bottles(item.glass_bottles)
      setBeverage_can(item.beverage_can)
      setBottle_caps(item.bottle_caps)
      setClothing(item.clothing)
      setCutlery(item.cutlery)
      setFood_packaging(item.food_packaging)
      setStraw(item.straw)
      setToy(item.toy)
      setBuoys(item.buoys)
      setPlastic_tarpaulin(item.plastic_tarpaulin)
      setNylon_ropes(item.Nylon_ropes)
      setFishing_nets(item.Fishing_nets)
      setFishing_lines(item.fishing_lines)
      setFishing_bait(item.fishing_bait)
      setBait_packaging(item.Bait_packaging)
      setWooden_pallet(item.wooden_pallet)
      setLubricating_oil(item.lubricating_oil)
      setScotch_tapes(item.scotch_tapes)
      setTraps(item.Traps)
      setCrates(item.crates)
      setPackage_of_cleaning_products(item.package_of_cleaning_products)
      setCigarette(item.Cigarette)
      setLighters(item.lighters)
      setCigar_butts(item.cigar_butts)
      setTobacco_Packaging(item.Tobacco_Packaging)
      setHome_appliances(item.Home_appliances)
      setBattery(item.battery)
      setConstruction_material(item.Construction_material)
      setCar_Parts(item.Car_Parts)
      setFuel_Barrel(item.fuel_Barrel)
      setTires(item.Tires)
      setCondoms(item.condoms)
      setDiapers(item.diapers)
      setSyringes(item.syringes)
      setPads(item.pads)
    }
  }, [item, praias, gruposands])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="xl"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Resultado Completo da Ficha de Limpeza</CModalTitle>
      </CModalHeader>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 100 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <strong>Praia :</strong> &nbsp; {praia}
              </StyledTableCell>

              <StyledTableCell align="left">
                <strong>Grupo :</strong>&nbsp; {gruposand}
              </StyledTableCell>

              <StyledTableCell align="left">
                <strong>Data do Limpeza :</strong>&nbsp;
                {moment(cleaning_date).format('DD-MM-YYYY')}
              </StyledTableCell>

              <StyledTableCell align="left">
                <strong>Tipo de Limpeza :</strong>&nbsp; {type}
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableCell align="right">&nbsp;</TableCell>
            <TableCell align="right">&nbsp;</TableCell>
            <TableCell align="right">&nbsp;</TableCell>
            <TableCell align="right">&nbsp;</TableCell>
          </TableBody>

          <TableHead>
            <TableRow>
              <TableCell>Extensão que foi limpa (Km)</TableCell>
              <TableCell> {extension_cleaned} </TableCell>
              <TableCell> Peso total coletado (Kg)</TableCell>
              <TableCell> {Estimated_total_weight_collected} </TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Nº de sacos de lixo enchidos</TableCell>
              <TableCell>{number_of_collected_garbage}</TableCell>
              <TableCell> Tempo gasto com a limpeza</TableCell>
              <TableCell> {time_spent_cleaning} </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableCell align="left">
              <p1>
                <strong style={{ color: 'darkblue' }}>
                  {' '}
                  Atividades a Beira-Mar e Recreativas{' '}
                </strong>{' '}
              </p1>
            </TableCell>
            <TableCell align="center"> </TableCell>
            <TableCell align="center"> </TableCell>
            <TableCell align="center"> </TableCell>
          </TableBody>

          <TableHead>
            <TableRow>
              <StyledTableCell>
                <strong>Material </strong>{' '}
              </StyledTableCell>
              <StyledTableCell>
                <strong>Quantidade</strong>
              </StyledTableCell>
              <StyledTableCell>
                {' '}
                <strong>Material </strong>
              </StyledTableCell>
              <StyledTableCell>
                <strong>Quantidade</strong>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Sacos (papel) </TableCell>
              <TableCell>{paper_bag}</TableCell>
              <TableCell>Sacos (plástico)</TableCell>
              <TableCell>{plastic_bag}</TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Balões </TableCell>
              <TableCell>{balloons}</TableCell>
              <TableCell>Garrafas de Plástico</TableCell>
              <TableCell>{plastic_bottles}</TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Garrafas de Vidro </TableCell>
              <TableCell>{glass_bottles}</TableCell>
              <TableCell>Latas de Bebidas</TableCell>
              <TableCell>{beverage_can}</TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Tampas de garrafas </TableCell>
              <TableCell>{bottle_caps}</TableCell>
              <TableCell>Peças de Vestuários</TableCell>
              <TableCell>{clothing}</TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Copos, Talheres </TableCell>
              <TableCell>{cutlery}</TableCell>
              <TableCell>Embalagem(Comida)</TableCell>
              <TableCell>{food_packaging}</TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell>Palinhas </TableCell>
              <TableCell>{straw}</TableCell>
              <TableCell>Brinquedos</TableCell>
              <TableCell>{toy}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableCell align="left">
              <p1>
                <strong style={{ color: 'darkblue' }}> Atividades Aquáticas </strong>{' '}
              </p1>
            </TableCell>
            <TableCell align="center"> </TableCell>
            <TableCell align="center"> </TableCell>
            <TableCell align="center"> </TableCell>
          </TableBody>

          <TableHead>
            <TableRow>
              <StyledTableCell>
                <strong>Material </strong>{' '}
              </StyledTableCell>
              <StyledTableCell>
                <strong>Quantidade</strong>
              </StyledTableCell>
              <StyledTableCell>
                {' '}
                <strong>Material </strong>
              </StyledTableCell>
              <StyledTableCell>
                <strong>Quantidade</strong>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Bóias </TableCell>
              <TableCell>{buoys}</TableCell>
              <TableCell>Lonas plásticas</TableCell>
              <TableCell>{plastic_tarpaulin}</TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Cordas de nylon </TableCell>
              <TableCell>{Nylon_ropes}</TableCell>
              <TableCell>Redes de pesca</TableCell>
              <TableCell>{Fishing_nets}</TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Linhas de pesca </TableCell>
              <TableCell>{fishing_lines}</TableCell>
              <TableCell>Isca de pescaria</TableCell>
              <TableCell>{fishing_bait}</TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Embalagens de iscas </TableCell>
              <TableCell>{Bait_packaging}</TableCell>
              <TableCell>Paleta de madeira</TableCell>
              <TableCell>{wooden_pallet}</TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Embalagens (óleo lubrificante) </TableCell>
              <TableCell>{lubricating_oil}</TableCell>
              <TableCell>Fitas de empacotamento</TableCell>
              <TableCell>{scotch_tapes}</TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell>Armadilhas de peixes/lagosta </TableCell>
              <TableCell>{Traps}</TableCell>
              <TableCell>Engradados/caixotes</TableCell>
              <TableCell>{crates}</TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell>Embalagens de produtos de limpeza </TableCell>
              <TableCell>{package_of_cleaning_products}</TableCell>
              <TableCell> -- </TableCell>
              <TableCell> -- </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableCell align="left">
              <p1>
                <strong style={{ color: 'darkblue' }}>
                  {' '}
                  Ativades Relacionadas ao Habíto de Fumar{' '}
                </strong>
              </p1>
            </TableCell>
            <TableCell align="center"> </TableCell>
            <TableCell align="center"> </TableCell>
            <TableCell align="center"> </TableCell>
          </TableBody>

          <TableHead>
            <TableRow>
              <StyledTableCell>
                <strong>Material </strong>{' '}
              </StyledTableCell>
              <StyledTableCell>
                <strong>Quantidade</strong>
              </StyledTableCell>
              <StyledTableCell>
                {' '}
                <strong>Material </strong>
              </StyledTableCell>
              <StyledTableCell>
                <strong>Quantidade</strong>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell>Cigarro </TableCell>
              <TableCell>{Cigarette}</TableCell>
              <TableCell> Isqueiros </TableCell>
              <TableCell> {lighters} </TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Pontas de charutos</TableCell>
              <TableCell>{cigar_butts}</TableCell>
              <TableCell> Embalagens de Tabaco </TableCell>
              <TableCell> {Tobacco_Packaging} </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableCell align="left">
              <p1>
                <strong style={{ color: 'darkblue' }}> Atividades Poluidoras </strong>
              </p1>
            </TableCell>
            <TableCell align="center"> </TableCell>
            <TableCell align="center"> </TableCell>
            <TableCell align="center"> </TableCell>
          </TableBody>

          <TableHead>
            <TableRow>
              <StyledTableCell>
                <strong>Material </strong>{' '}
              </StyledTableCell>
              <StyledTableCell>
                <strong>Quantidade</strong>
              </StyledTableCell>
              <StyledTableCell>
                {' '}
                <strong>Material </strong>
              </StyledTableCell>
              <StyledTableCell>
                <strong>Quantidade</strong>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Eletrodomésticos</TableCell>
              <TableCell>{Home_appliances}</TableCell>
              <TableCell> Pilhas </TableCell>
              <TableCell> {battery} </TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Material de construção</TableCell>
              <TableCell>{Construction_material}</TableCell>
              <TableCell> Peças de automóveis </TableCell>
              <TableCell> {Car_Parts} </TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Tambores (tonéis)</TableCell>
              <TableCell>{fuel_Barrel}</TableCell>
              <TableCell> Pneus </TableCell>
              <TableCell> {Tires} </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableCell align="left">
              <p1>
                <strong style={{ color: 'darkblue' }}>
                  Resíduos Hospitalares/Higiene Pessoal{' '}
                </strong>
              </p1>
            </TableCell>
            <TableCell align="center"> </TableCell>
            <TableCell align="center"> </TableCell>
            <TableCell align="center"> </TableCell>
          </TableBody>

          <TableHead>
            <TableRow>
              <StyledTableCell>
                <strong>Material </strong>{' '}
              </StyledTableCell>
              <StyledTableCell>
                <strong>Quantidade</strong>
              </StyledTableCell>
              <StyledTableCell>
                {' '}
                <strong>Material </strong>
              </StyledTableCell>
              <StyledTableCell>
                <strong>Quantidade</strong>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell> Preservativos</TableCell>
              <TableCell>{condoms}</TableCell>
              <TableCell> Fraldas </TableCell>
              <TableCell> {diapers} </TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell>Seringas</TableCell>
              <TableCell>{syringes}</TableCell>
              <TableCell> Absorventes </TableCell>
              <TableCell> {pads} </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </CModal>
  )
}
VerMaisFichaLimpeza.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  children: PropTypes.node,
}

export default VerMaisFichaLimpeza
