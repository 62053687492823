import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ErosaoService from '../../service/monitorizacao/Erosao'

export const createErosao = createAsyncThunk('erosao/create', async (data) => {
  const response = await ErosaoService.create(data)
  return response.data
})
export const retrieveErosao = createAsyncThunk('erosao/retrieve', async () => {
  const response = await ErosaoService.retrieve()
  return response.data
})

export const updateErosao = createAsyncThunk('erosao/update', async ({ data, id }) => {
  const response = await ErosaoService.update(data, id)
  return response.data
})
export const deleteErosao = createAsyncThunk('erosao/delete', async (id) => {
  const response = await ErosaoService.delete(id)
  return response.data
})

const initialState = []

const erosaoSlice = createSlice({
  name: 'Erosao',
  initialState,
  extraReducers: {
    [createErosao.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveErosao.fulfilled]: (state, action) => [...action.payload],
    [updateErosao.fulfilled]: (state, action) => {
      const index = state.findIndex((Erosao) => Erosao.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteErosao.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default erosaoSlice.reducer
