import React, { createContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { initAccount, logInAccount, logOutAccount } from 'src/store/reducers/accountSlice'

import jwtDecode from 'jwt-decode'
import { useHistory } from 'react-router-dom'
import { ADMIN } from 'src/utils/permission'
import axiosService from '../service/axios'
import AuthService from '../service/Auht'

/**
 * initialize the context
 */
const JWTContext = createContext({
  login: () => Promise.resolve(),
  logout: () => {},
})

const verifyToken = (token) => {
  if (!token) {
    return false
  }
  const decoded = jwtDecode(token)
  return decoded.exp > Date.now() / 1000
}

const setSession = (token) => {
  if (token) {
    localStorage.setItem('authToken', token)
    axiosService.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    localStorage.removeItem('authToken')
    delete axiosService.defaults.headers.common.Authorization
  }
}

/**
 * provide context values to the app
 */
export const JWTProvider = ({ children }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const login = async (data) => {
    const response = await AuthService.login(data)
    const { jwt, user } = response.data
    setSession(jwt)
    dispatch(logInAccount(user))

    if (ADMIN.includes(user.role.name)) {
      history.push('/dashboard')
    } else {
      history.push('praia/adicionar-info')
    }
  }
  const logout = () => {
    setSession(null)
    dispatch(logOutAccount())
    history.push('/')
  }

  useEffect(() => {
    const init = async () => {
      try {
        const token = localStorage.getItem('authToken')

        if (token && verifyToken(token)) {
          const response = await AuthService.me(token)
          setSession(token)
          dispatch(initAccount({ user: response.data }))
        }
      } catch (error) {}
    }
    init()
  }, [dispatch])
  return <JWTContext.Provider value={{ login, logout }}>{children}</JWTContext.Provider>
}

JWTProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default JWTContext
