import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import TableWrapper from 'src/components/TableWrapper'
import ReactDatatable from '@ashvin27/react-datatable'
import { deleteErosao, retrieveErosao } from 'src/store/reducers/erosaoSlice'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'
import SwalAlert from 'src/utils/SwalAlert'
import GalleryImage from 'src/components/ImageGallery'
import ModalImage from 'src/components/Modal/ImageModal'
import { ADMIN, SUPER_ADMIN } from 'src/utils/permission'
import EditErosaoDialog from './EditErosaoDialog'
import AddErosaoDialog from './AddErosaoDialog'
import { columns, config } from './tableConf'

function Erosao() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const erosaos = useSelector((state) => state.erosaos)
  const [currentItem, setCurrentItem] = useState(null)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')
  const [data, setData] = useState([])
  const [images, setImages] = useState(null)
  const [imageShow, setShowImage] = useState(false)
  const [role, setRole] = useState(null)

  const destroy = (record, index) => {
    SwalAlert.warning().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteErosao(record.id))
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success')
      }
    })
  }

  const changeItem = (record, index) => {
    setAction('')
    setCurrentItem(record)
    setVisible(true)
  }

  const showImage = (record, index) => {
    setImages(record.image)
    setShowImage(true)
  }

  useEffect(() => {
    dispatch(retrieveErosao())
      .unwrap()
      .then(() => setLoading(false))
  }, [dispatch])

  useEffect(() => {
    if (user) {
      let datas = erosaos

      if (user.role.name !== SUPER_ADMIN) {
        datas = erosaos.filter((erosao) => erosao.gruposand.id === user.gruposand.id)
      }

      const data = datas.map((item, index) => ({
        index: index + 1,
        id: item.id,
        tipo_perfil: item.profile_type,
        largura: item.measured_width,
        comprimento: item.measured_heigth,
        data_medicao: moment(item.measurement_date).format('DD-MM-YYYY'),
        praia: item.praia.name_praia,
        grupo: item.gruposand.name_grupo,
        descricao: item.point_description,
        distancia: item.point_distance,
        localizacao: item.point_location,
        image: item.point_image,
      }))
      setData(data)
      setRole(user.role.name)
    }
  }, [erosaos, user])
  return (
    <CContainer lg>
      <TableWrapper title="Lista Medições da Praia">
        <CRow className="mb-4 ">
          {ADMIN.includes(role) && (
            <CCol>
              <CButton
                className="main-button"
                onClick={() => {
                  setAction('add')
                  setVisible(true)
                }}
              >
                Adicionar
              </CButton>
            </CCol>
          )}
        </CRow>
        <div className="table-responsive">
          <ReactDatatable
            config={config(user && role)}
            records={data}
            loading={loading}
            columns={columns(destroy, changeItem, showImage, role)}
          />
        </div>
      </TableWrapper>
      {action === 'add' ? (
        <AddErosaoDialog visible={visible} setVisible={setVisible} />
      ) : (
        <EditErosaoDialog visible={visible} setVisible={setVisible} item={currentItem} />
      )}
      <ModalImage visible={imageShow} setVisible={setShowImage}>
        <GalleryImage items={images} />
      </ModalImage>
    </CContainer>
  )
}
export default Erosao
