import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ResultadoAguaService from '../../service/monitorizacao/ResultadoAgua'

export const createResultadoAgua = createAsyncThunk('resultadoAgua/create', async (data) => {
  const response = await ResultadoAguaService.create(data)
  return response.data
})

export const retrieveResultadoAgua = createAsyncThunk('resultadoAgua/retrieve', async () => {
  const response = await ResultadoAguaService.retrieve()
  return response.data
})

export const updateResultadoAgua = createAsyncThunk(
  'resultadoAgua/update',
  async ({ data, id }) => {
    const response = await ResultadoAguaService.update(data, id)
    return response.data
  },
)

export const deleteResultadoAgua = createAsyncThunk('resultadoAgua/delete', async (id) => {
  const response = await ResultadoAguaService.delete(id)
  return response.data
})

const initialState = []

const resultadoAguaSlice = createSlice({
  name: 'ResultadoAgua',
  initialState,
  extraReducers: {
    [createResultadoAgua.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveResultadoAgua.fulfilled]: (state, action) => [...action.payload],
    [updateResultadoAgua.fulfilled]: (state, action) => {
      const index = state.findIndex((ResultadoAgua) => ResultadoAgua.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteResultadoAgua.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default resultadoAguaSlice.reducer
