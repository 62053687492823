import React, { useState } from 'react'
import { FeatureGroup } from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import { useDispatch } from 'react-redux'
import { addCoordinate, addLayerId } from 'src/store/reducers/mapSlice'
import AddPraiaDialog from 'src/views/praia/AddInfo/AddPraiaDialog'
import PropTypes from 'prop-types'
import AddPraiaItemDialog from 'src/views/praia/AddItem/AddItemDialog'

function Draw({ action }) {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)

  const _onCreated = (event) => {
    const { layer, layerType } = event

    if (layerType === 'marker') {
      dispatch(addCoordinate(layer._latlng))
      dispatch(addLayerId(layer._leaflet_id))
      setShow(true)
    }
  }
  return (
    <>
      <FeatureGroup>
        <EditControl
          onCreated={_onCreated}
          position="topright"
          draw={{
            rectangle: false,
            circle: false,
            polygon: false,
            circlemarker: false,
          }}
        />
      </FeatureGroup>
      {action === 'addPraia' ? (
        <AddPraiaDialog visible={show} setVisible={setShow} />
      ) : (
        <AddPraiaItemDialog visible={show} setVisible={setShow} />
      )}
    </>
  )
}
Draw.propTypes = {
  action: PropTypes.string,
}

export default Draw
