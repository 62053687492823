import axiosService from '../../axios'

class ItemService {
  create(data) {
    return axiosService.post('/itens', data)
  }

  retrieve() {
    return axiosService.get('/itens')
  }

  update(data, id) {
    return axiosService.put(`itens/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`itens/${id}`)
  }
}

export default new ItemService()
