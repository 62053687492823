import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { CBadge } from '@coreui/react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export const AppSidebarNav = ({ items }) => {
  const location = useLocation()
  const { t } = useTranslation()
  const { user } = useSelector((state) => state.account)
  const [role, setRole] = useState('')
  const [routes, SetRoutes] = useState([])

  useEffect(() => {
    if (user) {
      setRole(user.role.name)
      const data = items(t).filter((item) => item.permission.includes(user.role.name))
      SetRoutes(data)
    }
  }, [user, items, t])

  const navLink = (name, icon, badge) => (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )

  const navItem = (item, index) => {
    const { component, name, badge, icon, permission, ...rest } = item
    const Component = component
    return (
      permission.includes(role) && (
        <Component
          {...(rest.to &&
            !rest.items && {
              component: NavLink,
              activeClassName: 'active',
            })}
          key={index}
          {...rest}
        >
          {navLink(name, icon, badge)}
        </Component>
      )
    )
  }
  const navGroup = (item, index) => {
    const { component, name, icon, to, permission, ...rest } = item
    const Component = component

    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index),
        )}
      </Component>
    )
  }

  return (
    <>
      {routes.map((item, index) => item.items ? navGroup(item, index) : navItem(item, index))}
    </>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.func.isRequired,
}
