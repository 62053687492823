import axiosService from '../../axios'

class DetritoService {
  create(data) {
    return axiosService.post('/detritos', data)
  }

  retrieve() {
    return axiosService.get('/detritos')
  }

  update(data, id) {
    return axiosService.put(`/detritos/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/detritos/${id}`)
  }
}

export default new DetritoService()
