import axiosService from '../axios'

class PraiaService {
  create(data) {
    return axiosService.post('/praias', data)
  }

  retrieve() {
    return axiosService.get('/praias')
  }

  update(data, id) {
    return axiosService.put(`/praias/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/praias/${id}`)
  }
}
export default new PraiaService()
