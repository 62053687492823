import * as Yup from 'yup'

export const schema = Yup.object({
  praia: Yup.string().required('Campo obrigatório'),
  // gruposand: Yup.string().required('Campo obrigatório'),
  profile_type: Yup.string().required('Campo obrigatório'),
  point_description: Yup.string().required('Campo obrigatório'),
  measurement_date: Yup.string().required('Campo obrigatório'),
  measured_width: Yup.number('Adicionar apenas numeros').positive(),
  measured_heigth: Yup.number('Adicionar apenas numeros').positive(),
  point_distance: Yup.number('Adicionar apenas numeros').positive(),
  point_location: Yup.string(),
})
