import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { retrieveGruposand } from 'src/store/reducers/gruposandSlice'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import { retrieveTartaruga } from 'src/store/reducers/TartarugaSlice'
import Swal from 'sweetalert2'
import { updateNinhoTartaruga } from 'src/store/reducers/ninhoTartarugaSlice'
import SwalAlert from 'src/utils/SwalAlert'
import { convert } from 'src/utils/Date'
import { ADMIN_PRAIA, SUPER_ADMIN } from 'src/utils/permission'
import UploadService from '../../../service/Upload'

function EditNinhoTartarugaDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch()
  const [grupoOptions, setGrupoOption] = useState([])
  const [praiaOptions, setPraiaOption] = useState([])
  const [tartarugaOptions, setTartarugaOption] = useState([])
  const [maciezOptions, setMaciezOption] = useState([])
  const { user } = useSelector((state) => state.account)

  const gruposands = useSelector((state) => state.gruposands)
  const praias = useSelector((state) => state.praias)
  const tartarugas = useSelector((state) => state.tartarugas)


  const [date, setDate] = useState()
  const [date_of_emergence_of_hatchlings, setDate_of_emergence_of_hatchlings] = useState()
  const [praia, setPraia] = useState('')
  const [gruposand, setGrupo] = useState()
  const [type_tartarugas, setTartaruga] = useState()
  const [distance_of_edge_of_the_vegetation, setDistance_of_edge_of_the_vegetation] = useState()
  const [sand_observations, setSand_observations] = useState()
  const [number_of_crab_holes_per_square_metre, setNumber_of_crab_holes_per_square_metre] =
    useState()
  const [observation_of_crab_holes, setObservation_of_crab_holes] = useState()
  const [comments, setComments] = useState()
  const [maciez, setMaciez] = useState()
  const [Img_N, setImg_Nest] = useState(null)
  const [Img_C, setImg_Crab] = useState(null)
  const [Img_S, setImg_Sand] = useState(null)

  const saveNinhoTartaruga = async (e) => {
    e.preventDefault()

    const formDataNest = new FormData()
    let Img_Nest
    if (Img_N) {
      formDataNest.append('files', Img_N[0])
      const { data } = await UploadService.upload(formDataNest)
      Img_Nest = data
    }
    const formDataCrab = new FormData()
    let Img_Crab
    if (Img_C) {
      formDataCrab.append('files', Img_C[0])
      const { data } = await UploadService.upload(formDataCrab)
      Img_Crab = data
    }
    const formData = new FormData()
    let Img_Sand
    if (Img_S) {
      formData.append('files', Img_S[0])
      const { data } = await UploadService.upload(formData)
      Img_Sand = data
    }
    const data = {
      date,
      praia,
      gruposand,
      type_tartarugas,
      distance_of_edge_of_the_vegetation,
      sand_observations,
      number_of_crab_holes_per_square_metre,
      observation_of_crab_holes,
      date_of_emergence_of_hatchlings,
      comments,
      maciez,
      Img_Nest,
      Img_Crab,
      Img_Sand,
    }

    dispatch(
      updateNinhoTartaruga({
        data,
        id: item.id,
      }),
    )
      .unwrap()
      .then(() => {
        setVisible(false)
        SwalAlert.success()
      })
      .catch(() => {
        SwalAlert.error()
      })
  }

  useEffect(() => {
    console.log(item)
    console.log(praia)
    if (item) {
      const praia = praias.find((praia) => praia.name_praia === item.praia)
      const gruposand = gruposands.find((grupo) => grupo.name_grupo === item.grupo)
      console.log(gruposand)
      const tartaruga = tartarugas.find(
        (tartaruga) => tartaruga.Especie_tartaruga === item.tartaruga,
      )
      setPraia(praia.id)
      setGrupo(gruposand.id)
      setTartaruga(tartaruga.id)
      setMaciez(item.maciez)
      setNumber_of_crab_holes_per_square_metre(item.number_crab)
      setComments(item.comments)
      setObservation_of_crab_holes(item.observation_crab)
      setSand_observations(item.sand_observations)
      setDistance_of_edge_of_the_vegetation(item.distance_edge)
      setDate(convert(item.date))
      setDate_of_emergence_of_hatchlings(convert(item.date_emergence))
    }
  }, [item, praias, gruposands, tartarugas])

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }))
    setGrupoOption(options)
  }, [gruposands])

  useEffect(() => {
    let filtered = praias

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id)
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }))
    setPraiaOption(options)
  }, [praias, user])

  useEffect(() => {
    const options = tartarugas.map((item) => ({
      value: item.id,
      label: item.Especie_tartaruga,
    }))
    setTartarugaOption(options)
  }, [tartarugas])

  useEffect(() => {
    const options = [
      { label: 'Alta', value: 'Alta' },
      { label: 'Media', value: 'Media' },
      { label: 'Baixa', value: 'Baixa' },
    ]

    setMaciezOption(options)
  }, [])

  useEffect(() => {
    dispatch(retrieveGruposand())
    dispatch(retrievePraias())
    dispatch(retrieveTartaruga())
  }, [dispatch])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar Ninho Tartaruga</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={saveNinhoTartaruga}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Praia</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={praiaOptions}
                  style={{ width: '100%' }}
                  value={praia}
                  onChange={setPraia}
                />
              </div>
            </CCol>
            {SUPER_ADMIN.includes(user.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">Grupo</CFormLabel>
                    </CCol>
                  </CRow>
                  <SelectPicker
                    data={grupoOptions}
                    style={{ width: '100%' }}
                    defaultValue={gruposand}
                    value={gruposand}
                    onChange={setGrupo}
                  />
                </div>
              </CCol>
            )}

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Data</CFormLabel>
                  </CCol>
                </CRow>
                <DatePicker
                  className="w-100"
                  format="dd-MM-yyyy"
                  value={date}
                  defaultValue={date}
                  onChange={setDate}
                />
              </div>
            </CCol>

            <CModalTitle>Maciez da Areia</CModalTitle>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Distancia da vegetação (m)</CFormLabel>
                <CFormInput
                  min={0}
                  type="number"
                  id="item"
                  value={distance_of_edge_of_the_vegetation}
                  onChange={(e) => setDistance_of_edge_of_the_vegetation(e.target.value)}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Maciez</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={maciezOptions}
                  style={{ width: '100%' }}
                  value={maciez}
                  onChange={setMaciez}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Observação de Maciez da Areia</CFormLabel>
                <CFormTextarea
                  defaultValue={sand_observations}
                  value={sand_observations}
                  onChange={(e) => setSand_observations(e.target.value)}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto da Areia</CFormLabel>
                <CFormInput
                  type="file"
                  id="item"
                  accept=".png,.jpg"
                  onChange={(e) => setImg_Sand(e.target.files)}
                />
              </div>
            </CCol>

            <CModalTitle>Sedimentos</CModalTitle>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Nº de buracos de caranguejo por metro quadrado
                </CFormLabel>
                <CFormInput
                  min={0}
                  type="number"
                  id="item"
                  value={number_of_crab_holes_per_square_metre}
                  onChange={(e) => setNumber_of_crab_holes_per_square_metre(e.target.value)}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Observação de buracos Caranguejo</CFormLabel>
                <CFormTextarea
                  defaultValue={observation_of_crab_holes}
                  value={observation_of_crab_holes}
                  onChange={(e) => setObservation_of_crab_holes(e.target.value)}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto do Caranguejo</CFormLabel>
                <CFormInput
                  type="file"
                  id="item"
                  accept=".png,.jpg"
                  onChange={(e) => setImg_Crab(e.target.files)}
                />
              </div>
            </CCol>

            <CModalTitle>Ninho Tartaruga</CModalTitle>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Data de emergência dos filhotes</CFormLabel>
                  </CCol>
                </CRow>
                <DatePicker
                  className="w-100"
                  format="dd-MM-yyyy"
                  value={date_of_emergence_of_hatchlings}
                  defaultValue={date_of_emergence_of_hatchlings}
                  onChange={setDate_of_emergence_of_hatchlings}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Tipo Tartaruga</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={tartarugaOptions}
                  style={{ width: '100%' }}
                  value={type_tartarugas}
                  onChange={setTartaruga}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Observação de Ninho Tartaruga</CFormLabel>
                <CFormTextarea
                  defaultValue={comments}
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto do Ninho de Tartaruga</CFormLabel>
                <CFormInput
                  type="file"
                  id="item"
                  accept=".png,.jpg"
                  onChange={(e) => setImg_Nest(e.target.files)}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
EditNinhoTartarugaDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
}

export default EditNinhoTartarugaDialog
