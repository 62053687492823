import * as Yup from 'yup'

export const schema = Yup.object({
  amostraagua: Yup.string().required('Campo obrigatório'),
  date_resultado: Yup.string().required('Campo obrigatório'),
  dissolved_oxygen: Yup.number().min(0),
  dissolved_oxygen_demand_BOD_ppm: Yup.number().min(0),
  fecal_coliform: Yup.number().min(0),
  Counting_enterococcos: Yup.number().min(0),
  Counting_escherichina: Yup.number().min(0),
  ph: Yup.number().min(0).required('Campo obrigatório'),
  phosphate_ppm: Yup.number().min(0),
  conductivity: Yup.number().min(0),
  temperature: Yup.number().min(0),
  turbidity_jtu: Yup.number().min(0),
  nitrate_ppm: Yup.number().min(0),
  salinity_ppt: Yup.number().min(0),
})
