import React, { useState, useEffect } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { cilSave, cilXCircle } from "@coreui/icons";
import { SelectPicker, DatePicker } from "rsuite";
import { retrieveGruposand } from "src/store/reducers/gruposandSlice";
import { retrievePraias } from "src/store/reducers/praiaSlice";
import { retrieveTartaruga } from "src/store/reducers/TartarugaSlice";
import { createNinhoTartaruga } from "src/store/reducers/ninhoTartarugaSlice";
import SwalAlert from "src/utils/SwalAlert";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ADMIN_PRAIA, SUPER_ADMIN } from "src/utils/permission";
import { schema } from "./validation";
import UploadService from "../../../service/Upload";

function AddNinhoTartarugaDialog({ visible, setVisible }) {
  const dispatch = useDispatch();
  const gruposands = useSelector((state) => state.gruposands);
  const praias = useSelector((state) => state.praias);
  const tartarugas = useSelector((state) => state.tartarugas);
  const { user } = useSelector((state) => state.account);
  const [grupoOptions, setGrupoOption] = useState([]);
  const [praiaOptions, setPraiaOption] = useState([]);

  const [tartarugaOptions, setTartarugaOption] = useState([]);
  const [maciezOptions, setMaciezOption] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [Img_N, setImg_Nest] = useState(null);
  const [Img_C, setImg_Crab] = useState(null);
  const [Img_S, setImg_Sand] = useState(null);

  /* save NinhoTartaruga */
  const saveNinhoTartaruga = async (data) => {
    const formDataNest = new FormData();
    let Img_Nest;
    if (Img_N) {
      formDataNest.append("files", Img_N[0]);
      const { data } = await UploadService.upload(formDataNest);
      Img_Nest = data;
    }
    const formDataCrab = new FormData();
    let Img_Crab;
    if (Img_C) {
      formDataCrab.append("files", Img_C[0]);
      const { data } = await UploadService.upload(formDataCrab);
      Img_Crab = data;
    }
    const formData = new FormData();
    let Img_Sand;
    if (Img_S) {
      formData.append("files", Img_S[0]);
      const { data } = await UploadService.upload(formData);
      Img_Sand = data;
    }

    const values = {
      ...data,
      praia: parseInt(data.praia, 10),
      type_tartarugas: parseInt(data.type_tartarugas, 10),
      gruposand: parseInt(data.gruposand, 10),
      date: new Date(data.date),
      date_of_emergence_of_hatchlings: new Date(
        data.date_of_emergence_of_hatchlings
      ),
      Img_Nest,
      Img_Crab,
      Img_Sand,
    };

    if (ADMIN_PRAIA.includes(user.role.name)) {
      values.gruposand = user.gruposand.id;
    }

    dispatch(
      createNinhoTartaruga({ ...data, ...values, Img_Nest, Img_Crab, Img_Sand })
    )
      .unwrap()
      .then(() => {
        SwalAlert.success();
        reset({
          praia: "",
          gruposand: "",
          distance_of_edge_of_the_vegetation: "0",
          sand_observations: "",
          number_of_crab_holes_per_square_metre: "0",
          observation_of_crab_holes: "",
          date: "",
          date_of_emergence_of_hatchlings: "",
          comments: "",
          type_tartarugas: "",
          maciez: "",
          Img_Nest: "",
          Img_Crab: "",
          Img_Sand: "",
        });
      })
      .catch((err) => {
        SwalAlert.error();
      });
  };

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }));
    setGrupoOption(options);
  }, [gruposands]);

  useEffect(() => {
    let filtered = praias;

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id);
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }));
    
    setPraiaOption(options);
  }, [praias, user]);

  useEffect(() => {
    const options = tartarugas.map((item) => ({
      value: item.id,
      label: item.Especie_tartaruga,
    }));
    setTartarugaOption(options);
  }, [tartarugas]);

  useEffect(() => {
    const options = [
      { label: "Alta", value: "Alta" },
      { label: "Media", value: "Media" },
      { label: "Baixa", value: "Baixa" },
    ];

    setMaciezOption(options);
  }, []);

  useEffect(() => {
    dispatch(retrieveGruposand());
    dispatch(retrievePraias());
    dispatch(retrieveTartaruga());
  }, [dispatch]);

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar Ninho Tartaruga</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={handleSubmit(saveNinhoTartaruga)}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Praia <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="praia"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker
                      {...field}
                      data={praiaOptions}
                      style={{ width: "100%" }}
                    />
                  )}
                />
                <small className="text-danger">{errors.praia?.message}</small>
              </div>
            </CCol>
            {SUPER_ADMIN.includes(user.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">
                        Grupo <strong className="text-danger">*</strong>
                      </CFormLabel>
                    </CCol>
                  </CRow>
                  <Controller
                    name="gruposand"
                    control={control}
                    render={({ field }) => (
                      <SelectPicker
                        {...field}
                        data={grupoOptions}
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                  <small className="text-danger">
                    {errors.gruposand?.message}
                  </small>
                </div>
              </CCol>
            )}

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker className="w-100" {...field} />
                  )}
                />
                <small className="text-danger">{errors.date?.message}</small>
              </div>
            </CCol>
            <CModalTitle>Maciez da Areia</CModalTitle>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Distancia da vegetação (m){" "}
                  <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  {...register("distance_of_edge_of_the_vegetation")}
                />
                <small className="text-danger">
                  {errors.distance_of_edge_of_the_vegetation?.message}
                </small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Maciez <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="maciez"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker
                      {...field}
                      data={maciezOptions}
                      style={{ width: "100%" }}
                    />
                  )}
                />
                <small className="text-danger">{errors.maciez?.message}</small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Observação de Maciez da Areia
                </CFormLabel>
                <CFormTextarea {...register("sand_observations")} />
                <small className="text-danger">
                  {errors.sand_observations?.message}
                </small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto da Areia</CFormLabel>
                <CFormInput
                  type="file"
                  id="item"
                  accept=".png,.jpg"
                  onChange={(e) => setImg_Sand(e.target.files)}
                />
              </div>
            </CCol>

            <CModalTitle>Sedimentos</CModalTitle>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Nº de buracos de caranguejo por metro quadrado{" "}
                  <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  min={0}
                  id="item"
                  {...register("number_of_crab_holes_per_square_metre")}
                />
                <small className="text-danger">
                  {errors.number_of_crab_holes_per_square_metre?.message}
                </small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Observação de buracos Caranguejo
                </CFormLabel>
                <CFormTextarea {...register("observation_of_crab_holes")} />
                <small className="text-danger">
                  {errors.observation_of_crab_holes?.message}
                </small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto do Caranguejo</CFormLabel>
                <CFormInput
                  type="file"
                  id="item"
                  accept=".png,.jpg"
                  onChange={(e) => setImg_Crab(e.target.files)}
                />
              </div>
            </CCol>

            <CModalTitle>Ninho Tartaruga</CModalTitle>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data de emergência dos filhotes{" "}
                      <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="date_of_emergence_of_hatchlings"
                  control={control}
                  render={({ field }) => (
                    <DatePicker className="w-100" {...field} />
                  )}
                />
                <small className="text-danger">
                  {errors.date_of_emergence_of_hatchlings?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Tipo Tartaruga <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="type_tartarugas"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker
                      {...field}
                      data={tartarugaOptions}
                      style={{ width: "100%" }}
                    />
                  )}
                />
                <small className="text-danger">
                  {errors.type_tartarugas?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Observação de Ninho Tartaruga
                </CFormLabel>
                <CFormTextarea {...register("comments")} />
                <small className="text-danger">
                  {errors.comments?.message}
                </small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Foto do Ninho de Tartaruga
                </CFormLabel>
                <CFormInput
                  type="file"
                  id="item"
                  accept=".png,.jpg"
                  onChange={(e) => setImg_Nest(e.target.files)}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  );
}
AddNinhoTartarugaDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default AddNinhoTartarugaDialog;
