import React, { useState } from 'react'
import {
  CHeader,
  CContainer,
  CHeaderBrand,
  CHeaderNav,
  CNavItem,
  CNavLink,
  CForm,
  CFormInput,
  CRow,
  CCol,
  CInputGroup,
  CInputGroupText,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilSearch, cilUser } from '@coreui/icons'
import { Link, useHistory } from 'react-router-dom'
import logo from '../../assets/SandwatchCV_Logo.png'

import pt from '../../assets/flags/portugal.png'
import en from '../../assets/flags/united-kingdom.png'
import { useTranslation } from 'react-i18next'

const langs = [
  { name: 'en', img: 'Inglês' },
  { name: 'pt', img: 'Português' },
]

function PublicHeader() {
  const [query, setQuery] = useState('')
  const history = useHistory()
  const { t, i18n } = useTranslation()

  const [selected, setSelected] = useState(i18n.language)

  const handleSelect = (flag) => {
    setSelected(flag)
    i18n.changeLanguage(flag)
  }

  const submit = (e) => {
    e.preventDefault()
    if (query.length > 1) {
      history.push(`/search/${query}`)
    }
  }
  return (
    <CHeader
      className="navbar navbar-expand-lg navbar-light bg-light "
      style={{ position: 'fixed', width: '100%', zIndex: 10000 }}
    >
      <CContainer lg>
        <CHeaderBrand href="/">
          <img src={logo} alt="logo" height={50} width={50} />
          <span className="ms-3 text-blue fw-bold">
            <span className="text-orange">Sandwatch</span>CV
          </span>
        </CHeaderBrand>
        <button
          className="navbar-toggler toggle-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <CRow className="w-100">
            <CCol lg={7} className="d-flex justify-content-center">
              <CHeaderNav className="navbar-nav  mb-2 mb-lg-0">
                <CNavItem>
                  <CNavLink href="/" className="navigation-link">
                    {t('headerList.home')}
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink className="navigation-link" href="/sobre-nos">
                    {t('headerList.about')}
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink className="navigation-link" href="/projectos-iniciativas">
                    {t('headerList.project')}
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink className="navigation-link" target='_blank' href="https://www.sandwatchfoundation.org/uploads/6/6/9/5/66958447/218344por.pdf">
                    {t('headerList.resource')}
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink className="navigation-link" href="/contatos">
                    {t('headerList.contact')}
                  </CNavLink>
                </CNavItem>
              </CHeaderNav>
            </CCol>
            <CCol lg={5}>
              <CHeaderNav>
                <CNavItem>
                  <CForm onSubmit={submit} className="d-flex">
                    <CInputGroup>
                      <CInputGroupText>
                        <CIcon icon={cilSearch} />
                      </CInputGroupText>
                      <CFormInput
                        className="form-control"
                        id="autoSizingInputGroup"
                        placeholder="Pesquisar praia"
                        type="search"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                    </CInputGroup>
                  </CForm>
                </CNavItem>

                <CNavItem>
                  <Link to="/login" className="btn btn text-orange ">
                    <CIcon icon={cilUser} />
                    {/*  {t("headerList.login")} */}
                  </Link>
                </CNavItem>
                <CNavItem>
                  <CDropdown className="flag-Dropdown">
                    <CDropdownToggle className="flag-drop-btn">
                      {selected === 'pt' && 'Português'}
                      {selected === 'en' && 'Inglês'}
                    </CDropdownToggle>
                    <CDropdownMenu className="flag-list">
                      {langs.map((lang) => (
                        <CDropdownItem
                          key={lang.name}
                          onClick={() => handleSelect(lang.name)}
                          className="flag-item"
                        >
                          {lang.img}
                        </CDropdownItem>
                      ))}
                    </CDropdownMenu>
                  </CDropdown>
                </CNavItem>
              </CHeaderNav>
            </CCol>
          </CRow>
        </div>
      </CContainer>
    </CHeader>
  )
}
export default PublicHeader
