import axiosService from '../../axios'

class ResultadoGService {
  create(data) {
    return axiosService.post('/resultadoamostraareiagranulometrias', data)
  }

  retrieve() {
    return axiosService.get('/resultadoamostraareiagranulometrias')
  }

  update(data, id) {
    return axiosService.put(`/resultadoamostraareiagranulometrias/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/resultadoamostraareiagranulometrias/${id}`)
  }
}

export default new ResultadoGService()
