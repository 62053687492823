import axiosService from '../../axios'

class DetritoPraiaService {
  create(data) {
    return axiosService.post('/detritospraias', data)
  }

  retrieve() {
    return axiosService.get('/detritospraias')
  }

  update(data, id) {
    return axiosService.put(`/detritospraias/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/detritospraias/${id}`)
  }
}

export default new DetritoPraiaService()
