import React, { useEffect, useState } from 'react'
import {
  CButton,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { updateTartaruga } from 'src/store/reducers/TartarugaSlice'
import UploadService from 'src/service/Upload'

function EditTartarugaDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch()
  const [name, setItem] = useState('')
  const [id, setId] = useState(null)
  const [images, setImage] = useState(null)

  useEffect(() => {
    setId(item.id)
    setItem(item.name)
  }, [item])

  const changeItem = async () => {
    const formData = new FormData()
    let img; let newData

    if (images) {
      formData.append('files', images[0])
      const { data } = await UploadService.upload(formData)
      img = data
      newData = { Especie_tartaruga: name, img }
    } else {
      newData = { Especie_tartaruga: name }
    }

    const data = newData
    dispatch(updateTartaruga({ data, id }))
      .unwrap()
      .then(() => {
        Swal.fire({
          text: 'Tartaruga editado com sucesso!',
          icon: 'success',
          confirmButtonColor: '#FF7F47',
          confirmButtonText: 'Ok!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        })
      })
      .catch((err) => {
        Swal.fire({
          text: 'Tartaruga não editado!',
          icon: 'error',
          confirmButtonColor: '#FF7F47',
          confirmButtonText: 'Ok!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        })
      })
    setVisible(false)
  }

  return (
    <CModal
      backdrop="static"
      alignment="center"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar Tartaruga</CModalTitle>
      </CModalHeader>

      <CModalBody>
        <div className="mb-3">
          <CFormLabel htmlFor="item">Nome</CFormLabel>
          <CFormInput
            type="text"
            id="item"
            value={name}
            onChange={(e) => setItem(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="formFile">Imagem</CFormLabel>
          <CFormInput type="file" id="formFile" onChange={(e) => setImage(e.target.files)} />
        </div>
      </CModalBody>

      <CModalFooter>
        <CButton color="secondary" onClick={() => setVisible(false)}>
          Cancelar
        </CButton>

        <CButton onClick={changeItem} className="main-button" color="primary">
          Editar
        </CButton>
      </CModalFooter>
    </CModal>
  )
}
EditTartarugaDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  item: PropTypes.func,
  children: PropTypes.node,
}

export default EditTartarugaDialog
