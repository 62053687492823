import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { updateResultadoG } from 'src/store/reducers/resultadoGSlice'
import SwalAlert from 'src/utils/SwalAlert'
import { convert } from 'src/utils/Date'

import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'

function EditResultadoGDialog({ visible, setVisible, item }) {
  const dispatch = useDispatch()

  const [corOptions, setCorOption] = useState([])
  const [formaOptions, setFormaOption] = useState([])

  const sedimentos = useSelector((state) => state.sedimentos)

  const [sedimento, setSedimento] = useState('')
  const [lote, setLote] = useState('')
  const [Size, setSize] = useState('')
  const [sand_collect_date, setSand_collect_date] = useState('')
  const [Color, setColor] = useState('')
  const [Shape, setShape] = useState('')

  const saveResultadoG = async (e) => {
    e.preventDefault()

    const data = {
      Size,
      sand_collect_date,
      Color,
      Shape,
    }

    dispatch(
      updateResultadoG({
        data,
        id: item.id,
      }),
    )
      .unwrap()
      .then(() => {
        setVisible(false)
        SwalAlert.success()
      })
      .catch(() => {
        SwalAlert.success()
      })
  }

  useEffect(() => {
    if (item) {
      const sedimento = sedimentos.find(
        (sedimento) => sedimento.sample_number_sand === item.amostra,
      )
      const lote = sedimentos.find((sedimento) => sedimento.batch_number_sand === item.lote)

      setSedimento(sedimento.sample_number_sand)
      setLote(lote.batch_number_sand)
      setSize(item.Size)
      setSand_collect_date(convert(item.sand_collect_date))
      setColor(item.Color)
      setShape(item.Shape)
    }
  }, [item, sedimentos])

  useEffect(() => {
    const options = [
      { label: 'Negro', value: 'Negro' },
      { label: 'Castanho', value: 'Castanho' },
      { label: 'Branco', value: 'Branco' },
    ]
    setCorOption(options)
  }, [])

  useEffect(() => {
    const options = [
      { label: 'Angular', value: 'Angular' },
      { label: 'Subangular', value: 'Subangular' },
      { label: 'Subarredonda', value: 'Subarredonda' },
      { label: 'Bem Arredonda', value: 'Bem Arredonda' },
    ]
    setFormaOption(options)
  }, [])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar Resultado da Análise Granulométrica das Amostras de Areia </CModalTitle>
      </CModalHeader>
      <CForm onSubmit={saveResultadoG}>
        <CModalBody>
          <CRow>
            <CCol lg={12}>
              <div className="mb-3">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 100 }}>
                    <TableCell>
                      <center>
                        <i style={{ fontSize: 16 }}>Número de Amostra :</i> &nbsp;
                        {sedimento}
                      </center>
                    </TableCell>
                    <TableCell>
                      <i style={{ fontSize: 16 }}> Lote :</i>&nbsp;
                      {lote}
                    </TableCell>
                  </Table>
                </TableContainer>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Data</CFormLabel>
                  </CCol>
                </CRow>
                <DatePicker
                  className="w-100"
                  format="dd-MM-yyyy"
                  defaultValue={sand_collect_date}
                  value={sand_collect_date}
                  onChange={setSand_collect_date}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Cor</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={corOptions}
                  style={{ width: '100%' }}
                  value={Color}
                  onChange={setColor}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">Forma</CFormLabel>
                  </CCol>
                </CRow>
                <SelectPicker
                  data={formaOptions}
                  style={{ width: '100%' }}
                  value={Shape}
                  onChange={setShape}
                />
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Tamanho(mm) </CFormLabel>
                <CFormInput
                  type="number"
                  step="0.001"
                  min={0}
                  id="item"
                  value={Size}
                  onChange={(e) => setSize(e.target.value)}
                />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
EditResultadoGDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
}

export default EditResultadoGDialog
