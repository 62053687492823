import React, { Component } from 'react'

import { useTranslation } from 'react-i18next'
import ImgLogo from '../../../assets/images/sobre-nos/Logo_1.png'
import Img1 from '../../../assets/SandwatchCV_Logo.png'

import Img3 from '../../../assets/images/sobre-nos/LOGO_CNU_4_SEM_FUNDO.png'
import Img4 from '../../../assets/images/sobre-nos/4.png'
import Img5 from '../../../assets/images/sobre-nos/5.png'
import Img6 from '../../../assets/images/sobre-nos/6.png'
import Img7 from '../../../assets/images/sobre-nos/7.png'

function AboutWrapper() {
  const { t } = useTranslation()
  return (
    <>
      {/* ===============  About wrapper area start =============== */}
      <div className="about-wrapper">
        <div className="container">
         {/*  <div className="row">
            <div className="col-lg-3 col-md-12 d-none d-lg-block">
              <div className="about-img d-md-flex justify-content-center">
                <img src={Img1} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-9 col-md-12">
              <div className="about-wrapper-right">
                <h3>{t('SECAO1.TITULO')}</h3>
                <p className="fs-6 text-justify">{t('SECAO1.CONTEUDO')}</p>
              </div>
            </div>
          </div> */}
          <div className="row mt-5">
            <div className="col-lg-8 col-md-12">
              <div className="about-wrapper-left">
                <h3>{t('SECAO2.TITULO')}</h3>
                <p className="p-about">{t('SECAO2.CONTEUDO')}</p>
                <p className="p-about">{t('SECAO2.CONTEUDO2')}</p>
                <p className="p-about">{t('SECAO2.CONTEUDO3')}</p>
                <p className="p-about">{t('SECAO2.CONTEUDO4')}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 d-flex justify-content-center align-items-center">
              <div className="about-img">
                <img src={Img1} alt="" className="img-fluid" width={150} height={150} />
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-4 col-md-12 d-none d-lg-block">
                <div className="about-img">
                  <img src={Img3} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="about-wrapper-right">
                  <h3>{t('SECAO3.TITULO')}</h3>
                  <p className="p-about">{t('SECAO3.CONTEUDO')}</p>
                  <ul>
                    <li><p className="p-about"> - {t('SECAO3.CONTEUDO2')}</p></li>
                    <li><p className="p-about"> - {t('SECAO3.CONTEUDO3')}</p></li>
                  </ul>
                  <p className="p-about">{t('SECAO3.CONTEUDO4')}</p>
                  <p className="p-about">{t('SECAO3.CONTEUDO5')}</p>
                </div>
              </div>
            </div>
            {/* <div className="row mt-5">
              <div className="col-lg-10 col-md-12">
                <div className="about-wrapper-left">
                  <h3>{t('SECAO4.TITULO')}</h3>
                  <p className="fs-6 text-justify">{t('SECAO4.CONTEUDO')}</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 d-none d-lg-block">
                <div className="about-img">
                  <img src={Img4} alt="" className="img-fluid" />
                </div>
              </div>
            </div> */}
            <div className="row mt-5">
              {/* <div className="col-lg-2 col-md-12 d-none d-lg-block">
                <div className="about-img">
                  <img src={Img5} alt="" className="img-fluid" />
                </div>
              </div> */}
              <div className="col-lg-12 col-md-12">
                <div className="about-wrapper-right">
                  <h3>{t('SECAO5.TITULO')}</h3>
                  <p className="fs-6 text-justify">{t('SECAO5.CONTEUDO')}</p>
                </div>
              </div>
            </div>
            <div className="row mt-5 d-flex align-items-center">
              <div className="col-lg-10 col-md-12">
                <div className="about-wrapper-right">
                  <h3>{t('SECAO6.TITULO')}</h3>
                  <p className="fs-6 text-justify">{t('SECAO6.CONTEUDO')}</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 d-none d-lg-block">
                <div className="about-img">
                  <img src={Img6} alt="" className="img-fluid" />
                </div>
              </div>
              <br />
              <div className="col-lg-3" />
              <div className="col-lg-6 mt-5">
                <div className="about-img">
                  <img src={Img7} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-3" />
            </div> 
          </div>
        </div>
      </div>

      {/* ===============  About wrapper area end =============== */}
    </>
  )
}

export default AboutWrapper
