import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { retrieveGruposand } from 'src/store/reducers/gruposandSlice'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import { createSedimento } from 'src/store/reducers/sedimentoSlice'
import SwalAlert from 'src/utils/SwalAlert'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ADMIN_PRAIA, SUPER_ADMIN } from "src/utils/permission";
import { schema } from './validation'
import UploadService from '../../../../service/Upload'


function AddSedimentoDialog({ visible, setVisible }) {
  const dispatch = useDispatch()

  const gruposands = useSelector((state) => state.gruposands)
  const praias = useSelector((state) => state.praias)
  const { user } = useSelector((state) => state.account);

  const [grupoOptions, setGrupoOption] = useState([])
  const [praiaOptions, setPraiaOption] = useState([])
  const [selecaoOptions, setSelecaoOption] = useState([])
  const [tipoOptions, setTipoOption] = useState([])

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const [images, setImage] = useState(null)

  const saveSedimento = async (data) => {
    const formData = new FormData()
    let photo
    if (images) {
      formData.append('files', images[0])
      const { data } = await UploadService.upload(formData)
      photo = data
    }

    const values = {
      ...data,
      praia: parseInt(data.praia, 10),
      gruposand: parseInt(data.gruposand, 10),
      date_collect: new Date(data.date_collect),
      photo,
    }
    if (ADMIN_PRAIA.includes(user.role.name)) {
      values.gruposand = user.gruposand.id;
    }

    dispatch(createSedimento({ ...data, ...values, photo }))
      .unwrap()
      .then(() => {
        SwalAlert.success()
        reset({
          praia: '',
          gruposand: '',
          selection: '',
          date_collect: '',
          sand_type: '',
          sample_number_sand: '',
          batch_number_sand: '',
        })
      })
      .catch((err) => {
        SwalAlert.error()
      })
  }

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }))
    setGrupoOption(options)
  }, [gruposands])

  useEffect(() => {
    let filtered = praias

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id)
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }))
    setPraiaOption(options)
  }, [praias, user])

  useEffect(() => {
    const options = [
      { label: 'Principalmente Fina', value: 'Principalmente Fina' },
      { label: 'Principalmente Grossa', value: 'Principalmente Grossa' },
      { label: 'Fina e Média', value: 'Fina e Media' },
      { label: 'Grossa e Média', value: 'Grossa e Media' },
      { label: 'Mistura de Grossa e Fina', value: 'Mistura de Grossa e Fina' },
    ]
    setSelecaoOption(options)
  }, [])

  useEffect(() => {
    const options = [
      { label: 'Areia Molhada', value: 'Areia Molhada' },
      { label: 'Areia Seca', value: 'Areia Seca' },
    ]
    setTipoOption(options)
  }, [])

  useEffect(() => {
    dispatch(retrieveGruposand())
    dispatch(retrievePraias())
  }, [dispatch])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar Sedimentos Praia</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={handleSubmit(saveSedimento)}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Praia <strong className="text-danger">*</strong>{' '}
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="praia"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker {...field} data={praiaOptions} style={{ width: '100%' }} />
                  )}
                />
                <small className="text-danger">{errors.praia?.message}</small>
              </div>
            </CCol>

            {SUPER_ADMIN.includes(user.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">
                        Grupo <strong className="text-danger">*</strong>
                      </CFormLabel>
                    </CCol>
                  </CRow>
                  <Controller
                    name="gruposand"
                    control={control}
                    render={({ field }) => (
                      <SelectPicker
                        {...field}
                        data={grupoOptions}
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                  <small className="text-danger">
                    {errors.gruposand?.message}
                  </small>
                </div>
              </CCol>
            )}

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Número de amostra <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput type="number" min={0} {...register('sample_number_sand')} />
                <small className="text-danger">{errors.sample_number_sand?.message}</small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Lote <strong className="text-danger">*</strong>
                </CFormLabel>
                <CFormInput type="number" min={0} {...register('batch_number_sand')} />

                <small className="text-danger">{errors.batch_number_sand?.message}</small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="date_collect"
                  control={control}
                  render={({ field }) => <DatePicker className="w-100" {...field} />}
                />
                <small className="text-danger">{errors.date_collect?.message}</small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Tipo de Areia <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="sand_type"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker {...field} data={tipoOptions} style={{ width: '100%' }} />
                  )}
                />
                <small className="text-danger">{errors.sand_type?.message}</small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Seleção
                      <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="selection"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker {...field} data={selecaoOptions} style={{ width: '100%' }} />
                  )}
                />
                <small className="text-danger">{errors.selection?.message}</small>
              </div>
            </CCol>

            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Foto</CFormLabel>
                <CFormInput type="file" id="item" onChange={(e) => setImage(e.target.files)} />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
AddSedimentoDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default AddSedimentoDialog
