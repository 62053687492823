import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilCursor, cilNotes, cilSpeedometer, cilBeachAccess, cilUser } from '@coreui/icons'
import { CNavGroup, CNavItem } from '@coreui/react'
import { ADMIN, ALL, SUPER_ADMIN } from './utils/permission'

const _nav = (t) => [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    permission: ADMIN,
  },
  {
    component: CNavGroup,
    name: 'Sandwatch',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    permission: ADMIN,
    items: [
      {
        component: CNavItem,
        name: t('sandwatch.user'),
        to: '/sandwatch/utilizadores',
        permission: ADMIN,
      },
      {
        component: CNavItem,
        name: t('sandwatch.group'),
        to: '/sandwatch/grupos',
        permission: SUPER_ADMIN,
      },
    ],
  },
  {
    component: CNavGroup,
    name: t('praia.name'),
    icon: <CIcon icon={cilBeachAccess} customClassName="nav-icon" />,
    permission: ALL,
    items: [
      {
        component: CNavItem,
        name: t('praia.addInfo'),
        to: '/praia/adicionar-info',
        permission: ALL,
      },
      {
        component: CNavItem,
        name: t('praia.addItem'),
        to: '/praia/adicionar-items',
        permission: ALL,
      },
      {
        component: CNavItem,
        name: t('praia.problems'),
        to: '/praia/problemas',
        permission: ALL,
      },
      {
        component: CNavItem,
        name: t('praia.projects'),
        to: '/praia/projectos-iniciativas',
        permission: ALL,
      },
    ],
  },
  {
    component: CNavGroup,
    name: t('monitorizacao.name'),
    icon: <CIcon icon={cilCursor} customClassName="nav-icon" />,
    permission: ALL,
    items: [
      {
        component: CNavItem,
        name: t('monitorizacao.agua'),
        to: '/monitorizacao/agua',
        permission: ALL,
      },
      {
        component: CNavItem,
        name: t('monitorizacao.sedimento'),
        to: '/monitorizacao/sedimento',
        permission: ALL,
      },
      {
        component: CNavItem,
        name: t('monitorizacao.erosao'),
        to: '/monitorizacao/erosao-acrecao',
        permission: ALL,
      },
      {
        component: CNavItem,
        name: t('monitorizacao.fauna'),
        to: '/monitorizacao/fauna',
        permission: ALL,
      },
      {
        component: CNavItem,
        name: t('monitorizacao.flora'),
        to: '/monitorizacao/flora',
        permission: ALL,
      },
      {
        component: CNavItem,
        name: t('monitorizacao.ninho'),
        permission: ALL,
        to: '/monitorizacao/ninho-tartaruga',
      },
      {
        component: CNavItem,
        name: t('monitorizacao.atividade'),
        permission: ALL,
        to: '/monitorizacao/atividades-humanas',
      },
      {
        component: CNavItem,
        name: t('monitorizacao.diario'),
        permission: ALL,
        to: '/monitorizacao/diario-praias',
      },
      {
        component: CNavItem,
        name: t('monitorizacao.detrito'),
        permission: ALL,
        to: '/monitorizacao/detrito',
      },
      {
        component: CNavItem,
        name: t('monitorizacao.medicao'),
        permission: ALL,
        to: '/monitorizacao/medicao',
      },
      {
        component: CNavItem,
        name: t('monitorizacao.ficha'),
        permission: ALL,
        to: '/monitorizacao/ficha-limpeza',
      },
    ],
  },
  {
    component: CNavGroup,
    name: t('parametrizacao.name'),
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
    permission: ADMIN,
    items: [
      {
        component: CNavItem,
        name: t('parametrizacao.actividade'),
        to: '/parametrizacao/actividades',
        permission: ADMIN,
      },
      {
        component: CNavItem,
        name: t('parametrizacao.fauna'),
        to: '/parametrizacao/fauna',
        permission: ADMIN,
      },
      {
        component: CNavItem,
        name: t('parametrizacao.flora'),
        to: '/parametrizacao/flora',
        permission: ADMIN,
      },
      {
        component: CNavItem,
        name: t('parametrizacao.tartaruga'),
        to: '/parametrizacao/tartaruga',
        permission: ADMIN,
      },

      {
        component: CNavItem,
        name: t('parametrizacao.item'),
        to: '/parametrizacao/item',
        permission: ADMIN,
      },
      {
        component: CNavItem,
        name: t('parametrizacao.detrito'),
        permission: ADMIN,
        to: '/parametrizacao/detrito',
      },
    ],
  },
]

export default _nav
