import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker } from 'rsuite'
import Swal from 'sweetalert2'
import { updateProfile } from 'src/store/reducers/accountSlice'
import UploadService from '../../service/Upload'

function EditProfile({ visible, setVisible, item }) {
  const dispatch = useDispatch()
  const [username, setUsername] = useState()
  const [email, setEmail] = useState()
  const [name, setName] = useState()
  const [last_name, setLast_name] = useState()
  const [telefone, setTelefone] = useState()
  const [password, setPassword] = useState()
  const [images, setImage] = useState(null)

  const initState = () => {}
  const saveProfile = async (e) => {
    e.preventDefault()

    try {
      const formData = new FormData()
      let foto
      if (images) {
        formData.append('files', images[0])
        const { data } = await UploadService.upload(formData)
        foto = data
      }
      const data = {
        username,
        email,
        password,
        name,
        last_name,
        foto,
        telefone,
      }

      dispatch(
        updateProfile({
          data,
          id: item.id,
        }),
      ).then(() => {
        setVisible(false)
        Swal.fire({
          text: 'Perfil atualizado com sucesso!',
          icon: 'success',
          confirmButtonColor: '#FF7F47',
          confirmButtonText: 'Ok!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        })
      })
      initState()
    } catch (e) {
      Swal.fire({
        text: 'Perfil não atualizado!',
        icon: 'error',
        confirmButtonColor: '#FF7F47',
        confirmButtonText: 'Ok!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      })
    }
  }

  useEffect(() => {
    if (item) {
      setUsername(item.username)
      setEmail(item.email)
      setPassword(item.password)
      setName(item.name)
      setTelefone(item.telefone)
      setLast_name(item.last_name)
    }
  }, [item])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Editar Perfil </CModalTitle>
      </CModalHeader>
      <CForm onSubmit={saveProfile}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item1">Nome</CFormLabel>
                <CFormInput
                  type="text"
                  id="item1"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Apelido</CFormLabel>
                <CFormInput
                  type="text"
                  id="item"
                  value={last_name}
                  onChange={(e) => setLast_name(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item2">Username</CFormLabel>
                <CFormInput
                  type="text"
                  id="item2"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item3">Email</CFormLabel>
                <CFormInput
                  type="text"
                  id="item3"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item4">Telefone</CFormLabel>
                <CFormInput
                  type="number"
                  id="item4"
                  maxlength="7"
                  value={telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item5">Password</CFormLabel>
                <CFormInput
                  type="password"
                  id="item5"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item6">Foto</CFormLabel>
                <CFormInput type="file" id="item6" onChange={(e) => setImage(e.target.files)} />
              </div>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
EditProfile.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string,
  item: PropTypes.object,
  children: PropTypes.node,
}

export default EditProfile
