import React, { useState, useEffect } from 'react'
import { CModal, CModalHeader, CModalTitle } from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { convert } from 'src/utils/Date'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import moment from 'moment'

function VerMaisResultadoDialog({ visible, setVisible, item }) {
  const amostras = useSelector((state) => state.amostras)

  const [amostra, setAmostra] = useState('')
  const [lote, setLote] = useState('')
  const [date_resultado, setDate_resultado] = useState('')
  const [dissolved_oxygen, setDissolved_oxygen] = useState('')
  const [dissolved_oxygen_demand_BOD_ppm, setDissolved_oxygen_demand_BOD_ppm] = useState('')
  const [fecal_coliform, setFecal_coliform] = useState('')
  const [Counting_enterococcos, setCounting_enterococcos] = useState('')
  const [Counting_escherichina, setCounting_escherichina] = useState('')
  const [ph, setPh] = useState('')
  const [nitrate_ppm, setNitrate_ppm] = useState('')
  const [phosphate_ppm, setPhosphate_ppm] = useState('')
  const [salinity_ppt, setSalinity_ppt] = useState('')
  const [conductivity, setConductivity] = useState('')
  const [temperature, setTemperature] = useState('')
  const [turbidity_jtu, setTurbidity_jtu] = useState('')

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#FFF3E0',

      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  useEffect(() => {
    if (item) {
      const amostra = amostras.find((amostra) => amostra.sample_number === item.amostra)
      const lote = amostras.find((amostra) => amostra.Batch_number === item.lote)

      setAmostra(amostra.sample_number)
      setLote(lote.Batch_number)
      setDate_resultado(convert(item.date_resultado))
      setDissolved_oxygen(item.dissolved_oxygen)
      setDissolved_oxygen_demand_BOD_ppm(item.dissolved_oxygen_demand_BOD_ppm)
      setFecal_coliform(item.fecal_coliform)
      setCounting_enterococcos(item.Counting_enterococcos)
      setCounting_escherichina(item.Counting_escherichina)
      setPh(item.ph)
      setNitrate_ppm(item.nitrate_ppm)
      setPhosphate_ppm(item.phosphate_ppm)
      setSalinity_ppt(item.salinity_ppt)
      setConductivity(item.conductivity)
      setTemperature(item.temperature)
      setTurbidity_jtu(item.turbidity_jtu)
    }
  }, [item, amostra])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Resultado Completo da Amostra de Água</CModalTitle>
      </CModalHeader>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 100 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <strong>Número de Amostra :</strong> &nbsp;&nbsp;&nbsp;&nbsp;
                <left> </left> {amostra}
              </StyledTableCell>
              <StyledTableCell align="center">
                <strong>Lote :</strong>&nbsp;&nbsp;&nbsp;&nbsp;
                {lote}
              </StyledTableCell>
              <StyledTableCell align="center">
                <strong>Data do Resultado :</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {moment(date_resultado).format('DD-MM-YYYY')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell align="right"> &nbsp;</TableCell>
            <TableCell align="right"> </TableCell>
            <TableCell align="right"> </TableCell>
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell>
                {' '}
                <strong>Parametros </strong>
              </TableCell>
              <TableCell align="center">
                <strong>Unidade</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Quantidade</strong>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableCell align="left">Oxigênio dissolvido</TableCell>
            <TableCell align="center">(mg/l)</TableCell>
            <TableCell align="center">{dissolved_oxygen}</TableCell>
          </TableBody>

          <TableBody>
            <TableCell align="left">Demanda Bioquímica de Oxigênio (DBQ)</TableCell>
            <TableCell align="center">(mg/l)</TableCell>
            <TableCell align="center">{dissolved_oxygen_demand_BOD_ppm}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Nitrato</TableCell>
            <TableCell align="center">(mg/l)</TableCell>
            <TableCell align="center">{nitrate_ppm}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Fosfato</TableCell>
            <TableCell align="center">--</TableCell>
            <TableCell align="center">{phosphate_ppm}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Bactérias coliformes fecais</TableCell>
            <TableCell align="center">--</TableCell>
            <TableCell align="center">{fecal_coliform}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">
              Contagem de Enterococcos fecais- Filtração por Membrana
            </TableCell>
            <TableCell align="center">--</TableCell>
            <TableCell align="center">{Counting_enterococcos}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">
              Contagem de Escherichina coli- Filtração por Membrana
            </TableCell>
            <TableCell align="center">--</TableCell>
            <TableCell align="center">{Counting_escherichina}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Ph</TableCell>
            <TableCell align="center">--</TableCell>
            <TableCell align="center">{ph}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Salinidade</TableCell>
            <TableCell align="center">(g/kg)</TableCell>
            <TableCell align="center">{salinity_ppt}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Condutividade</TableCell>
            <TableCell align="center">(uS/cm)</TableCell>
            <TableCell align="center">{conductivity}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Temperatura</TableCell>
            <TableCell align="center">(°C)</TableCell>
            <TableCell align="center">{temperature}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Turbidez</TableCell>
            <TableCell align="center">--</TableCell>
            <TableCell align="center">{turbidity_jtu}</TableCell>
          </TableBody>
        </Table>
      </TableContainer>
    </CModal>
  )
}
VerMaisResultadoDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
  children: PropTypes.node,
}

export default VerMaisResultadoDialog
