import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ConcelhoService from '../../service/Concelho'

export const retrieveConcelhos = createAsyncThunk('concelho/retrieve', async () => {
  const response = await ConcelhoService.retrieve()
  return response.data
})

export const updateConcelho = createAsyncThunk('concelho/update', async ({ data, id }) => {
  const response = await ConcelhoService.update(data, id)
  return response.data
})

const initialState = []

const concelhoSlice = createSlice({
  name: 'Concelho',
  initialState,
  extraReducers: {
    [retrieveConcelhos.fulfilled]: (state, action) => [...action.payload],
    [updateConcelho.fulfilled]: (state, action) => {
      const index = state.findIndex((item) => item.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
  },
})

export default concelhoSlice.reducer
