import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import TableWrapper from 'src/components/TableWrapper'
import ReactDatatable from '@ashvin27/react-datatable'
import { deleteFichaLimpeza, retrieveFichaLimpeza } from 'src/store/reducers/fichaLimpezaSlice'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'
import SwalAlert from 'src/utils/SwalAlert'
import GalleryImage from 'src/components/ImageGallery'
import ModalImage from 'src/components/Modal/ImageModal'
import { ADMIN, SUPER_ADMIN } from 'src/utils/permission'
import VerMaisFichaLimpeza from './VerMaisFichaLimpeza'
import EditFichaLimpezaDialog from './EditFichaLimpezaDialog'
import AddFichaLimpezaDialog from './AddFichaLimpezaDialog'
import { columns, config } from './tableConf'

function FichaLimpeza() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const fichaLimpezas = useSelector((state) => state.fichaLimpezas)
  const [currentItem, setCurrentItem] = useState(null)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')
  const [data, setData] = useState([])
  const [images, setImages] = useState(null)
  const [imageShow, setShowImage] = useState(false)
  const [role, setRole] = useState(null)

  const destroy = (record, index) => {
    SwalAlert.warning().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteFichaLimpeza(record.id))
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success')
      }
    })
  }
  const changeItem = (record, index) => {
    setAction('edit')
    setCurrentItem(record)
    setVisible(true)
  }

  const see = (record, index) => {
    setAction('')
    setCurrentItem(record)
    setVisible(true)
  }

  const showImage = (record, index) => {
    setImages(record.cleaning_picture)
    setShowImage(true)
  }

  useEffect(() => {
    dispatch(retrieveFichaLimpeza())
      .unwrap()
      .then(() => setLoading(false))
  }, [dispatch])

  useEffect(() => {
    if (user) {
      let datas = fichaLimpezas

      if (user.role.name !== SUPER_ADMIN) {
        datas = fichaLimpezas.filter((ficha) => ficha.gruposand.id === user.gruposand.id)
      }
      const data = datas.map((item, index) => ({
        index: index + 1,
        id: item.id,
        cleaning_date: moment(item.cleaning_date).format('DD-MM-YYYY'),
        praia: item.praia.name_praia,
        grupo: item.gruposand.name_grupo,
        type: item.type,
        cleaning_picture: item.cleaning_picture,
        extension_cleaned: item.extension_cleaned,
        number_of_collected_garbage: item.number_of_collected_garbage,
        Estimated_total_weight_collected: item.Estimated_total_weight_collected,
        time_spent_cleaning: item.time_spent_cleaning,
        note: item.note,
        paper_bag: item.paper_bag,
        plastic_bag: item.plastic_bag,
        balloons: item.balloons,
        plastic_bottles: item.plastic_bottles,
        glass_bottles: item.glass_bottles,
        beverage_can: item.beverage_can,
        bottle_caps: item.bottle_caps,
        clothing: item.clothing,
        cutlery: item.cutlery,
        food_packaging: item.food_packaging,
        straw: item.straw,
        toy: item.toy,
        buoys: item.buoys,
        plastic_tarpaulin: item.plastic_tarpaulin,
        Nylon_ropes: item.Nylon_ropes,
        Fishing_nets: item.Fishing_nets,
        fishing_lines: item.fishing_lines,
        fishing_bait: item.fishing_bait,
        Bait_packaging: item.Bait_packaging,
        wooden_pallet: item.wooden_pallet,
        lubricating_oil: item.lubricating_oil,
        scotch_tapes: item.scotch_tapes,
        Traps: item.Traps,
        crates: item.crates,
        package_of_cleaning_products: item.package_of_cleaning_products,
        Cigarette: item.Cigarette,
        lighters: item.lighters,
        cigar_butts: item.cigar_butts,
        Tobacco_Packaging: item.Tobacco_Packaging,
        Home_appliances: item.Home_appliances,
        battery: item.battery,
        Construction_material: item.Construction_material,
        Car_Parts: item.Car_Parts,
        fuel_Barrel: item.fuel_Barrel,
        Tires: item.Tires,
        condoms: item.condoms,
        diapers: item.diapers,
        syringes: item.syringes,
        pads: item.pads,
      }))
      setData(data)
      setRole(user.role.name)
    }
  }, [fichaLimpezas, user])

  return (
    <CContainer lg>
      <TableWrapper title="Ficha de Limpeza">
        <CRow className="mb-4 mt-4">
          {ADMIN.includes(role) && (
            <CCol>
              <CButton
                className="main-button"
                onClick={() => {
                  setAction('add')
                  setVisible(true)
                }}
              >
                Adicionar
              </CButton>
            </CCol>
          )}
        </CRow>
        <div className="table-responsive">
          <ReactDatatable
            config={config(user && role)}
            records={data}
            loading={loading}
            columns={columns(destroy, changeItem, showImage, see, role)}
          />
        </div>
      </TableWrapper>
      {action === 'add' ? (
        <AddFichaLimpezaDialog visible={visible} setVisible={setVisible} />
      ) : action === 'edit' ? (
        <EditFichaLimpezaDialog visible={visible} setVisible={setVisible} item={currentItem} />
      ) : (
        <VerMaisFichaLimpeza visible={visible} setVisible={setVisible} item={currentItem} />
      )}

      <ModalImage visible={imageShow} setVisible={setShowImage}>
        <GalleryImage items={images} />
      </ModalImage>
    </CContainer>
  )
}
export default FichaLimpeza
