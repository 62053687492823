import React, { Component } from 'react'
import { CContainer } from '@coreui/react'
import i18n from 'i18next'
import BreadCrumb from './BreadCrumb'
import AboutWrapper from './AboutWrapper'
import { pt } from './i18n/pt'
import { en } from './i18n/en'

/* i18n.addResourceBundle('pt', 'about', pt)
i18n.addResourceBundle('en', 'about', en) */

function AboutUs() {
  return (
    <>
      <div style={{ height: '77px' }} />
      <BreadCrumb />
      <CContainer lg>
        <AboutWrapper />
      </CContainer>
    </>
  )
}

export default AboutUs
