import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilSave, cilXCircle } from '@coreui/icons'
import { SelectPicker, DatePicker } from 'rsuite'
import { retrieveGruposand } from 'src/store/reducers/gruposandSlice'
import { retrievePraias } from 'src/store/reducers/praiaSlice'
import SwalAlert from 'src/utils/SwalAlert'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { createContagemAtividade } from 'src/store/reducers/contagemAtividadeSlice'
import { ADMIN_PRAIA, SUPER_ADMIN } from 'src/utils/permission'
import { addSchema } from './validation'

function AddContagemAtividadeDialog({ visible, setVisible }) {
  const dispatch = useDispatch()
  const [grupoOptions, setGrupoOption] = useState([])
  const [praiaOptions, setPraiaOption] = useState([])
  const gruposands = useSelector((state) => state.gruposands)
  const praias = useSelector((state) => state.praias)
  const { user } = useSelector((state) => state.account)

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addSchema) })

  const saveContagemAtividade = async (data) => {
    const soma =
      parseInt(data.sea_bathing) +
      parseInt(data.sunbathing) +
      parseInt(data.walking_and_or_running) +
      parseInt(data.picnicking) +
      parseInt(data.fishing) +
      parseInt(data.playing) +
      parseInt(data.windsurfing_kitsurfing) +
      parseInt(data.horse_riding) +
      parseInt(data.sitting) +
      parseInt(data.other)

    const values = {
      ...data,
      praia: parseInt(data.praia, 10),
      gruposand: parseInt(data.gruposand, 10),
      date: new Date(data.date),
      total: soma,
    }
    if (ADMIN_PRAIA.includes(user.role.name)) {
      values.gruposand = user.gruposand.id
    }
    dispatch(createContagemAtividade({ ...data, ...values }))
      .unwrap()
      .then(() => {
        SwalAlert.success()
        reset({
          date: '',
          praia: '',
          gruposand: '',
          time: '',
          sea_bathing: '0',
          sunbathing: '0',
          walking_and_or_running: '0',
          picnicking: '0',
          fishing: '0',
          playing: '0',
          windsurfing_kitsurfing: '0',
          horse_riding: '0',
          sitting: '0',
          other: '0',
          total: '0',
        })
      })
      .catch((err) => {
        SwalAlert.error()
      })
  }

  useEffect(() => {
    const options = gruposands.map((item) => ({
      value: item.id,
      label: item.name_grupo,
    }))
    setGrupoOption(options)
  }, [gruposands])

  useEffect(() => {
    let filtered = praias;

    if (user) {
      if (!SUPER_ADMIN.includes(user.role.name)) {
        filtered = praias.filter(({ gruposand }) => gruposand && user.gruposand.id === gruposand.id)
      }
    }
    const options = filtered.map((item) => ({
      value: item.id,
      label: item.name_praia,
    }))
    setPraiaOption(options)
  }, [praias, user])

  useEffect(() => {
    dispatch(retrieveGruposand())
    dispatch(retrievePraias())
  }, [dispatch])

  return (
    <CModal
      backdrop="static"
      alignment="center"
      size="lg"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Adicionar Contagem Atividades Humanas</CModalTitle>
      </CModalHeader>
      <CForm onSubmit={handleSubmit(saveContagemAtividade)}>
        <CModalBody>
          <CRow>
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Praia <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="praia"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker {...field} data={praiaOptions} style={{ width: '100%' }} />
                  )}
                />
                <small className="text-danger">{errors.praia?.message}</small>
              </div>
            </CCol>
            {SUPER_ADMIN.includes(user.role.name) && (
              <CCol lg={6}>
                <div className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormLabel htmlFor="item">
                        Grupo <strong className="text-danger">*</strong>
                      </CFormLabel>
                    </CCol>
                  </CRow>
                  <Controller
                    name="gruposand"
                    control={control}
                    render={({ field }) => (
                      <SelectPicker {...field} data={grupoOptions} style={{ width: '100%' }} />
                    )}
                  />
                  <small className="text-danger">{errors.gruposand?.message}</small>
                </div>
              </CCol>
            )}
            <CCol lg={6}>
              <div className="mb-3">
                <CRow>
                  <CCol>
                    <CFormLabel htmlFor="item">
                      Data <strong className="text-danger">*</strong>
                    </CFormLabel>
                  </CCol>
                </CRow>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => <DatePicker className="w-100" {...field} />}
                />
                <small className="text-danger">{errors.date?.message}</small>
              </div>
            </CCol>
            <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">
                  Tempo <strong className="text-danger">*</strong>
                </CFormLabel>

                <CFormInput step={2} type="time" id="item" {...register('time')} />
                <small className="text-danger">{errors.time?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Banho de mar</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="sea_bathing"
                  placeholder="0"
                  defaultValue={0}
                  {...register('sea_bathing')}
                />
                <small className="text-danger">{errors.sea_bathing?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Tomar sol</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="sunbathing"
                  placeholder="0"
                  defaultValue={0}
                  {...register('sunbathing')}
                />
                <small className="text-danger">{errors.sunbathing?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Caminhando e ou correndo</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="walking_and_or_running"
                  placeholder="0"
                  defaultValue={0}
                  {...register('walking_and_or_running')}
                />
                <small className="text-danger">{errors.walking_and_or_running?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Piqueniques</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="picnicking"
                  placeholder="0"
                  defaultValue={0}
                  {...register('picnicking')}
                />
                <small className="text-danger">{errors.picnicking?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Pescaria</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="fishing"
                  placeholder="0"
                  defaultValue={0}
                  {...register('fishing')}
                />
                <small className="text-danger">{errors.fishing?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Jogando</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="playing"
                  placeholder="0"
                  defaultValue={0}
                  {...register('playing')}
                />
                <small className="text-danger">{errors.playing?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Surfando</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="windsurfing_kitsurfing"
                  placeholder="0"
                  defaultValue={0}
                  {...register('windsurfing_kitsurfing')}
                />
                <small className="text-danger">{errors.windsurfing_kitsurfing?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Passeios a cavalo</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="horse_riding"
                  placeholder="0"
                  defaultValue={0}
                  {...register('horse_riding')}
                />
                <small className="text-danger">{errors.horse_riding?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Sentado</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="sitting"
                  placeholder="0"
                  defaultValue={0}
                  {...register('sitting')}
                />
                <small className="text-danger">{errors.sitting?.message}</small>
              </div>
            </CCol>
            <CCol lg={4}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Outros</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="other"
                  placeholder="0"
                  defaultValue={0}
                  {...register('other')}
                />
                <small className="text-danger">{errors.other?.message}</small>
              </div>
            </CCol>
            {/* <CCol lg={6}>
              <div className="mb-3">
                <CFormLabel htmlFor="item">Total</CFormLabel>

                <CFormInput
                  min={0}
                  type="number"
                  id="total"
                  placeholder="0"
                  defaultValue={0}
                  {...register("total")}
                />
                <small className="text-danger">{errors.total?.message}</small>
              </div>
            </CCol> */}
          </CRow>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            <CIcon icon={cilXCircle} className="me-2" />
            Cancelar
          </CButton>

          <CButton type="submit" className="main-button" color="primary">
            <CIcon icon={cilSave} className="me-2" />
            Salvar
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}
AddContagemAtividadeDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default AddContagemAtividadeDialog
