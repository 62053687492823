import axiosService from '../../axios'

class NinhoTartarugaService {
  create(data) {
    return axiosService.post('/ninhotartarugas', data)
  }

  retrieve() {
    return axiosService.get('/ninhotartarugas')
  }

  update(data, id) {
    return axiosService.put(`/ninhotartarugas/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/ninhotartarugas/${id}`)
  }
}

export default new NinhoTartarugaService()
