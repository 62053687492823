import React, { useState } from 'react'
import { Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import swimmer from '../../../assets/sea.png'

const markerIcon = (icon) =>
  new L.icon({
    iconUrl: icon,
    iconSize: [32, 32],
    iconAnchor: [15, 30],
  })

function RenderPraia({ renderedPraia, setVisible, setCurrentPraia }) {
  const [showPopup, setShow] = useState(false)
  return renderedPraia.map((praia) => (
    <Marker
      key={praia.id}
      icon={markerIcon(swimmer)}
      position={[praia.coordinates.lat, praia.coordinates.lng]}
      eventHandlers={{
        click: () => {
          if (setVisible && setCurrentPraia) {
            setVisible(true)
            setCurrentPraia(praia)
          } else {
            setShow(true)
          }
        },
      }}
    >
      {showPopup && <Popup>{praia.name_praia}</Popup>}
    </Marker>
  ))
}

export default RenderPraia
