import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import TableWrapper from 'src/components/TableWrapper'
import ReactDatatable from '@ashvin27/react-datatable'
import {
  deleteNinhoTartaruga,
  retrieveNinhoTartaruga,
} from 'src/store/reducers/ninhoTartarugaSlice'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'
import SwalAlert from 'src/utils/SwalAlert'
import GalleryImage from 'src/components/ImageGallery'
import ModalImage from 'src/components/Modal/ImageModal'
import { ADMIN, SUPER_ADMIN } from 'src/utils/permission'
import SeeNinhoTartarugaDialog from './SeeNinhoTartarugaDialog'
import EditNinhoTartarugaDialog from './EditNinhoTartarugaDialog'
import AddNinhoTartarugaDialog from './AddNinhoTartarugaDialog'
import { columns, config } from './tableConf'

function NinhoTartaruga() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.account)
  const ninhoTartarugas = useSelector((state) => state.ninhoTartarugas)
  const [currentItem, setCurrentItem] = useState(null)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')
  const [data, setData] = useState([])
  const [images, setImages] = useState(null)
  const [imageShowS, setShowImageS] = useState(false)
  const [imageShowC, setShowImageC] = useState(false)
  const [imageShowT, setShowImageT] = useState(false)
  const [role, setRole] = useState(null)

  const destroy = (record, index) => {
    SwalAlert.warning().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteNinhoTartaruga(record.id))
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success')
      }
    })
  }

  const changeItem = (record, index) => {
    setAction('edit')
    setCurrentItem(record)
    setVisible(true)
  }

  const showImageS = (record, index) => {
    setImages(record.image_sand)
    setShowImageS(true)
  }
  const showImageC = (record, index) => {
    setImages(record.image_crab)
    setShowImageC(true)
  }
  const showImageT = (record, index) => {
    setImages(record.image_tartaruga)
    setShowImageT(true)
  }

  const see = (record, index) => {
    setAction('')
    setCurrentItem(record)
    setVisible(true)
  }

  useEffect(() => {
    dispatch(retrieveNinhoTartaruga())
      .unwrap()
      .then(() => setLoading(false))
      .catch()
  }, [dispatch])

  useEffect(() => {
    if (user) {
      let datas = ninhoTartarugas

      if (user.role.name !== SUPER_ADMIN) {
        datas = ninhoTartarugas.filter((ninho) => ninho.gruposand.id === user.gruposand.id)
      }

      const data = datas.map((item, index) => ({
        index: index + 1,
        id: item.id,
        date: moment(item.date).format('DD-MM-YYYY'),
        praia: item.praia.name_praia,
        grupo: item.gruposand.name_grupo,
        distance_edge: item.distance_of_edge_of_the_vegetation,
        sand_observations: item.sand_observations,
        image_sand: item.Img_Sand,
        number_crab: item.number_of_crab_holes_per_square_metre,
        observation_crab: item.observation_of_crab_holes,
        image_crab: item.Img_Crab,
        date_emergence: moment(item.date_of_emergence_of_hatchlings).format('DD-MM-YYYY'),
        tartaruga: item.type_tartarugas && item.type_tartarugas.Especie_tartaruga,
        comments: item.comments,
        image_tartaruga: item.Img_Nest,
        maciez: item.maciez,
      }))
      setData(data)
      setRole(user.role.name)
    }
  }, [ninhoTartarugas, user])

  return (
    <CContainer fluid>
      <TableWrapper title="Lista Ninho Tartarugas">
        {ADMIN.includes(role) && (
          <CRow className="mb-4 ">
            <CCol>
              <CButton
                className="main-button"
                onClick={() => {
                  setAction('add')
                  setVisible(true)
                }}
              >
                Adicionar
              </CButton>
            </CCol>
          </CRow>
        )}

        <div className="table-responsive">
          <ReactDatatable
            config={config(user && user.role.name)}
            records={data}
            loading={loading}
            columns={columns(destroy, changeItem, showImageS, showImageC, showImageT, see, role)}
          />
        </div>
      </TableWrapper>
      {action === 'add' ? (
        <AddNinhoTartarugaDialog visible={visible} setVisible={setVisible} />
      ) : action === 'edit' ? (
        <EditNinhoTartarugaDialog visible={visible} setVisible={setVisible} item={currentItem} />
      ) : (
        <SeeNinhoTartarugaDialog visible={visible} setVisible={setVisible} item={currentItem} />
      )}

      <ModalImage visible={imageShowS} setVisible={setShowImageS}>
        <GalleryImage items={images} />
      </ModalImage>
      <ModalImage visible={imageShowC} setVisible={setShowImageC}>
        <GalleryImage items={images} />
      </ModalImage>
      <ModalImage visible={imageShowT} setVisible={setShowImageT}>
        <GalleryImage items={images} />
      </ModalImage>
    </CContainer>
  )
}
export default NinhoTartaruga
