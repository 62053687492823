import * as Yup from 'yup'

export const schema = Yup.object({
  amostraareia: Yup.string().required('Campo obrigatório'),
  collect_date_sand: Yup.string().required('Campo obrigatório'),
  sand_temperature: Yup.number().min(0),
  dissolved_oxygen_sand: Yup.number().min(0),
  dissolved_oxygen_demand_BOD_sand: Yup.number().min(0),
  nitrate_sand: Yup.number().min(0),
  phosphate_sand: Yup.number().min(0),
  yeast: Yup.number().min(0),
  filamentous_fungi: Yup.number().min(0),
  protozoa: Yup.number().min(0),
  fecal_coliform_sand: Yup.number().min(0),
  Counting_enterococcos_sand: Yup.number().min(0),
  Counting_escherichina_sand: Yup.number().min(0),
  salinity_sand: Yup.number().min(0),
  conductivity_sand: Yup.number().min(0),
  turbidity_sand: Yup.number().min(0),
  ph: Yup.number().min(0).max(14, 'Números de 0 a 14'),
})
