import axiosService from '../../axios'

class ContagemAtividadeService {
  create(data) {
    return axiosService.post('/contagematividades', data)
  }

  retrieve() {
    return axiosService.get('/contagematividades')
  }

  update(data, id) {
    return axiosService.put(`/contagematividades/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/contagematividades/${id}`)
  }
}

export default new ContagemAtividadeService()
