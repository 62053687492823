import axiosService from '../../axios'

class AtividadeHumanaService {
  create(data) {
    return axiosService.post('/atividadeshumanas', data)
  }

  retrieve() {
    return axiosService.get('/atividadeshumanas')
  }

  update(data, id) {
    return axiosService.put(`/atividadeshumanas/${id}`, data)
  }

  delete(id) {
    return axiosService.delete(`/atividadeshumanas/${id}`)
  }
}

export default new AtividadeHumanaService()
