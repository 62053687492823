import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
}

/* const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
} */

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    showSidebar: (state, action) => {
      state.sidebarShow = action.payload
    },
  },
})

export const { showSidebar } = sidebarSlice.actions

export default sidebarSlice.reducer
