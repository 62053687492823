import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import UserService from '../../service/User'

export const createUser = createAsyncThunk('user/create', async (data) => {
  const response = await UserService.create(data)
  return response.data
})
export const retrieveUser = createAsyncThunk('user/retrieve', async () => {
  const response = await UserService.retrieve()
  return response.data
})

export const updateUser = createAsyncThunk('user/update', async ({ data, id }) => {
  const response = await UserService.update(data, id)
  return response.data
})
export const deleteUser = createAsyncThunk('user/delete', async (id) => {
  const response = await UserService.delete(id)
  return response.data
})

const initialState = []

const UserSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: {
    [createUser.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [retrieveUser.fulfilled]: (state, action) => [...action.payload],
    [updateUser.fulfilled]: (state, action) => {
      const index = state.findIndex((user) => user.id === action.payload.id)
      state[index] = {
        ...state[index],
        ...action.payload,
      }
    },
    [deleteUser.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id)
      state.splice(index, 1)
    },
  },
})

export default UserSlice.reducer
