import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteItem } from 'src/store/reducers/itemSlice'

import Swal from 'sweetalert2'
import ReactDatatable from '@ashvin27/react-datatable'
import { deleteDetrito, retrieveDetrito } from 'src/store/reducers/detritoSlice'
import TableWrapper from '../../../components/TableWrapper'
import { columns, config } from './tableConf'
import AddDetritoDialog from './AddDetritoDialog'
import EditDetritoDialog from './EditDetritoDialog'

function Detrito() {
  const dispatch = useDispatch()
  const detritos = useSelector((state) => state.detritos)
  const [data, setData] = useState([])
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState('')
  const [currentItem, setCurrentItem] = useState({})
  const [images, setImages] = useState(null)
  const [ImageShow, setShowImage] = useState(true)

  const destroy = (record, index) => {
    Swal.fire({
      title: 'Eliminar Registo?',
      text: 'Ação não pode ser revertido!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF7F47',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar!',
      cancelButtonText: 'Cancelar',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteDetrito(record.id))
        Swal.fire('Eliminado!', 'Registo foi eliminado.', 'success')
      }
    })
  }

  const changeDetrito = (record, index) => {
    setAction('')
    setCurrentItem(record)
    setVisible(true)
  }

  const showImage = (record, index) => {
    setImages(record.image)
  }

  useEffect(() => {
    const data = detritos.map((item, index) => ({
      id: item.id,
      index: index + 1,
      name: item.name_detrito,
    }))
    setData(data)
  }, [detritos])

  useEffect(() => {
    dispatch(retrieveDetrito())
  }, [dispatch])

  return (
    <CContainer lg>
      <TableWrapper title="Lista Detritos">
        <CRow className="mb-4 ">
          <CCol>
            <CButton
              className="main-button"
              onClick={() => {
                setAction('add')
                setVisible(true)
              }}
            >
              Adicionar
            </CButton>
          </CCol>
        </CRow>
        <ReactDatatable config={config} records={data} columns={columns(destroy, changeDetrito)} />
      </TableWrapper>
      {action === 'add' ? (
        <AddDetritoDialog visible={visible} setVisible={setVisible} />
      ) : (
        <EditDetritoDialog visible={visible} setVisible={setVisible} item={currentItem} />
      )}
    </CContainer>
  )
}

export default Detrito
